import { Map } from 'immutable'
import * as constants from './constants'
import { localStorageGetItem, localStorageSetItem } from '../common/localstorageHelpers'

export function getInitialLoalizationState() {
    const code = determineCurrentLocalization()
    const locale = constants.LOCALES[code]
    const enMessage = Object.assign({}, constants.LOCALES['en'].messages)
    const messages = locale ? Object.assign(enMessage, locale.messages) : enMessage
    return new Map({
        localeCode: code,
        languageMessages: messages
    })
}

export function determineCurrentLocalization() {
    // code from local storage or languge from the browser, fallback to english
    let code = getLocalizationFromLocalStorage() || navigator.language || navigator.browserLanguage

    if (!code) {
        code = 'en'
    }

    const shortCode = code.substr(0, 2)
    if (shortCode === 'no') {
        code = 'nb' // reset no to nb to make the intl data load correctlty...
    }
    return Object.keys(constants.LOCALES).indexOf(code) !== -1 ? code : 'en'
}

export function setLocalizationInLocalStorage(code) {
    localStorageSetItem(constants.LOCAL_STORAGE_KEY, code)
}

export function getLocalizationFromLocalStorage() {
    return localStorageGetItem(constants.LOCAL_STORAGE_KEY)
}
