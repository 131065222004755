import React from 'react'
import { FILTER_BUTTON_TEXT } from '../../sales/sales/constants'
import { injectIntl } from 'react-intl'
import { List, Textfield } from '@frontrunners/ui-components'
import { DROPDOWNS } from '../../dashboard/translations'
import { formatItem } from '../../sales/sales/containers/salesFilterContainer'
import { ITEM_FILTER_VISIBILITY_THRESHOLD } from '../constants'
import './filterMobile.scss'

const FilterMobile = ({ options, selectedOptions, title, onChange, mode, intl }) => {
    const items = options.map(formatItem)
    if (items.length === 1) return null
    const { formatMessage } = intl
    const showClear = !!(selectedOptions && selectedOptions.length) && mode != 'single'
    const [filterText, setFilterText] = React.useState('')

    const filterListItems = (item) => filterText ? item.text && item.text.toLowerCase().includes(filterText.toLowerCase()) : true

    return (
        <div className="filter-mobile-item">
            <div className="filter-mobile-item__header flex-container-space-between">
                <div>{title}</div>
                {showClear && <div className="filter-mobile-item__clear" onClick={() => onChange([])} >{formatMessage(FILTER_BUTTON_TEXT['clear'])}</div>}
            </div>
            <div>
                <Textfield
                    placeholder={formatMessage(DROPDOWNS['filter_placeholder'])}
                    onChange={(_, value) => setFilterText(value)}
                    visible={options.length >= ITEM_FILTER_VISIBILITY_THRESHOLD}
                    />
                <List
                    items={items.filter(filterListItems)}
                    defaultSelectedItems={selectedOptions}
                    onChange={(_, items) => onChange(items)}
                    multiSelect={!(mode === 'single')} />
            </div>
        </div>
    )
}

export default injectIntl(FilterMobile)
