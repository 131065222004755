import React, { useContext, useEffect, useState } from 'react'
import { Badge, Button, ButtonV2, Dropdown, Modal, Table, Textfield, Icon } from '@frontrunners/ui-components'
import { getCreateEpayApiKeyMutation, getDeleteEpayApiKeyMutation, getEpayApiKeysQuery } from './epayQueries'
import { useQueryClient } from 'react-query'
import { ApplicationContext } from '../../common/reactContext'
import { useIntl } from 'react-intl'
import Spinner from '../../common/components/spinner'
import { API_KEY_MANAGEMENT as T } from './intlMessageKeys.js'
import { Buffer } from 'buffer'
import { getAccountsData } from '../../merchant/helpers/getAccountListData'
import './apiKeyManagement.scss'

export const ApiKeyManagement = () => {
    const { formatMessage: fm } = useIntl()
    const { config, activeMerchant } = useContext(ApplicationContext)
    const [apiKeyDropdownSelectedItem, setApiKeyDropdownSelectedItem] = useState(null)
    const [systemIdList, setSystemIdList] = useState(null)

    const [showCreateApiKeyModal, setShowCreateApiKeyModal] = useState(false)
    const [apiKeyToDelete, setApiKeyToDelete] = useState(null)

    const accounts = getAccountsData(activeMerchant)
    const apiQueryIsEnabled = (!!activeMerchant?.id && !!apiKeyDropdownSelectedItem?.systemId)

    useEffect(() => {
        const systemIds = accounts?.filter((system) => system.channel === 'zero').map(system => (system.id)) || []
        setSystemIdList(systemIds)
        const dropdownItem = systemIdToDropdownItem(systemIds[0])
        setApiKeyDropdownSelectedItem(dropdownItem)
    }, [activeMerchant])

    const {
        isFetching,
        isRefetching,
        data: apiKeyes
    } = getEpayApiKeysQuery(config.apiHost, activeMerchant, apiKeyDropdownSelectedItem?.systemId, {
        enabled: apiQueryIsEnabled
    })

    const isLoading = isFetching || isRefetching || !apiQueryIsEnabled
    const hasApiKeys = !isLoading && apiKeyes?.length > 0

    return <div className="api-keys">
        <h2 className="settings-paper-header">
            {fm(T.api_keys_header)}
        </h2>
        <p>{fm(T.api_keys_description)} {fm(T.api_keys_description_2)}</p>
        {
            systemIdList && systemIdList.length > 1 &&
            <>
                {fm(T.select_epay)}
                <Dropdown
                    key={`online-api-keys-dropdown-${apiKeyDropdownSelectedItem.systemId}`}
                    multiSelect={false}
                    items={systemIdList.map(systemIdToDropdownItem)}
                    minSelectedItems={1}
                    defaultSelectedItems={[apiKeyDropdownSelectedItem]}
                    onChange={(item) => setApiKeyDropdownSelectedItem(item)}
                />
            </>
        }
        {isLoading && <Spinner waiting={true} />}

        {!isLoading && !hasApiKeys && <p>{fm(T.api_keys_empty)}</p>}
        {
            hasApiKeys &&
            <>
                <p>{fm(T.api_keys_dropdown_description)} <strong>{apiKeyDropdownSelectedItem.systemId}</strong></p>
                <Table
                    key={apiKeyDropdownSelectedItem.systemId}
                    className="blank"
                    columns={[
                        {
                            header: fm(T.api_keys_table_header_identifier),
                            accessor: 'description',
                            className: 'identifier'
                        },
                        {
                            header: fm(T.api_keys_table_header_public_key),
                            accessor: 'accesstoken',
                            className: 'access-token'
                        },
                        {
                            header: '',
                            accessor: 'removeButton',
                            className: 'remove-button'
                        }
                    ]}
                    data={apiKeyes.map(key => {
                        key['removeButton'] = <>
                            <a
                                role="button"
                                onClick={() => setApiKeyToDelete(key)}
                            >
                                <Icon iconName="closeThin"/>
                                <div className="remove-text">{fm(T.api_keys_table_row_delete_button)}</div>
                            </a>
                        </>
                        return key
                    })}
                />
            </>
        }
        <ButtonV2
            buttonStyle="default"
            label={fm(T.api_keys_generate_new)}
            name="create-api-key"
            onClick={() => {
                setShowCreateApiKeyModal(true)
            }}
        />

        {
            showCreateApiKeyModal &&
            <CreateApiKeyModal
                activeApiKeyId={apiKeyDropdownSelectedItem.systemId}
                onClose={() => setShowCreateApiKeyModal(false)}
            />
        }

        {
            apiKeyToDelete &&
            <DeleteApiKeyModal
                activeApiKeyId={apiKeyDropdownSelectedItem.systemId}
                apiKey={apiKeyToDelete}
                onClose={() => setApiKeyToDelete(null)}
            />
        }
    </div>
}

export const CreateApiKeyModal = ({ activeApiKeyId, onClose: closeModal }) => {
    const { formatMessage: fm } = useIntl()
    const { config, activeMerchant, loggedInUser } = useContext(ApplicationContext)
    const queryClient = useQueryClient()
    const [newApiKeyName, setNewApiKeyName] = useState('')
    const { data: newApiKey, isLoading, mutate: createEpayApiKey } = getCreateEpayApiKeyMutation(queryClient, config.apiHost, activeMerchant, activeApiKeyId, loggedInUser)

    return <>
        <Modal
            className="create-api-key-modal"
            onClose={() => closeModal()}
        >
            <h3>{fm(T.api_keys_create_modal_title)}</h3>
            <p>{fm(T.api_keys_create_modal_description)}</p>

            {
                !newApiKey && !isLoading &&
                <>
                    <Textfield
                        className="compact"
                        placeholder={fm(T.api_keys_create_modal_key_name_placeholder)}
                        onChange={(_, value) => setNewApiKeyName(value)}
                    />
                    <Button
                        className="create-button no-margin"
                        disabled={!newApiKeyName}
                        label={fm(T.api_keys_generate_new)}
                        onClick={() => createEpayApiKey(newApiKeyName)}
                    />
                </>
            }

            {!newApiKey && isLoading && <div className="spinner-wrapper"><Spinner waiting={true} /></div>}
            {
                newApiKey &&
                <div>
                    <label>IDENTIFIER</label>
                    <p>{newApiKey.apiuser.description}</p>
                    <label>SECRET KEY</label>
                    <p>
                        <Badge className="turquoise">{newApiKey.apiuser.secrettoken}</Badge>
                    </p>
                    <label>ACCESS KEY</label>
                    <p>{newApiKey.apiuser.accesstoken}</p>
                    <label>ENCODED ACCESS KEY</label>
                    <p>{Buffer.from(newApiKey.apiuser.accesstoken).toString('base64')}</p>
                </div>
            }

            {
                newApiKey &&
                <Button
                    label="Close"
                    className="close-button no-margin"
                    onClick={async () => closeModal()}
                />
            }
        </Modal>
    </>
}

export const DeleteApiKeyModal = ({ activeApiKeyId, apiKey, onClose: closeModal }) => {
    const { formatMessage: fm } = useIntl()
    const { config, activeMerchant } = useContext(ApplicationContext)
    const queryClient = useQueryClient()
    const { mutateAsync: deleteApiKey } = getDeleteEpayApiKeyMutation(queryClient, config.apiHost, activeMerchant, activeApiKeyId)

    const { description: apiKeyName } = apiKey

    return <Modal onClose={() => closeModal()} className="delete-api-key-modal">
        <h3>{fm(T.api_keys_delete_modal_title2, { apiKeyName }) + '?'}</h3>
        <p>{fm(T.api_keys_delete_modal_description2)}</p>
        <div className="access-token">
            <Icon iconName="checkmarkRounded" />
            <p>{apiKey.accesstoken}</p>
        </div>

        <div className="buttons" data-testid="buttons">
            <Button
                className="blank"
                label={fm(T.api_keys_delete_modal_cancel_button)}
                onClick={() => {
                    closeModal()
                }}
            />
            <Button
                label={fm(T.api_keys_table_row_delete_button)}
                onClick={async () => {
                    await deleteApiKey(apiKey)
                    closeModal()
                }}
            />
        </div>
    </Modal>
}

const systemIdToDropdownItem = (systemId) => ({
    id: systemId,
    text: systemId,
    systemId: systemId
})
