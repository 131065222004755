export const NAME = 'online'

export const COLLECTOR_BANK = 'CollectorBank'

// order states
export const ORDER_STATES = {
    AUTHORIZED: 'AUTHORIZED',
    CAPTURED: 'CAPTURED',
    CANCELED: 'CANCELED',
    REFUNDED: 'REFUNDED',
    PARTLY_CAPTURED: 'PARTLY_CAPTURED',
    PARTLY_REFUNDED: 'PARTLY_REFUNDED',
    PARTLY_CAPTURED_PARTLY_REFUNDED: 'PARTLY_CAPTURED_PARTLY_REFUNDED',
    REJECTED: 'REJECTED',
    ZERO_AUTH: 'ZERO_AUTH'
}

export const PAYMENT_ROUTER_STATES = {
    AUTHORIZED: 'Authorized',
    CAPTURED: 'Captured',
    CANCELED: 'Canceled',
    REFUNDED: 'Refunded',
    REJECTED: 'Declined'
}

// operations that can appear on a payment from the payment router
export const OPERATIONS = {
    AUTHORIZE_CARD: 'Authorize card',
    AUTHORIZE_TOKEN: 'Authorize token',
    AUTHORIZE_INVOICE: 'Authorize invoice',
    CAPTURE: 'Capture',
    REFUND: 'Refund',
    CANCEL: 'Cancel'
}

export const AUTHORIZATION_OPERATIONS = [OPERATIONS.AUTHORIZE_CARD, OPERATIONS.AUTHORIZE_TOKEN, OPERATIONS.AUTHORIZE_INVOICE]

// operations that we can do on a payment from the payment router
export const MODAL_OPERATIONS = {
    CAPTURE: 'Capture',
    REFUND: 'Refund'
}
export const PAYMENT_METHODS = {
    CARD: 'CARD',
    INVOICE: 'INVOICE',
    WALLET: 'WALLET',
    OTHER: 'OTHER'
}

export const THREED_SECURE = [2, 5]

export const PROCESSOR = {
    ELIXIR: 'elixir',
    EPAY: 'epay'
}

export const OPERATION_DESCRIPTIONS = {
    order_event_authorize: {
        id: 'order-event-action-authorize',
        defaultMessage: 'Authorization at ',
        description: 'Authorization at'
    },
    order_event_capture: {
        id: 'order-event-action-capture',
        defaultMessage: 'Capture at ',
        description: 'Capture at'
    },
    order_event_refund: {
        id: 'order-event-action-refund',
        defaultMessage: 'Refund at ',
        description: 'Refund at'
    },
    order_event_cancel: {
        id: 'order-event-action-cancel',
        defaultMessage: 'Cancel at ',
        description: 'Cancel at'
    },
    order_event_reject: {
        id: 'order-event-action-reject',
        defaultMessage: 'Reject at ',
        description: 'Reject at'
    }
}
