import { defineMessages } from 'react-intl'

export const NAME = 'errors'
export const ERROR = defineMessages({
    title: {
        id: 'general-error-title',
        defaultMessage: 'Opps! We have some problem now.',
        description: 'general error title'
    },
    message: {
        id: 'general-error-message',
        defaultMessage: 'Please try again or contact our ',
        description: 'general error message'
    },
    link: {
        id: 'support-email-link',
        defaultMessage: 'support',
        description: 'support email link'
    },
    'try-again': {
        id: 'error-no-merchants-component-try-again-modal-button',
        defaultMessage: 'Try again',
        description: 'Button to refresh view'
    },
    'no-merchants-body':{
        id: 'error-no-merchants-component-log-in-modal-body',
        defaultMessage: 'We can\'t find any merchant data for your user. Please try to log in and out again.',
        description: 'No merchants modal body text'
    },
    'no-merchants-title':{
        id: 'error-no-merchants-component-log-in-modal-title',
        defaultMessage: 'Sorry',
        description: 'No merchants modal header'
    }
})

