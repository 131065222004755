import React from 'react'
import { SALES_DETAILS_TEXTS } from '../../sales/constants'
import { getSaleslocationNameFromMid } from '../../../settlements/helpers'


const SalesAcquiringPOS = ({merchant, payment, formatMessage}) => {
    const mid = payment.source_merchant_id
    const saleslocation = getSaleslocationNameFromMid(merchant.saleslocation_set, mid)
    const terminal_id = payment.terminal_id

    return (
        <div className="col-2 acquiring right">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS['details_point_of_sale_header'])}</div>
            <div className="detail-wrapper">
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['details_merchant_id'])}</div>
                    <div className="element">{mid}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['details_saleslocation'])}</div>
                    <div className="element">{saleslocation}</div>
                </div>
                {terminal_id ?
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_instore_terminal_id'])}</div>
                        <div className="element">{terminal_id}</div>
                    </div> : null}
            </div>
        </div>
    )
}

export default SalesAcquiringPOS
