import React from 'react'
import { SALES_DETAILS_TEXTS } from '../../../sales/constants'
import { getAuthorization } from '../../helpers'
import { PROCESSOR } from '../../constants'
import OnlineEventHistoryOperation from './onlineEventHistoryOperation'
import OnlineEventHistorySettledTransaction from './onlineEventHistorySettledTransaction'

const OnlineEventHistory = ({payment, formatMessage}) => {
    const authorization = getAuthorization(payment.data)
    const orderInformation = authorization.order_information
    return (
        <div className="order-event-history col-2 right">
           <div className={`header ${orderInformation ? 'invoice-with-orderline' : ''}`}>
                <div className="history-header">{formatMessage(SALES_DETAILS_TEXTS['payment_detail_history_header'])}</div>
                <div className="table table-lines">
                <div>
                    {renderEventHistory(payment, formatMessage)}
                </div>
                </div>
            </div>
        </div>
    )
}

const renderEventHistory = (order, formatMessage) => {
    const authorization = getAuthorization(order.data)
    const operations = order.data.operations
    const settled_transactions = addTimeToBatchDate(order.settled_transactions.transactions)
    const eventList = mergeOrdersTransactions(operations, settled_transactions)
    const sortedEventList = sortEventsByDate(eventList).reverse()
    return sortedEventList.map((orderTransaction) => {
        const transactiontype = getProcessor(orderTransaction)
        if(transactiontype === PROCESSOR.ELIXIR) {
            return ['Sale'].indexOf(orderTransaction.type) >= 0 ? <OnlineEventHistorySettledTransaction formatMessage={formatMessage} transaction={orderTransaction}/> : null
        } else {
            return <OnlineEventHistoryOperation order={order} operation={orderTransaction} authorization={authorization} key={orderTransaction.id}/>
        }
    })
}

export const mergeOrdersTransactions = (operations, settled_transactions) => {
    let eventList = []
    Array.prototype.push.apply(eventList, operations)
    Array.prototype.push.apply(eventList, settled_transactions)
    return eventList
}

export const getProcessor = (orderTransaction) => {
    // This is just to determine if it's a elixir transactions or a epay transaction.
    return orderTransaction.source == 'Elixir' ? PROCESSOR.ELIXIR : PROCESSOR.EPAY
}

export const sortEventsByDate = (eventList) => {
    return eventList.sort((a, b) => {
        return new Date(b.timestamp || b.approximate_datetime) - new Date(a.timestamp || a.approximate_datetime)
    })
}

export const addTimeToBatchDate = (transactions) => {
    const time = 'T08:30:00'
    // Since we don't get a 'time' from batch_date we hardcode a time for sorting purposes. (08.30)
    // We don't display the time, but use it as a estimate when we sort the complete list of transactions/orders.
    transactions.map((transaction) => {
        transaction.approximate_datetime = transaction.batch_date + time
    })
    return transactions
}


export default OnlineEventHistory
