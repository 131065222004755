import { useQuery } from 'react-query'

export const getPasswordPolicy = (apiHost) => useQuery(
    'passwordPolicy',
    async () => {
        const url =`${apiHost}/internal/v1/password-policy`
        const response = await fetch(url)
        const result = await response.json()

        return {
            minimum_length: result.MinimumLength,
            require_uppercase: result.RequireUppercase,
            require_lowercase: result.RequireLowercase,
            require_numbers: result.RequireNumbers,
            require_symbols: result.RequireSymbols
        }
    }
)
