import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Icon } from '@frontrunners/ui-components'
import { ApplicationContext } from '../common/reactContext'
import './settings.scss'
import { getActiveZeroMerchants } from './online/epayQueries'
import Spinner from '../common/components/spinner'
import { settingsPages as pages } from './settingsPages'
import { SETTINGS_PAGES as T } from './intlMessageKeys'


export const Settings = () => {
    const [selectedItem, setSelectedItem] = useState({})
    const [settingsPages, setSettingsPages] = useState(null)

    const { activeMerchant, config } = useContext(ApplicationContext)
    const { formatMessage } = useIntl()
    const { data: activeZeroIdSet, isLoading} = getActiveZeroMerchants(config.apiHost, activeMerchant.id)

    const requestedPage = getPageIndex(activeZeroIdSet, activeMerchant)

    useEffect(() => {
        const _pages = pages({ activeZeroIdSet, activeMerchant, formatMessage })
        setSettingsPages(_pages)
        setSelectedItem(_pages[requestedPage])
    }, [activeZeroIdSet, requestedPage])

    if (isLoading) {
        return <div className="settings"><Spinner waiting={true} /></div>
    }

    return (
        <div className="settings">
            <h1 className="settings-heading">
                <div className="heading">
                    {formatMessage(T['settings'])}
                </div>
                <Icon iconName="arrowRight" />
                <div className="settings-breadcrumb">
                    {selectedItem.name}
                </div>
            </h1>
            <div className="settings-menu">
                {settingsPages && settingsPages.filter(activePage => activePage.active).map(page => (
                    <Link
                        to={`/merchants/${activeMerchant.id}${page.path}`}
                        data-testid={`settings-menu-item-${page.name.toLowerCase().replace(' ', '-')}`}
                        className={`settings-menu-item ${page.name === selectedItem.name ? 'selected' : ''}`}
                        key={page.name}
                    >
                        <div className="settings-menu-item-icon">
                            {page.icon}
                        </div>
                        <div className="settings-menu-item-text">
                            {page.name}
                        </div>
                    </Link>
                ))}
            </div>
            <div className="settings-content">
                {selectedItem.component}
            </div>
        </div>
    )
}

const getPageIndex = (activeZeroIdSet, activeMerchant) => {
    const { formatMessage } = useIntl()

    const views = (pages({ activeZeroIdSet, activeMerchant, formatMessage }))
    const containsPath = (page) => window.location.pathname.includes(page.path)

    return Math.max(0, views.findIndex(containsPath))
}
