import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Checkbox, Paper, Badge } from '@frontrunners/ui-components'
import { USER_PREFERENCE_TEXT } from '../../merchant/constants'
import { USER_PROFILE_TEXTS, TERMINALS_TEXTS } from './intlMessageKeys'
import { FEATURE_DISABLED } from '../../common/translations'
import requestHelper from '../../helpers/requestHelper'
import Spinner from '../../common/components/spinner'
import { ApplicationContext } from '../../common/reactContext'
import './notifications.scss'


export const Notifications = () => {
    const [notificationSettings, setNotificationSettings] = useState([])
    const [isError, setIsError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const { activeMerchant, loggedInUser, config } = useContext(ApplicationContext)

    const baseUrl = config.apiHost
    const { formatMessage } = useIntl()
    const notificationTypes = getNotificationTypes(formatMessage)

    useEffect(() => {
        !loggedInUser.isSupport && getNotificationSettings(
            baseUrl, activeMerchant.id, loggedInUser.sub, setIsError, setLoading, setNotificationSettings)
    }, [])

    if (isError) {
        return (
            <Paper className="notifications" >
                <div className="error">
                    {formatMessage(TERMINALS_TEXTS['terminal_error_header'])}
                </div>
            </Paper>
        )
    }
    else if (isLoading) {
        return (
            <Paper className="notifications" >
                <Spinner waiting={true}></Spinner>
            </Paper>
        )
    }
    return (
        <Paper className="notifications" >
            <div className="notifications-header">
                <h2 className="settings-paper-header">
                    {formatMessage(USER_PROFILE_TEXTS['merchant-profile-notifications-header'])}
                </h2>
                {loggedInUser.isSupport &&
                    <Badge className="orange">{formatMessage(FEATURE_DISABLED.disabled_for_support_agents)}</Badge>
                }
            </div>

            {notificationTypes.map((notification) =>
                <Notification
                    key={notification.type}
                    name={notification.name}
                    description={notification.description}
                    setting={getSetting(notification.type, notificationSettings)}
                    isActive={!loggedInUser.isSupport}
                    onClickAction={
                        (newValue) => {
                            updateNotificationSetting(
                                baseUrl,
                                notification.type,
                                activeMerchant.id,
                                loggedInUser.sub,
                                setIsError,
                                setNotificationSettings,
                                newValue
                            )
                        }
                    }
                />
            )}
        </Paper>
    )
}

const Notification = ({ name, description, setting, isActive, onClickAction }) => {
    const isEnabled = setting ? setting.destinations[0].enabled : false

    return (
        <div className={`notification ${isActive ? '' : 'disabled'}`} data-testid="notification">
            <div className="slider-wrapper">
                <Checkbox
                    slider
                    initialValue={isEnabled}
                    referenceState={isActive ? null : false}
                    onClick={isActive ? onClickAction : () => { }}
                />
            </div>

            <div className="notification-body">
                <div className="settings-paper-subheader">
                    {name}
                </div>
                <div className="description">
                    {description}
                </div>
            </div>

        </div>
    )
}

const getNotificationSettings = (baseUrl, merchantId, subjectId, setError, setLoading, setNotificationSettings) => {
    const path = `/v1/merchants/${merchantId}/users/${subjectId}/notifications`

    setLoading(true)

    const successAction = (_, result) => {
        setNotificationSettings(result['data'])
        setError(false)
        setLoading(false)
    }

    const errorAction = () => {
        setError(true)
        setLoading(false)
    }

    requestHelper(baseUrl, path, successAction, errorAction, null, null, 'GET', null)
}

const getSetting = (type, settings) => {
    return settings ? settings.find((notification) => notification.event_type == type) : []
}

const updateNotificationSetting = (baseUrl, type, merchantId, subjectId, setIsError, setNotificationSettings, newValue) => {
    const path = `/v1/users/${subjectId}/settings/backoffice/notifications`

    setIsError(false)

    const requestObject = {
        merchant_id: merchantId,
        event_type: type,
        destinations: [{
            type: 'email',
            enabled: newValue
        }]
    }

    const handleResponse = (_, response) => {
        setNotificationSettings(oldSettings => {
            return oldSettings.map((setting) => setting.id === response.id ? response : setting)
        })
    }

    const errorHandler = () => {
        setIsError(true)
    }

    requestHelper(baseUrl, path, handleResponse, errorHandler, null, requestObject, 'POST', null)
}

const getNotificationTypes = (formatMessage) => {
    return [
        {
            type: 'invoice_report',
            name: formatMessage(USER_PREFERENCE_TEXT['notification_name_invoice']),
            description: formatMessage(USER_PREFERENCE_TEXT['invoice_notification'])
        },
        {
            type: 'monthly_report',
            name: formatMessage(USER_PREFERENCE_TEXT['notification_name_monthly_report']),
            description: formatMessage(USER_PREFERENCE_TEXT['monthly_report_notification'])
        },
        {
            type: 'payout_report',
            name: formatMessage(USER_PREFERENCE_TEXT['notification_name_payout']),
            description: formatMessage(USER_PREFERENCE_TEXT['payout_notification'])
        }
    ]
}
