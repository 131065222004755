import React from 'react'
import * as onlineHelpers from '../../helpers'
import moment from 'moment'
import { SALES_DETAILS_TEXTS } from '../../../sales/constants'


const OnlinePaymentAuthorizationDetails = ({formatMessage, payment}) => {
    const authorization = onlineHelpers.getAuthorization(payment)
    return (
        <div className="detail-wrapper">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS.transaction_detail_header)}</div>
            <div className="row list">
                <div className="something">{formatMessage(SALES_DETAILS_TEXTS.authorized_date)}</div>
                <div className="element datetime">
                    {moment(authorization.timestamp_local).format('YYYY-MM-DD HH:mm:ss')}
                </div>
            </div>
            <div className="row list">
                <div className="something">{formatMessage(SALES_DETAILS_TEXTS.payment_detail_bambora_txn_id)}</div>
                <div className="element">{onlineHelpers.getAuthRetrievalReferenceNumber(payment)}</div>
            </div>
            <div className="row list">
                <div className="something">{formatMessage(SALES_DETAILS_TEXTS.payment_detail_order_id)}</div>
                <div className="element">{authorization.merchant_reference || '-'}</div>
            </div>
        </div>
    )
}

export default OnlinePaymentAuthorizationDetails
