import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { SETTINGS_TEXTS as T } from '../../merchant/constants'
import { UpdateMerchantComponent } from '../../merchant/components/updateMerchantModalBody'
import parsePhoneNumber from 'libphonenumber-js'
import { Icon } from '@frontrunners/ui-components'
import './merchantAddressInformation.scss'
import { ApplicationContext } from '../../common/reactContext'
import { isOwner, isAdmin } from '../../common/userRoles'


export const MerchantAddressInformation = () => {

    const { formatMessage: fm } = useIntl()
    const { activeMerchant, loggedInUser } = useContext(ApplicationContext)

    if (!activeMerchant) return ''

    const [showUpdateMerchantModal, setShowUpdateMerchantModal] = React.useState(false)

    const phoneNumber = parsePhoneNumber(activeMerchant.phoneNumber)
    const userCanEditMerchant = isOwner(loggedInUser.role) || isAdmin(loggedInUser.role)

    return (
        <div className="merchant-address-information">
            {userCanEditMerchant ?
                HeaderWithEditIcon(setShowUpdateMerchantModal) : Header()
            }
            <div className="merchant-row">
                <div className="merchant-key">{fm(T['merchant_profile_merchant_user_name'])}</div>
                <div className="merchant-value">{activeMerchant.name}</div>
            </div>
            <div className="merchant-row">
                <div className="merchant-key">{fm(T['merchant_org_number'])}</div>
                <div className="merchant-value">{activeMerchant.orgNumber || ' -'}</div>
            </div>
            <div className="merchant-row">
                <div className="merchant-key">{fm(T['merchant_phone_number'])}</div>
                <div className="merchant-value">{phoneNumber ? phoneNumber.formatInternational() : activeMerchant.phoneNumber}</div>
            </div>
            <div className="merchant-row">
                <div className="merchant-key">{fm(T['merchant_address'])}</div>
                <div className="merchant-value">{<VisitingAddressComponent address={activeMerchant.billingAddress} />}</div>
            </div>
            <UpdateMerchantComponent
                visible={showUpdateMerchantModal}
                onClose={() => setShowUpdateMerchantModal(false)}
            />
        </div>
    )
}

const Header = () => {
    const { formatMessage: fm } = useIntl()
    return <h2 className="settings-paper-header">{fm(T['merchant'])}</h2>
}

const HeaderWithEditIcon = (setShowUpdateMerchantModal) => {
    const { formatMessage: fm } = useIntl()
    return (
        <h2 className="editable">
            <a onClick={() => setShowUpdateMerchantModal(true)} className="settings-paper-header">{fm(T['merchant'])}
                <span>
                    <Icon iconName="pen" scale={0.8} />
                    {fm(T['edit'])}
                </span>
            </a>
        </h2>
    )
}

const VisitingAddressComponent = ({ address }) => {
    const { city, country, postal_code, street_address } = address || {}

    return <div className="visiting-address">
        {street_address && <span className="address">{street_address}</span>}
        {postal_code && <span className="postal-code">{postal_code}</span>}
        {city && <span className="city">{city}</span>}
        {country && <span className="country">{country}</span>}
    </div >
}
