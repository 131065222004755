import React, { Component } from 'react'
import { connect } from 'react-redux'
import { redirectToLogout } from '../common/redirectActions'
import { injectIntl } from 'react-intl'
import { ERROR } from './constants'

class LoggedOutMessage extends Component {

  render() {
    const show = this.props.show
    const formatMessage = this.props.intl.formatMessage

    return (
      show ? (
        <div className="modal-wrap">
          <div className="backdrop"></div>
          {this.props.type === 'no-merchants' && <div className="error-modal">
            <h1>{formatMessage(ERROR['no-merchants-title'])}</h1>
            <p>{formatMessage(ERROR['no-merchants-body'])}</p>
            <div className="btn-group">
              <div className="btn btn-primary" onClick={() => {
                this.props.dispatch(redirectToLogout())
              }}>{formatMessage(ERROR['try-again'])}</div>
            </div>
          </div>}
        </div>
      ) : (null)
    )
  }
}

export default connect()(injectIntl(LoggedOutMessage))
