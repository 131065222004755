import React from 'react'
import { Notifications } from './notifications'
import { UserDetails } from './userDetails'
import Language from './language'
import './userProfile.scss'

export const UserProfile = () => {
    return (
        <div className="user-profile">
            <UserDetails />
            <Language />
            <Notifications />
        </div>
    )
}
