import *  as cookie from './cookie'

export const getExpires = (days=null) => {
    if (days) {
        const ONE_DAY = 24 * 60 * 60 * 1000
        const d = new Date()
        d.setTime(d.getTime() + (days * ONE_DAY))
        return d.toUTCString()
    }
    return ''
}

export const setCookie = (name, value, daysExpires=null) => {
    document.cookie = `${name}=${value};Expires=${cookie.getExpires(daysExpires)};Path=/;SameSite=Strict;Secure;`
}

export const getCookieValue = (name) => {
    const nameEq = `${name}=`
    const cookies = document.cookie.split(';')
    for (let cookie of cookies){ // eslint-disable-line no-unused-vars
        cookie = cookie.trim()
        if (cookie.startsWith(nameEq)) {
            return cookie.substring(nameEq.length, cookie.length)
        }
    }
    return null
}
