export const LIST_SUCCESS = 'merchant/LIST_SUCCESS'
export const LIST_ERROR = 'merchant/LIST_ERROR'
export const LIST_USERS_START = 'merchant/LIST_USERS_START'
export const LIST_USERS_SUCCESS = 'merchant/LIST_USERS_SUCCESS'
export const LIST_USERS_ERROR = 'merchant/LIST_USERS_ERROR'
export const TOGGLE_ADD_USER_MODAL = 'merchant/TOGGLE_ADD_USER_MODAL'
export const TOGGLE_RENAME_USER_MODAL = 'merchant/TOGGLE_RENAME_USER_MODAL'
export const TOGGLE_REMOVE_USER_MODAL = 'merchant/TOGGLE_REMOVE_USER_MODAL'
export const ADD_USER_START = 'merchant/ADD_USER_START'
export const ADD_USER_SUCCESS = 'merchant/ADD_USER_SUCCESS'
export const ADD_USER_ERROR = 'merchant/ADD_USER_ERROR'
export const REMOVE_USER_START = 'merchant/REMOVE_USER_START'
export const REMOVE_USER_SUCCESS = 'merchant/REMOVE_USER_SUCCESS'
export const REMOVE_USER_ERROR = 'merchant/REMOVE_USER_ERROR'
export const CHANGE_USER_ROLE_START = 'merchant/CHANGE_USER_ROLE_START'
export const CHANGE_USER_ROLE_ERROR = 'merchant/CHANGE_USER_ROLE_ERROR'
export const CHANGE_USER_ROLE_SUCCESS = 'merchant/CHANGE_USER_ROLE_SUCCESS'
export const SET_MERCHANT = 'merchant/SET_MERCHANT'
export const ADD_MERCHANT_TO_LIST = 'merchant/ADD_MERCHANT_TO_LIST'
export const UPDATE_USER_START = 'merchant/UPDATE_USER_START'
export const UPDATE_USER_ERROR = 'merchant/UPDATE_USER_ERROR'
export const UPDATE_USER_SUCCESS = 'merchant/UPDATE_USER_SUCCESS'
export const SET_ACTIVE_MERCHANT = 'merchant/SET_ACTIVE_MERCHANT'
export const GET_MERCHANTS_FOR_USER_START = 'merchant/GET_MERCHANTS_FOR_USER_START'
export const GET_MERCHANTS_FOR_USER = 'merchant/GET_MERCHANTS_FOR_USER'
export const GET_MERCHANTS_FOR_USER_ERROR = 'merchant/GET_MERCHANTS_FOR_USER_ERROR'

export const UPDATE_MERCHANTS_LIST = 'merchant/UPDATE_MERCHANTS_LIST'
