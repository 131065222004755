import React, { useState, useRef, useEffect } from 'react'
import BamboraSvgIcon from './bamboraSvgIcon'
import './searchbox.scss'

const ENTER_KEY = 13

const ReferenceBox = ({ reference, fetchByReference, setShowBox, inputEl, clearQuery }) => {
    const closeReferenceBox = () => {
        fetchByReference('')
        setShowBox(false)
        clearQuery()
    }
    const clickReference = () => {
        setShowBox(true)
        setTimeout(() => {
            inputEl.current.focus()
        })
    }
    return <div className="reference-box">
        <div onClick={() => clickReference()} title={reference} className="reference-box__reference">{reference}</div>
        <BamboraSvgIcon icon="close-white" onClick={() => closeReferenceBox()} />
    </div>
}

const SearchIcon = ({ setShowBox, inputEl }) => {
    const clickSearchIcon = () => {
        setShowBox(true)
        setTimeout(() => {
            inputEl.current.focus()
        })
    }
    return (
        <div className="search-icon" onClick={() => { clickSearchIcon() }}><BamboraSvgIcon icon="search" /></div>
    )

}

const SearchBox = ({ inputEl, fetchByReference, setShowBox, originalReference, placeHolder, inputLengthLimit }) => {
    const [reference, setReference] = useState(originalReference)
    const detectKeyPress = (event) => {
        if (event.keyCode === ENTER_KEY) {
            if (inputLengthLimit && (reference.length > inputLengthLimit)) return

            fetchByReference(reference)
            setShowBox(false)
        }
    }
    const clickClose = () => {
        setReference('')
        fetchByReference('')
        setShowBox(false)
    }

    useEffect(() => {
        const onClick = (e) => {
            if (e.target !== inputEl.current) {
                setShowBox(false)
            }
        }

        //this is work around for the input onblur, since the native input onblur is always triggered when the close icon clicked
        document.addEventListener('click', onClick, true)
        return function cleanup() {
            document.removeEventListener('click', onClick, true)
        }
    })

    return <div className="search-box">
        <div className="search-form">
            <div className="form-group">
                <input
                    ref={inputEl}
                    type="text"
                    className="reference-search"
                    name="search"
                    value={reference}
                    placeholder={placeHolder}
                    autoComplete="off"
                    maxLength={inputLengthLimit}
                    onKeyDown={(e) => detectKeyPress(e)}
                    onChange={(e) => setReference(e.target.value)}
                />
            </div>
        </div>
        <div className="close-icon" onClick={() => clickClose()} >
            <BamboraSvgIcon icon="close" />
        </div>
    </div>
}

const SearchBoxComponent = ({ fetchByReference, placeHolder, reference, inputLengthLimit, clearQuery }) => {

    reference = reference ? reference : ''

    const [showBox, setShowBox] = useState(false)
    const inputEl = useRef(null)

    return (
        <div className="searchbox-component">
            {reference && !showBox &&
                <ReferenceBox
                    reference={reference}
                    fetchByReference={fetchByReference}
                    setShowBox={setShowBox}
                    inputEl={inputEl}
                    clearQuery={clearQuery}
                />}
            {(!reference && !showBox) &&
                <SearchIcon
                    setShowBox={setShowBox}
                    inputEl={inputEl}
                />}
            {(showBox) &&
                <SearchBox
                    inputEl={inputEl}
                    fetchByReference={fetchByReference}
                    setShowBox={setShowBox}
                    originalReference={reference}
                    placeHolder={placeHolder}
                    inputLengthLimit={inputLengthLimit}
                />}
        </div>
    )
}

export default SearchBoxComponent
