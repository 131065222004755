import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { ApplicationContext } from '../../common/reactContext'
import { LoggedInUserMenu } from './loggedInUserMenu'
import getPageIndicator from '../helpers/getPageIndicator'
import { Icon, Dropcard } from '@frontrunners/ui-components'
import { NotificationBanners } from '../../components/NotificationBanners/NotificationBanners'
import { DeveloperModeContext } from '../../common/helpers/featureToggle'
import { LeftNavBar, TopNavBar } from './leftNav'
import {
    Invoices,
    Language,
    Loans,
    Logout,
    OnlinePortalLink,
    Overview,
    Payouts,
    Reports,
    Sales,
    SettingsDesktop,
    SettingsMobile,
    SwitchMerchant,
    UserDetails,
    WlIcon
} from './navItems'
import uuid from 'uuid'
import './navContainer.scss'


export const NavContainer = () => {
    const { developerMode, featureToggle } = useContext(DeveloperModeContext)
    const showLeftNav = developerMode && featureToggle.leftNav

    return (
        <>
            {showLeftNav ?
                <LeftNavView /> :
                <LegacyView />
            }
        </>
    )
}

const LegacyView = () => {
    return (
        <>
            <div className="nav-container">
                <DesktopView />
                <MobileView />
            </div>
            <NotificationBanners />
        </>
    )
}

const LeftNavView = () => {
    return (
        <div className="nav-container navbar-left">
            <NotificationBanners />
            <TopNavBar />
            <LeftNavBar />
        </div>
    )
}

export const DesktopView = () => {
    return (
        <nav className="navbar-desktop">
            <div className="align-left">
                <WlIcon />
                <Overview />
                <Payouts />
                <Sales />
                <Reports />
                <Loans />
                <OnlinePortalLink />
            </div>
            <div className="align-right">
                <Language />
                <LoggedInUserMenu className="nav-item-container" />
                <SettingsDesktop />
            </div>
        </nav>
    )
}

export const MobileView = () => {
    const { formatMessage: fm } = useIntl()
    const { activeMerchant } = useContext(ApplicationContext)

    const pageName = getPageIndicator(window.location.pathname, fm)

    const redirectToOverview = (e) => {
        e.stopPropagation()
        window.location.replace(`/merchants/${activeMerchant.id}`)
    }

    return (
        <nav className="navbar-mobile">
            <Dropcard
                key={uuid.v4()}
                label={pageName}
                icon={<div onClick={redirectToOverview}><Icon iconName="wlPalmtree" /></div>}
                expandIconName="burger"
                minimizeIconName="closeThin"
                minimizeIconRotationDegrees={0}
            >
                <Overview />
                <Payouts />
                <Sales />
                <Reports />
                <Loans />
                <Invoices />
                <OnlinePortalLink />
                <SettingsMobile />
                <SwitchMerchant />
                <Logout />
                <UserDetails />
            </Dropcard>
        </nav>
    )
}
