import React from 'react'

const notFoundState = ({children}) => {
  // a generic empty state for a collection of things
    return (
    <div className="not-found-notification">
      <div className="not-found-icon"></div>
      {children}
    </div>
    )
}

export default notFoundState
