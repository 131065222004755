import { defineMessages } from 'react-intl'

export const NAME = 'instore'

export const INSTORE_STATES = {
    AUTHORIZED: 'AUTHORIZED',
    VOIDED: 'VOIDED',
    CAPTURED: 'CAPTURED',
    CLEARED: 'CLEARED',
    FAILED: 'FAILED',
    INCOMPLETE: 'INCOMPLETE'
}

export const TRANSACTION_TYPE_REFUND = ['Refund', 'MPRefund', 'IRefund', 'ApmRefnd']
export const TRANSACTION_TYPE_SALE = ['Sale', 'MPSale', 'ISale', 'ApmSale', 'PComp']

export const OPERATIONS = {
    AUTHORIZED: 'Authorized',
    CLEARED: 'Cleared',
    CAPTURED: 'Captured',
    REFUNDED: 'Refunded',
    VOIDED: 'Voided'
}

export const INSTORE_PAYMENT_STATES_TEXT = defineMessages({
    refunded: {
        id: 'instore-list-item-refunded-button',
        defaultMessage: 'Refund',
        description: 'Refunded button'
    }
})

export const STATE_REFUND_INACTIVE_VIEW = {
    'AUTHORIZED': {
        button: true,
        amount: false,
        shouldToggle: false
    },
    'CAPTURED': {
        button: false,
        amount: true,
        shouldToggle: false

    },
    'CANCELED': {
        button: false,
        amount: false,
        shouldToggle: false
    },
    'REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: false
    },
    'PARTLY_CAPTURED': {
        button: false,
        amount: true,
        shouldToggle: true
    },
    'PARTLY_REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: false
    },
    'PARTLY_CAPTURED_PARTLY_REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: true
    },
    'REJECTED': {
        button: false,
        amount: false,
        shouldToggle: false
    }
}
