import { NAVLINK_TEXTS } from '../constants'

const indicatorArr = ['sales', 'overview', 'loans', 'reports', 'payouts', 'settings', 'support', 'invoices']
const getPageIndicator = (pathname, formatMessage) => {
    let keyArr = pathname && pathname.split('/')
    let key

    keyArr.some(item=>{
        if(indicatorArr.includes(item)){
            key=item
            return true
        }
    })

   if(!key && (keyArr[keyArr.length - 3] === 'merchants'||keyArr[keyArr.length - 2] === 'merchants')) key = 'overview'

    if (key) return formatMessage(NAVLINK_TEXTS[`navbar-main-link-${key}`])
    else return ''
}

export default getPageIndicator
