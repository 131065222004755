import { Map } from 'immutable'
import * as types from './actionTypes'

const initState = Map({
    profile: Map(),
    preference: Map()
})

export default function (state = initState, action) {
    switch (action.type) {
        case types.SET_USER_PROFILE:
            return state.set('profile', Map(action.user))
        case types.SET_USER_PREFERENCE:
            return state.setIn(['preference', action.payload.type], action.payload.value)
        case types.INITIAL_USER_PREFERENCE:
            return state.set('preference', Map(action.value))
        case types.RESET_USER_PREFERENCE_FOR_MERCHANT:
            var gmid = action.payload.gmid
            var preference = state.get('preference')
            preference = preference.delete(gmid)
            return state.set('preference', preference)
        default:
            return state
    }
}
