import React from 'react'
import iso4217 from 'currency-codes'
import * as onlineHelpers from '../../helpers'
import { SALES_DETAILS_TEXTS } from '../../../sales/constants'
import MonetaryAmount from  '../../../../common/components/monetaryAmount'
import { OPERATIONS } from '../../constants'
import { getExponentedAmountString } from '../../../../common/amountHelpers'

const OnlinePaymentStatusDetails = ({formatMessage, payment}) => {
    const authorization = onlineHelpers.getAuthorization(payment)
    const currency = iso4217.code(authorization.currency)
    const currency_exponent = currency.digits
    const capturedAmount = onlineHelpers.reduceOperationsAmounts(payment, [OPERATIONS.CAPTURE])
    const refundedAmount = onlineHelpers.reduceOperationsAmounts(payment, [OPERATIONS.REFUND])

    return (
        <div className="detail-wrapper">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS.status_detail_header)}</div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS.payment_detail_authorized)}</div>
                <div className="element">
                    <MonetaryAmount amount={authorization.success ? authorization.amount : 0}
                                    currency={authorization.currency}
                                    amount_exponent={currency_exponent}
                                    className="fg-darkgreen"
                                    hideInsignificantDecimals={false} />
                </div>
            </div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS.payment_detail_captured)}</div>
                <div className="element">{getExponentedAmountString(capturedAmount, currency_exponent)} {authorization.currency}</div>
            </div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS.payment_detail_refunded)} </div>
                <div className="element">{getExponentedAmountString(refundedAmount, currency_exponent)} {authorization.currency}</div>
            </div>
        </div>
    )
}

export default OnlinePaymentStatusDetails
