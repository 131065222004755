import React from 'react'

const LoanErrorNotification = ({message}) => {
    return (
        <div className="modal-wrap">
            <div className="error-modal">
                <span className="supportal-text">
                    <p>{message}</p>
                    <div
                        className="btn back-btn rebranded-primary-btn"
                        onClick={() => history.back()}>
                            Go back
                    </div>
                </span>
            </div>
        </div>
    )
}

export default LoanErrorNotification
