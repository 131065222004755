import { defineMessages } from 'react-intl'

export const CUSTOM_PERIOD = defineMessages({
    yesterday: {
        id: 'custom-period-yesteray',
        defaultMessage: 'Yesterday',
        description: 'custom period yesteray'
    },
    last_month: {
        id: 'custom-period-last-month',
        defaultMessage: 'Last month',
        description: 'custom period month'
    },
    last_week: {
        id: 'custom-period-last-week',
        defaultMessage: 'Last week',
        description: 'custom period last week'
    }
})

export const NO_DATA_IN_TABLE = defineMessages({
    nothing_to_see: {
        id: 'no-settlements-title-with-filter',
        defaultMessage: 'Nothing to see here',
        description: 'Nothing to see here'
    },
    no_filter: {
        id: 'no-settlements-body-with-filter',
        defaultMessage: 'We could not find any payouts matching your filter.',
        description: 'Settlement list empty filtered state body text'
    },
    no_settlement_title: {
        id: 'no-settlements-title',
        defaultMessage: 'You have no settlements yet',
        description: 'Settlement list empty state title'
    },
    no_settlement_body: {
        id: 'no-settlements-body',
        defaultMessage: 'But once you get set up, all your settlements will live here.',
        description: 'Settlement list empty state body text'
    }
})

export const DATE_PICKER = defineMessages({
    time_limit: {
        id: 'time_limit',
        defaultMessage: 'Select time period less than 35 days to download',
        description: 'Select time period less than 35 days to download'

    }
})

export const REPORT = defineMessages({
    download: {
        id: 'settlement-download',
        defaultMessage: 'Download',
        description: 'Download'

    }
})

export const ERROR = defineMessages({
    error_boundary_msg: {
        id: 'error_boundary_msg',
        defaultMessage: 'Something went wrong',
        description: 'error boundary message'
    }
})

export const TRIAL = defineMessages({
    msg: {
        id: 'trial-status-message',
        defaultMessage: 'You are currently in test environment',
        description: 'Test state banner message'

    },
    link_active: {
        id: 'trial-banner-activate-account-message',
        defaultMessage: 'Activate account',
        description: 'Switch to active link text'

    },
    link_switch: {
        id: 'trial-banner-switch-to-live-message',
        defaultMessage: 'Switch to live',
        description: 'Switch to live link text'

    }
})


export const LOAN_INFO_BOX = defineMessages({
    title: {
        id: 'loan_info_box_title',
        defaultMessage: 'Invest in your business with {currency} {amount} - the money in your account within 24 hours',
        description: 'loan info box title'
    },
    title_no_number: {
        id: 'loan_info_box_title_no_number',
        defaultMessage: 'Invest in your business - the money in your account within 24 hours',
        description: 'loan info box title without number'
    },
    text2: {
        id: 'loan_info_box_text2',
        defaultMessage: 'Explore new opportunities for your business with Worldline financing. We\'re here to support you, offering flexible refund options that adapt to your sales. Enjoy larger installments during good times and less during challenging ones. Let\'s work together to empower your business growth.',
        description: 'loan info box title'
    }
})

export const REPORTS_FEATURE_HIGHLIGHT_MODAL_TEXT = defineMessages({
    header: {
        id: 'reports_feature_highlight_header',
        defaultMessage: 'We\'ve improved our Reports page',
        description: 'headline for feature highlight'
    },
    preamble: {
        id: 'reports_feature_highlight_preamble',
        defaultMessage: 'In order to improve your experience of ordering monthly reports we have made the following changes:',
        description: 'a preamble that goes under the headline'
    },
    list_item_1: {
        id: 'reports_feature_highlight_list_item_1',
        defaultMessage: 'You can now leave the page, or even turn off your computer, while your report is being generated.',
        description: 'First item in the list'
    },
    list_item_2: {
        id: 'reports_feature_highlight_list_item_2',
        defaultMessage: 'When you return you will get a notification if your report is ready for download.',
        description: 'Second item in the list'
    },
    list_item_3: {
        id: 'reports_feature_highlight_list_item_3',
        defaultMessage: 'You will see an estimate of when your report will be ready.',
        description: 'Third item in the list'
    },
    list_item_4: {
        id: 'reports_feature_highlight_list_item_4',
        defaultMessage: 'And we hope you will enjoy the new design.',
        description: 'Fourth item in the list'
    },
    footer: {
        id: 'reports_feature_highlight_footer',
        defaultMessage: 'See for yourself',
        description: 'Footer text with link'
    }
})

export const TEXT = defineMessages({
    'read_more': {
        id: 'read-more',
        defaultMessage: 'Read more',
        description: 'read more'
    },
    'no': {
        id: 'no',
        defaultMessage: 'No',
        description: 'No (antonym to yes)'
    }
})

export const SUPPORTED = defineMessages({
    unsupported_browser: {
        id: 'unsupported_browser',
        defaultMessage: 'Your browser is not supported, please use {browsers} or {browser}.',
        description: 'unsupported browser message'
    },
    device: {
        id: 'unsupported_device',
        defaultMessage: 'Your device size is not supported. Please use a device with a screen width larger than 360 pixels.',
        description: 'unsupported device message'
    },
    btn: {
        id: 'unsupported_btn',
        defaultMessage: 'Dismiss & Try Anyway',
        description: 'unsupported button'
    }
})

export const FEATURE_UNAVAILABLE = defineMessages({
    title: {
        id: 'feature-unavailable-title',
        defaultMessage: 'Maintenance!',
        description: 'feature unavailable title'
    },
    text: {
        id: 'feature-unavailable-text',
        defaultMessage: 'The {sectionName} section is under maintenance, please come back later.',
        description: 'feature unavailable text'
    }
})

export const FEATURE_DISABLED = defineMessages({
    disabled_for_support_agents: {
        id: 'disabled-for-support-agents',
        defaultMessage: 'Disabled for support agents',
        description: 'feature disabled for support agents text'
    }
})
