import { TRANSACTION_STATUS } from '../settlements/constants'
import { FILTER_STATES } from '../sales/sales/constants'

const AMEX = ['americanexpress', 'american express', 'amex']

export const formatCardNumber = (cardNumber) => {
    if(!cardNumber){
        return ''
    }

    cardNumber = cardNumber.replace(/-/g, '')

    var splitted = cardNumber.split('')
    var chunked = ''

    //chunk card number in groups of 4 digits
    splitted.forEach(function(c,i){
        if(i !== 0 && i % 4 === 0){
            chunked += ' '
        }
        chunked += c
    })
    var result = chunked.replace(/x|X/g,'*')
    return result
}

export const getLastFourDigits = (cardNumber) => {
    return cardNumber.substring(cardNumber.length - 4)
}

export const getAmexTranslation = (transaction) => {
    if (transaction.settlement_id && AMEX.includes(transaction.card_scheme.toLowerCase())) {
        return FILTER_STATES['cleared']
    }

    return transaction.settlement_id ? TRANSACTION_STATUS['paid'] : TRANSACTION_STATUS['pending']
}
