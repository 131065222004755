import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import settlements from '../../settlements'
import EmptyState from '../../common/components/emptyState'
import CommonErrorComponent from '../../common/commonErrorComponent'
import SettlementList from '../components/settlementList'
import FilterMenu from '../components/filterMenu'
import { setPagination } from '../../common/components/paginationLoadMore'
import { NO_DATA_IN_TABLE } from '../../common/translations'
import { injectIntl } from 'react-intl'
import ErrorBoundary from '../../common/components/errorBoundary'


const TABLE_ROW_PER_PAGE = 20

class SettlementsListContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      queryOutOfRange: false,
      tableSize: 20
    }
  }

  static getDerivedStateFromProps(nextProps, prevStates) {
    if (!nextProps.settlements) return null
    let settlements = nextProps.settlements.toJS()

    const isOutOfRange = !!(settlements && settlements.list && settlements.list.isOutOfRange)
    if (isOutOfRange !== prevStates.queryOutOfRange) {
      if (isOutOfRange) nextProps.history.replace(nextProps.location.pathname)
      return {
        queryOutOfRange: settlements.list.isOutOfRange
      }
    }
    return null
  }

  increaseTableSize() {
    this.setState({tableSize: this.state.tableSize + TABLE_ROW_PER_PAGE})
  }

  render() {
    const { formatMessage } = this.props.intl
    if (!this.props.settlements) return null
    const settlements = this.props.settlements.toJS()
    let nextPageAvailable = false
    let nextPage = settlements.list.nextPage
    if (nextPage) {
      nextPageAvailable = nextPage.data.length > 0
    }

    if (settlements.list.isError) {
      return (
        <CommonErrorComponent />
      )
    }

    if (settlements.list.emptyList && !settlements.list.isFetching && !settlements.list.hasActiveFilters) {
      return (
        <div className="main-width list-container settlements-list-container">
          <EmptyState>
            <h2 className="text-center">
              {formatMessage(NO_DATA_IN_TABLE['no_settlement_title'])}
            </h2>
            <p className="text-center">
              {formatMessage(NO_DATA_IN_TABLE['no_settlement_body'])}
            </p>
          </EmptyState>
        </div>
      )
    }

    return (
      <div>
        {settlements.list &&
          <div>
            <ErrorBoundary><FilterMenu /></ErrorBoundary>
            <ErrorBoundary>
              <div className="main-width-3 list-container settlements-list-container">
                {this.props.payouts && <SettlementList
                  formatMessage={formatMessage}
                  settlements={this.props.payouts}
                  isFetching={settlements.list.isFetching}
                  nextPageAvailable={nextPageAvailable}
                  increaseTableSize={this.increaseTableSize.bind(this)}
                  loadMore={this.loadMore.bind(this)}
                  tableSize={this.state.tableSize}
                  dispatch={this.props.dispatch}
                  merchantId={this.props.match.params.merchantId}
                />}
                {settlements.list.hasActiveFilters && settlements.list.emptyList &&
                  <EmptyListWithActiveFilters formatMessage={formatMessage} />
                }
              </div>
            </ErrorBoundary>
          </div>
        }
      </div>
    )
  }

  componentDidMount() {
    this.props.dispatch(settlements.actions.resetPagination())
    setTimeout(() => {
      this.fetchPage()
    })
  }

  updateStateWithNextPage() {

    let search = setPagination(this.props.search)

    this.props.dispatch(settlements.actions.loadMore(
      this.props.merchantId,
      search
    ))
  }

  loadMore() {
    const merchantId = this.props.match.params.merchantId
    this.updateStateWithNextPage(merchantId)

    setTimeout(() => {
      this.fetchPage()
    })
  }

  fetchPage() {
    this.props.dispatch(
      settlements.actions.getSettlements(
        this.props.match.params.merchantId,
        this.props.search
      )
    )
  }
}


const EmptyListWithActiveFilters = ({ formatMessage }) => {
  return (
    <div className="main-width list-container settlements-list-container">
      <EmptyState>
        <h2 className="text-center">
          {formatMessage(NO_DATA_IN_TABLE['nothing_to_see'])}
        </h2>
        <p className="text-center">
          {formatMessage(NO_DATA_IN_TABLE['no_filter'])}
        </p>
      </EmptyState>
    </div>
  )
}


SettlementsListContainer.propTypes = function () {
  return {
    dispatch: PropTypes.func,
    list: PropTypes.object
  }
}

const mapPropsToState = (state) => {
  const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
  return {
    settlements: state.getIn([activeMerchant, 'settlements']),
    payouts: state.getIn([activeMerchant, 'settlements', 'list', 'data']),
    search: state.getIn([activeMerchant, 'settlements', 'list', 'search']),
    merchant: state.get('merchant').get('data')
  }
}

export default connect(mapPropsToState)(injectIntl(SettlementsListContainer))
