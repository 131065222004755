import requestHelper from '../helpers/requestHelper'

export const generateReport = async (
    baseUrl,
    selectedReportType,
    from_date,
    to_date,
    locale,
    successHandler,
    errorHandler
) => {
    const parameters = {
        locale,
        from_date,
        to_date
    }
    return requestHelper(baseUrl, selectedReportType.createReportTaskPath, successHandler, errorHandler, parameters, null, 'GET', null)
}

export const getDownloadLink = async (merchantId, userId, reportTaskId, baseUrl, successHandler, errorHandler) => {
    const path = `/v1/merchants/${merchantId}/users/${userId}/reports/active/${reportTaskId}/download`
    return requestHelper(baseUrl, path, successHandler, errorHandler, null, null, 'GET', null)
}

export const fetchActiveReports = async (merchantId, userId, baseUrl, successHandler, errorHandler) => {
    const path = `/v1/merchants/${merchantId}/users/${userId}/reports/active`
    return requestHelper(baseUrl, path, successHandler, errorHandler, null, null, 'GET', null)
}

export const cancelReportTask = async (merchantId, userId, taskId, baseUrl, successHandler, errorHandler) => {
    const path = `/v1/merchants/${merchantId}/users/${userId}/reports/active/${taskId}/cancel`
    return requestHelper(baseUrl, path, successHandler, errorHandler, null, null, 'PUT', null)
}
