import {sumAmounts} from '../../common/amountHelpers'
import {reasonGroups} from '../constants'
import {groupBy} from '../../common/helpers/arrayHelper'


export default function (adjustment_set){
    adjustment_set = applyTheSameReason(adjustment_set)
    let adjustmentsWithSubType = adjustment_set.filter((adjustment) => adjustment && adjustment.subType)
    let adjustmentsWithoutSubType = adjustment_set.filter((adjustment) => adjustment && !adjustment.subType)

    adjustmentsWithSubType = aggregateBySubType(adjustmentsWithSubType)
    adjustmentsWithoutSubType = aggregateByReasonCode(adjustmentsWithoutSubType)

    return [...adjustmentsWithSubType, ...adjustmentsWithoutSubType]
}


function aggregateBySubType(adjustments){
    const adjustmentGroups = groupBy(adjustments, 'subType')
    let newAdjustment = []
    for (let subType in adjustmentGroups) { // eslint-disable-line
        const amount = sumAmounts(adjustmentGroups[subType])
        newAdjustment.push({subType, ...amount})
    }
    return newAdjustment
}


function aggregateByReasonCode(adjustments){
    const adjustmentGroups = groupBy(adjustments, 'reason_code')
    let newAdjustment = []
    for (let reason_code in adjustmentGroups) { // eslint-disable-line
        const amount = sumAmounts(adjustmentGroups[reason_code])
        newAdjustment.push({reason_code, ...amount})
    }
    return newAdjustment
}


// when the UI reason of adjustment reason_code or subType could be the same  as other reason_code or subType,
// substitude the reason_code with the first one in the reasonGroup
function applyTheSameReason(adjustments){

   return adjustments.map(adjustment => {

        const reasonGroup = reasonGroups.find(group => (group.includes(adjustment.subType) || group.includes(adjustment.reason_code)))

        if(reasonGroup){
            adjustment.reason_code = reasonGroup[0]
            if(adjustment.subType) delete adjustment.subType
        }

        return adjustment

    })
}
