import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { CHECKOUT_TEST_MODE as T } from './intlMessageKeys'
import { ApplicationContext } from '../../common/reactContext'
import { STATES } from '../../merchant/constants'
import { Checkbox } from '@frontrunners/ui-components'
import { useHistory } from 'react-router-dom'
import './checkoutTestMode.scss'

const isMerchantInTestMode = (activeMerchant) => [STATES.TRIAL_ACTIVATED, STATES.TRIAL_NOT_ACTIVATED].includes(activeMerchant.state)

export const CheckoutTestMode = () => {
    const { formatMessage: fm } = useIntl()
    const { push: navigateTo } = useHistory()
    const { activeMerchant, loggedInUser } = useContext(ApplicationContext)
    const switchToMerchantId = activeMerchant.prodMerchantId || activeMerchant.trialMerchantId

    if (!loggedInUser.hasAccessToMerchant(switchToMerchantId)) return null

    return <div className="checkout-test-mode">
        <h3>{fm(T.header)}</h3>
        <Checkbox
            slider
            initialValue={isMerchantInTestMode(activeMerchant)}
            onClick={() => navigateTo(`/merchants/${switchToMerchantId}/settings/online`)}
        />
        <p>{fm(T.description)}</p>
    </div>
}
