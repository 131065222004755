import { defineMessages } from 'react-intl'

export const NAME = 'loans'

export const REQUEST_ERROR_MESSAGE = 'Something went wrong when attempting to load the loan section'
export const SUPPORTAL_ERROR_MESSAGE = 'You cant view the loan section until the merchant has given consent to share its information.'

export const TEXT = defineMessages({
    'start-page-title': {
        id: 'loan-start-title',
        defaultMessage: 'Financial room to grow your business!',
        description: 'loan start title'
    },
    'start-page-p1': {
        id: 'loan-start-p1',
        defaultMessage: 'Need to invest in new machines? Move to a bigger space? Or just want a buffer for unexpected expenses?',
        description: 'loan start paragraph 1'
    },
    'start-page-p2': {
        id: 'loan-start-p2',
        defaultMessage: 'Together with our partner Froda, we’ve developed a new financing option, especially adapted for your needs as a Bambora customer.',
        description: 'loan start paragraph 2'
    },
    'start-page-p3': {
        id: 'loan-start-p3',
        defaultMessage: 'Read more and see your available credit. It’s free to find out how how much you can borrow.',
        description: 'loan start paragraph 3'
    },
    'start-page-p4': {
        id: 'loan-start-p4',
        defaultMessage: 'By proceeding, I consent to the sharing of my personal and company information with Froda, a part of Monetise Capital AB ("Froda"), in order to check my credit.',
        description: 'loan start paragraph 4'
    },
    'start-page-footer-section1-title': {
        id: 'loan-start-footer-section1-title',
        defaultMessage: 'Does it really only take a few seconds? ',
        description: 'loan start footer section1 title'
    },
    'start-page-footer-section1-p1': {
        id: 'loan-start-footer-section1-p1',
        defaultMessage: 'Yes, when you click \"Show me how much I can borrow\"  you will be able to see the amount within a few seconds. The credit space is based on your sales through Bambora.',
        description: 'loan start footer section1 paragraph 1'
    },
    'start-page-footer-section2-title': {
        id: 'loan-start-footer-section2-title',
        defaultMessage: 'Does it cost anything?',
        description: 'loan start footer section2 title'
    },
    'start-page-footer-section2-p1': {
        id: 'loan-start-footer-section2-p1',
        defaultMessage: 'No, it doesn\'t cost you anything to have access to our new business credit. You will only pay after you make a withdrawal from. ',
        description: 'loan start footer section2 paragraph 1'
    },
    'start-page-footer-section3-title': {
        id: 'loan-start-footer-section3-title',
        defaultMessage: 'Do I commit to anything when I want to see how much I can borrow?',
        description: 'loan start footer section3 title'
    },
    'start-page-footer-section3-p1': {
        id: 'loan-start-footer-section3-p1',
        defaultMessage: 'No, you are not committing to anything by seeing your credit space. You will have immediate access to financing the day you need it. ',
        description: 'loan start footer section3 paragraph 1'
    },
    'button': {
        id: 'give-consent-button',
        defaultMessage: 'Show me how much I can borrow',
        description: 'give consent button'
    },
    'menu-start': {
        id: 'loan-menu-start',
        defaultMessage: 'Start',
        description: 'loan menu start'
    },
    'loan-notification-link': {
        id: 'loan-notification-link',
        defaultMessage: 'See how much you can borrow',
        description: 'loan notification link'
    },
    'loan-notification-message': {
        id: 'loan-notification-message',
        defaultMessage: 'Discover Bambora\'s new business credit!',
        description: 'loan notification message'
    },
    'loan-consent-text': {
        id: 'loan-consent-text',
        defaultMessage: 'See how much you can borrow, it is free and you are not committing to anything.',
        description: 'loan notification message'
    }
})
