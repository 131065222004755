import React, { Component } from 'react'
import iso4217 from 'currency-codes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import PaginationLoadMore from '../../../common/components/paginationLoadMore'
import AcquiringList from '../../acquiring/components/acquiringList'
import OnlineList from '../../online/components/onlineList'
import InstoreList from '../../instore/components/instoreList'
import OnlineOperationModal from '../../online/components/operations/onlineOperationModal'
import OperationCapture from '../../online/containers/operationCapture'
import InvoiceRefundModal from '../../online/components/operations/onlineInvoiceRefundModal'
import { SALES_TYPES } from '../constants'
import { PAYMENT_METHODS, OPERATIONS } from '../../online/constants'
import { setButtonState } from '../../../common/components/paginationLoadMore'

import * as onlineHelpers from '../../online/helpers'
import * as onlineActions from '../../online/actions'
import { load } from '../actions'
import { getMerchant } from '../../../common/helpers/findMerchant'


const loadSalesActions = (activeView) => {
    const actions = load(activeView)
    return actions
}


class SalesListContainer extends Component {

    constructor(props) {
        super(props)

    }

    _clearSearch() {
        this.props.history.push({ pathname: this.props.history.pathname, search: '' })
    }

    componentDidMount() {
        this._loadActions()
        if (this.props.list && !this.props.list.get('search')) {
            if (this.props.location.state && this.props.location.state.query) {
                return
            }
            this.fetchPage()
        }
    }

    componentDidUpdate(prevProps) {
        this._loadActions()
        const saleTypeViewChanged = this.props.salesType !== prevProps.salesType
        if (this.props.location.search !== prevProps.location.search || saleTypeViewChanged) {
            this.fetchPage()
        }
    }

    componentWillUnmount() {
        this.props.dispatch(this.actions.setDefaultPagination())
    }

    _loadActions() {
        if (this.props.activeView) {
            this.actions = loadSalesActions(this.props.activeView)
        }
    }

    fetchPage() {
        const merchantObject = getMerchant(this.props.match.params.merchantId, this.props.merchant)
        this.props.dispatch(
            this.actions.getList(
                merchantObject,
                this.props.match.params.merchantId,
                this.props.filters.toJS(),
                false

            )
        )
    }

    pickPage(salesType, item) {
        /* Find a better solution for this? (maybe good enough for now)
         * We only need to setCurrent on acquiring because we don't have a /{id} endpoint
         * on other salesTypes we fetch the details from GPR
         */
        if (salesType === 'acquiring') {
            this.props.dispatch(
                this.actions.setCurrent(
                    item
                )
            )
        }

        const payment_id = this.getDetailsPaymentId(item, salesType)
        this.props.history.push({ pathname: `${this.props.location.pathname}/${salesType}/${payment_id}` })
    }

    getDetailsPaymentId(item, salesType) {
        if (salesType === SALES_TYPES.instore) {
            // We abstract certain instore payments such that the item.id does not match what is stored in GPR. (details endpoint)
            return item.source_payment_id
        } else {
            return item.id
        }
    }

    listSelector(params) {
        if (params.saleType === SALES_TYPES.acquiring) {
            return <AcquiringList {...params} />
        } else if (params.salesType === SALES_TYPES.online) {
            params.showOperationsModal = this.showOperationsModal.bind(this)
            return <OnlineList {...params} />
        } else if (params.salesType === SALES_TYPES.instore) {
            return <InstoreList {...params} />
        }
        return <AcquiringList {...params} />
    }

    showOperationsModal(event, order, operation) {
        event.stopPropagation()
        this.props.dispatch(onlineActions.getDetails(this.props.match.params.merchantId, order.id))
        setTimeout(() => {
            this.props.dispatch(onlineActions.showOperationsModal(this.props.match.params.merchantId, operation, order.id, true))
        }, 250)

    }

    loadMore() {
        const merchantId = this.props.match.params.merchantId
        const merchantObject = getMerchant(this.props.match.params.merchantId, this.props.merchant)
        this.props.dispatch(
            this.actions.getList(
                merchantObject,
                merchantId,
                this.props.filters.toJS()
            )
        )
    }

    renderList(salesList) {
        const { formatMessage } = this.props.intl

        const params = {
            salesType: this.props.salesType,
            list: salesList,
            pickPage: this.pickPage.bind(this),
            merchant: this.props.match.params.merchantId,
            formatMessage: formatMessage,
            filter: this.props.filters,
            merchants: this.props.merchant.get('merchants')
        }
        return this.listSelector(params)
    }

    render() {
        const salesList = this.props.list
        if (!salesList) return null

        const buttonState = setButtonState(salesList.isFetching)
        const action = this.props.operations.get('action')
        const order = this.props.currentOrder ? this.props.currentOrder.toJS() : null
        let nextPageAvailable = false
        let nextPage = salesList.get('nextPage')

        if (nextPage) {
            nextPageAvailable = nextPage.data.length > 0
        }

        const authorization = onlineHelpers.getAuthorization(order.data)

        return (
            <div>
                <div className={`main-width-3 list-container ${this.props.salesType}-list-container`}>
                    <div className="list-groups">
                        {this.renderList(salesList)}
                    </div>
                    {this.props.operations.get('displayModal') && order.data.payment_method &&
                        renderOperationModal(action, order, authorization, this.props.match.params.merchantId)
                    }
                </div>
                <div className="sales-pagination">
                    {!salesList.get('isFetching') && !salesList.get('isError') && <PaginationLoadMore
                        data={salesList.data}
                        loadMore={this.loadMore.bind(this)}
                        nextPageAvailable={nextPageAvailable}
                        emptyList={salesList.emptyList}
                        buttonState={buttonState}
                    />}
                </div>
            </div>
        )
    }
}

const renderOperationModal = (action, order, authorization, merchantId) => {
    const amount_exponent = iso4217.code(authorization.currency).digits
    const acquirerName = order['data']['acquirer_name']
    authorization.amount_exponent = amount_exponent
    if (action === OPERATIONS.REFUND && order.data.payment_method === PAYMENT_METHODS.INVOICE) {
        return <InvoiceRefundModal authorization={authorization} />
    }
    return onlineHelpers.isNewOperationFlow(acquirerName) ? <OperationCapture
        authorization={authorization}
        merchantId={merchantId}
        order={order['data']}
    /> : <OnlineOperationModal authorized_amount={authorization.amount} amount_exponent={amount_exponent} />
}

function mapStateToProps(state) {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    const activeView = state.getIn([activeMerchant, 'sales', 'activeView'])
    return {
        sales: state.getIn([activeMerchant, 'sales']),
        list: state.getIn([activeMerchant, activeView, 'list']),
        filters: state.getIn([activeMerchant, activeView, 'filters']),
        operations: state.getIn([activeMerchant, 'online', 'operations']),
        currentOrder: state.getIn([activeMerchant, 'online', 'current']),
        merchant: state.get('merchant'),
        activeView: activeView
    }
}


export default connect(mapStateToProps)(injectIntl(withRouter(SalesListContainer)))
