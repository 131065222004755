import * as t from './actionTypes'
import { Map } from 'immutable'
import moment from 'moment'
import { getPayoutAggregatesForInterval } from './helpers'

export const initState = Map({
    totalCount: 0,
    totalAmount: 0,
    exponent: 2,
    data: [],
    payouts: Map({}),
    loan: Map({}),
    filters: Map({}),
    isFetching: false,
    currentMonth: Map({})
})

const reducer = (dashboard = initState, action) => {
    switch (action.type) {
        case t.LIST_SALES_AGGREGATES_START:
            return dashboard.set('isFetching', true)
        case t.LIST_SALES_AGGREGATES_SUCCESS:
            return dashboard.set('data', action.payload.aggregates.data)
                .set('totalCount', action.payload.aggregates.totalCount)
                .set('totalAmount', action.payload.aggregates.totalAmount)
                .set('exponent', action.payload.aggregates.data[0].exponent)
                .set('currency', action.payload.aggregates.currency)
                .set('isFetching', false)
        case t.LIST_SALES_AGGREGATES_ERROR:
            return dashboard.set('isFetching', false)
        case t.LIST_PAYOUT_AGGREGATES_START:
            return dashboard.setIn(['payouts', 'isFetching'], true)
        case t.LIST_PAYOUT_AGGREGATES_SUCCESS:
            var latestAggregate = getPayoutAggregatesForInterval(action.payload.data)
            var monthlyAggregates = getPayoutAggregatesForInterval(action.payload.data, moment().startOf('month').format('YYYY-MM-DD'))
            var yearlyAggregates = getPayoutAggregatesForInterval(action.payload.data, moment().startOf('year').format('YYYY-MM-DD'))
            return dashboard.setIn(['payouts', 'latest'], latestAggregate)
                .setIn(['payouts', 'monthly'], monthlyAggregates)
                .setIn(['payouts', 'yearly'], yearlyAggregates)
                .setIn(['payouts', 'isFetching'], false)
        case t.LIST_LOAN_DASHBOARD_START:
            return dashboard.setIn(['loan', 'isFetching'], true)
        case t.LIST_LOAN_DASHBOARD_SUCCESS:
            return dashboard.setIn(['loan', 'isFetching'], false)
                .setIn(['loan', 'hasActiveLoan'], true)
        case t.LIST_LOAN_DASHBOARD_ERROR:
            return dashboard.setIn(['loan', 'isFetching'], false)
                .setIn(['loan', 'hasActiveLoan'], false)
        case t.LIST_LOAN_CREDIT_START:
            return dashboard.setIn(['loan', 'isFetching'], true)
        case t.LIST_LOAN_CREDIT_SUCCESS:
            return dashboard.setIn(['loan', 'isFetching'], false)
                .setIn(['loan', 'hasActiveLoan'], true)
                .setIn(['loan', 'available_credit'], action.payload.available_credit)
                .setIn(['loan', 'remaining_debt'], action.payload.remaining_debt)
        case t.LIST_LOAN_CREDIT_ERROR:
            return dashboard.setIn(['loan', 'isFetching'], false)
                .setIn(['loan', 'hasActiveLoan'], false)
        default:
            return dashboard.setIn(['payouts', 'isFetching'], false)
    }
}

export default reducer
