import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import getFlagSrc from '../../common/helpers/flagSrc'
import BamboraSvgIcon from '../../common/components/bamboraSvgIcon'
import { DASHBOARD_TRANSLATIONS, DROPDOWNS } from '../translations'
import { FILTER_BUTTON_TEXT } from '../../sales/sales/constants'
import FilterMobile from '../../common/components/filterMobile'
import { SETTINGS_TEXTS, INACTIVE_ACQUIRING_STATUS } from '../../merchant/constants'
import SelectDropdown from '../../common/components/selectDropdown'
import ToggleButton from '../../common/components/toggleButton'
import { FILTERS } from '../constants'
import { ITEM_FILTER_VISIBILITY_THRESHOLD } from '../../common/constants'
import './dashboardFilter.scss'
import { Dropdown } from '@frontrunners/ui-components'


const getChannelFilterProps = (options, channelSeleted, addFilter) => {
    if (options.length <= 1) return
    const selectedChannel = channelSeleted ? channelSeleted : options[0]
    const selectedOptions = [{
        text: selectedChannel
    }]

    const filterType = FILTERS.channels
    const onChange = (option) => {
        const filters = [{
            type: filterType,
            value: option[0].text
        }, {
            type: FILTERS.source_merchant_ids,
            value: []
        }]

        addFilter(filters)
    }

    options = options.map(option => ({ text: option }))

    return { options, selectedOptions, onChange: onChange, className: 'dropdown-channel', align: 'right', mode: 'single', filterType, title: 'channel' }
}

const formatItems = (items, statusText) => {
    const formattedItems = items.map(item => {
        if (item.name) {
            return {
                text: item.name,
                subtext: `${item.mid} ${statusText(item.status)}`,
                id: item.mid
            }
        }
        return { text: item , subtext: '', id: item}
    })
    return formattedItems
}

const getCombineMidsFilterProps = (items, channelOptions, sourceMerchantIdsSelected, channelSeleted, addFilter, formatMessage) => {
    if (items.length === 0 || (items.length <= 1 && channelOptions.length <= 1)) return
    const statusText = (status) => status === INACTIVE_ACQUIRING_STATUS ? formatMessage(SETTINGS_TEXTS[`merchant_profile_status_${status}`]) : ''
    const filterType = FILTERS.source_merchant_ids
    const buttonText = formatMessage(FILTER_BUTTON_TEXT['source_merchant_id'])
    items = formatItems(items, statusText)
    const selectedOptions = items.length === 1 ? items : sourceMerchantIdsSelected

    const onChange = (selectedItem) => {
        addFilter([{type: filterType, value: selectedItem}])
    }

    return {
        options: items,
        selectedOptions: selectedOptions ? selectedOptions : [],
        isDisabled: items.length === 1 && channelSeleted,
        buttonText: buttonText,
        className: 'dropdown-mids',
        onChange: onChange,
        mode: items.length === 1 ? 'single' : 'multi',
        align: 'right',
        filterType: filterType,
        title: buttonText
    }
}

const getStandardMidsFilterProps = (items, channelOptions, sourceMerchantIdsSelected, channelSeleted, addFilter, formatMessage) => {
    if (items.length === 0 || (items.length <= 1 && channelOptions.length <= 1)) return
    const statusText = (status) => status === INACTIVE_ACQUIRING_STATUS ? formatMessage(SETTINGS_TEXTS[`merchant_profile_status_${status}`]) : ''
    items = formatItems(items, statusText)
    const selectedOptions = items.length === 1 ? items : sourceMerchantIdsSelected

    const onChange = (selectedItem) => {
        addFilter([{type: FILTERS.source_merchant_ids, value: selectedItem}])
    }

    return {
        items: items,
        selectedOptions: selectedOptions ? selectedOptions : [],
        disable: items.length === 1 && channelSeleted,
        label: selectedOptions && selectedOptions.length === 1 ? selectedOptions[0].text : formatMessage(FILTER_BUTTON_TEXT['source_merchant_id']),
        className: 'dropdown-mids',
        onChange: onChange,
        align: 'right',
        filter: items.length >= ITEM_FILTER_VISIBILITY_THRESHOLD,
        multiSelect: true,
        labelFilterPlaceholderText: formatMessage(DROPDOWNS['filter_placeholder']),
        labelFilterClearText: formatMessage(DROPDOWNS['clear_selections'])}
}

const getCurrencyFilterProps = (options, currencySelected, addFilter, formatMessage) => {
    if (options && options.length <= 1) return
    const selectedCurrency = currencySelected ? currencySelected : options[0]

    const selectedOptions = [{
        text: selectedCurrency,
        img: getFlagSrc(selectedCurrency)
    }]

    const filterType = FILTERS.currency
    const onChange = (options) => {
        addFilter([{ type: filterType, value: options[0].text }])
    }
    options = options.map((option) => ({ text: option, img: getFlagSrc(option) }))
    return { options, selectedOptions, className: 'dropdown-currency', mode: 'single', onChange, align: 'right', filterType: filterType, title: formatMessage(FILTER_BUTTON_TEXT['currency']) }
}

const DateFilterOptions = ({ selectDateFilter, isYearly, formatMessage }) => {
    const buttonArray = ['year', 'month']

    const data = buttonArray.map(key => {
        const checked = key === 'year' ? isYearly : !isYearly
        return {
            name: 'period',
            value: key,
            checked,
            onChange: selectDateFilter,
            text: formatMessage(DASHBOARD_TRANSLATIONS[key])
        }
    })

    return (
        <ToggleButton data={data} className="date-toggle" />
    )
}

const ChannelToggleBtn = ({ options, channelSeleted, filterType, addFilter }) => {
    const data = options.map(channel => {
        const checked = channel === channelSeleted
        const onChange = (e) => {
            const filters = [{
                type: filterType,
                value: e.target.value
            }, {
                type: FILTERS.source_merchant_ids,
                value: []
            }]

            addFilter(filters)
        }
        return {
            name: 'channel',
            value: channel,
            checked,
            onChange,
            text: channel
        }
    })

    return (
        <ToggleButton data={data} className="channel-toggle" />
    )
}

const CombineFilters = ({dropdownProps, formatMessage, channelFilterProps, channelOptions, addFilter, selectDateFilter, filterSelectedValue}) => {
    const [showFilterModal, setShowFilterModal] = useState(false)
    return (
        <React.Fragment>
            <button className="rebranded-primary-btn" style={{borderRadius: '5px'}}
                onClick={() => setShowFilterModal(true)}>{formatMessage(FILTER_BUTTON_TEXT['filters'])}
            </button>
            <Modal
                className="dashboard-filter-modal filter-modal"
                show={showFilterModal}
                onHide={() => setShowFilterModal(false)}>
                <Modal.Header>
                    <h3>{formatMessage(FILTER_BUTTON_TEXT['filters'])}</h3>
                    <div className="close-modal" onClick={() => setShowFilterModal(false)}>
                        <BamboraSvgIcon className="fill-inherit" icon={'close-thin'} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="dashboard-filter-modal__toggle-btns">
                        <DateFilterOptions
                            selectDateFilter={selectDateFilter}
                            isYearly={filterSelectedValue.isYearly}
                            formatMessage={formatMessage}
                        />
                        {channelOptions && channelOptions.length > 1 && <ChannelToggleBtn options={channelOptions} addFilter={addFilter} channelSeleted={filterSelectedValue.channel} filterType={channelFilterProps.filterType} />}
                    </div>
                    {dropdownProps.map(dropdown => dropdown && dropdown.filterType !== 'channels' && <FilterMobile
                        key={`dashboard-mobile-filter-${dropdown.filterType}`}
                        {...dropdown}
                    />)}
                </Modal.Body>
                <button className="rebranded-primary-btn filter-modal__apply-btn" onClick={() => setShowFilterModal(false)}>
                    {formatMessage(FILTER_BUTTON_TEXT['apply'])}
                </button>
            </Modal>
        </React.Fragment>
    )
}

const StandardFilters = ({alignmentClass, selectDateFilter, filterSelectedValue, formatMessage, currencyFilterProps, channelFilterProps, midsFilterProps}) => {
    return (
        <div className={`dropdowns ${alignmentClass}`}>
            <DateFilterOptions selectDateFilter={selectDateFilter} isYearly={filterSelectedValue.isYearly} formatMessage={formatMessage}/>
            {currencyFilterProps && <SelectDropdown key={`dashboard-filter-${currencyFilterProps.filterType}`} {...currencyFilterProps}/>}
            {channelFilterProps && <SelectDropdown key={`dashboard-filter-${channelFilterProps.filterType}`} {...channelFilterProps}/>}
            {midsFilterProps && midsFilterProps.items.length > 1 && <Dropdown {...midsFilterProps} onChange={(_, items) => midsFilterProps.onChange(items)}/>}
        </div >)
}

const DashboardFilters = ({channelOptions, midOptions, currencyOptions, addFilter, selectDateFilter, filterSelectedValue, intl}) => {
    const hasMoreDropdowns = channelOptions.length <= 1 && midOptions.length <= 1 && currencyOptions.length <= 1
    const alignmentClass = hasMoreDropdowns ? 'dropdowns--date-toggle-left' : ''
    const formatMessage = intl.formatMessage
    const COMBINE_FILTERS = window.matchMedia('(max-width: 800px)').matches
    const [combineFilters, setCombineFilters] = useState(COMBINE_FILTERS)

    useEffect(() => {
        const handleResize = () => {
            const COMBINE_FILTERS = window.matchMedia('(max-width: 800px)').matches
            setCombineFilters(COMBINE_FILTERS)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const channelFilterProps = getChannelFilterProps(channelOptions, filterSelectedValue.channel, addFilter)
    const currencyFilterProps = getCurrencyFilterProps(currencyOptions, filterSelectedValue.currency, addFilter, formatMessage)

    if (combineFilters) {
        const midsFilterProps = getCombineMidsFilterProps(
            midOptions, channelOptions, filterSelectedValue.sourceMerchantId, filterSelectedValue.channel, addFilter, formatMessage, combineFilters)
        const dropdownProps = [currencyFilterProps, channelFilterProps, midsFilterProps]
        const props = {dropdownProps, formatMessage, channelFilterProps, channelOptions, addFilter, selectDateFilter, filterSelectedValue}
        return <CombineFilters {...props} />
    } else {
        const midsFilterProps = getStandardMidsFilterProps(
            midOptions, channelOptions, filterSelectedValue.sourceMerchantId, filterSelectedValue.channel, addFilter, formatMessage, combineFilters)
        const props = {alignmentClass, selectDateFilter, filterSelectedValue, formatMessage, currencyFilterProps, channelFilterProps, midsFilterProps}
        return <StandardFilters {...props} />
    }
}

export default injectIntl(DashboardFilters)
