import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import lookup from 'country-code-lookup'
import { Icon, Button, Paper } from '@frontrunners/ui-components'
import { SUPPORT_NUMBER_BY_COUNTRY, SUPPORT_EMAIL_BY_COUNTRY } from '../constants'
import { getMerchantCountry } from '../../common/helpers/findMerchant'
import { SUPPORT_TEXT as T } from './intlMessageKeys'
import { FAQ_EXTERNAL_URLS, CONTRACT_TERMS_AND_CONDITIONS_EXTERNAL_URLS } from './constants'
import { ApplicationContext } from '../../common/reactContext'
import './support.scss'


export const Support = () => {
    const { activeMerchant } = useContext(ApplicationContext)
    const merchantCountry = getMerchantCountry(activeMerchant)
    const merchantCountryDetails = merchantCountry ? lookup.byCountry(merchantCountry) : null

    return (
        <div className="support">
            <Contact merchantCountry={merchantCountry} />
            <Chat />
            <TermsAndConditions country={merchantCountryDetails} />
            <FAQ country={merchantCountryDetails} />
            <WelcomeGuide />
        </div>
    )
}

const Contact = ({ merchantCountry }) => {
    const { formatMessage: fm } = useIntl()
    const emailAddress = getEmailAddress(merchantCountry)

    return (
        <Paper className="contact">
            <Icon className="contact-icon" iconName="speachBubbles" scale={0.7} />
            <div className="support-card-body">
                <h4 className="settings-paper-subheader">
                    {fm(T['contact-card-header'])}
                </h4>
                <hr />
                <div className="contact-details">
                    <Icon iconName="telephone" className="phone" scale={1} />
                    {fm(T['contact-phone']) + ': '}
                    {getPhoneNumber(merchantCountry)}
                </div>
                <div className="contact-details">
                    <Icon iconName="envelope" className="email" scale={1.2} />
                    {fm(T['contact-email']) + ': '}
                    <a href={`mailto:${emailAddress}`} >{emailAddress}</a>
                </div>
            </div>
        </Paper>
    )
}

const TermsAndConditions = ({ country }) => {
    const { formatMessage: fm } = useIntl()
    const tacLink = CONTRACT_TERMS_AND_CONDITIONS_EXTERNAL_URLS.getURL(country?.iso2)

    return (
        <Paper>
            <Icon iconName="document2" className="toc-icon" scale={0.8} />
            <div className="support-card-body">
                <h4 className="settings-paper-subheader">
                    {fm(T['toc-header'])}
                </h4>
                <p>{fm(T['toc-body'])}</p>
            </div>
            <Button
                className="turquoise"
                iconName="newWindow"
                label={<div className="button-label">{fm(T['button-text-read'])}</div>}
                onClick={() => window.open(tacLink, '_blank')}
            />
        </Paper>
    )
}

const Chat = () => {
    const { formatMessage: fm } = useIntl()

    return (
        <Paper>
            <Icon iconName="headset" className="chat-icon" scale={0.8} />
            <div className="support-card-body">
                <h4 className="settings-paper-subheader">
                    {fm(T['chat-header'])}
                </h4>
                <p>{fm(T['chat-body'])}</p>
            </div>
            <Button
                className="turquoise"
                label={<div className="button-label">{fm(T['button-text-chat'])}</div>}
                onClick={() => document.getElementById('helpButtonSpan').click()}
            />
        </Paper>
    )
}

const FAQ = ({ country }) => {
    const { formatMessage: fm } = useIntl()
    const faqLink = FAQ_EXTERNAL_URLS.getURL(country?.iso2)

    return (
        <Paper>
            <Icon iconName="questionMark2" className="faq-icon" scale={1} />
            <div className="support-card-body">
                <h4 className="settings-paper-subheader">
                    {fm(T['faq-header'])}
                </h4>
                <p>{fm(T['faq-body'])}</p>
            </div>
            <Button
                className="turquoise"
                iconName="newWindow"
                label={<div className="button-label">{fm(T['button-text-read'])}</div>}
                onClick={() => window.open(faqLink, '_blank')}
            />
        </Paper>
    )
}

const WelcomeGuide = () => {
    const { formatMessage: fm } = useIntl()
    const url = window.location.href.replace('settings/support', 'welcome')

    return (
        <Paper>
            <Icon iconName="handshake" className="welcome-icon" scale={0.7} />
            <div className="support-card-body">
                <h4 className="settings-paper-subheader">
                    {fm(T['welcome-guide-header'])}
                </h4>
                <p>{fm(T['welcome-guide-body'])}</p>
            </div>
            <Button
                className="turquoise"
                label={<div className="button-label">{fm(T['button-text-start'])}</div>}
                onClick={() => window.location.replace(url)}
            />
        </Paper>
    )
}

const getPhoneNumber = (merchantCountry) => {
    return SUPPORT_NUMBER_BY_COUNTRY[merchantCountry.toLowerCase()] || SUPPORT_NUMBER_BY_COUNTRY.sweden
}

const getEmailAddress = (merchantCountry) => {
    return SUPPORT_EMAIL_BY_COUNTRY[merchantCountry.toLowerCase()] || 'support.nordics@worldline.com'
}
