import React from 'react'
import { CARD_TYPES, SALES_DETAILS_TEXTS } from '../../sales/constants'
import { formatCardNumber } from '../../../common/cardHelpers'
import lookup from 'country-code-lookup'


const AcquiringPaymentCardDetails = ({payment, formatMessage, localeCode}) => {
    const cardTypeDisplayKey = Object.keys(CARD_TYPES).includes(payment.card_type) ? CARD_TYPES[payment.card_type] : CARD_TYPES['Other']
    const cardCountryTranslated = getTranslatedCardCountry(payment, localeCode)

    return (
        <div className="col-2 acquiring right buffer">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_detail_header'])}</div>
            <div className="detail-wrapper">
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_number'])}</div>
                    <div className="element">{formatCardNumber(payment.card_number)}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_type'])}</div>
                    <div className="element">{payment.card_scheme}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['card_type'])}</div>
                    <div className="element">{formatMessage(cardTypeDisplayKey)}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['card_issuing_country'])}</div>
                    <div className="element">{cardCountryTranslated}</div>
                </div>
            </div>
        </div>
    )
}

export const getTranslatedCardCountry = (payment, localeCode) => {
    if (!payment.card_country) {
        return null
    }
    const cardCountry = lookup.byIso(parseInt(payment.card_country))
    const regionName = new Intl.DisplayNames(localeCode, { type: 'region' })
    return regionName.of(cardCountry.iso2)
}

export default AcquiringPaymentCardDetails
