import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { FILTER_WIDTH } from '../constants'
import DatePickerDropdown from '../../../common/components/datePickerDropdown'
import SearchBox from '../../../common/components/searchBox'
import { getMetaDataForView, anyActiveFilters, getOptionsForFilters } from '../helpers'
import { getMerchant } from '../../../common/helpers/findMerchant'
import { load } from '../actions'
import { SETTLEMENT_FILTER_TEXT } from '../../../settlements/constants'
import { SALES_DETAILS_TEXTS } from '../constants'
import FileComponent from '../../../reports/components/fileComponent'
import { requestSalesReport, requestSalesReportReset } from '../../../reports/actions'
import diffDay from '../helpers/diffDay'
import mixpanel from 'mixpanel-browser'
import FilterModal from '../../../common/components/filterModal'
import { mixpanelPrefix } from '../../../common/helpers/mixpanelPrefix'
import { Dropdown } from '@frontrunners/ui-components'
import './salesFilterContainer.scss'
import { createBrowserHistory as createHistory } from 'history'
import { DROPDOWNS } from '../../../dashboard/translations'
import { FILTER_BUTTON_TEXT } from '../constants'
import { parseSearch } from '../../../common/locationHelpers'
import { ITEM_FILTER_VISIBILITY_THRESHOLD } from '../../../common/constants'
import * as dateHelpers from '../../../common/dateHelpers'
import { format } from 'date-fns'
import { isMaintenanceMode } from '../../../common/dateHelpers'


const loadSalesActions = (activeView) => {
    const actions = load(activeView)
    return actions
}

const getInputLengthLimit = (activeView) => {
    if (activeView === 'acquiring') {
        return 12
    } else {
          return undefined
    }
}

const createClassForImg = (img, countryCode) => {
    const style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `.currency-flag.${countryCode} span {background-image: url(${img})}`
    document.getElementsByTagName('head')[0].appendChild(style)
  }

export const formatItem = (orgItem) => {
    const item = {...orgItem}
    item.text = item.text.charAt(0).toUpperCase() + item.text.slice(1)
    if (item.imgClass) {
        item.className = item.imgClass
    }
    if (item.img) {
        const countryCode = item.img.replace('/svg/', '').replace('.svg', '')
        item.className = `currency-flag ${countryCode}`
        createClassForImg(item.img, countryCode)
    }
    return item
}

// set to 30 days instead of 31 as a day is added to the end date in `requestSalesReport`
const timelimit = 30

class SalesFilterContainer extends Component {

    //Start of React Life cycle
    constructor(props) {
        super(props)
        this.state = {
            startDownloadProcess: false,
            openedFilter: null,
            isDatePickerOpen: false
        }

        this.applyDatesChange = this.applyDatesChange.bind(this)
        this.downloadReport = this.downloadReport.bind(this)
        this.clearFilters = this.clearFilters.bind(this)
        this.reportRef = React.createRef()
        this.dropdownRef = React.createRef()
    }

    setIsDatePickerOpen(valueOrFunction) {
        const valueToSet = typeof valueOrFunction === 'function'
            ? valueOrFunction(this.state.isDatePickerOpen)
            : valueOrFunction

        if (!valueToSet) {
            this.setState({ startDownloadProcess: false })
        }

        this.setState({ isDatePickerOpen: valueToSet })
    }

    render() {
        const merchantId = this.props.match.params.merchantId
        const activeView = this.props.activeView !== null ? this.props.activeView : this.props.activeViewState

        const metadata = getMetaDataForView(activeView, merchantId, this.props.merchant)
        let allFilters = Object.keys(metadata).filter((filterType) => this.showFilter(filterType))
        let showMoreFilters = false
        let filtersShows = allFilters
        const showFilterNumber = this.setShowFilterNumber()
        if (allFilters && allFilters.length > showFilterNumber) {
            filtersShows = allFilters.slice(0, showFilterNumber - 1)
            showMoreFilters = true
        }

        const { formatMessage } = this.props.intl
        const loading = this.props.reports.getIn(['sales', 'fetching'])
        const items_fetched = this.props.reports.getIn(['sales', 'items_fetched'])
        const items_total = this.props.reports.getIn(['sales', 'items_total'])
        const error = this.props.reports.getIn(['sales', 'error'])
        const report = { loading, format: 'excel', items_fetched, items_total, error }
        const availableFilters = this.getAvailableFilters(filtersShows)
        const inputLengthLimit = getInputLengthLimit(this.props.activeView)
        const getMode = (filterType) => {
            return activeView === 'online' && filterType === 'state' ? 'single' : 'multi'
        }

        const isError = this.props.acquiringList.getIn(['list', 'isError'])
        const isFetching = this.props.acquiringList.getIn(['list', 'isFetching'])
        const disableReportButton = isError || isFetching  || isMaintenanceMode(merchantId)

        return (
            <div className="filter-sales">
                {
                    !this.props.isMobile ? <div className="filter-wrapper">
                        <div className="filter-wrapper-left">
                            {this.renderDateFilter()}
                            {availableFilters.map((item) => {
                                return (
                                    <Dropdown
                                        key={`filterType ${item.filterType}`}
                                        label={item.title}
                                        items={item.options.map(formatItem)}
                                        multiSelect={getMode(item.filterType) === 'multi'}
                                        filter={item.options.length >= ITEM_FILTER_VISIBILITY_THRESHOLD}
                                        onChange={this.dropdownFilterChange.bind(this, item.filterType)}
                                        className="dropdown-filter"
                                        defaultSelectedItems={item.selectedOptions}
                                        labelFilterPlaceholderText={formatMessage(DROPDOWNS['filter_placeholder'])}
                                        clearSelectionsText={formatMessage(DROPDOWNS['clear_selections'])}
                                        reference={this.dropdownRef}
                                    />
                                )
                            })}
                            {showMoreFilters && this.renderCombinedFilter(false, allFilters.slice(showFilterNumber - 1))}
                            {<SearchBox
                                fetchByReference={this.fetchByReference.bind(this)}
                                placeHolder={this.getPlaceHolderForSearchbox(activeView, formatMessage)}
                                reference={this.props.filters.get('search')}
                                inputLengthLimit={inputLengthLimit}
                                clearQuery={this.clearQuery}
                            />
                            }
                            {this.renderClearAll()}
                        </div>
                        <div className="filter-wrapper-right sales-download-icon" data-testid="sales-download-btn">
                            <FileComponent
                                formatMessage={formatMessage}
                                disabled={disableReportButton}
                                fileRef="sales-download-excel"
                                report={report}
                                isText={false}
                                downloadReport={this.downloadReport}
                                ref={this.reportRef}
                            />
                        </div>
                    </div> : <div className="filter-wrapper--combined">
                            {this.renderCombinedFilter(true)}
                        </div>
                }
            </div>
        )
    }

    componentDidMount() {
        this._loadActions()
        if (this.props.location.state && this.props.location.state.query && this.actions) {
            const { search } = parseSearch(this.props.location.state.query)
            this.props.dispatch(this.actions.clearAllFilters(this.props.match.params.merchantId, false))
            setTimeout(() => {
                this.fetchByReference(search)
            })
        }
    }

    componentDidUpdate() {
        this._loadActions()
    }

    //End of React Life cycle

    downloadReport() {
        const loading = this.props.reports.getIn(['sales', 'fetching'])

        if (!loading) {
            let filter = this.props.filters.toJS()
            if (filter.offset !== undefined) delete filter.offset
            if (filter.limit !== undefined) delete filter.limit
            if (filter.return_total_count !== undefined) delete filter.return_total_count
            if (filter.source !== undefined) delete filter.source

            const activeView = this.props.activeView !== null ? this.props.activeView : this.props.activeViewState

            if (filter.from_datetime && filter.to_datetime
                && diffDay(filter.to_datetime, filter.from_datetime) <= timelimit) {
                const merchantId = this.props.match.params.merchantId
                const locale = this.props.localization.get('localeCode')
                this.props.dispatch(requestSalesReport(merchantId, locale, this.reportRef.current, filter, activeView))
            }
            else {
                this.state.startDownloadProcess = true
                this.setState({
                    startDownloadProces: true,
                    isDatePickerOpen: true
                })
            }
        }
    }

    setShowFilterNumber() {

        let showFilterNumber = 0
        if (!this.props.isMobile) {
            showFilterNumber = Math.floor(this.props.widthForFilterDropdown / FILTER_WIDTH)
        }

        return showFilterNumber

    }

    getPlaceHolderForSearchbox(activeView, formatMessage) {
        let placeholderKey
        switch (activeView) {
            case 'online':
                placeholderKey = 'searchbox_online_placeholder'
                break
            case 'instore':
                placeholderKey = 'searchbox_instore_placeholder'
                break
            default:
                placeholderKey = 'searchbox_acquiring_placeholder'
                break
        }
        return formatMessage(SALES_DETAILS_TEXTS[placeholderKey])
    }

    _loadActions() {
        if (this.props.activeView) {
            this.actions = loadSalesActions(this.props.activeView)
        }
    }

    applyDatesChange(range) {
        if (range) {
            const startDate = range.from
            const endDate = range.to
            const formatString = "yyyy-MM-dd'T'HH':'mm':'ss'.'SSS'Z'" // eslint-disable-line
            this.applyFilter('from_datetime', format(startDate, formatString))
            this.applyFilter('to_datetime', format(endDate, formatString))

            setTimeout(() => {
                this.fetchList()
            })

            mixpanel.track(
                `${mixpanelPrefix.SALES} Applied date filter in ${this.props.activeView} view.`
            )
        } else {
            this.clearDates()
        }
    }

    applyFilter(filterType, filterValue) {
        this.props.dispatch(requestSalesReportReset())
        this.props.dispatch(this.actions.applyFilter(
            this.props.activeView,
            filterType,
            filterValue)
        )
    }

    fetchList() {
        const merchantObject = getMerchant(this.props.match.params.merchantId, this.props.merchant)
        const hasActiveFilter = anyActiveFilters(this.props.filters.toJS())
        this.props.dispatch(this.actions.getList(
            merchantObject,
            this.props.match.params.merchantId,
            this.props.filters.toJS(),
            hasActiveFilter
        ))
    }

    combineFilterChange(filterType, selectedItem) {
        this.filterChange(filterType, selectedItem)
    }

    dropdownFilterChange(filterType, _, selectedItems) {
        this.filterChange(filterType, selectedItems)
    }

    filterChange(filterType, items) {
        items = items.map(item => item.id ? item.id : item.text)
        this.props.dispatch(this.actions.filterChange(filterType, items))
        setTimeout(() => {
            this.fetchList()
        })

    }

    _closeActiveFilter() {
        this.setState({ openedFilter: null })
    }

    _openFilter(filterType) {
        this.setState({ openedFilter: filterType })
    }

    toggleFilter(filterType) {
        if (this.state.openedFilter === filterType || filterType === null) {
            this._closeActiveFilter()
        } else {
            this._openFilter(filterType)
        }
    }

    clearFilters(filterType) {
        const activeView = this.props.activeView
        this.props.dispatch(
            this.actions.clearFilter(this.props.match.params.merchantId, activeView, filterType)
        )
        this.props.dispatch(requestSalesReportReset())
    }

    isDateSelected() {
        return !!this.props.filters.get('from_datetime')
    }

    clearDates() {
        this.applyFilter('to_datetime', null)
        this.applyFilter('from_datetime', null)
        setTimeout(() => {
            this.fetchList()
        })
    }

    getFilterDates() {
        const fromDateFilter = this.props.filters.get('from_datetime')
        const toDateFilter = this.props.filters.get('to_datetime')
        const fromDate = fromDateFilter ? new Date(this.props.filters.get('from_datetime')) : null
        const toDate = toDateFilter ? new Date(this.props.filters.get('to_datetime')) : null
        return { fromDate, toDate }
    }

    renderDateFilter() {
        const { fromDate, toDate } = this.getFilterDates()
        const defaultRange = fromDate && toDate ? dateHelpers.createDateRange(fromDate, toDate) : null

        const minSelectableDate = dateHelpers.DataAvailableFromDate.instance.getDate()
        const maxSelectableDate = dateHelpers.getTodayMidnight()

        return (
            <DatePickerDropdown
                isOpen={this.state.isDatePickerOpen}
                setIsOpen={this.setIsDatePickerOpen.bind(this)}
                defaultRange={defaultRange}
                minSelectableDate={minSelectableDate}
                maxSelectableDate={maxSelectableDate}
                maxDayRange={this.state.startDownloadProcess ? 31 : null}
                onDatesChange={this.applyDatesChange}
                localeCode={this.props.localization.get('localeCode')}
                key={this.props.activeViewState}
            />
        )
    }

    disableFilter(items) {
        return !items || items.length <= 1
    }
    showFilter(filterType) {
        const activeView = this.props.activeView !== null ? this.props.activeView : this.props.activeViewState
        const filterValues = getMetaDataForView(activeView, this.props.match.params.merchantId, this.props.merchant)[filterType]
        return !this.disableFilter(filterValues)
    }

    _isActiveClass(filterType) {
        const filters = this.props.filters.getIn([filterType, 'selected'])
        return filters && filters.size > 0 ? 'active-filter' : ''
    }

    _getStateForFilter(filterType) {
        const activeView = this.props.activeView !== null ? this.props.activeView : this.props.activeViewState
        const filterValues = getMetaDataForView(activeView, this.props.match.params.merchantId, this.props.merchant)[filterType]
        const toggleClass = this.state.openedFilter === filterType ? 'opened' : ''
        const selectedFilterValues = this.props.filters.get(filterType) ? this.props.filters.getIn([filterType, 'selected']) : new Set()
        const overflowClass = toggleClass ? ' overflow' : 'hide'
        const filterButtonActiveClass = this._isActiveClass(filterType)
        const disableFilter = this.disableFilter(filterValues)
        const { formatMessage } = this.props.intl
        const cssClasses = { toggleClass, overflowClass, filterButtonActiveClass, filterType }

        return { activeView, filterValues, selectedFilterValues, cssClasses, disableFilter, formatMessage, filterType }
    }

    clearQuery() {
        const history = createHistory()
        if (history.location.state && history.location.state.query) {
            let state = { ...history.location.state }
            delete state.query
            history.replace({ ...history.location, state })
        }
    }

    renderClearAll() {
        if (!anyActiveFilters(this.props.filters.toJS())) return null

        const { formatMessage } = this.props.intl
        const merchantId = this.props.match.params.merchantId
        const dropdownIsAvailable = this.dropdownRef.current !== null

        return (
            <div className="filters-sales clear-all"
                onClick={() => {
                    this.props.dispatch(this.actions.clearAllFilters(merchantId))
                    this.props.dispatch(requestSalesReportReset())
                    this.clearQuery()
                    this.clearFilters()
                    dropdownIsAvailable && this.dropdownRef.current.clearSelection()
                }}>
                {formatMessage(SETTLEMENT_FILTER_TEXT['settlement-filter-clear-all'])}
            </div>
        )
    }

    getAvailableFilters(enableFilters) {
        const activeView = this.props.activeView !== null ? this.props.activeView : this.props.activeViewState

        const filters = this.props.filters

        const { formatMessage } = this.props.intl

        const merchantId = this.props.match.params.merchantId

        const metadata = getMetaDataForView(activeView, merchantId, this.props.merchant)

        let availableFilters = Object.keys(metadata)
        availableFilters = availableFilters.filter((filterType) => this.showFilter(filterType))

        if (enableFilters) {
            availableFilters = availableFilters.filter((key) => enableFilters.includes(key))
        }
        availableFilters = availableFilters.map(filterType => {
            const options = getOptionsForFilters(filterType, metadata[filterType], formatMessage)
            const selectedOptions = options.filter(item => {
                const key = item.id ? item.id : item.text
                const selectedFilterValues = filters.get(filterType) ? filters.getIn([filterType, 'selected']) : new Set()
                return selectedFilterValues && selectedFilterValues.has(key)
            })
            const title = formatMessage(FILTER_BUTTON_TEXT[filterType])
            return { options, selectedOptions, filterType, title }
        })

        return availableFilters
    }

    renderCombinedFilter(isMobile, moreFilters) {
        let availableFilters = this.getAvailableFilters(moreFilters)

        if (!availableFilters || !availableFilters.length) return this.renderDateFilter('right')

        return (
            <FilterModal
                opened={this.state.openedFilter}
                filters={this.props.filters}
                availableFilters={availableFilters}
                onFilterChange={this.combineFilterChange.bind(this)}
                toggleCombineFilter={this.toggleFilter.bind(this, 'combined')}
                moreFilters={moreFilters}
                isMobile={isMobile}
                renderClearAll={this.renderClearAll.bind(this)}
                renderDateFilter={this.renderDateFilter.bind(this)}
                isDateSelected={this.isDateSelected.bind(this)}
            />
        )
    }

    fetchByReference(value) {
        this.setReference(value)
        setTimeout(() => {
            this.fetchList()
        })
    }
    setReference(value) {
        this.applyFilter('search', value)
        mixpanel.track(
            `${mixpanelPrefix.SALES} Applied search filter in ${this.props.activeView} view`
        )
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    const activeView = state.getIn([activeMerchant, 'sales', 'activeView'])
    return {
        merchant: state.get('merchant'),
        sales: state.getIn([activeMerchant, 'sales']),
        activeView: activeView,
        filters: state.getIn([activeMerchant, activeView, 'filters']),
        localization: state.get('localization'),
        acquiringList: state.getIn([activeMerchant, 'acquiring']),
        reports: state.getIn([activeMerchant, 'reports'])
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(SalesFilterContainer)))
