import { defineMessages } from 'react-intl'

export const NAME = 'sales'

export const WINDOW_SIZE_COMBILED_FILTER = 150

export const FILTER_WIDTH = 150

export const SALES_TYPES = {
    acquiring: 'acquiring',
    instore: 'instore',
    online: 'online'
}

export const INSTORE_PAYMENT_STATES = ['Authorized', 'Captured', 'Voided', 'Failed', 'Cleared']
export const ONLINE_PAYMENT_STATES = ['capturable', 'fully_captured', 'refunded', 'canceled', 'rejected']
export const INSTORE_PAYMENT_TYPES = ['Sale', 'Refund']

export const ACTIVE_TABS = {
    detail: 'isDetailActive',
    history: 'isHistoryActive',
    orderlines: 'isOrderlinesActive'
}

export const STATE_VIEW_TO_SALE_TYPE = {
    showAcquiringView: 'acquiring',
    showInstoreView: 'instore',
    showOnlineView: 'online'
}
export const SALES_TYPES_MAPPING_TO_STATE = {
    acquiring: 'showAcquiringView',
    instore: 'showInstoreView',
    online: 'showOnlineView'
}

export const DEFAULT_VIEWS = {
    views: {
        showAcquiringView: false,
        showInstoreView: false,
        showOnlineView: false
    },
    activeView: null
}

export const SALES_TYPES_SUB_MENU = defineMessages({
    acquiring: {
        id: 'sales-sub-menu-acquiring',
        defaultMessage: 'Acquiring',
        description: 'Sub menu sales type (acquiring)'
    },
    online: {
        id: 'sales-sub-menu-online',
        defaultMessage: 'Online',
        description: 'Sub menu sales type (online)'
    },
    instore: {
        id: 'sales-sub-menu-instore',
        defaultMessage: 'Instore',
        description: 'Sub menu sales type (instore)'
    }
})


export const SALES_ERROR_TEXT_TITLES = defineMessages({
    acquiring: {
        id: 'acquiring-error-title',
        defaultMessage: 'Bummer, we couldn\'t find your acquiring sales..',
        description: 'Failed getting acquiring list title'
    },
    instore: {
        id: 'instore-error-title',
        defaultMessage: 'Bummer, we couldn\'t find your instore sales..',
        description: 'Failed getting instore list title'
    },
    online: {
        id: 'online-error-title',
        defaultMessage: 'Bummer, we couldn\'t find your online sales..',
        description: 'Failed getting online list title'
    }
})

export const SALES_ERROR_TEXT_BODY = defineMessages({
    sales_fetching_error: {
        id: 'sales-error-body',
        defaultMessage: 'Please try to refresh this page or log out and in again.',
        description: 'Failed getting sales list body'
    }
})

export const SALES_EMPTY_STATE_TITLES = defineMessages({
    acquiring: {
        id: 'acquiring-empty-state-title',
        defaultMessage: 'You have no acquiring transactions yet',
        description: 'Failed getting acquiring list title'
    },
    instore: {
        id: 'instore-empty-state-title',
        defaultMessage: 'You have no instore transactions yet..',
        description: 'Failed getting instore list title'
    },
    online: {
        id: 'online-empty-state-title',
        defaultMessage: 'You have no instore transactions yet..',
        description: 'Failed getting online list title'
    }
})

export const SALES_EMPTY_BODY_TEXT = defineMessages({
    acquiring: {
        id: 'no-acquiring-body',
        defaultMessage: 'But once you get set up, all your transactions will live here.',
        description: 'Transaction list empty state body text'
    },
    instore: {
        id: 'no-instore-body',
        defaultMessage: 'But once you get set up, all your instore transactions will live here.',
        description: 'Transaction list empty state body text'
    },
    online: {
        id: 'no-online-body',
        defaultMessage: 'But once you get set up, all your online transactions will live here.',
        description: 'Transaction list empty state body text'
    }
})

export const SALES_MAIN_TITLE = defineMessages({
    acquiring: {
        id: 'acquiring-main-sale-title',
        defaultMessage: 'Transactions',
        description: 'main header acquiring list title'
    },
    instore: {
        id: 'instore-main-sale-title',
        defaultMessage: 'Orders',
        description: 'main header instore list title'
    },
    online: {
        id: 'online-main-sale-title',
        defaultMessage: 'Instore',
        description: 'main header online list title'
    }
})

export const SALES_EMPTY_FILTER_TITLE = defineMessages({
    empty_filter: {
        id: 'no-filtered-transactions-title',
        defaultMessage: 'Nothing to see here...',
        description: 'Filtered transaction list is empty'
    }
})

export const SALES_EMPTY_FILTER_BODY = defineMessages({
    acquiring: {
        id: 'no-filtered-transactions-body',
        defaultMessage: 'We could not find any transactions matching your filter.',
        description: 'Filtered transaction list is empty body text'
    },
    instore: {
        id: 'no-filtered-instore-body',
        defaultMessage: 'We could not find any instore transactions matching your filter.',
        description: 'Filtered instore transaction list is empty body text'
    },
    online: {
        id: 'no-filtered-online-body',
        defaultMessage: 'We could not find any online transactions matching your filter.',
        description: 'Filtered online transaction list is empty body text'
    }
})

export const ONLINE_TRANSACTION_STATES_TEXT = defineMessages({
    canceled: {
        id: 'online-list-item-canceled',
        defaultMessage: 'Canceled',
        description: 'Transaction has been canceled'
    },
    rejected: {
        id: 'online-list-item-rejected',
        defaultMessage: 'Rejected',
        description: 'Transaction has been rejected'
    },
    declined: {
        id: 'online-list-item-declined',
        defaultMessage: 'Declined',
        description: 'Transaction has been declined'
    },
    captured: {
        id: 'online-list-item-captured-button',
        defaultMessage: 'Capture',
        description: 'Capture button'
    },
    refunded: {
        id: 'online-list-item-refunded-button',
        defaultMessage: 'Refund',
        description: 'Refunded button'
    }
})

export const INSTORE_REFUND_TEXTS = defineMessages({
    invalid_input_reason: {
        id: 'invalid-input-too-many-characters',
        defaultMessage: 'Maximum characters',
        description: 'Maximum characters:'
    },
    invalid_input_amount: {
        id: 'invalid-input-amount',
        defaultMessage: 'Amount must consist of 0 or 2 decimals e.g. 100 or 100.00',
        description: 'Format amount'
    },
    maximum_refund: {
        id: 'input-maximum-refund-amount',
        defaultMessage: 'Maxium amount to refund',
        description: 'Maxium amount to refund'
    }
})


export const FILTER_BUTTON_TEXT = defineMessages({
    dates: {
        id: 'filter-standalone-dates',
        defaultMessage: 'Dates',
        description: 'Dates'
    },
    apply: {
        id: 'filter-apply-button',
        defaultMessage: 'Apply',
        description: 'Apply'
    },
    filters: {
        id: 'filter-header-title',
        defaultMessage: 'Filters',
        description: 'Filters'
    },
    clear: {
        id: 'filter-header-clear',
        defaultMessage: 'Clear',
        description: 'Clear.'
    },
    from: {
        id: 'filter-standalone-date-from-dropdown',
        defaultMessage: 'From date',
        description: 'Date (from) filter in sales view'
    },
    to: {
        id: 'filter-standalone-date-to-dropdown',
        defaultMessage: 'To date',
        description: 'Date (to) filter in sales view'
    },
    currency: {
        id: 'filter-standalone-currency-dropdown',
        defaultMessage: 'Currency',
        description: 'Currency filter in sales view'
    },
    source_merchant_id: {
        id: 'filter-standalone-saleslocation-dropdown',
        defaultMessage: 'Saleslocation',
        description: 'Saleslocation filter in sales view'
    },
    card_scheme: {
        id: 'filter-standalone-card-type',
        defaultMessage: 'Card type',
        description: 'Card type'
    },
    type: {
        id: 'filter-transaction-type',
        defaultMessage: 'Type',
        description: 'Type'
    },
    state: {
        id: 'filter-standalone-state',
        defaultMessage: 'State',
        description: 'State'
    },
    payment_category: {
        id: 'filter-standalone-transaction-type',
        defaultMessage: 'Type',
        description: 'Type'
    },
    terminal_id: {
        id: 'filter-standalone-terminal-id',
        defaultMessage: 'Terminal ID',
        description: 'Terminal ID'
    },
    cashier_identity: {
        id: 'filter-standalone-cashier-id',
        defaultMessage: 'Cashier ID',
        description: 'Cashier ID'
    },
    payment_type: {
        id: 'filter-standalone-payment-type',
        defaultMessage: 'Payment type',
        description: 'Card type'
    }
})


export const FILTER_STATES = defineMessages({
    authorized: {
        id: 'payment-instore-state-authorized',
        defaultMessage: 'Authorized',
        description: 'Authorized state on a payment'
    },
    captured: {
        id: 'payment-instore-state-capatured',
        defaultMessage: 'Captured',
        description: 'Captured state on a payment'
    },
    voided: {
        id: 'payment-instore-state-voided',
        defaultMessage: 'Voided',
        description: 'Voided state on a payment'
    },
    failed: {
        id: 'payment-instore-state-failed',
        defaultMessage: 'Failed',
        description: 'Failed state on a payment'
    },
    cleared: {
        id: 'payment-instore-state-cleared',
        defaultMessage: 'Cleared',
        description: 'Cleared state on a payment'
    },
    capturable: {
        id: 'online-sate-sidebar-open',
        defaultMessage: 'Open',
        description: 'Filter to see online that can be captured'
    },
    fully_captured: {
        id: 'online-sate-sidebar-captured-online',
        defaultMessage: 'Captured',
        description: 'Filter to see fully captured online '
    },
    refunded: {
        id: 'online-sate-sidebar-refunded',
        defaultMessage: 'Refunded',
        description: 'Filter to see refunded transactions'
    },
    canceled: {
        id: 'online-sate-sidebar-canceled',
        defaultMessage: 'Canceled',
        description: 'Filter to see canceled online'
    },
    rejected: {
        id: 'online-sate-sidebar-rejected',
        defaultMessage: 'Rejected',
        description: 'Filter to see rejected online'
    },
    sale: {
        id: 'transaction-list-type-sale',
        defaultMessage: 'Sale',
        description: 'Sale'
    },
    refund: {
        id: 'transaction-list-type-refund',
        defaultMessage: 'Refund',
        description: 'Refund'
    }
})

export const STATE_CAPTURE_INACTIVE_VIEW = {
    'AUTHORIZED': {
        button: true,
        amount: false,
        shouldToggle: false
    },
    'CAPTURED': {
        button: false,
        amount: true,
        shouldToggle: false

    },
    'CANCELED': {
        button: false,
        amount: false,
        shouldToggle: false
    },
    'REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: false
    },
    'PARTLY_CAPTURED': {
        button: false,
        amount: true,
        shouldToggle: true
    },
    'PARTLY_REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: false
    },
    'PARTLY_CAPTURED_PARTLY_REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: true
    },
    'REJECTED': {
        button: false,
        amount: false,
        shouldToggle: false
    },
    'ZERO_AUTH': {
        button: false,
        amount: true,
        shouldToggle: false
    }
}

export const STATE_REFUND_INACTIVE_VIEW = {
    'AUTHORIZED': {
        button: false,
        amount: true,
        shouldToggle: false
    },
    'CAPTURED': {
        button: true,
        amount: false,
        shouldToggle: false

    },
    'CANCELED': {
        button: false,
        amount: false,
        shouldToggle: false
    },
    'REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: false
    },
    'PARTLY_CAPTURED': {
        button: true,
        amount: false,
        shouldToggle: false
    },
    'PARTLY_REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: true
    },
    'PARTLY_CAPTURED_PARTLY_REFUNDED': {
        button: false,
        amount: true,
        shouldToggle: true
    },
    'REJECTED': {
        button: false,
        amount: false,
        shouldToggle: false
    }
}

export const CARD_TYPES = defineMessages({
    Other: {
        id: 'card-type-other',
        defaultMessage: 'Other',
        description: 'Card type'
    },
    Debit: {
        id: 'card-type-debit',
        defaultMessage: 'Debit',
        description: 'Card type'
    },
    Credit: {
        id: 'card-type-credit',
        defaultMessage: 'Credit',
        description: 'Card type'
    },
    Commercial: {
        id: 'card-type-commercial',
        defaultMessage: 'Commercial',
        description: 'Card type'
    },
    CommercialDebit: {
        id: 'card-type-commercial-debit',
        defaultMessage: 'Commercial Debit',
        description: 'Card type'
    }
})

export const SALES_DETAILS_TEXTS = defineMessages({
    eci: {
        id: 'online-detail-eci',
        defaultMessage: 'ECI',
        description: 'Electronic Commerce Indicator'
    },
    original_transaction_reference: {
        id: 'original-tranaction-reference',
        defaultMessage: 'Original transaction reference',
        description: 'OTR'
    },
    reason: {
        id: 'refund-reason',
        defaultMessage: 'Reason',
        description: 'Reason for refund'
    },
    sales_details_back: {
        id: 'sales-details-back',
        defaultMessage: 'Back',
        description: 'Back'
    },
    transaction_details: {
        id: 'sales-details-transaction-details',
        defaultMessage: 'Transaction Details',
        description: 'Transaction Details'
    },
    card_type: {
        id: 'transaction-details-card-detailfield-card-type',
        defaultMessage: 'Card type',
        description: 'Card Type'
    },
    details_instore_id: {
        id: 'details_instore_id',
        defaultMessage: 'Instore ID',
        description: 'Instore ID'
    },
    details_acquiring_id: {
        id: 'details_acquiring_id',
        defaultMessage: 'Acquiring ID',
        description: 'Acquiring ID'
    },
    details_online_id: {
        id: 'details_online_id',
        defaultMessage: 'Online ID',
        description: 'Online ID'
    },
    failed_get_details_header: {
        id: 'failed-get-details-header',
        defaultMessage: 'Something went wrong..',
        description: 'Error message when failing to get details about payment'
    },
    failed_get_details_body: {
        id: 'failed-get-details-body',
        defaultMessage: 'Could not get additional information about this payment',
        description: 'Error message when failing to get details about payment'
    },
    authorized_date: {
        id: 'sales-details-online-authorization-date',
        defaultMessage: 'Authorization date',
        description: 'Authorization date for online payment in detail view'
    },
    details_tab: {
        id: 'sales-details-online-details-tab',
        defaultMessage: 'Details',
        description: 'Details header in tab details'
    },
    history_tab: {
        id: 'sales-history-online-history-tab',
        defaultMessage: 'History',
        description: 'history header in tab history'
    },
    purchased_items_tab: {
        id: 'sales-details-online-purchased-items-tab',
        defaultMessage: 'Purchased items',
        description: 'Details header in tab purchased items'
    },
    purchased_items_header: {
        id: 'sales-details-online-purchased-items-header',
        defaultMessage: 'Purchased Items',
        description: 'Purchased items header'
    },
    transaction_detail_header: {
        id: 'sales-details-online-transaction-detail-header',
        defaultMessage: 'Transaction',
        description: 'Transaction detail header'
    },
    status_detail_header: {
        id: 'sales-details-online-status-detail-header',
        defaultMessage: 'Status',
        description: 'Status detail header'
    },
    payment_detail_header: {
        id: 'sales-details-online-payment-detail-header',
        defaultMessage: 'Payment',
        description: 'Payment detail header'
    },
    payment_detail_bambora_txn_id: {
        id: 'sales-details-online-bambora-txn-id',
        defaultMessage: 'Bambora Reference',
        description: 'Bambora Transaction ID detail'
    },
    payment_detail_order_id: {
        id: 'sales-details-online-order-id',
        defaultMessage: 'Order ID',
        description: 'Order ID detail'
    },
    payment_detail_authorized: {
        id: 'sales-details-online-authorized',
        defaultMessage: 'Authorized',
        description: 'Authorized'
    },
    payment_detail_captured: {
        id: 'sales-details-online-payment-detail-captured',
        defaultMessage: 'Captured',
        description: 'Payment detail captured'
    },
    payment_instore_operation_settled: {
        id: 'sales-details-online-payment-detail-settled',
        defaultMessage: 'Money sent to your bank account',
        description: 'Payment detail settled'
    },
    payment_detail_refunded: {
        id: 'sales-details-online-payment-detail-refunded',
        defaultMessage: 'Refunded',
        description: 'Payment detail refunded'
    },
    payment_detail_payment_type: {
        id: 'sales-details-online-payment-detail-type',
        defaultMessage: 'Type',
        description: 'type in details'
    },
    payment_detail_identifer: {
        id: 'sales-details-online-payment-detail-identifer',
        defaultMessage: 'Identifer',
        description: 'Payment detail indentifer'
    },
    payment_detail_3d_secure: {
        id: 'sales-details-online-payment-detail-3d-secure',
        defaultMessage: '3D Secure',
        description: '3D secure details'
    },
    payment_detail_history_header: {
        id: 'payment-detail-history-header',
        defaultMessage: 'History',
        description: 'History header on detail page'
    },
    payment_acquiring_amount: {
        id: 'payment-acquiring-amount',
        defaultMessage: 'Amount',
        description: 'Amount'
    },
    payment_acquiring_type: {
        id: 'payment-acquiring-type',
        defaultMessage: 'Type',
        description: 'Type'
    },
    payment_acquiring_date: {
        id: 'payment-acquiring-date',
        defaultMessage: 'Date',
        description: 'Date'
    },
    payment_acquiring_time: {
        id: 'payment-acquiring-time',
        defaultMessage: 'Time',
        description: 'Time'
    },
    payment_acquiring_merchant: {
        id: 'payment-acquiring-merchant',
        defaultMessage: 'Merchant',
        description: 'Merchant'
    },
    payment_acquiring_reference: {
        id: 'payment-acquiring-reference',
        defaultMessage: 'Card number or payment reference',
        description: 'Reference'
    },
    payment_acquiring_payout_reference: {
        id: 'payment-acquiring-batch',
        defaultMessage: 'Payout reference',
        description: 'Payout reference'
    },
    payment_acquiring_transaction: {
        id: 'payment-acquiring-transaction',
        defaultMessage: 'Transaction',
        description: 'Transaction'
    },
    payment_acquiring_card_scheme: {
        id: 'payment-acquiring-card-scheme',
        defaultMessage: 'Brand',
        description: 'Brand'
    },
    payment_acquiring_card_type: {
        id: 'payment-acquiring-card-type',
        defaultMessage: 'Type',
        description: 'Card Type'
    },
    payment_acquiring_card_number: {
        id: 'payment-acquiring-card-number-identifier',
        defaultMessage: 'Identifier',
        description: 'Card number Identifier'
    },
    payment_acquiring_card_detail_header: {
        id: 'payment-acquiring-card-detail-header',
        defaultMessage: 'Payment',
        description: 'Payment'
    },
    details_point_of_sale_header: {
        id: 'details_point_of_sale_header',
        defaultMessage: 'Point of sale',
        description: 'Point of sale'
    },
    details_merchant_id: {
        id: 'details-merchant-id',
        defaultMessage: 'Merchant',
        description: 'Merchant'
    },
    details_saleslocation: {
        id: 'details-saleslocation',
        defaultMessage: 'Sales location',
        description: 'Sales location'
    },
    payment_acquiring_additional_reference: {
        id: 'payment-acquiring-additional-reference',
        defaultMessage: 'Additional reference(s)',
        description: 'Additional references'
    },
    payment_instore_cashier_id: {
        id: 'payment-instore-cashier-id',
        defaultMessage: 'Cashier ID',
        description: 'Cashier ID'
    },
    payment_instore_tip_amount: {
        id: 'payment-instore-tip-amount',
        defaultMessage: 'Tip',
        description: 'Tip'
    },
    payment_instore_terminal_id: {
        id: 'payment-instore-terminal-id',
        defaultMessage: 'Terminal ID',
        description: 'Terminal ID'
    },
    payment_instore_transaction_id: {
        id: 'payment-instore-transaction-id',
        defaultMessage: 'Transaction ID',
        description: 'Transaction ID'
    },
    payment_instore_terminal_reference: {
        id: 'payment-instore-terminal-reference',
        defaultMessage: 'Terminal retrieval reference',
        description: 'Terminal retrieval reference'
    },
    payment_detail_3d_secure_yes: {
        id: 'payment-detail-3d-secure-yes',
        defaultMessage: 'Yes',
        description: 'Yes'
    },
    payment_detail_3d_secure_no: {
        id: 'payment-detail-3d-secure-no',
        defaultMessage: 'No',
        description: 'No'
    },
    payment_detail_provider: {
        id: 'payment-detail-provider',
        defaultMessage: 'Provider',
        description: 'Provider'
    },
    payment_instore_operation_authorized: {
        id: 'payment-instore-operation-authorized',
        defaultMessage: 'Authorized',
        description: 'Authorized'
    },
    payment_instore_operation_captured: {
        id: 'payment-instore-operation-captured',
        defaultMessage: 'Captured',
        description: 'Captured'
    },
    payment_instore_operation_voided: {
        id: 'payment-instore-operation-voided',
        defaultMessage: 'Cancelled',
        description: 'Cancelled'
    },
    payment_instore_operation_cleared: {
        id: 'payment-instore-operation-cleared',
        defaultMessage: 'Cleared',
        description: 'Cleared'
    },
    payment_instore_operation_refunded: {
        id: 'payment-instore-operation-refunded',
        defaultMessage: 'Refunded',
        description: 'Refunded'
    },
    payment_detail_instore_at: {
        id: 'payment-detail-instore-at',
        defaultMessage: 'at',
        description: 'at, as in "Im meeting a friend AT 9.30pm'
    },
    payment_success: {
        id: 'payment_success',
        defaultMessage: 'Success',
        description: 'Success'
    },
    payment_rejected: {
        id: 'payment_rejected',
        defaultMessage: 'Rejected',
        description: 'Rejected'
    },
    searchbox_online_placeholder: {
        id: 'searchbox_online_placeholder',
        defaultMessage: 'Order ID, card number or payment reference',
        description: 'Reference or Order ID'
    },
    searchbox_instore_placeholder: {
        id: 'searchbox_instore_placeholder',
        defaultMessage: 'Card number or payment reference',
        description: 'Reference'
    },
    searchbox_acquiring_placeholder: {
        id: 'searchbox_acquiring_placeholder',
        defaultMessage: 'Card number or payment reference',
        description: 'Reference or card number'
    },
    payment_instore_transaction_reference: {
        id: 'payment_instore_transaction_reference',
        defaultMessage: 'Transaction reference',
        description: 'Transaction reference'
    },
    card_issuing_country: {
        id: 'card_issuing_country',
        defaultMessage: 'Issuing country',
        description: 'Card issuing country'
    }
})
