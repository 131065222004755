import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { RoleToggle2 } from '../../../common/containers/roleToggle'
import Spinner from '../../../common/components/spinner'
import { ROLES, SETTINGS_TEXTS, SETTINGS_USER_TEXT } from '../../../merchant/constants'
import { Button, ButtonV2 } from '@frontrunners/ui-components'
import { AddUserModal, RemoveUserModal, RenameUserModal } from './userModals'
import { getUsers, getAddUserMutation, getRenameUserMutation, getRemoveUserMutation, getChangeUserRoleMutation } from '../../../common/userQueries'
import { ApplicationContext } from '../../../common/reactContext'
import { UserRow } from './userRow'
import './userAdministration.scss'

export const UserAdministration = () => {
    const { formatMessage } = useIntl()
    const [userToAdministrate, setUserToAdministrate] = React.useState(null)
    const [showDeleteUserModal, setShowDeleteUserModal] = React.useState(false)
    const [showRenameUserModal, setShowRenameUserModal] = React.useState(false)
    const [showAddUserModal, setShowAddUserModal] = React.useState(false)

    const app = useContext(ApplicationContext)
    const queryClient = useQueryClient()

    const apiHost = app.config.apiHost
    const loggedInUser = app.loggedInUser
    const activeMerchant = app.activeMerchant

    const {
        isLoading: isLoadingUsers,
        refetch: refetchUsers,
        data: users
    } = getUsers(apiHost, activeMerchant.id)

    const addUserMutation = getAddUserMutation(queryClient, apiHost, activeMerchant.id)
    const renameUserMutation = getRenameUserMutation(queryClient, apiHost)
    const changeUserRoleMutation = getChangeUserRoleMutation(queryClient, apiHost, activeMerchant.id)
    const removeUserMutation = getRemoveUserMutation(queryClient, apiHost, activeMerchant.id)

    const showLoading = isLoadingUsers || addUserMutation.isLoading || removeUserMutation.isLoading

    useEffect(() => {
        refetchUsers()
    }, [])

    return (
        <div className="user-administration">
            <h2 className="settings-paper-header">{formatMessage(SETTINGS_USER_TEXT['header'])}</h2>
            {showLoading && <div className="user-loading">
                <Spinner waiting={true} />
            </div>}
            {!showLoading && <>
                <div className="user-table">
                    <div className="headers settings-paper-subheader">
                        <div className="header user-name">{formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_name'])}</div>
                        <div className="header user-email">{formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_email'])}</div>
                        <div className="header user-role">{formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_access'])}</div>
                    </div>
                    {
                        users.map(user => <UserRow
                            key={user.id}
                            user={user}
                            loggedInUser={loggedInUser}
                            onRenameUserClick={(user) => {
                                setUserToAdministrate(user)
                                setShowRenameUserModal(true)
                            }}
                            onChangeUserRoleClick={(userRole) => {
                                setUserToAdministrate(user)
                                changeUserRoleMutation.mutateAsync({ user, userRole })
                                    .then(() => setUserToAdministrate(null))
                            }}
                            isUserRenaming={userToAdministrate && userToAdministrate.id === user.id && renameUserMutation.isLoading}
                            isUserChangingRole={userToAdministrate && userToAdministrate.id === user.id && changeUserRoleMutation.isLoading}
                        >
                            <RoleToggle2 userRole={loggedInUser.role} allow={[ROLES.OWNER, ROLES.ADMIN]}>
                                <ButtonV2
                                    key={`rb-${user.id}`}
                                    iconName="closeThin"
                                    label={formatMessage(SETTINGS_TEXTS['merchant_checkout_remove_logo'])}
                                    className="remove-user-button"
                                    buttonStyle="turquoise no-border compact hide-label-for-mobile"
                                    name="openRemoveUserDialog"
                                    onClick={() => {
                                        setUserToAdministrate(user)
                                        setShowDeleteUserModal(true)
                                    }}
                                />
                            </RoleToggle2>
                        </UserRow>
                        )
                    }
                    {users.length === 0 && formatMessage(SETTINGS_USER_TEXT['no_users_exists'])}
                </div>
                <div className="add-user-container">
                    <RoleToggle2 userRole={loggedInUser.role} allow={[ROLES.OWNER, ROLES.ADMIN]}>
                        <Button
                            label={formatMessage(SETTINGS_USER_TEXT['add_user'])}
                            className="add-user-button no-margin"
                            iconName="plus"
                            onClick={() => setShowAddUserModal(true)}
                        />
                        <div className="add-user-description">{formatMessage(SETTINGS_TEXTS['merchant_add_user_description'])}</div>
                    </RoleToggle2>
                </div>
            </>
            }

            {showAddUserModal && <AddUserModal
                onClose={() => {
                    setShowAddUserModal(false)
                }}
                onConfirm={(user) => {
                    addUserMutation.mutate(user)
                    setShowAddUserModal(false)
                }}
            />}

            {showRenameUserModal && <RenameUserModal
                user={userToAdministrate}
                onClose={() => {
                    setUserToAdministrate(null)
                    setShowRenameUserModal(false)
                }}
                onConfirm={(user) => {
                    renameUserMutation.mutateAsync(user)
                        .then(() => setUserToAdministrate(null))
                    setShowRenameUserModal(false)
                }}
            />}

            {showDeleteUserModal && <RemoveUserModal
                user={userToAdministrate}
                onClose={() => {
                    setUserToAdministrate(null)
                    setShowDeleteUserModal(false)
                }}
                onConfirm={() => {
                    removeUserMutation.mutate(userToAdministrate)
                    setUserToAdministrate(null)
                    setShowDeleteUserModal(false)
                }}
            />}
        </div>
    )
}
