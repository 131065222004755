import React from 'react'
import uuid from 'uuid'
import iso4217 from 'currency-codes'

import MonetaryAmount from  '../../../../common/components/monetaryAmount'


const OnlinePaymentOrderlinesDetails = ({authorization}) => {
    const orderInformation = authorization.order_information
    const currency = iso4217.code(authorization.currency)
    const amount_exponent = currency.digits
    return (
        <div className="detail-wrapper">
            { orderInformation.orderLines.map(line =>
                <div className="row list" key={uuid.v4()}>
                    <div>{`${line.quantity} x ${line.description}`}</div>
                    <div className="element">
                        <MonetaryAmount className="fg-darkgreen" amount={line.unitPrice * line.quantity} currency={authorization.currency} amount_exponent={amount_exponent} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default OnlinePaymentOrderlinesDetails
