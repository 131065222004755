import { defineMessages } from 'react-intl'

export const USER_PROFILE_TEXTS = defineMessages({
    'merchant-profile-merchant-user-role-admin-title': {
        id: 'merchant-profile-merchant-user-role-admin-title',
        defaultMessage: 'Admin',
        description: 'Merchant user list role, admin with administrator privileges.'
    },
    'merchant-profile-merchant-user-role-owner-title': {
        id: 'merchant-profile-merchant-user-role-owner-title',
        defaultMessage: 'Owner',
        description: 'Merchant user list role, account owner with administrator privileges.'
    },
    'merchant-profile-merchant-user-role-user-title': {
        id: 'merchant-profile-merchant-user-role-user-title',
        defaultMessage: 'User',
        description: 'Merchant user list role, user with read only access.'
    },
    'merchant-profile-merchant-user-role-none-title': {
        id: 'merchant-profile-merchant-user-role-none-title',
        defaultMessage: 'No role',
        description: 'Merchant user list no role.'
    },
    'merchant-profile-user-preferences-header': {
        id: 'user-preferences-header',
        defaultMessage: 'User preferences',
        description: 'User preferences header'
    },
    'merchant-profile-notifications-header': {
        id: 'notifications-header',
        defaultMessage: 'Notifications',
        description: 'User notification settings header'
    },
    'merchant-profile-language-header': {
        id: 'language-header',
        defaultMessage: 'Language',
        description: 'User language setting header'
    },
    'merchant-profile-language-description': {
        id: 'language-description',
        defaultMessage: 'Change to your preferred language',
        description: 'User language setting description'
    }
})

export const TERMINALS_TEXTS = defineMessages({
    terminal_settle_time: {
        id: 'terminal_settle_time',
        defaultMessage: 'End of day',
        description: 'End of day (dagsavslut)'
    },
    terminal_error_header: {
        id: 'terminal_error_header',
        defaultMessage: 'Oops! A problem occured!',
        description: 'Terminal error header'
    },
    terminal_error_body: {
        id: 'terminal_error_body',
        defaultMessage: 'There was a problem loading your card reader information',
        description: 'Terminal error body'
    },
    terminal_model:{
        id: 'terminal-model',
        defaultMessage: 'Terminal Model',
        description: 'Terminal Model'
    },
    account_id:{
        id: 'account-id',
        defaultMessage: 'Account ID',
        description: 'Account ID'
    },
    terminal_header: {
        id: 'terminal-header',
        defaultMessage: 'Your card readers',
        description: 'Your card readers (terminals)'
    },
    on: {
        id: 'settings-enabled-on',
        defaultMessage: 'On',
        description: 'On (on/off)'
    },
    off: {
        id: 'settings-disabled-off',
        defaultMessage: 'Off',
        description: 'Off (on/off)'
    },
    show_password: {
        id: 'settings-show-password',
        defaultMessage: 'Show password',
        description: 'Show password'
    },
    tip: {
        id: 'settings-terminal-tip',
        defaultMessage: 'Tip',
        description: 'tip'
    },
    contactless: {
        id: 'settings-terminal-contractless',
        defaultMessage: 'Contactless',
        description: 'Contactless'
    },
    direct_capture: {
        id: 'settings-terminal-direct-capture',
        defaultMessage: 'Direct capture',
        description: 'Direct capture'
    },
    password: {
        id: 'settings-terminal-password',
        defaultMessage: 'Password',
        description: 'Password'
    }
})
