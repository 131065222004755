import { defineMessages } from 'react-intl'

export const NAME = 'invoice'
export const TEXT = defineMessages({
    'show-details': {
        id: 'show-details',
        defaultMessage: 'Show details',
        description: 'Show details'
    },
    'hide-details': {
        id: 'hide-details',
        defaultMessage: 'Hide details',
        description: 'Hide details'
    },
    'invoice-title-description':{
        id:'invoice-title-description',
        defaultMessage: 'Description',
        description: 'invoice title description'
    },
    'invoice-title-total':{
        id:'invoice-title-total',
        defaultMessage: 'Total',
        description: 'invoice title total'
    },
    'bambora_ab_footnote_2':{
        id:'bambora_ab_footnote_2',
        defaultMessage: 'and is invoiced from',
        description: 'invoice bambora footnote 2'
    },
    'bambora_ab_footnote_1':{
        id:'bambora_ab_footnote_1',
        defaultMessage: 'Your flexible transaction fee is based on your turnover for',
        description: 'invoice bambora footnote 1'
    },
    'bambora_device_footnote':{
        id:'bambora_device_footnote',
        defaultMessage: 'Invoiced from',
        description: 'invoice bambora device footnote'
    },
    'common_footnote':{
        id:'common_footnote',
        defaultMessage: 'Only if there’s a remaining balance after 30 days will we send you details regarding how to pay the rest',
        description: 'invoice common footnote'
    },
    'total_fees_exclude_vat':{
        id:'total_fees_exclude_vat',
        defaultMessage: 'Total monthly fee ex VAT',
        description: 'invoice Total monthly fee ex VAT'
    },
    'monthly_fees_total_vat':{
        id:'monthly_fees_total_vat',
        defaultMessage: 'VAT 25%',
        description: 'invoice VAT 25%'
    },
    'total_fees_inc_vat':{
        id:'total_fees_inc_vat',
        defaultMessage: 'Total monthly fee inc VAT',
        description: 'invoice Total monthly fee inc VAT'
    },
    'general_invoice_info':{
        id:'general_invoice_info',
        defaultMessage: 'The balance will be withdrawn from your daily settlement payouts until the full invoiced amount is paid. You don’t need to do anything.',
        description: 'general invoice info'
    },
    'invoice-report-list-serial-number':{
        id: 'invoice-report-list-serial-number',
        defaultMessage: 'Invoice nr',
        description: 'Invoice nr'
    },
    'no_vat':{
        id: 'no_vat',
        defaultMessage: 'No VAT',
        description: 'no vat'
    },
    'no_charge':{
        id: 'no_charge',
        defaultMessage: 'No charge',
        description: 'no charge'
    },
    'monthly_fees_total_vat_without_rates': {
        id: 'monthly_fees_total_vat_without_rates',
        defaultMessage: 'VAT',
        description: 'VAT'
    },
    'reverse_charge_label': {
        id: 'reverse_charge_label',
        defaultMessage: 'VAT 0%',
        description: 'For merchants outside nordic'
    },
    'reverse_charge_comment': {
        id: 'vat_reverse_charge',
        defaultMessage: 'Reverse charge',
        description: 'For merchants outside nordic'
    }
})
