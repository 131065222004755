import { injectIntl } from 'react-intl'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { hideOperationsModal } from '../actions'
import * as onlineActions from '../actions'
import * as onlineHelpers from '../helpers'
import OrderOperationModal from '../components/orderOperationModal'

/* NOTE: This is used for the "new" CollectorBank API */

const OperationCapture = (props) => {
    const formatMessage = props.intl.formatMessage

    const amountExponent = props.authorization && props.authorization.amount_exponent

    const orderLines = props.authorization && props.authorization.order_information && props.authorization.order_information.orderLines.map(order => ({
        id: order.id,
        quantity: order.quantity,
        description: order.description,
        text: `${order.quantity} x ${order.description}`,
        unitPrice: order.unitPrice,
        unique_id: order.unique_id
    }))

    const [selectItem, setSelectItem] = useState(orderLines && orderLines.map(item => ({ id: item.id, quantity: item.quantity, unitPrice: item.unitPrice, description: item.description, unique_id: item.unique_id })))

    useEffect(() => {
        const currentSelectItem = orderLines && orderLines.map(item => ({ id: item.id, quantity: item.quantity, unitPrice: item.unitPrice, description: item.description, unique_id: item.unique_id }))
        setSelectItem(currentSelectItem)
    }, [props.authorization])

    const onHide = () => {
        props.dispatch(hideOperationsModal())
    }

    const onOperation = () => {
        const amount = selectItem.reduce((acc, current) => acc + current.quantity * current.unitPrice, 0)
        let order_lines_to_capture = selectItem.map(item => ({
            id: item.id,
            totalpriceinclvat: item.unitPrice * item.quantity,
            quantity: item.quantity,
            description: item.description,
            unitpriceinclvat: item.unitPrice
        }))
        order_lines_to_capture = order_lines_to_capture.filter(item => item.quantity > 0)
        const captureBody = {
            amount,
            currency: props.authorization.currency,
            use_new_online_api: true,
            order_lines_to_capture
        }

        const externalId = props.order.external_id
        const operationId = onlineHelpers.getOperationId(props.order)
        props.dispatch(onlineActions.captureOrder(
            props.merchantId,
            captureBody,
            externalId,
            operationId
        ))
    }

    const orderOperationModalProps = {
        orderLines,
        amountExponent,
        selectItem,
        setSelectItem,
        formatMessage,
        onOperation,
        onHide,
        operations: props.operations,
        operationName: 'capture',
        isSuccess: props.isSuccess,
        isError: props.isError,
        isFetching: props.isFetching
    }
    return (
        <OrderOperationModal {...orderOperationModalProps} />
    )
}

function mapStateToProps(state) {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        operations: state.getIn([activeMerchant, 'online', 'operations']),
        isSuccess: state.getIn([activeMerchant, 'online', 'operations', 'isSuccess']),
        isFetching: state.getIn([activeMerchant, 'online', 'operations', 'isFetching']),
        isError: state.getIn([activeMerchant, 'online', 'operations', 'isError']),
        orders: state.getIn([activeMerchant, 'online']),
        order: state.getIn([activeMerchant, 'online', 'current', 'data'])
    }
}

export default connect(mapStateToProps)(injectIntl(OperationCapture))
