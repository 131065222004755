import React  from 'react'
import iso4217 from 'currency-codes'
import BamboraSvgIcon from  '../../../../common/components/bamboraSvgIcon'
import { SALES_DETAILS_TEXTS } from '../../../sales/constants'
import { SETTLEMENT_TABLE_HEADER } from '../../../../settlements/constants'


const OnlineEventHistorySettledTransaction = ({transaction, formatMessage}) => {
    if(transaction && !transaction.settlement_id) {
        return null
    }

    const currency = iso4217.code(transaction.currency)
    transaction.amount_exponent = currency.digits

    return (
        <div className="row-wrapper sales">
            <div className="row">
            <div className="icon"><BamboraSvgIcon icon="sent"/></div>
                <div className="event">
                    <div className="operation-event settled">
                        {formatMessage(SALES_DETAILS_TEXTS['payment_instore_operation_settled'])}
                    </div>
                    { transaction && transaction.batch_date &&
                        <div className="operation-event settled">
                            {formatMessage(SETTLEMENT_TABLE_HEADER['payout'])}:  { transaction.batch_date }
                        </div>
                    }
                </div>
                <div className="pull-right">
                    { transaction.batch_date }
                </div>
                <div className="details-row settled">
                    <div className="action">
                        <span className="pull-right fg-success">{formatMessage(SALES_DETAILS_TEXTS['payment_success'])}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnlineEventHistorySettledTransaction
