import React from 'react'


const ReportTypeSelector = ({ selectedReportType, setSelectedReportType, availableReportTypes }) => {
    return (
        <div className="report-type-selector" data-testid="report-type-selector">
            { availableReportTypes.map((reportType) =>
                <ReportTypeButton
                    key={reportType.id}
                    reportType={reportType}
                    onClick={setSelectedReportType}
                    active={selectedReportType.id === reportType.id}
                />
            )}
        </div>
    )
}

const ReportTypeButton = ({ reportType, onClick, active }) => {
    return (
        <div
            key={reportType.id}
            className={`btn-report-type ${active ? 'selected' : ''}`}
            onClick={() => onClick(reportType)}>
                {reportType.description}
        </div>
    )
}

export default ReportTypeSelector