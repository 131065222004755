import React from 'react'
import { useIntl } from 'react-intl'
import { Badge, ButtonV2 } from '@frontrunners/ui-components'
import { SETTINGS_TEXTS } from '../../merchant/constants'

import './activateMerchant.scss'

export const ActivateMerchant = ({ activeMerchant }) => {
    const { formatMessage } = useIntl()
    return (
        <div className="activate-merchant">
            <h3>{formatMessage(SETTINGS_TEXTS['merchant_checkout_activation_status_text'])}</h3>
            <Badge className="orange">{formatMessage(SETTINGS_TEXTS['merchant_checkout_activation_status_button'])}</Badge>

            <p>
                {formatMessage(SETTINGS_TEXTS['merchant_checkout_activation_description'])}
            </p>

            <ButtonV2
                label={formatMessage(SETTINGS_TEXTS['merchant_checkout_activation_status_text'])}
                className="default compact"
                onClick={() => {
                    window.open(activeMerchant.activationLink, '_blank')
                }}
            />
            <small>{formatMessage(SETTINGS_TEXTS['merchant_checkout_activation_side_note'])}</small>
        </div>
    )
}
