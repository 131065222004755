import { Map } from 'immutable'
import * as types from './actionTypes'
import { fakeDownloadReportTotal } from './constants'
import { updatedReports, updateSettlementReorts } from './helpers'
export const initState = Map({
    reportFormat: 'pdf',
    reportType: 'detailed',
    monthlyReports: [],
    settlementReports: [],
    monthlyDropdownActive: false,
    monthlyReportsLoading: true,
    monthlyReportsError: false,
    activeYear: new Date().getFullYear(),
    sales: new Map({})
})

export const reducer = (reports = initState, action) => {

    switch (action.type) {
        case types.GET_REPORT_TASK_START:
            return reports.set('settlementReports', updateSettlementReorts(reports.get('settlementReports'), action.payload, true))
        case types.GET_REPORT_TASK_DONE:
        case types.GET_REPORT_TASK_ERROR:
            return reports.set('settlementReports', updateSettlementReorts(reports.get('settlementReports'), action.payload, 'DONE'))
        case types.GET_SETTLEMENT_REPORT_TASK_RESET:
            return reports.set('settlementReports', updateSettlementReorts(reports.get('settlementReports'), action.payload, false))
        case types.GET_AVAILABLE_MONTHLY_REPORTS:
            if (action.payload.length == 0) {
                return reports.set('monthlyReportsLoading', false)
                    .set('monthlyReportsError', false)
            }
            return reports.set('monthlyReports', action.payload)
                .set('monthlyReportsLoading', false)
                .set('monthlyReportsError', false)
        case types.GET_MONTHLY_REPORT_TASK_START:
            return reports.set('monthlyReports', updatedReports(reports.get('monthlyReports'), action.payload, true))
        case types.GET_MONTHLY_REPORT_TASK_ERROR:
        case types.GET_MONTHLY_REPORT_TASK_SUCCESS:
            return reports.set('monthlyReports', updatedReports(reports.get('monthlyReports'), action.payload, 'DONE'))
        case types.GET_MONTHLY_REPORT_TASK_PROCESS:
            return reports.set('monthlyReports', updatedReports(reports.get('monthlyReports'), action.payload, true))
        case types.GET_MONTHLY_REPORT_TASK_RESET:
            return reports.set('monthlyReports', updatedReports(reports.get('monthlyReports'), action.payload, false))
        case types.GET_SALES_REPORT_TASK_START:
            return reports
                .setIn(['sales', 'fetching'], true)
                .setIn(['sales', 'error'], false)
                .setIn(['sales', 'items_fetched'], 0)
                .setIn(['sales', 'items_total'], fakeDownloadReportTotal)
        case types.GET_SALES_REPORT_TASK_ERROR:
            return reports
                .setIn(['sales', 'fetching'], false)
                .setIn(['sales', 'error'], true)
        case types.GET_SALES_REPORT_TASK_RESET:
            return reports
                .setIn(['sales', 'fetching'], false)
                .setIn(['sales', 'error'], false)
        case types.POLL_SALES_REPORT_TASK_SUCCESS:
            return reports.setIn(['sales', 'fetching'], false).setIn(['sales', 'error'], false)
        case types.GET_SALES_REPORT_TASK_PROCESS:
            return reports
                .setIn(['sales', 'items_fetched'], action.payload.items_fetched)
        default:
            return reports
    }
}

export default reducer
