export const LIST_SALES_AGGREGATES_START = 'dashboard/LIST_SALES_AGGREGATES_START'
export const LIST_SALES_AGGREGATES_SUCCESS = 'dashboard/LIST_SALES_AGGREGATES_SUCCESS'
export const LIST_SALES_AGGREGATES_ERROR = 'dashboard/LIST_SALES_AGGREGATES_ERROR'

export const LIST_PAYOUT_AGGREGATES_START = 'dashboard/LIST_PAYOUT_AGGREGATES_START'
export const LIST_PAYOUT_AGGREGATES_SUCCESS = 'dashboard/LIST_PAYOUT_AGGREGATES_SUCCESS'
export const LIST_PAYOUT_AGGREGATES_ERROR = 'dashboard/LIST_PAYOUT_AGGREGATES_ERROR'

export const LIST_LOAN_DASHBOARD_START = 'dashboard/LIST_LOAN_DASHBOARD_START'
export const LIST_LOAN_DASHBOARD_SUCCESS = 'dashboard/LIST_LOAN_DASHBOARD_SUCCESS'
export const LIST_LOAN_DASHBOARD_ERROR = 'dashboard/LIST_LOAN_DASHBOARD_ERROR'

export const LIST_LOAN_CREDIT_START = 'dashboard/LIST_LOAN_CREDIT_START'
export const LIST_LOAN_CREDIT_SUCCESS = 'dashboard/LIST_LOAN_CREDIT_SUCCESS'
export const LIST_LOAN_CREDIT_ERROR = 'dashboard/LIST_LOAN_CREDIT_ERROR'
