import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { ApplicationContext } from '../../common/reactContext'
import {
    Badge,
    Button,
    Dropcard,
    Icon
} from '@frontrunners/ui-components'
import { getRoleDisplayText } from '../../common/userRoles'
import { getUser } from '../../common/userQueries'
import { localStorageClear, localStorageGetItem } from '../../common/localstorageHelpers'
import { signOut } from '@frontrunners/wl-cognito-login'
import { TRANSLATIONS as T } from './loggedInUserMenu.intl'
import './loggedInUserMenu.scss'
import { IMPERSONATE_USER_ID } from '../../config/constants'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { isMaintenanceMode } from '../../common/dateHelpers'


export const LoggedInUserMenu = ({ className }) => {
    const { formatMessage } = useIntl()
    const { config, loggedInUser, activeMerchant } = useContext(ApplicationContext)
    const userSub = localStorageGetItem(IMPERSONATE_USER_ID) || loggedInUser.sub
    const disableInvoices = isMaintenanceMode(activeMerchant.id)

    const {
        isLoading: userLoading,
        data: user
    } = getUser(config.apiHost, activeMerchant.id, userSub, !!activeMerchant.id)

    const userName = [user?.givenName, user?.familyName].filter(Boolean).join(' ').trim()

    return <div aria-busy={userLoading} role="menu" className={className}>
        <Dropcard
            icon={<Icon iconName="user2" />}
            className="user-dropcard no-wrap">
            <div className="dropcard-content">
                <Header />
                <hr />
                <UserInfo
                    name={userName}
                    email={loggedInUser.email || user?.email}
                    role={loggedInUser.role}
                />
                <MerchantInfo activeMerchant={activeMerchant} />
                {activeMerchant?.hasInvoices &&
                    <Link
                        className={`invoices ${disableInvoices ? 'disabled' : ''}`}
                        to={`/merchants/${activeMerchant.id}/invoices`}
                    >
                        {formatMessage(T.invoices)}
                    </Link>
                }
                <hr />
                <Buttons loggedInUser={loggedInUser} />
            </div>
        </Dropcard>
    </div>
}

const Header = () => {
    const { formatMessage } = useIntl()

    return <div className="header">
        <Icon
            iconName="user2"
            className="label-icon" />
        {formatMessage(T.logged_in_as)}:
    </div>
}

const UserInfo = ({ name, email, role }) => {
    const { formatMessage } = useIntl()

    return <div className="user">
        <label>{formatMessage(T.user)}</label>
        <div className="user-email">
            <span className="address">{email}</span>
            <Badge className="turquoise">{getRoleDisplayText(formatMessage, role)}</Badge>
        </div>
        <div className="user-name">
            {name}
        </div>
    </div>
}

const MerchantInfo = ({ activeMerchant }) => {
    const { formatMessage } = useIntl()

    return <div className="merchant">
        <label>{formatMessage(T.merchant)}</label>
        <div className="org-name">
            {activeMerchant?.name && activeMerchant.name}
            {activeMerchant.isTrialMerchant && <Badge className="orange">{formatMessage(T.test_badge)}</Badge>}
        </div>
        <div className="org-number">
            {activeMerchant?.orgNumber && activeMerchant.orgNumber}
        </div>
    </div>
}

const Buttons = ({ loggedInUser }) => {
    const { formatMessage } = useIntl()
    const { config } = useContext(ApplicationContext)

    return <div className="buttons">
        {loggedInUser.hasMultipleMerchants && <Button
            label={formatMessage(T.switch_merchant)}
            className="default"
            onClick={() => {
                window.location.replace('/merchants')
            }} />}
        <Button
            label={formatMessage(T.log_out)}
            className="turquoise"
            onClick={() => {
                signOut({
                    UserPoolId: config.cognitoPoolId,
                    ClientId: config.cognitoClientId
                })
                localStorageClear()
                window.location.replace('/')
            }} />
    </div>
}
