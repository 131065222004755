import React from 'react'
import { useIntl } from 'react-intl'

import { formatMillisecondsToReadableTime, getEstimatedDuration } from './helpers'
import { ProgressBar } from '@frontrunners/ui-components'
import { MONTHLY_REPORTS_TEXT } from './constants'
import { STATES } from './constants'
import './monthlyReportsProgressBar.scss'

export const SHOW_PROGRESS_THRESHOLD_MS = 30 * 1000

export const TaskProgressBar = ({task}) => {
    const intl = useIntl()
    const [offset, estimatedDuration] = getEstimatedDuration(task)

    const timeRemainingMessage = estimatedDuration > SHOW_PROGRESS_THRESHOLD_MS ? formatMillisecondsToReadableTime(estimatedDuration - offset) : ' '

    const failed = task.status === STATES.failed
    const style = failed ? 'error' : ''
    const statusMessage = failed ? intl.formatMessage(MONTHLY_REPORTS_TEXT['report_failed']) : timeRemainingMessage

    return (
        <div className="progress-bar-wrapper" data-testid="progress-bar-wrapper">
            <div className={`status ${style}`}>
                { statusMessage }
            </div>
            <ProgressBar
                duration={estimatedDuration}
                offset={offset}
                stopped={failed}
                completed={!!task.completed_at}
                className={getClassName(task, estimatedDuration)}
            />
        </div>
    )
}

const getClassName = (task, estimatedDuration) => {
    if (task.status === STATES.failed) {
        return 'failed'
    } else if (estimatedDuration === 0 && !task.completed_at) {
        return 'overtime'
    } else {
        return ''
    }
}
