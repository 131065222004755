import React, { useState } from 'react'
import { Icon } from '@frontrunners/ui-components'

import { LoggedInUserMenu } from './loggedInUserMenu'
import { Language, SettingsDesktop, Overview, Payouts, Sales, Reports, Loans, OnlinePortalLink, Invoices } from './navItems'
import './leftNav.scss'


export const LeftNavBar = () => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <nav className={`left-navbar-desktop ${isExpanded ? 'expanded' : ''}`}>

            <div className="navbar-align-top">
                <div className="navbar-icon-wrapper">
                    <Icon iconName="wlHorizontal" className={isExpanded ? '' : 'hidden'} />
                    <Icon iconName="wlPalmtree" className={isExpanded ? 'hidden' : ''} />
                </div>
                <Overview />
                <Payouts />
                <Sales />
                <Reports />
                <Loans />
                <OnlinePortalLink />
            </div>

            <div className="navbar-align-bottom">
                <Invoices />
            </div>

            <div className="navbar-toggle-wrapper">
                <div className="expand-navbar-toggler" onClick={() => setIsExpanded(!isExpanded)} >
                    <Icon iconName="arrowRight" className={isExpanded ? 'expanded' : ''} />
                </div>
            </div>

        </nav>
    )
}

export const TopNavBar = () => {
    return (
        <nav className="top-navbar-desktop">
            <div className="align-right">
                <Language />
                <LoggedInUserMenu className="nav-item-container" />
                <SettingsDesktop />
            </div>
        </nav>
    )
}

