import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { SETTLEMENT_PAGINATION_BUTTON } from '../../settlements/constants'
import { injectIntl } from 'react-intl'
import { PAGE_LIMIT, PAGE_OFFSET } from '../../common/constants'


class PaginationLoadMore extends Component {

    constructor(props) {
        super(props)

        const { formatMessage } = this.props.intl

        this.BUTTON_STATES = {
            'loadMore': {
                className: 'rebranded-primary-btn',
                text: formatMessage(SETTLEMENT_PAGINATION_BUTTON['pagination-btn-load-more-state'])
            },
            'loading': {
                className: 'settlement-pagination__loading',
                text: formatMessage(SETTLEMENT_PAGINATION_BUTTON['pagination-btn-loading-state'])
            }
        }
    }

    render() {
        const className = this.BUTTON_STATES[this.props.buttonState].className
        return (
            <div className="pagination">
                { !this.props.emptyList && this.props.nextPageAvailable &&
                    <div
                        className={`${className}`}
                        onClick={() => {
                            this.props.loadMore()
                        }}>{this.BUTTON_STATES[this.props.buttonState].text}</div>
                    }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        merchant: state.get('merchant')
    }
}


export const DEFAULT_PAGINATION = {
    offset: PAGE_OFFSET,
    limit: PAGE_LIMIT
}

export const setPagination = (search) => {
    if (!search) {
        search = DEFAULT_PAGINATION
    }
    if (!search.offset) {
        search.offset = PAGE_OFFSET + PAGE_LIMIT
    } else {
        search.offset = search.offset + PAGE_LIMIT
    }
    return search
}

export const setButtonState = (isLoading) => {
    return isLoading ? 'loading' : 'loadMore'
}

export const appendNextPage = (oldList, newList, search) => {
    if (search.offset == 0) {
        return newList
    }
    if (!oldList) oldList = []
    return oldList.concat(newList)
}

export default connect(mapStateToProps)(withRouter(injectIntl(PaginationLoadMore)))
