import { defineMessages } from 'react-intl'


export const TRANSLATIONS = defineMessages({
    'logged_in_as': {
        id: 'logged-in-as',
        defaultMessage: 'Logged in as',
        description: 'Label for logged in as'
    },
    'user': {
        id: 'merchant-profile-add-user-role-user-title',
        defaultMessage: 'User',
        description: 'Label for user'
    },
    'merchant': {
        id: 'Merchant',
        defaultMessage: 'Merchant',
        description: 'Label for merchant'
    },
    'switch_merchant': {
        id: 'navbar-merchant-switch-merchant',
        defaultMessage: 'Switch Merchant',
        description: 'Label for switch merchant'
    },
    'log_out': {
        id: 'log-out',
        defaultMessage: 'Log out',
        description: 'Label for log out'
    },
    'test_badge': {
        id: 'select-merchant-test-account',
        defaultMessage: 'Test',
        description: 'Label for test badge'
    },
    'invoices': {
        id: 'navbar-invoices',
        defaultMessage: 'Invoices',
        description: 'Link to invoices page'
    }
})
