import { runRequestWrapper, fetchJson, customLoanError } from '../common/requestActionHelper'
import { FRODA_PROXY_BASE, LOAN_BASE } from '../common/constants'
import { GET_CONSENT, GET_TOKEN } from './actionTypes'

const customReponseHandler = {
    '400': customLoanError,
    '401': customLoanError,
    '403': customLoanError,
    '404': customLoanError,
    '500': customLoanError,
    '502': customLoanError,
    '504': customLoanError
}

export const getFrodaToken = (merchantId) => {
    const requestObj = {
        url: `${FRODA_PROXY_BASE}/user_token`,
        merchantId
    }
    return runRequestWrapper(GET_TOKEN, requestObj, customReponseHandler)
}

export const getConsent = (merchantId) => {
    const requestObj = {
        url: `${LOAN_BASE}/consent`,
        merchantId
    }
    return runRequestWrapper(GET_CONSENT, requestObj)
}

export const putConsent = (merchantId) => {
    return (dispatch, getState) => {
        const requestObj = {
            url: `${LOAN_BASE}/consent`,
            method: 'PUT',
            merchantId
        }
        return fetchJson(dispatch, getState, requestObj, customReponseHandler)
    }
}
