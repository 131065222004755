import * as salesActions from '../sales/actionTypes'

let actions = {}

for (let actionName of Object.keys(salesActions)) { // eslint-disable-line
    actions[actionName] = `instore/${actionName}`
}

actions['GET_DETAILS_SUCCESS'] = 'instore/GET_DETAILS_SUCCESS'
actions['GET_DETAILS_ERROR'] = 'instore/GET_DETAILS_ERROR'
actions['GET_DETAILS_START'] = 'instore/GET_DETAILS_START'

actions['GET_SETTLED_INFORMATION_SUCCESS'] = 'instore/GET_SETTLED_INFORMATION_SUCCESS'
actions['GET_SETTLED_INFORMATION_ERROR'] = 'instore/GET_SETTLED_INFORMATION_ERROR'
actions['GET_SETTLED_INFORMATION_START'] = 'instore/GET_SETTLED_INFORMATION_START'

export default actions
