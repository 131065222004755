import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import '../helpers/filterLocales'

import * as actions from '../actions'
import { PAGE_OFFSET, PAGE_LIMIT } from '../../common/constants'
import { SETTLEMENT_FILTER_TEXT } from '../constants'
import Filters from './filters'
import { updateSearch, hasActiveSearch } from '../helpers'
import { parseSearch } from '../../common/locationHelpers'
import DatePickerDropdown from '../../common/components/datePickerDropdown'
import SearchBox from '../../common/components/searchBox'
import mixpanel from 'mixpanel-browser'
import { mixpanelPrefix } from '../../common/helpers/mixpanelPrefix'
import * as dateHelpers from '../../common/dateHelpers'
import { format, isEqual } from 'date-fns'

const baseSearchObj = {
    offset: PAGE_OFFSET,
    limit: PAGE_LIMIT
}

class FilterMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            startDate: props.initialStartDate,
            endDate: props.initialEndDate,
            saleslocationMenuOpened: false,
            search: '',
            combineFilters: false
        }

        this.onDatesChanged = this.onDatesChanged.bind(this)
        this.onFocusChange = this.onFocusChange.bind(this)
        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize, false)

        if (this.props.location.search) {
            const { from_date, to_date } = parseSearch(this.props.location.search)

            if(!from_date || !to_date || from_date > to_date) return

            this.updateDatesSearch({ startDate: from_date, endDate: to_date })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize, false)
    }

    handleResize() {
        const COMBINE_FILTERS = window.matchMedia('(max-width: 750px)').matches
        if (this.state.combineFilters !== COMBINE_FILTERS) {
            this.setState({ combineFilters: COMBINE_FILTERS })
        }
    }

    buildDateSearchObject(startDate, endDate) {
        const resetDate = { to_date: null, from_date: null }
        let search = { ...this.props.search, ...resetDate, ...baseSearchObj }

        if (startDate) {
            search.from_date = startDate
        }

        if (endDate) {
            search.to_date = endDate
        }

        if (search.to_date === null) delete search.to_date
        if (search.from_date === null) delete search.from_date

        return search
    }


    onChangeSearch(reference) {
        const merchantId = this.props.match.params.merchantId
        let searchObject = {
            search: reference
        }

        const currentActiveSearch = this.props.search

        this.setState({ search: reference })

        let updatedSearchObject = updateSearch(searchObject, currentActiveSearch)
        updatedSearchObject.search = updatedSearchObject.search.trim()

        if (!updatedSearchObject.search) delete updatedSearchObject.search
        if (updatedSearchObject.offset) updatedSearchObject.offset = 0
        this._fetchAfterUpdate(merchantId, updatedSearchObject)

        mixpanel.track(
            `${mixpanelPrefix.PAYOUTS} Applied search filter.`
        )
    }

    _fetchAfterUpdate(merchantId, search) {
        this.props.dispatch(actions.loadMore(merchantId, search))
        this.props.dispatch(actions.getSettlements(merchantId, search))
    }

    onDatesChanged(range) {
        const { fromDate, toDate } = this.getFilterDates()
        // TODO extract into separate function
        if (
            !range && !fromDate && !toDate
            || range && isEqual(range.from, fromDate) && isEqual(range.to, toDate)
        ) {
            return
        }

        const startDate = range ? format(range.from, 'yyyy-MM-dd') : null
        const endDate = range ? format(range.to, 'yyyy-MM-dd') : null

        mixpanel.track(
            `${mixpanelPrefix.PAYOUTS} Applied date filter.`
        )

        this.updateDatesSearch({ startDate, endDate })
        this.fetchListAfterDateUpdate(startDate, endDate)
    }

    updateDatesSearch({ startDate, endDate }) {
        const search = this.buildDateSearchObject(startDate, endDate)

        const merchantId = this.props.match.params.merchantId
        this.props.dispatch(actions.updateFilters(
            merchantId,
            search
        ))
    }

    fetchListAfterDateUpdate(startDate, endDate) {
        const merchantId = this.props.match.params.merchantId

        const search = this.buildDateSearchObject(startDate, endDate, false)

        this.setState({ focusedInput: null })
        this.props.dispatch(actions.getSettlements(merchantId, search))
    }

    getMerchant() {
        const merchantId = this.props.match.params.merchantId
        return this.props.merchant.get('merchants').find(m => m.id === merchantId)
    }

    clearFilters() {
        this.updateDatesSearch({ startDate: null, endDate: null })

        this.setState({ search: '' })

        this.props.dispatch(actions.clearAllFilters(
            this.props.match.params.merchantId,
            { removeAllKeys: true }
        ))

        setTimeout(() => {
            this.props.dispatch(actions.getSettlements(
                this.props.match.params.merchantId
            ))
        })
    }

    renderReferenceSearchBox() {
        const { formatMessage } = this.props.intl

        const clearFilter = () => {
            this.setState({search: ''})
        }

        if (this.state.combineFilters) return null

        return (
            <SearchBox
                fetchByReference={this.onChangeSearch.bind(this)}
                reference={this.state.search}
                placeHolder={formatMessage(SETTLEMENT_FILTER_TEXT['settlement-filter-reference'])}
                clearQuery={clearFilter}
            />
        )
    }

    isDateSelected() {
        const search = this.props.settlements.getIn(['list', 'search'])
        return search && search.from_date
    }

    getFilterDates() {
        const search = this.props.settlements.getIn(['list', 'search'])
        const fromDateFilter = search && search.from_date ? search.from_date : null
        const toDateFilter = search && search.to_date ? search.to_date : null
        const fromDate = fromDateFilter ? new Date(fromDateFilter) : null
        const toDate = toDateFilter ? new Date(toDateFilter) : null
        return { fromDate, toDate }
    }

    renderDateFilter() {

        const { fromDate, toDate } = this.getFilterDates()
        const defaultRange = fromDate && toDate ? dateHelpers.createDateRange(fromDate, toDate) : null
        const minSelectableDate = dateHelpers.DataAvailableFromDate.instance.getDate()
        const maxSelectableDate = dateHelpers.getTodayMidnight()

        return (
            <DatePickerDropdown
                defaultRange={defaultRange}
                onDatesChange={this.onDatesChanged}
                localeCode={this.props.localization.get('localeCode')}
                minSelectableDate={minSelectableDate}
                maxSelectableDate={maxSelectableDate}
            />
        )
    }

    renderClearAll() {
        const { formatMessage } = this.props.intl
        if (!hasActiveSearch(this.props.search)) return null
        else return <div className={'clear-all'}><div onClick={() => this.clearFilters()}>
            {formatMessage(SETTLEMENT_FILTER_TEXT['settlement-filter-clear-all'])}
        </div> </div>
    }


    render() {

        let wrapperClass = this.state.focusedInput ? 'small-bg' : ''
        return (
            <div className={'sub-menu-container settlement-filter-container ' + wrapperClass}>
                <div className="payout-filters main-width-3">
                    <div className="standalone-filter">
                        {!this.state.combineFilters && this.renderDateFilter()}
                    </div>
                    <div className="right-filter">

                        {!this.state.combineFilters && this.renderClearAll()}

                        <Filters
                            renderDateFilter={this.renderDateFilter.bind(this)}
                            combineFilters={this.state.combineFilters}
                            renderClearAll={this.renderClearAll.bind(this)}
                            isDateSelected={this.isDateSelected.bind(this)}
                        />
                        {this.renderReferenceSearchBox()}

                    </div>
                </div>
            </div>
        )
    }

    onFocusChange(focusedInput) {
        this.setState({ focusedInput })
    }
}


const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        merchant: state.get('merchant'),
        preference: state.get('user').get('preference'),
        settlements: state.getIn([activeMerchant, 'settlements']),
        localization: state.get('localization'),
        search: state.getIn([activeMerchant, 'settlements', 'list', 'search']) || {}
    }
}

export default connect(mapStateToProps)(injectIntl(withRouter(FilterMenu)))
