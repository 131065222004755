import React, { Component } from 'react'
import iso4217 from 'currency-codes'
import BamboraSvgIcon from  '../../../../common/components/bamboraSvgIcon'
import { OPERATIONS, OPERATION_DESCRIPTIONS }  from '../../constants'
import MonetaryAmount from '../../../../common/components/monetaryAmount'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { SALES_DETAILS_TEXTS } from '../../../sales/constants'


const AUTHORIZATION_TYPES = ['authorize invoice', 'authorize token', 'authorize card']

class OnlineEventHistoryOperation extends Component {

    renderIcon (operation) {
        if(operation.currency){
            const currency = iso4217.code(operation.currency)
            operation.amount_exponent = currency.digits
        }

        if(operation.rejected || !operation.success || operation.type == OPERATIONS.CANCEL) {
            return <BamboraSvgIcon icon="history-cancel" />
        } else if(operation.type == OPERATIONS.REFUND) {
            return <BamboraSvgIcon icon="history-refund" />
        } else {
            return <BamboraSvgIcon icon="history-captured" />
        }
    }

    render () {
        const operation = this.props.operation
        const authorization = this.props.authorization
        const { formatMessage } = this.props.intl
        const operationResult = operation.success ? 'payment_success' : 'payment_rejected'
        let operationTypeString = operation.type.toLowerCase()

        if(AUTHORIZATION_TYPES.includes(operationTypeString)) {
            operationTypeString = 'authorize'
        }

        return (
        <div className="row-wrapper sales">
            <div className="row">
                <div className="icon">{this.renderIcon(operation, authorization)}</div>
                <div className="event">
                    <div className="operation-event">
                        <MonetaryAmount element={operation} className="fg-darkest" />
                    </div>
                </div>
                <div className="pull-right">
                    <div className="timestamp">
                    {moment(operation.timestamp_local).format('YYYY-MM-DD')}
                    </div>
                </div>
                <div className="details-row">
                    <div className="action">
                        <span className="pull-left operation-type">{formatMessage(OPERATION_DESCRIPTIONS[`order_event_${operationTypeString}`])}</span>
                        <span className="pull-left event-time">
                            {moment(operation.timestamp_local).format('HH:mm')}
                        </span>
                        <span className={`pull-right ${operation.success ? ' fg-success' : 'fg-danger'}`}>
                            {formatMessage(SALES_DETAILS_TEXTS[operationResult])}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default injectIntl(OnlineEventHistoryOperation)
