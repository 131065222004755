export function getActualAmount(amount, amount_exponent) {
    return amount / Math.pow(10, amount_exponent)
}

export function getExponentedAmountString(amount, amount_exponent, min) {
    let actualAmount = getActualAmount(amount, amount_exponent)
    if (Number.isInteger(min)) actualAmount = actualAmount < min ? min : actualAmount

    return actualAmount.toFixed(amount_exponent)
}

export const thousandSeperator = (amount_string) => {
    return amount_string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const getAmountString = function (element) {
    return getExponentedAmountString(element.amount, element.amount_exponent)
}

export const getAmountStringWithCurrency = (element) => {
    const amount = getExponentedAmountString(element.amount, element.exponent)
    return `${thousandSeperator(amount)} ${element.currency}`
}

export const getExponentedAmountInt = (amount, amount_exponent) => {
    const amountStr = getExponentedAmountString(amount, amount_exponent)
    return thousandSeperator(Math.round(amountStr))
}

export const toPercentageString = function (num) {
    var percentage = num * 100
    return percentage.toFixed(2) + '%'
}


function amountAsHighestExponent(elem, highestExponent) {
    // helper method to turn the element.amount into the highest exponent
    // that is present in a set
    // eg 2 * 10^-2 ==> 20 * 10^-3
    if (elem.amount_exponent === highestExponent) return elem.amount
    let addExponent = highestExponent - elem.amount_exponent
    return elem.amount * Math.pow(10, addExponent)
}

export const validateExponent = (amount, exponent) => {
    const splitAmount = amount.split('.')

    if (splitAmount.length === 1) {
        return true
    }

    if (splitAmount.length === 2) {
        const decimals = splitAmount.pop()
        if (decimals.length === exponent) {
            return true
        }
    }
    return false
}

export const stringWithExponentToInteger = (amount, exponent) => {
    amount = cleanInput(amount)
    const splitAmount = amount.split('.')
    if (splitAmount.length === 1) {
        return addExponent(amount, exponent)
    } else {
        return removeExponent(amount, exponent)
    }
}

export const validateAvailableAmount = (availableAmount, amount, exponent) => {
    const input = stringWithExponentToInteger(amount, exponent)
    return input <= availableAmount
}

export const removeExponent = (amount) => {
    return parseInt(amount.replace('.', ''))
}

export const addExponent = (amount, exponent) => {
    return parseInt(amount * Math.pow(10, exponent))
}

export const sumAmounts = function (amounts) {
    if (amounts.length === 0) {
        return null
    }
    const is_same_currency = amounts.reduce((reduced, a) => { return reduced && a.currency === amounts[0].currency }, true)
    const highest_exponent = amounts.reduce((highest, a) => { return a.amount_exponent > highest ? a.amount_exponent : highest }, amounts[0].amount_exponent)
    if (is_same_currency) {
        // make sure all the amounts have the same exponent, before
        // adding them togher, so that we only do math on ints and not floats
        // eg. (2 * 10^-1) + (3 * 10^-2) ==> (20 + 3) * 10^-3
        const amountsByHighestExponent = amounts.map((a) => amountAsHighestExponent(a, highest_exponent))
        const result = amountsByHighestExponent.reduce((summed, amount) => { return amount + summed }, 0)
        return {
            amount: result,
            amount_exponent: highest_exponent,
            currency: amounts[0].currency
        }
    }
    return null
}


function chunk(numToChunck) {
    let numberString = numToChunck + ''
    let reversedSpaced = []
    numberString.split('').reverse().forEach((e, i) => {
        if (i % 3 === 0 && i > 0) {
            reversedSpaced.push(' ')
        }
        reversedSpaced.push(e)
    })
    return reversedSpaced.reverse().join('').trim()
}

export const getRoundedChunkedAmountString = function (elem) {
    if (elem.amount > 0) {
        let normalized = elem.amount / Math.pow(10, elem.amount_exponent)
        let rounded = Math.round(normalized)
        return chunk(rounded)
    }
    return 0
}

export const cleanInput = (input) => {
    const nonNullInput = input || ''
    // turn commas into dots
    const dotted = nonNullInput.replace(',', '.')
    // trim whitespace
    return dotted.replace(/\s/, '')
}

export const fnumWithM = (x) => {
    if (isNaN(x)) return x

    if (x < 1000000000) {
        return thousandSeperator(Math.round(x))
    }

    return Math.round(x / 1000000) + 'M'
}
