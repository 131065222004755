const getActivePayoutsFilterTypes = (filters) => {
    if (filters === null) {
        return null
    }

    let filterTypes = {
        'currencies': false,
        'source_merchant_ids': false
    }

    const incomingKeys = Object.keys(filters)
    const supportedKeys = Object.keys(filterTypes)

    function validateIncomingKeys(key) {
        return supportedKeys.includes(key)
    }

    const validatedKeys = incomingKeys.filter(validateIncomingKeys)

    if (validatedKeys.length) {
        for (const key of validatedKeys) { // eslint-disable-line
            filterTypes[key] = true
        }
        return filterTypes
    } else {
        return null
    }
}

export default getActivePayoutsFilterTypes
