import React, { Component } from 'react'
import {FormattedDate, injectIntl} from 'react-intl'
import {code as countryCode} from 'currency-codes'
import {byCountry as countryLookup} from 'country-code-lookup'
import { ADJUSTMENT_TEXT, SUBTYPE_MAPPING } from '../../settlements/constants'
import { TEXT } from '../constants'
import { getActualAmount, getExponentedAmountString } from '../../common/amountHelpers'
import MonetaryAmount from '../../common/components/monetaryAmount'

const getRowHeader = mid => mid.dba_name ? `${mid.dba_name}, ${mid.mid}` : mid.mid
class ReportForCountry extends Component {

    renderReverseCharge(report) {
        const { formatMessage } = this.props.intl
        if (report.has_reverse_charge) {
            return (
                <div className="report-for-country__total-section">
                    <div className="vat-rows">
                        <div className="vat-label">
                            {formatMessage(TEXT['reverse_charge_label'])}
                        </div>
                        <div className="vat-label-reverse">
                            {formatMessage(TEXT['reverse_charge_comment'])}
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderVATpercentage(report) {
        const { formatMessage } = this.props.intl

        if (report.monthly_fees_total_vat && report.monthly_fees_total_vat.rate_percent) {
            return (
                <div>
                    {formatMessage(TEXT['monthly_fees_total_vat_without_rates'])} {getExponentedAmountString(report.monthly_fees_total_vat.rate_percent, report.monthly_fees_total_vat.rate_percent_exponent)}%
                </div>
            )
        }

        return (
            <div>
                {formatMessage(TEXT['monthly_fees_total_vat'])}
            </div>
        )
    }

    render() {

        const { intl, countryReport } = this.props
        const { formatMessage } = intl

        const currencies = countryReport.currencies
        const numberOfCurrencies = Object.keys(currencies).length
        const currencyExponent = (currency) => currencies[currency].currency_exponent
        const feesIncVat = (currency) => currencies[currency].monthly_fees_total_inc_vat
        const feesExclVat = (currency) => currencies[currency].monthly_fees_total_excl_vat
        const feesVat = (currency) => {
            const fVat = currencies[currency].monthly_fees_total_vat
            if(fVat && fVat.hasOwnProperty('amount')) return fVat.amount // eslint-disable-line no-prototype-builtins
            return fVat
        }

        const BamboraName = countryReport && countryReport.footnotes && countryReport.footnotes['BAMBORA'] && countryReport.footnotes['BAMBORA'].name
        const BamboraDeviceName = countryReport && countryReport.footnotes && countryReport.footnotes['BAMBORA_DEVICE'] && countryReport.footnotes['BAMBORA_DEVICE'].name
        const noDataClassName = countryReport.rows ? '' : 'report-for-country--nodata'

        const getComment = (d, c) => {
            if (d.legal_entity === 'BAMBORA'){
                return formatMessage(TEXT['no_vat'])
            }
            else if (d.pricePerUnit) {
                return d.units * getActualAmount(d.pricePerUnit, currencyExponent[c])
            }
            else if (d.comment === 'no_charge') {
                return formatMessage(TEXT['no_charge'])
            }
            return d.comment
        }

        const getCountryCodeForCurrency = (currency) => {
            if (currency === 'EUR') return 'eu'
            const countryLowerCase = countryCode(currency).countries[0]
            const countryLeadingUpperCase = `${countryLowerCase.substr(0, 1).toUpperCase()}${countryLowerCase.substr(1)}`
            return countryLookup(countryLeadingUpperCase).iso2.toLowerCase()
        }

        return (
            Object.keys(currencies).map((currency, idx) =>
                <div className={`report-for-country ${noDataClassName}`} key={currency}>
                    {numberOfCurrencies > 1 &&
                        <div className="currency-header">
                            <span><img src={`/svg/${getCountryCodeForCurrency(currency)}.svg`} className="flag" /> <h4>{currency}</h4></span>
                        </div>
                    }
                    <div className="invoice-report-list-number-download">
                        <div className="report-list-serial-number">
                            {formatMessage(TEXT['invoice-report-list-serial-number'])}
                        <span className="serial-number">{this.props.report.display_name}</span>
                        </div>
                    </div>
                    <p>{formatMessage(TEXT['general_invoice_info'])}<sup>1</sup></p>
                    <React.Fragment>
                        <div className="invoice-headers">
                            <div className="header">Description</div>
                            <div className="header"></div>
                            <div className="header">{`Total (${currency})`}</div>
                        </div>
                        <div className="invoice-detail-info">
                            {countryReport.mids.map((mid, index) =>
                                <React.Fragment key={index}>
                                    <div className="header mid">{mid.rows ? getRowHeader(mid) : null}</div>
                                    {mid.rows && mid.rows.map((row, index) =>
                                        row.currency === currency &&
                                            <div className="_row" key={index}>
                                                <div className="item-1">{formatMessage(ADJUSTMENT_TEXT[SUBTYPE_MAPPING[row.name]])}<sup>{row.footnote.number}</sup></div>
                                                <div className="item-2 __commentcolumn">{getComment(row, currency)}</div>
                                                <div className="item-3">
                                                    <MonetaryAmount className="fg-darkest" amount={row.price} amount_exponent={currencyExponent(currency)} />
                                                </div>
                                            </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                    <div>
                        <div className="report-for-country__total-section">
                            <div>{formatMessage(TEXT['total_fees_exclude_vat'])}</div>
                            <div>{feesExclVat(currency) && <MonetaryAmount className="fg-darkest" amount={feesExclVat(currency)} amount_exponent={currencyExponent(currency)} />}</div>
                        </div>
                        {countryReport.has_monthly_fees_total_vat &&
                            <div className="report-for-country__total-section">
                                { this.renderVATpercentage(countryReport)}
                                <div>{feesVat(currency) && <MonetaryAmount className="fg-darkest" amount={feesVat(currency)} amount_exponent={currencyExponent(currency)} />}</div>
                            </div>
                        }
                        { this.renderReverseCharge(countryReport)}

                        <div className="report-for-country__total-section">
                            <div>{formatMessage(TEXT['total_fees_inc_vat'])}</div>
                            <div>{feesIncVat(currency) && <MonetaryAmount className="fg-darkest" amount={feesIncVat(currency)} amount_exponent={currencyExponent(currency)} />}</div>
                        </div>

                    </div>
                    <div className="report-for-country__footer">
                        <ul>
                            <li key={1}>
                                {formatMessage(TEXT['common_footnote'])}
                            </li>
                            {countryReport && countryReport.footnotes && 'BAMBORA' in countryReport.footnotes &&
                                <li key={2}>
                                    {`
                                        ${formatMessage(TEXT['bambora_ab_footnote_1'])}
                                    `}
                                    <FormattedDate value={this.props.report.period} month="long" />
                                    {`
                                        ${formatMessage(TEXT['bambora_ab_footnote_2'])}
                                        ${BamboraName}
                                        ${countryReport['legal_entities'][BamboraName]['org_number']}
                                    `}
                                </li>
                            }
                            {countryReport && countryReport.footnotes && 'BAMBORA_DEVICE' in countryReport.footnotes &&
                                <li key={3}>
                                    {`
                                    ${formatMessage(TEXT['bambora_device_footnote'])}
                                    ${BamboraDeviceName}
                                    ${countryReport['legal_entities'][BamboraDeviceName]['org_number']}
                                    ${countryReport['legal_entities'][BamboraDeviceName]['vat_number']}
                                    `}
                                </li>
                            }
                        </ul>
                    </div>
                    {idx + 1 < Object.keys(currencies).length ? <hr/> : <br/>}
                </div>
            )
        )
    }

}

export default injectIntl(ReportForCountry)
