import { defineMessages } from 'react-intl'


export const UPDATE_MERCHANT_REQUEST = defineMessages({
    header: {
        id: 'merchant-profile-update-merchant-header',
        defaultMessage: 'Update Merchant Request',
        description: 'Update Merchant Request'
    },
    informationText: {
        id: 'merchant-profile-update-merchant-information',
        defaultMessage: 'It is possible to send an update request for the following fields. Note that the update request must be processed manually and does not result in an immediate change.',
        description: 'Update Merchant Request'
    },
    informationText2: {
        id: 'merchant-profile-update-merchant-information2',
        defaultMessage: 'Updates to this information must be processed manually and does not result in an immediate change.',
        description: 'Update Merchant Request'
    },
    submitButton: {
        id: 'merchant-profile-update-merchant-submit',
        defaultMessage: 'Submit request',
        description: 'Submit request'
    },
    merchant_name_placeholder: {
        id: 'merchant-profile-update-merchant-name-placeholder',
        defaultMessage: 'New Merchant Name',
        description: 'Update Merchant Request'
    },
    merchant_street_address_placeholder: {
        id: 'merchant-profile-update-merchant-street-address-placeholder',
        defaultMessage: 'New Street Address',
        description: 'Update Merchant Request'
    },
    merchant_city_placeholder: {
        id: 'merchant-profile-update-merchant-city-placeholder',
        defaultMessage: 'New City',
        description: 'Update Merchant Request'
    },
    merchant_postal_code_placeholder: {
        id: 'merchant-profile-update-merchant-postal-code-placeholder',
        defaultMessage: 'New Postal Code',
        description: 'Update Merchant Request'
    },
    merchant_country_placeholder: {
        id: 'merchant-profile-update-merchant-country-placeholder',
        defaultMessage: 'New Country',
        description: 'Update Merchant Request'
    },
    merchant_phone_placeholder: {
        id: 'merchant-profile-update-merchant-phone-placeholder',
        defaultMessage: 'New Phone',
        description: 'Update Merchant Request'
    },
    merchantRequestSuccess: {
        id: 'merchant-profile-update-merchant-request-success-header',
        defaultMessage: 'We have now received your request to update merchant information!',
        description: 'Update Merchant Request'
    },
    merchantRequestSuccessBody: {
        id: 'merchant-profile-update-merchant-request-success-body',
        defaultMessage: 'You will not see an immediate change, it could take up to 48 hours until you can see the new information.',
        description: 'Update Merchant Request'
    },
    merchantRequestFail: {
        id: 'merchant-profile-update-merchant-request-failed-header',
        defaultMessage: 'We were unable to update your merchant information',
        description: 'Update Merchant Request'
    },
    merchantRequestFailBody: {
        id: 'merchant-profile-update-merchant-request-failed-body',
        defaultMessage: 'Please contact our support for more details.',
        description: 'Update Merchant Request'
    },
    name: {
        id: 'merchant-profile-update-merchant-request-merchant-name',
        defaultMessage: 'Merchant Name',
        description: 'Merchant name header'
    },
    phone: {
        id: 'merchant-profile-update-merchant-request-merchant-phone-number',
        defaultMessage: 'Phone number',
        description: 'Merchant phone number header'
    },
    street_address: {
        id: 'merchant-profile-update-merchant-request-merchant-street-address',
        defaultMessage: 'Street address',
        description: 'Merchant street address header'
    },
    city: {
        id: 'merchant-profile-update-merchant-request-merchant-city',
        defaultMessage: 'City',
        description: 'Merchant city header'
    },
    postal_code: {
        id: 'merchant-profile-update-merchant-request-merchant-postal-code',
        defaultMessage: 'Postal code',
        description: 'Merchant postal code header'
    },
    country: {
        id: 'merchant-profile-update-merchant-request-merchant-country',
        defaultMessage: 'Country',
        description: 'Merchant country header'
    },
    closeButton: {
        id: 'merchant-profile-update-merchant-request-close-button',
        defaultMessage: 'Close',
        description: 'Close button'
    },
    backButton: {
        id: 'merchant-profile-update-merchant-request-back-button',
        defaultMessage: 'Back',
        description: 'Back button'
    },
    cancelButton: {
        id: 'merchant-profile-update-merchant-request-cancel-button',
        defaultMessage: 'Cancel',
        description: 'Cancel button'
    },
    reviewButton: {
        id: 'merchant-profile-update-merchant-request-review-button',
        defaultMessage: 'Review changes',
        description: 'Proceed to review changes page'
    },
    confirmationText: {
        id: 'merchant-profile-update-merchant-request-confirmation-text',
        defaultMessage: 'Your changes will be visible within 48 hours',
        description: 'Confirmation text'
    },
    confirmationHeader: {
        id: 'merchant-profile-update-merchant-request-confirmation-header',
        defaultMessage: 'Thank you!',
        description: 'Confirmation header'
    },
    summaryUnchangedDetails: {
        id: 'merchant-profile-update-merchant-request-summary-unchanged-details',
        defaultMessage: 'Unchanged details',
        description: 'Sub-header for unchanged details'
    },
    summaryChangedDetails: {
        id: 'merchant-profile-update-merchant-request-summary-changed-details',
        defaultMessage: 'Edited details',
        description: 'Sub-header for changed details'
    },
    summaryHeader: {
        id: 'merchant-profile-update-merchant-request-summary-header',
        defaultMessage: 'Confirm your changes',
        description: 'Header for summary / confirmaion page'
    }
})
