import React from 'react'
import CountUp from 'react-countup'
import { getExponentedAmountString } from '../../common/amountHelpers'
import { SALES_MAIN_TITLE } from '../../sales/sales/constants'
import {TEXT} from '../constants'

const OverviewStatistics = ({sales, count, exponent, formatMessage, currency}) => {

    return (
        <div className="totals">
            <div className="year">
                <div className="total header">{formatMessage(TEXT['sales-in'])} {currency}</div>
                <div className="total body">
                    <CountUp separator={' '} end={parseInt(getExponentedAmountString(sales, exponent))} duration={1} decimals={0}/>
                </div>
            </div>
            <div className="today">
                <div className="total header">{formatMessage(SALES_MAIN_TITLE['acquiring'])}</div>
                <div className="total body">
                    <CountUp separator={' '} end={ count } duration={1} decimals={0}/>
                </div>
            </div>
        </div>
    )
}

export default OverviewStatistics
