import React from 'react'
import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import Banner from '../../components/Banner'
import { Button } from '@frontrunners/ui-components'
import { getCookieValue, setCookie } from '../cookie'
import { redirectToLogin } from '../redirectActions'
import './cookieBanner.scss'

export const CONSENT_COOKIE = 'cookieConsent'

export const TEXT = defineMessages({
    'cookie-banner-header': {
        id: 'cookie-banner-header',
        defaultMessage: 'We respect your privacy!',
        description: 'header message'
    },
    'cookie-banner-purpose': {
        id: 'cookie-banner-purpose',
        defaultMessage: 'This site uses only cookies that are necessary for the basic function of this site. You cannot use this site without accepting the use of these cookies.',
        description: 'purpose message'
    },
    'cookie-banner-moreInfo': {
        id: 'cookie-banner-moreInfo',
        defaultMessage: 'For more information related to this topic, please visit <a>https://www.bambora.com/privacy-policy/</a>',
        description: 'moreInfo message'
    },
    'cookie-banner-moreInfoLink': {
        id: 'cookie-banner-moreInfoLink',
        defaultMessage: 'https://www.bambora.com/privacy-policy/',
        description: 'moreInfoLink'
    },
    'cookie-banner-btnReject': {
        id: 'cookie-banner-btnReject',
        defaultMessage: 'Reject',
        description: 'btnReject label'
    },
    'cookie-banner-btnAccept': {
        id: 'cookie-banner-btnAccept',
        defaultMessage: 'Ok, got it!',
        description: 'btnAccept label'
    }
})

const cookieBanner = ({ intl, dispatch, config }) => {
    const { formatMessage } = intl

    if (getCookieValue(CONSENT_COOKIE) === '1') {
        dispatch(redirectToLogin(config))
        return null
    }
    return (
        <div className="cookie-banner" data-testid="cookie-banner-test">
            <Banner
                visible={true}
                header={formatMessage(TEXT['cookie-banner-header'])}
                purpose={formatMessage(TEXT['cookie-banner-purpose'])}
                moreInfo={formatMessage(TEXT['cookie-banner-moreInfo'], {
                    a: (...chunks) => `<a href="${formatMessage(TEXT['cookie-banner-moreInfoLink'])}">${chunks.join('')}</a>`
                })}
                actionButtons={[
                    <Button key={TEXT['cookie-banner-btnReject'].id} label={formatMessage(TEXT['cookie-banner-btnReject'])} onClick={() => { window.location.href = 'https://worldline.com' }} />,
                    <Button key={TEXT['cookie-banner-btnAccept'].id} label={formatMessage(TEXT['cookie-banner-btnAccept'])} onClick={() => {
                        setCookie(CONSENT_COOKIE, '1', 3650)
                        dispatch(redirectToLogin(config))
                    }}
                    />
                ]}
            />
        </div>
    )
}

export const CookieBanner = injectIntl(cookieBanner)

export default connect(null)(CookieBanner)
