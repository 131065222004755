import { defineMessages } from 'react-intl'


export const SETTINGS_PAGES = defineMessages({
    'general': {
        id: 'general',
        defaultMessage: 'General',
        description: 'Name of the settings page General'
    },
    'accounts': {
        id: 'accounts',
        defaultMessage: 'Accounts',
        description: 'Name of the settings page Accounts'
    },
    'my-profile': {
        id: 'my-profile',
        defaultMessage: 'My profile',
        description: 'Name of the settings page User profile'
    },
    'online': {
        id: 'online',
        defaultMessage: 'Online',
        description: 'Name of the settings page Online'
    },
    'instore': {
        id: 'instore',
        defaultMessage: 'Instore',
        description: 'Name of the settings page Instore'
    },
    'support': {
        id: 'support',
        defaultMessage: 'Support',
        description: 'Name of the settings page Support'
    },
    'settings': {
        id: 'settings',
        defaultMessage: 'Settings',
        description: 'Settings page header'
    }
})
