import React from 'react'
import { SALES_DETAILS_TEXTS, FILTER_BUTTON_TEXT } from '../../../sales/constants'
import { getSystemIdToMid, getSaleslocationFromAcquiringMid } from '../../../../merchant/helpers'


const OnlinePointOfSale = ({ merchant, formatMessage, payment }) => {
    let system_id = payment.source_merchant_id
    let address = ''

    if (merchant.saleslocation_set.length == 1) {
        const saleslocation = merchant.saleslocation_set[0]
        address = saleslocation.name
    }
    else {
        let system_id_to_mid = getSystemIdToMid(merchant, 'zero', system_id)

        if (system_id_to_mid) {
            let saleslocation = getSaleslocationFromAcquiringMid(merchant,system_id_to_mid['acquiring_mid'])

            if (saleslocation) {
                address = saleslocation.name
            }
        }
    }

    return (
        <div className="detail-wrapper">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS['details_point_of_sale_header'])}</div>
            <div className="detail-wrapper">
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['details_online_id'])}</div>
                    <div className="element">{system_id}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(FILTER_BUTTON_TEXT['source_merchant_id'])}</div>
                    <div className="element">{address}</div>
                </div>
            </div>
        </div>
    )
}

export default OnlinePointOfSale
