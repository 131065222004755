import React, { useContext } from 'react'
import { defineMessages } from 'react-intl'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import { mixpanelPrefix } from '../../common/helpers/mixpanelPrefix'
import { TEXT as LOAN_TEXTS } from '../../loans/constants'
import { setUserPreference } from '../../user/actions'
import { TRIAL_STATES, STATES } from '../../merchant/constants'
import { ROLES } from '../../merchant/constants'
import { TRIAL } from '../../common/translations'
import { getMerchantCountry } from '../../common/helpers/findMerchant'
import { getTerminalModelsQuery } from '../../common/instoreQueries'
import { ApplicationContext } from '../../common/reactContext'
import { isMaintenanceMode } from '../../common/dateHelpers'
import BamboraSvgIcon from '../../common/components/bamboraSvgIcon'
import './NotificationBanners.scss'


export const NotificationBanners = () => {
    const { config, activeMerchant, loggedInUser } = useContext(ApplicationContext)
    const { formatMessage: fm } = useIntl()

    const showLoanBanner = activeMerchant.loanEligible
        && !loggedInUser?.portalPreferences.loanBannerVisited
        && [ROLES.OWNER, ROLES.ADMIN].includes(loggedInUser.role)
    const showTrialBanner = TRIAL_STATES.includes(activeMerchant.state)
    const trialBannerText = activeMerchant.state === STATES.TRIAL_NOT_ACTIVATED ?
        fm(TRIAL['link_active']) : fm(TRIAL['link_switch'])
    const trialBannerLink = `/merchants/${activeMerchant.prodMerchantId}/settings`
    const country = getMerchantCountry(activeMerchant)?.toLowerCase()
    const terminalSwapUrl = getTerminalSwapUrl(country)
    const { data: terminals } = getTerminalModelsQuery(
        config.apiHost, activeMerchant.id, [1012, 1014, 1015, 1016, 1020]
    )

    const showTerminalSwapBanner = terminalSwapUrl
        && !loggedInUser?.portalPreferences?.terminalSwapBannerVisited
        && terminals?.length > 0

    const closeLoanBanner = () => {
        config.dispatch(setUserPreference({ value: true, type: 'loanVisited' }))
    }

    const closeTerminalSwapBanner = () => {
        mixpanel.track(`${mixpanelPrefix.TERMINAL_SWAP} Close terminal swap banner`)
        config.dispatch(setUserPreference({ value: true, type: 'terminalSwapBannerVisited' }))
    }

    const terminalsMixpanelEvent = () => {
        mixpanel.track(`${mixpanelPrefix.TERMINAL_SWAP} Click on terminal swap banner`)
    }

    return (
        <>
            {showLoanBanner &&
                <Notification
                    msgText={fm(LOAN_TEXTS['loan-notification-message'])}
                    linkText={fm(LOAN_TEXTS['loan-notification-link'])}
                    linkUrl={`/merchants/${activeMerchant.id}/loans`}
                    closeAction={closeLoanBanner}
                />
            }
            {showTrialBanner &&
                <Notification
                    msgText={fm(TRIAL['msg'])}
                    linkText={activeMerchant.hasAccessToProdMerchant ? trialBannerText: null}
                    linkUrl={activeMerchant.hasAccessToProdMerchant ? trialBannerLink : null}
                />
            }
            {showTerminalSwapBanner &&
                <Notification
                    msgText={fm(TERMINAL_SWAP_TEXT['terminal-swap-info'])}
                    closeAction={closeTerminalSwapBanner}
                    customLink={
                        <a href={terminalSwapUrl} target="_blank" onClick={terminalsMixpanelEvent}>
                            {fm(TERMINAL_SWAP_TEXT['terminal-swap-read-more'])}
                        </a>
                    }
                />
            }
            {isMaintenanceMode(activeMerchant.id) &&
                <Notification
                    msgText={fm(REPORTS_MAINTENANCE_MODE_TEXT['reports-maintenance-info'])}
                />
            }
        </>
    )
}

const Notification = ({ msgText = '', linkText, linkUrl, closeAction, customLink }) => {
    const getLink = () => {
        if (customLink) {
            return customLink
        } else if (linkText && linkUrl) {
            return <Link to={linkUrl} >{linkText}</Link>
        }
    }

    return (
        <div className="notification-wrapper" data-testid="notification-banner">
            <div className="notification-banner-message">
                {msgText}
                {getLink()}
            </div>
            {closeAction &&
                <BamboraSvgIcon
                    icon="close-thin"
                    onClick={closeAction}
                    className="notification-close"
                />
            }
        </div>
    )
}

const TERMINAL_SWAP_TEXT = defineMessages({
    'terminal-swap-info': {
        id: 'terminal-swap-info',
        defaultMessage: 'Important! One or several of your card terminals needs to be replaced in the near future.',
        description: 'Info banner about terminal swap'
    },
    'terminal-swap-read-more': {
        id: 'terminal-swap-read-more',
        defaultMessage: 'Read more.',
        description: 'Link to a web site'
    }
})

const REPORTS_MAINTENANCE_MODE_TEXT = defineMessages({
    'reports-maintenance-info': {
        id: 'reports-maintenance-info',
        defaultMessage: 'Reports and invoices are temporarily unavailable due to maintenance. We apologize for the inconvenience and appreciate your understanding.',
        description: 'Info banner about ongoing maintenance to the reports features'
    }
})

const getTerminalSwapUrl = (country) => {
    return {
        'sweden': 'http://www.worldline.com/sv-se/campaigns/ms/Terminal-swap.html',
        'norway': 'http://www.worldline.com/no-no/campaigns/ms/Terminal-swap.html',
        'denmark': 'http://www.worldline.com/da-dk/campaigns/ms/Terminal-swap.html',
        'finland': 'http://www.worldline.com/fi-fi/campaigns/ms/Terminal-swap.html'
    }[country]
}

