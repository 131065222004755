export const groupBy = (arr, key) => {
    return arr.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
}

export function arrayMergeAndUnique(items) {
  const reducer = (accumulator, currentValue) => [...accumulator, ...currentValue]
  const mergedArr = items.reduce(reducer, [])
  return arrayUnique(mergedArr)
}

export function arrayUnique(array) {
  var a = array.concat()
  for(var i=0; i<a.length; ++i) {
      for(var j=i+1; j<a.length; ++j) {
          if(a[i] === a[j])
              a.splice(j--, 1)
      }
  }

  return a
}