import { defineMessages } from 'react-intl'

export const NAME = 'dashboard'

export const WELCOME = 'welcome'

export const DATE_NAVIGATION = {
    prev: 'prev',
    next: 'next'
}

export const FILTERS = {
    channels: 'channels',
    source_merchant_ids: 'source_merchant_ids',
    currency: 'currency',
    date: 'date'
}

export const defaultChannel = ['instore', 'online', 'acquiring']

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'

export const TEXT = defineMessages({
    'sales-in': {
        id: 'sales-in',
        defaultMessage: 'Sales in',
        description: 'Sales in'
    },
    'merchant': {
        id: 'merchant',
        defaultMessage: 'Merchant',
        description: 'Merchant'
    },
    'sales-link': {
        id: 'sales-link',
        defaultMessage: 'Sales',
        description: 'sales'
    }
})
export const WELCOME_TEXT = defineMessages({
    'welcome-title-2': {
        id: 'welcome-welcome-title-2',
        defaultMessage: 'Welcome!',
        description: 'welcome-welcome-title'
    },
    'welcome-text-2': {
        id: 'welcome-welcome-text-2',
        defaultMessage: 'In our reporting tool you get access to all reporting from Bambora in one place.',
        description: 'welcome-welcome-text'
    },
    'overview-title': {
        id: 'welcome-overview-title',
        defaultMessage: 'Overview',
        description: 'welcome-overview-title'
    },
    'overview-text': {
        id: 'welcome-overview-text',
        defaultMessage: 'Here you can get an instant understanding of your latest sales numbers and gain new insights into how your business is performing.',
        description: 'welcome-overview-text'
    },
    'payouts-title': {
        id: 'welcome-payouts-title',
        defaultMessage: 'Payouts',
        description: 'welcome-payouts-title'
    },
    'payouts-text': {
        id: 'welcome-payouts-text',
        defaultMessage: 'On the Payouts tab you can see all payouts from Bambora and the transactions included in each payout.',
        description: 'welcome-payouts-text'
    },
    'sales-title': {
        id: 'welcome-sales-title',
        defaultMessage: 'Sales',
        description: 'welcome-sales-title'
    },
    'sales-text': {
        id: 'welcome-sales-text',
        defaultMessage: 'All your transactions are available on the Sales page where you also can filter and search for specific transactions',
        description: 'welcome-sales-text'
    },
    'report-title': {
        id: 'welcome-report-title',
        defaultMessage: 'Monthly reports',
        description: 'welcome-report-title'
    },
    'report-text': {
        id: 'welcome-report-text',
        defaultMessage: 'You can also download monthly summaries from the Monthly reports dropdown.',
        description: 'welcome-report-text'
    },
    'report-text-2': {
        id: 'welcome-report-text-2',
        defaultMessage: 'You can also download monthly reports containing all your payouts and transactions.',
        description: 'welcome-report-text-2'
    },
    'tool-title': {
        id: 'welcome-tool-title',
        defaultMessage: 'Settings',
        description: 'welcome-tool-title'
    },
    'tool-admin-text-2': {
        id: 'welcome-tool-admin-text-2',
        defaultMessage: 'On the Settings page you can see all your Bambora accounts and also add colleagues as users.',
        description: 'welcome-tool-admin-text'
    },
    'tool-user-text': {
        id: 'welcome-tool-user-text',
        defaultMessage: 'On the Settings page you can see all your Bambora accounts and update language preferences.',
        description: 'welcome-tool-user-text'
    },
    'loan-title': {
        id: 'welcome-loan-title',
        defaultMessage: 'Loan',
        description: 'welcome-loan-title'
    },
    'loan-text': {
        id: 'welcome-loan-text',
        defaultMessage: 'On the Loans page you can access Bambora’s loan product Growth Finance that allows you to apply for a loan completely digitally without any tedious paperwork.',
        description: 'welcome-loan-text'
    },
    'end-title': {
        id: 'welcome-end-title',
        defaultMessage: 'We hope you will like it!',
        description: 'welcome-end-title'
    },
    'start': {
        id: 'welcome-start',
        defaultMessage: 'Start',
        description: 'welcome-start'
    },
    'next-page': {
        id: 'next-page',
        defaultMessage: 'Next',
        description: 'Go to next page in welcome guide'
    },
    'prev-page': {
        id: 'prev-page',
        defaultMessage: 'Previous',
        description: 'Go to previous page in welcome guide'
    }
})

export const GRAPH_DATE_TRANSLATIONS = {
    'en': {
        'dateTime': '%x, %X',
        'date': '%-m/%-d/%Y',
        'time': '%-I:%M:%S %p',
        'periods': ['AM', 'PM'],
        'days': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        'shortDays': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'months': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        'shortMonths': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    'sv': {
        'dateTime': '%A den %d %B %Y %X',
        'date': '%Y-%m-%d',
        'time': '%H:%M:%S',
        'periods': ['fm', 'em'],
        'days': ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
        'shortDays': ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
        'months': ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        'shortMonths': ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
    },
    'nb': {
        'dateTime': '%A den %d. %B %Y %X',
        'date': '%d.%m.%Y',
        'time': '%H:%M:%S',
        'periods': ['AM', 'PM'],
        'days': ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
        'shortDays': ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
        'months': ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'],
        'shortMonths': ['jan', 'feb', 'mars', 'apr', 'mai', 'juni', 'juli', 'aug', 'sep', 'okt', 'nov', 'des']
    },
    'da': {
        'dateTime': '%A den %d %B %Y %X',
        'date': '%d-%m-%Y',
        'time': '%H:%M:%S',
        'periods': ['AM', 'PM'],
        'days': ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
        'shortDays': ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
        'months': ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'],
        'shortMonths': ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec']
    },
    'fi': {
        'dateTime': '%A, %-d. %Bta %Y klo %X',
        'date': '%-d.%-m.%Y',
        'time': '%H:%M:%S',
        'periods': ['a.m.', 'p.m.'],
        'days': ['sunnuntai', 'maanantai', 'tiistai', 'keskiviikko', 'torstai', 'perjantai', 'lauantai'],
        'shortDays': ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La'],
        'months': ['tammikuu', 'helmikuu', 'maaliskuu', 'huhtikuu', 'toukokuu', 'kesäkuu', 'heinäkuu', 'elokuu', 'syyskuu', 'lokakuu', 'marraskuu', 'joulukuu'],
        'shortMonths': ['Tammi', 'Helmi', 'Maalis', 'Huhti', 'Touko', 'Kesä', 'Heinä', 'Elo', 'Syys', 'Loka', 'Marras', 'Joulu']
    },
    'de': {
        'dateTime': '%A, der %e. %B %Y, %X',
        'date': '%d.%m.%Y',
        'time': '%H:%M:%S',
        'periods': ['AM', 'PM'],
        'days': ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        'shortDays': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        'months': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        'shortMonths': ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    },
    'fr': {
        'dateTime': '%A %e %B %Y à %X',
        'date': '%d/%m/%Y',
        'time': '%H:%M:%S',
        'periods': ['AM', 'PM'],
        'days': ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        'shortDays': ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        'months': ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        'shortMonths': ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
    },
    'it': {
        'dateTime': '%A %e %B %Y, %X',
        'date': '%d/%m/%Y',
        'time': '%H:%M:%S',
        'periods': ['AM', 'PM'],
        'days': ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        'shortDays': ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        'months': ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        'shortMonths': ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
    },
    'nl-NL': {
        'dateTime': '%a %e %B %Y %X',
        'date': '%d-%m-%Y',
        'time': '%H:%M:%S',
        'periods': ['AM', 'PM'],
        'days': ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        'shortDays': ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        'months': ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        'shortMonths': ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec']
    },
    'fr-BE': {
        'dateTime': '%A %e %B %Y à %X',
        'date': '%d/%m/%Y',
        'time': '%H:%M:%S',
        'periods': ['AM', 'PM'],
        'days': ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        'shortDays': ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        'months': ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        'shortMonths': ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
    }
}
