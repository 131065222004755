import React from 'react'
import BamboraSvgIcon from '../../common/components/bamboraSvgIcon'
import { getFirstDateOfMonth, getFirstDateOfYear, isDateAfterMinimumTransactionDate } from '../../common/dateHelpers'
import { DATE_NAVIGATION } from '../constants'

const DashboardDatePicker = ({selectedDate, isDisabled, isYearly, handleDateChange}) => {
    const requestDate = isYearly ? getFirstDateOfYear(selectedDate.toDate()) : getFirstDateOfMonth(selectedDate.toDate())
    const hasPreviousPage = isDateAfterMinimumTransactionDate(requestDate)
   return (
        <div className="date-selector">
           <button disabled={!hasPreviousPage} className="date-select previous" onClick={() => {
                handleDateChange(DATE_NAVIGATION.prev)}}>
                    <BamboraSvgIcon icon="arrow-left"/>
            </button>
            <div className="date">{selectedDate ? selectedDate.format(isYearly ? 'YYYY' : 'MMM YYYY') : ''}</div>
            <button disabled={isDisabled} className="date-select next" onClick={() => {
                handleDateChange(DATE_NAVIGATION.next)}}>
                    <BamboraSvgIcon icon="arrow-left"/>
            </button>
        </div>
    )
}

export default DashboardDatePicker
