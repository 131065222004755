import { defineMessages } from 'react-intl'

export const STATES = {
    started: 'started',
    pending: 'pending',
    done: 'done',
    failed: 'failed'
}

export const POLL_STATES = [STATES.started, STATES.pending]
export const TERMINAL_STATES = [STATES.done, STATES.failed]
export const REPORT_TYPES = ['multimid_v2', 'multimid_detailed', 'detailed']

export const MONTHLY_REPORTS_TEXT = defineMessages({
    report_notification_header: {
        id: 'monthly-report-error-notification-header',
        defaultMessage: 'Report could not be delivered',
        description: 'Header of notification'
    },
    report_notification_body: {
        id: 'monthly-report-error-notification-body',
        defaultMessage: 'Please cancel ({cancelIcon}) failed reports and try again. If the problem remains, please contact support.',
        description: 'Body of notification'
    },
    multimid_v2_description_1: {
        id: 'multimid-v2-description-1',
        defaultMessage: 'Details about payouts, fees and transactions',
        description: 'Monthly reports description'
    },
    multimid_detailed_description_1: {
        id: 'multimid-description-1',
        defaultMessage: 'Summary of payouts and fees',
        description: 'Monthly reports description'
    },
    report_downloading: {
        id: 'monthly-report-downloading',
        defaultMessage: 'Downloading',
        description: 'Message to display while report is being downloaded'
    },
    report_failed: {
        id: 'monthly-report-failed',
        defaultMessage: 'Failed',
        description: 'Message to display if a report fails to download'
    },
    pending_download_notification_header: {
        id: 'pending-download-notification-header',
        defaultMessage: 'Report ready for download',
        description: 'Header of pending download notification'
    },
    pending_download_notification_header_dismiss: {
        id: 'pending-download-notification-header-dismiss',
        defaultMessage: 'Dismiss?',
        description: 'Header of pending download dismissal notification'
    },
    pending_download_notification_body: {
        id: 'pending-download-notification-body',
        defaultMessage: 'Your monthly report {monthYear} ({reportType}) is now ready for download.',
        description: 'Message to display when user has pending downloads'
    },
    pending_download_notification_body_dismiss: {
        id: 'pending-download-notification-body-dismiss',
        defaultMessage: 'If you dismiss the report you will need to generate it again.',
        description: 'Message to display when user selects to dismiss the download'
    },
    pending_download_notification_footer_download: {
        id: 'pending-download-notification-footer-download',
        defaultMessage: 'Download',
        description: 'Download link'
    },
    pending_download_notification_footer_back: {
        id: 'pending-download-notification-footer-back',
        defaultMessage: 'Back',
        description: 'Cancel download dismissal'
    },
    pending_download_notification_footer_dismiss: {
        id: 'pending-download-notification-footer-dismiss',
        defaultMessage: 'Dismiss',
        description: 'Confirm download dismissal'
    },
    report_type_summary: {
        id: 'report-type-summary',
        defaultMessage: 'Summary',
        description: 'Report type in pending download notification'
    },
    report_type_detailed: {
        id: 'report-type-detailed',
        defaultMessage: 'Detailed',
        description: 'Report type in pending download notification'
    }
})
