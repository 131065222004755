import React, { Component } from 'react'
import FrodaComponent from '../components/frodaComponent'
import { connect } from 'react-redux'
import { getFrodaToken, getConsent } from '../actions'
import * as userActions from '../../user/actions'
import Spinner from '../../common/components/spinner'
import { getMerchant } from '../../reports/helpers'
import { withRouter } from 'react-router-dom'
import LoanErrorNotification from '../components/loanErrorNotification'
import { SUPPORTAL_ERROR_MESSAGE, REQUEST_ERROR_MESSAGE } from '../constants'

class LoanContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            consent: false
        }
    }

    renderConsent(token, url, localeCode) {
        const merchantId = this.props.match.params.merchantId
        const merchants = this.props.merchants
        const activeMerchant = getMerchant(merchantId, merchants)
        if(!this.props.loans.get('givenConsent')) {
            if(activeMerchant.loggedInUserIsSupport) {
              return <LoanErrorNotification message={SUPPORTAL_ERROR_MESSAGE}/>
            }
        }
        if (!token) {
            return <LoanErrorNotification message={REQUEST_ERROR_MESSAGE}/>
        }

        return (
            <div>
                {token && <FrodaComponent token={token} url={url} merchantId={this.props.match.params.merchantId} locale={localeCode}/>}
            </div>
        )
    }

    static getDerivedStateFromProps(nextProps, state) {
        if(nextProps.loans.get('givenConsent') !== state.consent) {
            nextProps.dispatch(getFrodaToken(nextProps.match.params.merchantId))
            state.consent = nextProps.loans.get('givenConsent')
            return state
        } else {
            return null
        }
    }

    render() {
        const token = this.props.loans.get('access_token')
        const url = this.props.config.get('frodaHost')
        const fetching = this.props.loans.get('isFetching')
        const localeCode = this.props.localization.get('localeCode')
        return (
            <div className="loan-consent-container">
                {(() => {
                    if (this.props.isFetching || fetching) return <Spinner waiting={true} />
                    else return this.renderConsent(token, url, localeCode)
                })()}
            </div>
        )
    }

    componentDidMount() {
        this.props.dispatch(userActions.setUserPreference({value:true, type:'loanVisited'}))
        const merchantId = this.props.match.params.merchantId
        const merchants = this.props.merchants
        const activeMerchant = getMerchant(merchantId, merchants)

        // If this is a supportal user, verify that consent has been given.
        if (activeMerchant && activeMerchant.loggedInUserIsSupport) {
            this.props.dispatch(
                getConsent(merchantId)
            )
        } else {
            this.props.dispatch(
                getFrodaToken(merchantId)
            )
        }
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        loans: state.getIn([activeMerchant, 'loans']),
        config: state.get('config'),
        userId: state.get('user').get('profile'),
        localization: state.get('localization'),
        merchants: state.getIn(['merchant', 'merchants'])
    }
}

export default connect(mapStateToProps)(withRouter(LoanContainer))
