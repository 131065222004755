import React, { useContext, useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NAVLINK_TEXTS as NT } from '../constants'
import { TRANSLATIONS as T } from './loggedInUserMenu.intl'
import { LanguageSelector } from './languageSelector'
import { LANGUAGES } from '../../merchant/constants'
import { ApplicationContext } from '../../common/reactContext'
import { submitLanguageSelection } from '../helpers/languageSelectionHelpers'
import { localStorageClear } from '../../common/localstorageHelpers'
import { Icon } from '@frontrunners/ui-components'
import { settingsPages } from '../../settings/settingsPages'
import { getActiveZeroMerchants, getEpayRedirect } from '../../settings/online/epayQueries'
import { getUser } from '../../common/userQueries'
import { signOut } from '@frontrunners/wl-cognito-login'
import { ROLES } from '../../merchant/constants'
import { isMaintenanceMode } from '../../common/dateHelpers'


const BASE_PATH = '/merchants/'


export const MenuItem = ({ path, content, iconName, disabled, id, activeClassName = 'active', className = '' }) => {
    const { activeMerchant } = useContext(ApplicationContext)

    if (disabled) return null
    return (
        <NavLink
            id={id}
            to={BASE_PATH + activeMerchant.id + path}
            activeClassName={activeClassName}
            className={'navbar-item ' + className}
            exact
        >
            {iconName && <Icon iconName={iconName} />}
            {content}
        </NavLink>
    )
}

export const WlIcon = () => {
    const { activeMerchant } = useContext(ApplicationContext)
    return (
        <Link data-testid="wl-logo" className="wl-logo" to={BASE_PATH + activeMerchant.id}>
            <Icon iconName="wlPalmtree" />
        </Link>
    )
}

export const Overview = () => {
    const { formatMessage: fm } = useIntl()

    return (
        <MenuItem
            path={''}
            content={fm(NT['navbar-main-link-overview'])}
            iconName="chart"
            id="navbar-link-overview"
        />
    )
}

export const Payouts = () => {
    const { formatMessage: fm } = useIntl()
    const { activeMerchant } = useContext(ApplicationContext)

    return (
        <MenuItem
            path={'/payouts'}
            content={fm(NT['navbar-main-link-payouts'])}
            iconName="circledArrow"
            disabled={activeMerchant.isDeviceMerchant !== false}
            id="navbar-link-payouts"
        />
    )
}

export const Sales = () => {
    const { formatMessage: fm } = useIntl()

    return (
        <MenuItem
            path={'/sales'}
            content={fm(NT['navbar-main-link-sales'])}
            iconName="cards"
            id="navbar-link-sales"
        />
    )
}

export const Reports = () => {
    const { formatMessage: fm } = useIntl()
    const { activeMerchant } = useContext(ApplicationContext)

    return (
        <MenuItem
            path={'/reports'}
            content={fm(NT['navbar-main-link-reports'])}
            iconName="checkList"
            id="navbar-link-reports"
            className={isMaintenanceMode(activeMerchant.id) ? 'disabled' : ''}
        />
    )
}

export const Invoices = () => {
    const { formatMessage: fm } = useIntl()
    const { activeMerchant } = useContext(ApplicationContext)

    return (
        <MenuItem
            path={'/invoices'}
            content={fm(NT['navbar-main-link-invoices'])}
            iconName="invoice"
            disabled={!activeMerchant?.hasInvoices}
            id="navbar-link-invoices"
            className={isMaintenanceMode(activeMerchant.id) ? 'disabled' : ''}
        />
    )
}

export const OnlinePortalLink = () => {
    const { formatMessage: fm } = useIntl()
    const { config, activeMerchant, loggedInUser } = useContext(ApplicationContext)

    const { data: activeZeroIdSet } = getActiveZeroMerchants(config.apiHost, activeMerchant.id)
    const hasActiveZeroIds = !!activeZeroIdSet?.data && activeZeroIdSet?.data.length > 0

    const { data: user } = getUser(config.apiHost, activeMerchant.id, loggedInUser.sub, hasActiveZeroIds)
    const { data: link } = getEpayRedirect(config.apiHost, activeMerchant, loggedInUser, user, { enabled: hasActiveZeroIds && !!user })


    if (!link?.redirect_link) {
        return null
    }

    return (
        <a
            className="navbar-item"
            id="navbar-link-online-portal"
            href={link?.redirect_link}
            target="blank"
        >
            <Icon iconName="newWindow" />
            {fm(NT.navbar_main_link_online_portal)}
        </a>
    )
}

export const Loans = () => {
    const { formatMessage: fm } = useIntl()
    const { activeMerchant, loggedInUser } = useContext(ApplicationContext)

    return (
        <MenuItem
            path={'/loans'}
            content={fm(NT['navbar-main-link-loans'])}
            iconName="graph"
            disabled={!(activeMerchant?.loanEligible && [ROLES.ADMIN, ROLES.OWNER].includes(loggedInUser.role))}
            id="navbar-link-loans"
        />
    )
}

export const Language = () => {
    const { config, loggedInUser } = useContext(ApplicationContext)

    return (
        <div className="nav-item-container">
            <LanguageSelector
                onSelect={
                    submitLanguageSelection(
                        loggedInUser.isChatEligible,
                        loggedInUser.locale,
                        loggedInUser.sub,
                        config.dispatch
                    )
                }
                languages={LANGUAGES}
                selectedLocaleCode={loggedInUser.locale}
            />
        </div>
    )
}

export const SettingsDesktop = () => {
    return (
        <MenuItem
            path={'/settings'}
            content={
                <div className="nav-item-container" data-testid="settings-link">
                    <Icon iconName="cogWheel" />
                </div>
            }
            id="navbar-link-setting"
        />
    )
}

export const SettingsMobile = () => {
    const { formatMessage: fm } = useIntl()
    const { activeMerchant, config } = useContext(ApplicationContext)

    const [isOpen, setIsOpen] = useState(false)
    const [views, setViews] = useState([])

    const { data: activeZeroIdSet, isLoading} = getActiveZeroMerchants(
        config.apiHost, activeMerchant.id
    )

    const isActive = window.location.pathname.includes('settings')

    useEffect(() => {
        setViews(settingsPages({activeZeroIdSet, activeMerchant, formatMessage: fm}))
    }, [activeZeroIdSet])

    return (
        <>
            <div className={`navbar-item ${isActive ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)} data-testid="settings">
                <Icon iconName="cogWheel" />
                <div className="settings-main">
                    {fm(NT['navbar-main-link-settings'])}
                    <Icon iconName="arrowDown" rotationDegrees={isOpen ? 180 : 0} />
                </div>
            </div>
            <div className={`submenu ${!isOpen || isLoading ? 'closed' : ''}`}>
                {views.map(page => {
                    if (page.active) {
                        return (
                            <MenuItem
                                key={page.name}
                                path={page.path}
                                content={page.name}
                                activeClassName=""
                            />
                        )
                    }
                })}
            </div>
        </>
    )
}

export const SwitchMerchant = () => {
    const { formatMessage: fm } = useIntl()
    const { loggedInUser } = useContext(ApplicationContext)

    if (!loggedInUser.hasMultipleMerchants) {
        return null
    }

    return (
        <NavLink to={BASE_PATH} activeClassName="active" className="navbar-item" exact>
            <Icon iconName="swap" />
            {fm(T.switch_merchant)}
        </NavLink>
    )
}

export const Logout = () => {
    const { config } = useContext(ApplicationContext)
    const { formatMessage: fm } = useIntl()
    console.log('config',config)
    const onClickHandler = () => {
        signOut({
            UserPoolId: config.cognitoPoolId,
            ClientId: config.cognitoClientId
        })
        localStorageClear()
        window.location.replace('/')
    }

    return (
        <div className="navbar-item" onClick={onClickHandler}>
            <Icon iconName="logout" />
            {fm(T.log_out)}
        </div>
    )
}

export const UserDetails = () => {
    const { activeMerchant, loggedInUser } = useContext(ApplicationContext)
    return (
        <div className="user-details-container navbar-item">
            <Icon iconName="user" />
            <div className="user-details">
                <div className="user-name">
                    {loggedInUser.email}
                </div>
                <div className="merchant-name">
                    {activeMerchant.name}
                </div>
            </div>
        </div>
    )
}
