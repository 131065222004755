export const CACHE = {}
const CACHE_LENGTH = 1000 * 60 * 10

export const makeRequest = async (method, url, successHandler, errorHandler, parameters={}, requestBody=null, additionalHeaders={}, useCache=false) => {
    if (!successHandler || !errorHandler) {
        throw new Error('You must pass a successHandler/errorHandler function to handle the response!')
    }

    const requestUrl = parameters ? `${url}?${parseParametersToQueryString(parameters)}` : url
    const requestObject = { method: method, headers: getHeaders(additionalHeaders) }

    if(requestBody) {
        requestObject.body = JSON.stringify(requestBody)
    }

    if (useCache) {
        const { response, result } = useCachedResponse(requestUrl)
        if (result) {
            return successHandler(response, result)
        }
    }

    const response = await fetch(requestUrl, requestObject).catch(() => {})

    if (!response) {
        errorHandler('An unknown error has occured')
        return
    }

    /*
     * If response isn't JSON-serializable we return a empty object
     * If we don't do this, we will have to special treat 204s in the catch-clause
     */

    const result = await response.json().catch(() => {})

    try {
        if([200, 201, 202, 204].includes(response.status) && response.ok) {
            successHandler(response, result)
        } else {
            errorHandler(response)
        }
        if(useCache) saveToCache(requestUrl, response, result)
    } catch (error) {
        errorHandler(error)
    }
}

const requestHelper = async (baseUrl, path, successHandler, errorHandler, parameters, requestBody, method='GET', additionalHeaders, useCache=false) => {
    return makeRequest(method, `${baseUrl}${path}`, successHandler, errorHandler, parameters, requestBody, additionalHeaders, useCache)
}

const saveToCache = (key, response, result) => {
    const expires = new Date().valueOf() + CACHE_LENGTH
    CACHE[key] = {
        result: result,
        response: response,
        expires: expires
    }
}

const useCachedResponse = (key) => {
    const time = new Date().valueOf()
    const cacheHit = CACHE[key]
    const noCacheHit = { result: null, response: null }

    if (cacheHit && time < cacheHit.expires) {
        return {
            result: cacheHit.result,
            response: cacheHit.response
        }
    }
    return noCacheHit
}

const parseParametersToQueryString = (parameters) => {
    if (!parameters) return
    const queryString = Object.keys(parameters)
        .map((key) => encodeURIComponent(key) + '=' +
            encodeURIComponent(parameters[key])).join('&')
    return queryString
}

export const getHeaders = (additionalHeaders) => {
    const token = localStorage.getItem('accessToken')

    let _headers = new Headers()

    _headers.append('Accept', 'application/json')
    _headers.append('Content-Type', 'application/json')

    if (additionalHeaders) {
        for (var [key, value] of Object.entries(additionalHeaders)) {
            _headers.append(key, typeof value === 'string' ? value : JSON.stringify(value))
        }
    }

    if (token) {
        _headers.append('Authorization', `Bearer ${token}`)
    }

    return _headers
}

export const downloadFileFromLink = (link) => {
    const elem = document.createElement('a')
    elem.setAttribute('download', 'download')
    elem.href = link
    elem.click()
    elem.remove()
}

export default requestHelper
