export const LIST_AVAILABLE_INVOICE_STATEMENTS_START = 'invoice/LIST_AVAILABLE_INVOICE_STATEMENTS_START'
export const LIST_AVAILABLE_INVOICE_STATEMENTS_SUCCESS = 'invoice/LIST_AVAILABLE_INVOICE_STATEMENTS_SUCCESS'
export const LIST_AVAILABLE_INVOICE_STATEMENTS_ERROR = 'invoice/LIST_AVAILABLE_INVOICE_STATEMENTS_ERROR'

export const REQUEST_INVOICE_REPORT_START = 'invoice/REQUEST_INVOICE_REPORT_START'
export const REQUEST_INVOICE_REPORT_SUCCESS = 'invoice/REQUEST_INVOICE_REPORT_SUCCESS'
export const REQUEST_INVOICE_REPORT_ERROR = 'invoice/REQUEST_INVOICE_REPORT_ERROR'

export const REQUEST_INVOICE_JSON_SUCCESS = 'invoice/REQUEST_INVOICE_JSON_SUCCESS'
export const REQUEST_INVOICE_JSON_START =  'invoice/REQUEST_INVOICE_JSON_START'
export const REQUEST_INVOICE_JSON_ERROR =  'invoice/REQUEST_INVOICE_JSON_ERROR'
