import React from 'react'
import { TEXT } from '../../common/translations'
import { DASHBOARD_TRANSLATIONS } from '../translations'
import Spinner from '../../common/components/spinner'
import { fnumWithM, thousandSeperator } from '../../common/amountHelpers'

const LoanWidget = ({ availableCredit, remainingDebt, isFetching, formatMessage, hasActiveLoan, redirectLink }) => {

    const activeLoan = hasActiveLoan && remainingDebt ? remainingDebt.amount > 0 : false
    const descriptionKey = activeLoan ? 'loan_paragraph_active_loan' : 'loan_paragraph_no_loan'

    const LoanHeader = () => {
        return (
            <div className="loan header">
                <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['loans'])}</div>
                <div className="link" onClick={() => redirectLink()}>{formatMessage(DASHBOARD_TRANSLATIONS['loans'])}</div>
            </div>
        )
    }

    const LoanDescription = () => {
        return (
            <div className="loan">
                <div className="paragraph">{formatMessage(DASHBOARD_TRANSLATIONS[descriptionKey])}</div>
            </div>
        )
    }

    const LoanCredit = () => {
        return (
            <div className="loan">
                <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['available_credit'])}</div>
                <div className="amount" title={availableCredit ? `${thousandSeperator(availableCredit.amount)} ${availableCredit.currency}` : formatMessage(DASHBOARD_TRANSLATIONS['loan_not_activated'])}>
                    {availableCredit ? `${fnumWithM(availableCredit.amount)} ${availableCredit.currency}` : formatMessage(DASHBOARD_TRANSLATIONS['loan_not_activated'])}
                </div>
            </div>
        )
    }

    const LoanDebt = () => {
        if (!remainingDebt) return null

        return (
            <div className="loan">
                <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['used_credit'])}</div>
                <div className="amount" title={remainingDebt && `${thousandSeperator(remainingDebt.amount)} ${remainingDebt.currency}`}>
                    {remainingDebt && `${fnumWithM(remainingDebt.amount)} ${remainingDebt.currency}`}
                </div>
            </div>
        )
    }

    if (isFetching) {
        return (
            <div className="widget">
                <div className="loans">
                    <LoanHeader />
                    <Spinner waiting={true} />
                </div>
            </div>
        )
    }

    return (
        <div className="widget">
            <div className="loans">
                <LoanHeader />
                <LoanDescription />
                <LoanCredit />
                {activeLoan ? <LoanDebt /> : <div className="read-more-link" onClick={() => redirectLink()}>
                    {formatMessage(TEXT['read_more'])}
                </div>
                }
            </div>
        </div>
    )
}

export default LoanWidget
