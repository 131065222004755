import React, { Component } from 'react'
import { ERROR } from '../translations'
import { injectIntl } from 'react-intl'
import './errorBoundary.scss'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) {
            return <p className="error-boundary" data-testid="error-boundary">{this.props.intl.formatMessage(ERROR['error_boundary_msg'])}</p>
        }

        return this.props.children
    }
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
    }
}

export default injectIntl(ErrorBoundary)
