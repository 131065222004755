export const LIST = 'settlements/LIST'
export const LIST_FIRST_PAGE = 'settlements/LIST_FIRST_PAGE'
export const LIST_START = 'settlements/LIST_START'
export const LIST_ERROR = 'settlements/LIST_ERROR'
export const LIST_OUT_OF_RANGE = 'settlements/LIST_OUT_OF_RANGE'
export const LIST_DETAILS = 'settlements/LIST_DETAILS'

export const LIST_NEXT_PAGE = 'settlements/LIST_NEXT_PAGE'
export const LIST_SETTLEMENTS_NEXT_PAGE_SUCCESS = 'settlements/LIST_SETTLEMENTS_NEXT_PAGE_SUCCESS'
export const LIST_TRANSACTIONS_NEXT_PAGE_SUCCESS = 'settlements/LIST_TRANSACTIONS_NEXT_PAGE_SUCCESS'

export const DETAIL_ERROR = 'settlements/DETAIL_ERROR'
export const FETCH_SETTLEMENT_REFERENCE_START = 'settlements/FETCH_SETTLEMENT_REFERENCE_START'
export const FETCH_SETTLEMENT_REFERENCE_SUCCESS = 'settlements/FETCH_SETTLEMENT_REFERENCE_SUCCESS'
export const FETCH_SETTLEMENT_REFERENCE_ERROR = 'settlements/FETCH_SETTLEMENT_REFERENCE_ERROR'
export const SET_SETTLEMENT_REFERENCE_EMPTY = 'settlements/SET_SETTLEMENT_REFERENCE_EMPTY'

export const LIST_TRANSACTIONS = 'settlements/LIST_TRANSACTIONS'
export const LIST_TRANSACTIONS_START = 'settlements/LIST_TRANSACTIONS_START'
export const LIST_TRANSACTIONS_ERROR = 'settlements/LIST_TRANSACTIONS_ERROR'

export const SET_EXPANDED_SETTLEMENT_INDEX = 'settlements/SET_EXPANDED_SETTLEMENT_INDEX'

export const LOAD_MORE = 'settlements/LOAD_MORE'
export const CLEAR_ALL_FILTERS = 'settlements/CLEAR_ALL_FILTERS'
export const UPDATE_FILTERS = 'settlements/UPDATE_FILTERS'
export const CHANGE_FILTER = 'settlements/CHANGE_FILTER'

export const LOAD_MORE_TRANSACTIONS = 'settlements/LOAD_MORE_TRANSACTIONS'
export const RESET_TRANSACTION_PAGINATION = 'settlements/RESET_TRANSACTION_PAGINATION'

export const RESET_PAGINATION = 'settlements/RESET_PAGINATION'

export const UPDATE_SINGLE_START = 'settlements/UPDATE_START'
export const UPDATE_SINGLE_SUCCESS = 'settlements/UPDATE_SUCCESS'
