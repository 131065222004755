import * as types from './actionTypes'

export function setUserProfile(user) {
    return {
        type: types.SET_USER_PROFILE,
        user
    }
}

export function setUserPreference(payload) {
    return {
        type: types.SET_USER_PREFERENCE,
        payload
    }
}

export function resetUserPreferenceForMechant(gmid) {
    return {
        type: types.RESET_USER_PREFERENCE_FOR_MERCHANT,
        payload: { gmid }
    }
}

export function initialUserPreference(value) {
    return {
        type: types.INITIAL_USER_PREFERENCE,
        value
    }
}

