
export const getPoolData = (store) => {

    const state = store.getState()
    const config = state.get('config')

    const poolData = {
        UserPoolId: config.get('cognitoPoolId'),
        ClientId: config.get('cognitoClientId')
    }

    return poolData
}
