const getActiveFilterTypes = (activeView, filters) => {

  let filterTypes

  switch (activeView) {
    case 'acquiring':
      filterTypes = {
        'card_scheme': false,
        'type': false,
        'currency': false,
        'source_merchant_id': false
      }
      break
    case 'online':
      filterTypes = {
        'currency': false,
        'payment_type': false,
        'state': false
      }
      break
    case 'instore':
      filterTypes = {
        'payment_type': false,
        'payment_category': false,
        'state': false,
        'currency': false,
        'terminal_id': false,
        'source_merchant_ids': false
      }
      break
    default:
      return null
  }

  const incomingKeys = Object.keys(filters)
  const supportedKeys = Object.keys(filterTypes)

  function validateIncomingKeys(key) {
    let keyIsActive = false

    const keyIsSupported = supportedKeys.includes(key)

    if (keyIsSupported) {
      keyIsActive = filters[key]['selected'].length > 0
    }

    return keyIsSupported && keyIsActive
  }

  const validatedKeys = incomingKeys.filter(validateIncomingKeys)

  if (validatedKeys.length) {
    for (const key of validatedKeys) { // eslint-disable-line
      filterTypes[key] = true
    }
  } else {
    return null
  }

  return filterTypes
}

export default getActiveFilterTypes
