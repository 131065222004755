import React, { Component } from 'react'
import { connect } from 'react-redux'
import {injectIntl, FormattedMessage} from 'react-intl'
import * as actions from '../actions'
import InvoiceReportItem from '../containers/invoiceReportItem'
import Spinner from '../../common/components/spinner'
import uuid from 'uuid'
import {Accordion} from '@frontrunners/ui-components'


class InvoiceContainer extends Component {
    constructor(props){
        super(props)
        this.state = {
            expandedInvoice: '',
            activeYear: String(new Date().getFullYear())
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        const shouldTrackEvent = true
        dispatch(actions.listAvailableInvoiceStatements(this.props.match.params.merchantId, shouldTrackEvent))
    }

    renderInvoiceReports() {
        const availableInvoiceReports = this.props.invoiceReports
        if(this.props.invoice.get('isFetching')) {
            return (
                <div>
                    <Spinner waiting={true}/>
                </div>
            )
        } else {
            const invoiceYearList = availableInvoiceReports.reduce((invoiceReportsByYear, invoiceReport) => {
                const year = invoiceReport.year
                if (!invoiceReportsByYear[year]) {
                    invoiceReportsByYear[year] = []
                }
                invoiceReportsByYear[year].push(invoiceReport)
                return invoiceReportsByYear
            }, {})
            const invoiceYears = Object.keys(invoiceYearList).reverse()
            return (
                <div className="main-width list-container settlements-list-container">
                    {invoiceYears.map(year => (
                        <Accordion
                            key={year}
                            isOpen={this.state.activeYear === year}
                            header={year}
                            onToggleClick={this.setActiveYear.bind(this)}
                            content={invoiceYearList[year].map(invoiceReport => <InvoiceReportItem
                                report={invoiceReport}
                                invoice={this.props.invoice}
                                merchantId={this.props.match.params.merchantId}
                                expandedInvoice={this.state.expandedInvoice}
                                expandInvoice={this.expandInvoice.bind(this)}
                                key={uuid()}
                            />
                            )}
                        />
                    ))}
                </div>
            )
        }
    }

    render() {
        if(!this.props.invoice) return null
        return (
            <div className="main-width list-container settlements-list-container">
                <div className="invoice-header">
                    <FormattedMessage
                        id="invoice-list-report-header"
                        defaultMessage={'Your invoices.'}
                        description="Invoice header"
                    />
                </div>
                <div className="invoice-info">
                    <FormattedMessage
                        id="invoice-list-report-header-info-body"
                        defaultMessage={'The balance owed from the invoices will automatically be withdrawn from your daily settlement payouts, until the full invoiced amount is paid. Only if there’s a remaining balance after 30 days we will send you more information on how to pay the remaining balance.'}
                        description="Descriptive text on how balance will automatically be drawn"
                    />
                </div>
                <div>
                    {this.renderInvoiceReports()}
                </div>
            </div>
        )
    }

    expandInvoice(name){
        if(name === this.state.expandedInvoice) this.setState({'expandedInvoice': ''})
        else this.setState({'expandedInvoice': name})
    }

    setActiveYear(year) {
        this.setState({ 'activeYear': this.state.activeYear === year ? null : year })
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        merchant: state.get('merchant'),
        locale: state.get('localization'),
        invoice: state.getIn([activeMerchant, 'invoice']),
        invoiceReports: state.getIn([activeMerchant, 'invoice', 'availableReports'])
    }
}

export default connect(mapStateToProps)(injectIntl(InvoiceContainer))
