import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Spinner from '../../../common/components/spinner'
import { Icon, PopUp, Dropdown } from '@frontrunners/ui-components'
import { FEATURE_DISABLED } from '../../../common/translations'
import { ROLES, SETTINGS_USER_TEXT, SETTINGS_TEXTS } from '../../../merchant/constants'
import { RoleToggle2 } from '../../../common/containers/roleToggle'
import { getRoleDisplayText, isOwner, getAssignableUserRoles } from '../../../common/userRoles'
import { USER_PROFILE_TEXTS } from '../../user_profile/intlMessageKeys'
import './userRow.scss'

export const UserRow = ({
    user,
    loggedInUser,
    children,
    onRenameUserClick,
    onChangeUserRoleClick,
    isUserRenaming,
    isUserChangingRole
}) => {
    const { formatMessage } = useIntl()
    const assignableUserRoles = getAssignableUserRoles(formatMessage)
    const usersUserRole = assignableUserRoles.filter(role => role.value === user.role)

    return (
        <div className="user-table-row">
            <span className="user-name">
                {isUserRenaming && <Spinner waiting={true} />}
                {
                    !isUserRenaming &&
                    <UserName
                        user={user}
                        isLoggedIn={loggedInUser.sub === user.id}
                        isSupportalUser={loggedInUser.loggedInUserIsSupport}
                        onRenameUserClick={onRenameUserClick}
                    />
                }
            </span>
            <span className="user-email"><span>{user.email}</span></span>
            <span className="user-role">
                {isUserChangingRole && <Spinner waiting={true} />}
                {
                    !isUserChangingRole &&
                    usersUserRole.length > 0 &&
                    <RoleToggle2
                        userRole={loggedInUser.role}
                        allow={[ROLES.OWNER, ROLES.ADMIN]}
                        fallback={getRoleName(user.role)}
                    >
                        <Dropdown
                            multiSelect={false}
                            items={assignableUserRoles}
                            minSelectedItems={1}
                            defaultSelectedItems={usersUserRole}
                            onChange={onChangeUserRoleClick}
                        />
                    </RoleToggle2>
                }
                {isOwner(user.role) && getRoleName(user.role)}

            </span>
            {
                loggedInUser.sub !== user.id // cannot delete yourself
                && user.role !== ROLES.OWNER // cannot delete owner
                && children
            }
        </div>
    )
}

const UserName = ({ user, isLoggedIn, isSupportalUser, onRenameUserClick }) => {
    const { formatMessage } = useIntl()
    const [popUpIsShown, setPopUpIsShown] = useState(false)
    const username = [user.givenName, user.familyName].join(' ').replace(/^ $/, '')

    const editIsDisabled = isSupportalUser ? 'disabled' : ''
    const emptyUsername = !username ? 'notset' : undefined

    return (
        <>
            <span className={emptyUsername}>{getDisplayUsername(username, isLoggedIn)}</span>
            {isLoggedIn &&
                <span
                    className={`edit-username ${editIsDisabled}`}
                    onMouseEnter={() => isSupportalUser && setPopUpIsShown(true)}
                    onMouseLeave={() => isSupportalUser && setPopUpIsShown(false)}
                    onClick={() => {
                        if (isSupportalUser) return
                        onRenameUserClick(user)
                    }}
                >
                    <Icon iconName="pen" scale={0.8} />
                    {formatMessage(SETTINGS_TEXTS['edit'])}
                    <PopUp
                        text={formatMessage(FEATURE_DISABLED['disabled_for_support_agents'])}
                        visible={popUpIsShown}
                        className="animation"
                    />
                </span>
            }
        </>
    )
}

const getRoleName = (role) => {
    const { formatMessage } = useIntl()

    const roleText = getRoleDisplayText(formatMessage, role)
    if (roleText) return roleText

    return <span className="notset">
        {formatMessage(USER_PROFILE_TEXTS['merchant-profile-merchant-user-role-none-title'])}
    </span>
}

const getDisplayUsername = (username, isLoggedIn) => {
    const { formatMessage } = useIntl()
    if (username) return username

    return isLoggedIn ?
        formatMessage(SETTINGS_USER_TEXT['user_list_item_edit_name'])
        : formatMessage(SETTINGS_USER_TEXT['user_list_item_no_name'])
}
