import { defineMessages } from 'react-intl'

export const CONTRACTS_TEXTS = defineMessages({
        contracts_header: {
            id: 'contracts-header',
            defaultMessage: 'Contracts',
            description: 'Header for the Contracts page'
        },
        contracts_table_column_product: {
            id: 'contracts-column-product',
            defaultMessage: 'Product',
            description: 'Table column for Pruduct'
        },
        contracts_table_column_id: {
            id: 'contracts-column-id',
            defaultMessage: 'Account ID',
            description: 'Table column for account ID'
        },
        contracts_table_column_start_time: {
            id: 'contracts-column-start-time',
            defaultMessage: 'Start time',
            description: 'Table column for contract Start time'
        },
        contracts_table_column_terminaton_date: {
            id: 'contracts-column-termination-date',
            defaultMessage: 'Termination date',
            description: 'Table column for contract Termination date'
        },
        contracts_table_load_more: {
            id: 'contracts-table-load-more',
            defaultMessage: 'Load more',
            description: 'Click to load more contracts'
        },
        contracts_numbers_displayed: {
            id: 'contracts-numbers-displayed',
            defaultMessage: 'Displaying {contractsDisplayed} of {contractsTotal}',
            description: 'Number of contracts displayed'
        },
        contracts_current_page: {
            id: 'contracts-current-page',
            defaultMessage: 'Page {currentPage} / {totalPages}',
            description: 'The currently open page'
        },
        contracts_table_error_modal_header: {
            id: 'contracts-table-error-modal-header',
            defaultMessage: 'Contract could not be delivered',
            description: 'The header on the error modal'
        },
        contracts_table_error_modal_description_1: {
            id: 'contracts-table-error-modal-description',
            defaultMessage: 'There was a problem downloading your contract, please try again later.',
            description: 'The description on the error modal'
        },
        contracts_table_error_modal_description_2: {
            id: 'general-problem-contact-support',
            defaultMessage: 'If the problem persists, please contact support.',
            description: 'The description on the error modal'
        }
})
