export const NAME = 'localization'
export const LOCAL_STORAGE_KEY = 'localization'

export const LOCALES = {
    en:{ messages:require('../../i18n/en.json')},
    sv:{ messages:require('../../i18n/sv.json')},
    nb:{ messages:require('../../i18n/nb.json')},
    da:{ messages:require('../../i18n/da.json')},
    fi:{ messages:require('../../i18n/fi.json')},
    de:{ messages:require('../../i18n/de.json')},
    fr:{ messages:require('../../i18n/fr.json')},
    it:{ messages:require('../../i18n/it.json')},
    'nl-NL':{ messages:require('../../i18n/nl-NL.json')},
    'fr-BE':{ messages:require('../../i18n/fr-BE.json')}
}
