import { useMutation, useQuery } from 'react-query'
import { getElevatedRole } from '../merchant/actions'
import { callApi } from './reactQuery'

export const getUsers = (apiHost, merchantId) => useQuery(
    'users',
    async () => {
        return callApi({ url: `${apiHost}/v1/merchants/${merchantId}/users` })
            .then((users) => users.map((user) => ({
                ...user,
                role: getElevatedRole(user.merchant_roles[0].roles)
            })))
    }
)

export const getUser = (apiHost, merchantId, userId, enabled) => useQuery({
    queryKey: ['user', userId],
    queryFn: async () => {
        const users = await callApi({ url: `${apiHost}/v1/merchants/${merchantId}/users` })
        return users.find((user) => user.id === userId)
    },
    enabled
})

export const getAddUserMutation = (queryClient, apiHost, merchantId) => useMutation({
    mutationFn: async (user) => callApi({
        method: 'POST',
        url: `${apiHost}/v1/merchants/${merchantId}/users`,
        body: user
    }),
    onSuccess: (user) => {
        queryClient.setQueryData('users', (oldData) => {
            return [
                ...oldData,
                {
                    ...user,
                    role: getElevatedRole(user.merchant_roles[0].roles)
                }
            ]
        })
    }
})

export const getRenameUserMutation = (queryClient, apiHost) => useMutation({
    mutationFn: async (user) => callApi({
        method: 'PUT',
        url: `${apiHost}/v1/users/${user.id}`,
        body: user
    })
    ,
    onSuccess: (user) => {
        queryClient.setQueryData('users', (oldData) => {
            if (!oldData) {
                return [user]
            }

            return oldData.map(u => {
                if (u.id === user.id) {
                    return {
                        ...u,
                        givenName: user.givenName,
                        familyName: user.familyName
                    }
                }
                return u
            })
        })
        queryClient.invalidateQueries({
            queryKey: ['user', user.id],
            exact: true
        })
    }
})

export const getChangeUserRoleMutation = (queryClient, apiHost, merchantId) => useMutation({
    mutationFn: async ({ user, userRole }) => callApi({
        method: 'PUT',
        url: `${apiHost}/v1/merchants/${merchantId}/users/${user.id}`,
        body: {
            role: userRole.value
        }
    }),
    onSuccess: (user) => {
        queryClient.setQueryData('users', (oldData) => {
            return oldData.map(u => {
                if (u.id === user.id) {
                    return {
                        ...user,
                        role: getElevatedRole(user.merchant_roles[0].roles)
                    }
                }
                return u
            })
        })
    }
})

export const getRemoveUserMutation = (queryClient, apiHost, merchantId) => useMutation({
    mutationFn: async (user) => {
        await callApi({
            method: 'DELETE',
            url: `${apiHost}/v1/merchants/${merchantId}/users/${user.id}`,
            parseJson: false
        })
        return user
    },
    onSuccess: (data) => {
        queryClient.setQueryData('users', (oldData) => {
            return oldData.filter(user => user.id !== data.id)
        })
    }
})
