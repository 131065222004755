import {defaultChannel} from '../constants'
export const calculateDefaultChannel = (availableChannel, currentMonth) => {
    if (!currentMonth) return availableChannel[0]
    else return Object.keys(currentMonth)
        .sort((c1, c2) => {
            if ((currentMonth[c1].totalAmount === currentMonth[c2].totalAmount)) {
                return availableChannel.indexOf(c1) - availableChannel.indexOf(c2)
            }
            else {
                return currentMonth[c2].totalAmount - currentMonth[c1].totalAmount
            }
        })[0]
}

export const determineChannelOptions=(availableChannels)=> {
    return defaultChannel.filter(channel => availableChannels[channel])
}

