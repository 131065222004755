import iso4217 from 'currency-codes'
import React, { Component } from 'react'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import * as actions from '../../actions'
import { cleanInput } from '../../../../common/amountHelpers'
import BamboraSvgIcon from '../../../../common/components/bamboraSvgIcon'
import NotFoundState from '../../../../common/components/notFoundState'
import Spinner from '../../../../common/components/spinner'
import MonetaryAmount from '../../../../common/components/monetaryAmount'
import * as helpers from '../../helpers'
import { MODAL_OPERATIONS, PAYMENT_METHODS } from '../../constants'
import { getExponentedAmountString, validateExponent, stringWithExponentToInteger, validateAvailableAmount } from '../../../../common/amountHelpers'

class OnlineOperationModal extends Component {
    constructor(props) {
        super(props)

        const availableAmountString = this.getAvailableAmountString()
        const inputIsAllowed = availableAmountString ? true : false

        this.state = {
            amount: availableAmountString ? availableAmountString : '',
            inputIsAllowed: inputIsAllowed,
            inputValidated: true
        }

    }

    getAvailableAmountString() {
        let exponent
        const actionName = this.props.operations.get('action')
        const paymentRouterObject = this.props.operations.get('order')

        if (actionName === MODAL_OPERATIONS.CAPTURE) {
            const capturableAmount = helpers.getCapturableAmount(paymentRouterObject)
            exponent = helpers.getAmountExponent(paymentRouterObject)
            return getExponentedAmountString(capturableAmount, exponent)
        } else if (actionName === MODAL_OPERATIONS.REFUND) {
              const refundableAmount = helpers.getRefundableAmount(paymentRouterObject)
              exponent = helpers.getAmountExponent(paymentRouterObject)
              return getExponentedAmountString(refundableAmount, exponent)
        }
    }

    componentDidUpdate (prevProps) {
      const hasNewOrder = !prevProps.operations.get('order') && this.props.operations.get('order')

      if (hasNewOrder) {
        const availableAmountString = this.getAvailableAmountString()
        const inputIsAllowed = availableAmountString ? true : false

        this.setState({
          amount: availableAmountString,
          inputIsAllowed: inputIsAllowed
        })

      }

      if (this.props.operations.get('isSuccess') && !prevProps.operations.get('isSuccess')) {
        this.timeout = setTimeout(() => {
          this.hideOperationsModal()
        }, 500)
      }

    }

    hideOperationsModal() {
        clearTimeout(this.timeout)
        this.props.dispatch(actions.hideOperationsModal())
    }

    validateInput(input, exponent) {
        const availableAmountString = this.getAvailableAmountString()
        const availableAmount = stringWithExponentToInteger(availableAmountString, exponent)

        const cleaned = cleanInput(input)
        const regex = /^\d*[\.]?\d+$/
        const isValid = regex.test(cleaned) && parseFloat(cleaned) > 0
            && validateExponent(cleaned, exponent)
            && validateAvailableAmount(availableAmount, cleaned, exponent)
        this.setState({ inputValidated: isValid, amount: input })
    }

    doAction(action) {
        let order = this.props.order

        if(Map.isMap(this.props.order)) {
            order = this.props.order.toJS()
        }

        const operationBody = this.getOperationBody()
        let operationId = helpers.getOperationId(order)
        this.props.dispatch(
            action(
                order.merchant_id,
                operationBody,
                order.external_id,
                operationId
            )
        )
    }

    captureOrder() {
        this.doAction(actions.captureOrder)
    }

    refundOrder() {
        this.doAction(actions.refundOrder)
    }

    getOperationBody() {
        const paymentRouterObject = this.props.operations.get('order')
        const currencyCode = helpers.getCurrency(paymentRouterObject)
        const currency = iso4217.code(currencyCode)
        const exponent = currency.digits
        const cleanedAmount = cleanInput(this.state.amount)
        let operationBody = {
            amount: stringWithExponentToInteger(cleanedAmount, exponent),
            currency: helpers.getCurrency(paymentRouterObject)
        }
        return operationBody
    }

    getAction(actionName) {
        // TODO: rewrite this use the MODAL_OPERATIONS and be internationalized
        const actions = {
            [MODAL_OPERATIONS.CAPTURE]: {
                title: <FormattedMessage
                    id="order-operation-modal-capture-title"
                    defaultMessage="Capture"
                    description="Capture amount modal title"
                />,
                label: <FormattedMessage
                    id="order-operation-modal-capture-label"
                    defaultMessage="Amount to capture"
                    description="Capture amount input text label"
                />,
                submit: this.captureOrder,
                getOperationAmount: helpers.getCapturableAmount,
                defaultValue: helpers.getCapturableAmount
            },
            [MODAL_OPERATIONS.REFUND]: {
                title: <FormattedMessage
                    id="order-operation-modal-refund-title"
                    defaultMessage="Refund"
                    description="Refund amount modal title"
                />,
                label: <FormattedMessage
                    id="order-operation-modal-refund-label"
                    defaultMessage="Amount to refund"
                    description="Refund amount input text label"
                />,
                submit: this.refundOrder,
                getOperationAmount: helpers.getRefundableAmount,
                defaultValue: () => { }
            }
        }
        return actions[actionName]
    }

    renderWrapper(content) {
        // block closing of modale while waiting
        const waiting = this.props.operations.get('isFetching')
        return <div className="modal-wrapper">
            <div className="backdrop" onClick={() => waiting || this.hideOperationsModal()}></div>
            <div className="operations operations-modal" role="dialog">
                <span className="close-operation" onClick={() => waiting || this.hideOperationsModal()}><BamboraSvgIcon icon="close" /></span>
                {content}
            </div>
        </div>
    }

    getErrorMessage(actionName) {
        if (actionName == MODAL_OPERATIONS.CAPTURE) {
            return <FormattedMessage
                id="order-operation-modal-capture-error"
                defaultMessage="Capture failed"
                description="Capture failed error message"
            />
        }
        if (actionName == MODAL_OPERATIONS.REFUND) {
            return <FormattedMessage
                id="order-operation-modal-refund-error"
                defaultMessage="Refund failed"
                description="Refund failed error message"
            />
        }
        return <FormattedMessage
            id="order-operation-modal-not-found-error"
            defaultMessage="Unable to find payment"
            description="Unable to find payement for capture or refund error message"
        />
    }

    isPartlyActionable(paymentMethod, actionName) {
        if (paymentMethod === PAYMENT_METHODS.INVOICE && actionName !== MODAL_OPERATIONS.REFUND) {
            return false
        }

        return true
    }

    render() {
        const success = this.props.operations.get('isSuccess')
        const waiting = this.props.operations.get('isFetching')
        if (waiting || success) {
            return this.renderWrapper(
                <div className="operations-state-change action">
                    <Spinner waiting={waiting} success={success} />
                </div>
            )
        }

        const actionName = this.props.operations.get('action')
        const error = this.props.operations.get('isError')
        if (error) {
            return this.renderWrapper(
                <NotFoundState>
                    <div className="text-center">
                        {this.getErrorMessage(actionName)}
                    </div>
                </NotFoundState>
            )
        }

        const paymentRouterObject = this.props.operations.get('order')
        if (!paymentRouterObject) {
            return null
        }
        const currencyCode = helpers.getCurrency(paymentRouterObject)
        const currency = iso4217.code(currencyCode)
        const exponent = currency.digits
        const inputClass = this.state.inputValidated ? 'input-amount' : 'input-amount invalid'
        const paymentMethod = helpers.getPaymentMethod(paymentRouterObject)
        const invoiceClass = paymentMethod === PAYMENT_METHODS.INVOICE ? ' invoice' : ''
        const action = this.getAction(actionName)
        const operationAmount = action.getOperationAmount(paymentRouterObject)
        const actionable = this.isPartlyActionable(paymentMethod, actionName)
        return this.renderWrapper(
            <div>
                <span className="title">{action.title}</span>
                <span>
                    <FormattedMessage
                        id="order-operation-modal-available-amount"
                        defaultMessage="Amount available"
                        description="Avaliable amount text for capture/refund amount"
                    />
                    <MonetaryAmount
                        amount={operationAmount}
                        currency={currencyCode}
                        amount_exponent={exponent}
                        className="available"
                        hideInsignificantDecimals={true}
                    />
                </span>
                <form onSubmit={() => false} onClick={(e) => {
                    e.preventDefault()
                    if (this.state.inputValidated) {
                        action.submit.bind(this)
                    }
                }
                }>
                    <div className="form-group">
                        {actionable &&
                            <label className="order-operation-label" htmlFor="order-operation-amount">{action.label}</label>
                        }
                        <div className={'flex-container' + invoiceClass}>
                            {actionable &&
                                <input
                                    id="order-operation-amount"
                                    className={inputClass}
                                    type="text"
                                    placeholder={currencyCode}
                                    autoComplete="off"
                                    onChange={(e) => this.validateInput(e.target.value, exponent)}
                                    value={this.state.amount}
                                    maxLength="15"
                                    disabled={!this.state.inputIsAllowed}
                                />
                            }

                            <button
                                disabled={!this.state.inputValidated || !this.state.inputIsAllowed}
                                className="rebranded-primary-btn"
                                onClick={action.submit.bind(this)}
                            >
                                {action.title}
                            </button>
                        </div>
                        {!this.state.inputValidated &&
                            <label htmlFor="order-operation-amount" className="invalid">
                                <FormattedMessage
                                    id="order-operation-modal-input-invalid"
                                    defaultMessage="Invalid amount. Try again."
                                    description="Input validation error message for capture/refund amount"
                                />
                            </label>
                        }
                    </div>
                </form>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        operations: state.getIn([activeMerchant, 'online', 'operations']),
        orders: state.getIn([activeMerchant, 'online']),
        order: state.getIn([activeMerchant, 'online', 'current', 'data'])
    }
}

export default connect(mapStateToProps)(OnlineOperationModal)
