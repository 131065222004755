import React from 'react'
import { NAVLINK_TEXTS } from '../../nav/constants'
import { ROLES } from '../../merchant/constants'
import { WELCOME_TEXT } from '../constants'

import { Icon } from '@frontrunners/ui-components'

import imgWelcome from '../../styles/img/welcome/welcome.svg'
import imgOverview from '../../styles/img/welcome/overview.svg'
import imgSales from '../../styles/img/welcome/sales.svg'
import imgPayouts from '../../styles/img/welcome/payouts.svg'
import imgLoan from '../../styles/img/welcome/loan.svg'
import imgReport from '../../styles/img/welcome/report.svg'
import imgSetting from '../../styles/img/welcome/setting.svg'
import imgStart from '../../styles/img/welcome/start.svg'

const getData = (isLoanEligible, merchantObject, formatMessage) => {
    const reportData = {
        id: 'REPORT',
        title: formatMessage(WELCOME_TEXT['report-title']),
        text: formatMessage(WELCOME_TEXT['report-text-2']),
        img: imgReport,
        element_id: 'navbar-link-reports',
        highLightText: formatMessage(NAVLINK_TEXTS['navbar-main-link-reports'])
    }
    const welcomeData = {
        id: 'WELCOME',
        title: formatMessage(WELCOME_TEXT['welcome-title-2']),
        text: formatMessage(WELCOME_TEXT['welcome-text-2']),
        img: imgWelcome
    }
    const overviewData = {
        id: 'OVERVIEW',
        title: formatMessage(WELCOME_TEXT['overview-title']),
        text: formatMessage(WELCOME_TEXT['overview-text']),
        img: imgOverview,
        element_id: 'navbar-link-overview',
        highLightText: formatMessage(NAVLINK_TEXTS['navbar-main-link-overview'])
    }
    const payoutsData = {
        id: 'PAYOUTS',
        title: formatMessage(WELCOME_TEXT['payouts-title']),
        text: formatMessage(WELCOME_TEXT['payouts-text']),
        img: imgPayouts,
        element_id: 'navbar-link-payouts',
        highLightText: formatMessage(NAVLINK_TEXTS['navbar-main-link-payouts'])
    }
    const salesData = {
        id: 'SALES',
        title: formatMessage(WELCOME_TEXT['sales-title']),
        text: formatMessage(WELCOME_TEXT['sales-text']),
        img: imgSales,
        element_id: 'navbar-link-sales',
        highLightText: formatMessage(NAVLINK_TEXTS['navbar-main-link-sales'])
    }
    const loanData = {
        id: 'LOAN',
        title: formatMessage(WELCOME_TEXT['loan-title']),
        text: formatMessage(WELCOME_TEXT['loan-text']),
        img: imgLoan,
        element_id: 'navbar-link-loans',
        highLightText: formatMessage(NAVLINK_TEXTS['navbar-main-link-loans'])
    }
    const toolAdminData = {
        id: 'TOOL-ADMIN',
        title: formatMessage(WELCOME_TEXT['tool-title']),
        text: formatMessage(WELCOME_TEXT['tool-admin-text-2']),
        img: imgSetting,
        element_id: 'navbar-link-setting',
        logo: () => <Icon iconName="cogWheel" />
    }
    const toolUserData = {
        id: 'TOOL-USER',
        title: formatMessage(WELCOME_TEXT['tool-title']),
        text: formatMessage(WELCOME_TEXT['tool-user-text']),
        img: imgSetting,
        element_id: 'navbar-link-setting',
        logo: () => <div className="icon-cogwheel" />
    }
    const endData = {
        id: 'END',
        title: formatMessage(WELCOME_TEXT['end-title']),
        img: imgStart
    }
    let data = [
        welcomeData,
        overviewData,
        payoutsData,
        salesData,
        reportData,
        loanData,
        toolAdminData,
        toolUserData,
        endData
    ]

    const { loggedInUserRole, device } = merchantObject
    let idToRemove = []

    if (!isLoanEligible || loggedInUserRole === ROLES.USER) idToRemove.push('LOAN')
    if (device) idToRemove.push('PAYOUTS')
    loggedInUserRole !== ROLES.USER ? idToRemove.push('TOOL-USER') : idToRemove.push('TOOL-ADMIN')

    return data.filter(item => !idToRemove.includes(item.id))
}

export default getData
