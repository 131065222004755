import { updateUser } from '../../merchant/actions'
import { setLocalization } from '../../localization/actions'


export const submitLanguageSelection = (chatEligible, currentLocale, subId, dispatch) => {
    return (newLocale) => {
        if (chatEligible) {
            if (currentLocale !== newLocale) {
                // Due to limitations in chat script, we're forced to do a page reload here.
                window.location.reload()
            }
        }

        const userData = {
            id: subId,
            locale: newLocale
        }

        dispatch(updateUser(userData))
        dispatch(setLocalization(newLocale))
    }
}
