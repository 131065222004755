import iso4217 from 'currency-codes'
import { OPERATIONS, PAYMENT_METHODS, THREED_SECURE, AUTHORIZATION_OPERATIONS, PAYMENT_ROUTER_STATES, ORDER_STATES, COLLECTOR_BANK } from './constants'


export function getAuthorization(order) {
    if (!order.operations) return

    return order.operations.find((operation) => {
        return AUTHORIZATION_OPERATIONS.includes(operation.type)
    })
}

export const isNewOperationFlow = (acquireName) => {
    return acquireName === COLLECTOR_BANK
}

export function getRefunds(order) {
    if (!order.operations) return []

    return order.operations.filter((operation) => {
        return OPERATIONS.REFUND === operation.type
    })
}

function getSingleCapture(order) {
    if (!order.operations) return []

    const successfulOperations = removeFailedOperations(order)

    return successfulOperations && successfulOperations.operations && successfulOperations.operations.find((operation) => {
        return OPERATIONS.CAPTURE === operation.type
    })
}

export const getAvailableRefundOrderLine = (order) => {
    const capture = getSingleCapture(order)
    let availableRefundOrderLine = capture && capture.order_lines_to_capture &&
        capture.order_lines_to_capture.map(item => {
            const quantity = getAvailableQuantityForOrderLine(order, item.unique_id, item.quantity)
            return {
                id: item.id,
                unique_id: item.unique_id,
                quantity: quantity,
                description: item.description,
                text: `${quantity} x ${item.description}`,
                unitPrice: item.unitpriceinclvat
            }
        })

    return availableRefundOrderLine ? availableRefundOrderLine.filter(item => item.quantity > 0) : []
}

export function getUpdatedOrderList(orderList, actionPayload) {
    const index = orderList.findIndex(order => actionPayload.id === order.id)
    if (orderList.size === 0 || index === -1) {
        return orderList
    }
    return orderList.update(index, () => mapPaymentRouterToIndexObject(actionPayload.order))
}

export function mapPaymentRouterToIndexObject(paymentRouterObject) {
    let newPaymentObject = {}
    newPaymentObject.id = paymentRouterObject.external_id
    newPaymentObject.authorized_amount = reduceOperationsAmounts(paymentRouterObject, AUTHORIZATION_OPERATIONS)
    newPaymentObject.captured_amount = reduceOperationsAmounts(paymentRouterObject, [OPERATIONS.CAPTURE])
    newPaymentObject.refunded_amount = reduceOperationsAmounts(paymentRouterObject, [OPERATIONS.REFUND])
    newPaymentObject.canceled = isCanceled(paymentRouterObject)
    newPaymentObject.updated = getUpdatedAt(paymentRouterObject)
    newPaymentObject.currency = getCurrency(paymentRouterObject)
    newPaymentObject.created = getCreatedAt(paymentRouterObject)
    newPaymentObject.merchant_id = paymentRouterObject.merchant_id
    newPaymentObject.payment_id = paymentRouterObject.external_id
    newPaymentObject.payment_method = getPaymentMethod(paymentRouterObject)
    newPaymentObject.wallet = getPaymentWallet(paymentRouterObject)
    newPaymentObject.payment_type = getPaymentType(paymentRouterObject)
    newPaymentObject.retrieval_reference_number_set = getRrnSet(paymentRouterObject)
    newPaymentObject.amount_exponent = getAmountExponent(paymentRouterObject)
    newPaymentObject.acquirer_name = paymentRouterObject.acquirer_name
    const auth = getAuthorization(paymentRouterObject)
    if (auth) {
        newPaymentObject.merchant_reference = auth.merchant_reference
    }
    return newPaymentObject
}

export function isCancelable(paymentRouterObject) {
    return paymentRouterObject.state === PAYMENT_ROUTER_STATES.AUTHORIZED
}

export function isCanceled(paymentRouterObject) {
    return paymentRouterObject.state === PAYMENT_ROUTER_STATES.CANCELED
}

function getRrnSet(paymentRouterObject) {
    return paymentRouterObject.operations.map(o => o.retrieval_reference_number).filter(rrn => rrn)
}

function getPaymentType(paymentRouterObject) {
    const authorization = getAuthorization(paymentRouterObject)
    return authorization.payment_type
}

export function getPaymentMethod(paymentRouterObject) {
    const authorization = getAuthorization(paymentRouterObject)
    return authorization && authorization.payment_method && authorization.payment_method.toUpperCase()
}

export function getPaymentWallet(paymentRouterObject) {
    const authorization = getAuthorization(paymentRouterObject)
    return authorization.wallet
}

export function getCreatedAt(paymentRouterObject) {
    const authorization = getAuthorization(paymentRouterObject)
    return authorization.timestamp
}

export function getCurrency(paymentRouterObject) {
    const authorization = getAuthorization(paymentRouterObject)
    return authorization.currency
}

export function getAmountExponent(paymentRouterObject) {
    const curr = getCurrency(paymentRouterObject)
    const currency = iso4217.code(curr)
    return currency.digits
}

export function getCapturableAmount(paymentRouterObject) {
    const authorized_amount = reduceOperationsAmounts(paymentRouterObject, AUTHORIZATION_OPERATIONS)
    const captured_amount = reduceOperationsAmounts(paymentRouterObject, [OPERATIONS.CAPTURE])
    return (authorized_amount - captured_amount)
}

export function getRefundableAmount(paymentRouterObject) {
    const captured_amount = reduceOperationsAmounts(paymentRouterObject, [OPERATIONS.CAPTURE])
    const refunded_amount = reduceOperationsAmounts(paymentRouterObject, [OPERATIONS.REFUND])
    return (captured_amount - refunded_amount)
}

function getUpdatedAt(paymentRouterObject) {
    // assuming that that last operation is the most recent one
    const operations = paymentRouterObject.operations
    return operations[operations.length - 1].timestamp
}

export function reduceOperationsAmounts(paymentRouterObject, operationsToReduce) {
    // sum all operation amounts of a given type
    if (paymentRouterObject.state === 'Declined') { return 0 }

    const successfulOperations = removeFailedOperations(paymentRouterObject)

    return successfulOperations.operations.reduce((acc, operation) => {
        const diff = operationsToReduce.includes(operation.type) ? operation.amount : 0
        return acc + diff
    }, 0)
}

export function getPaymentIcon(order, authorization) {
    if (order.data.payment_method === PAYMENT_METHODS.WALLET) {
        return order.data.wallet || 'Wallet'
    }
    if (authorization.wallet) {
        return authorization.wallet
    } else {
        return authorization.payment_type
    }
}

export function getInvoiceSum(orderLines) {
    const totalAmount = orderLines ? orderLines.reduce((acc, item) => {
        return acc + parseInt(item.unitPrice, 10) * item.quantity
    }, 0) : 0
    return totalAmount
}

export function getOperationId(order) {
    let operationId = order.originalOrder.operations.length + 1
    while (
        order.originalOrder.operations.find((operation) => {
            return operation.id === operationId
        })) {
        operationId++
    }
    return operationId
}

export function determine3dSecure(operation) {
    try {
        return THREED_SECURE.includes(parseInt(operation.card.eci))
    } catch (error) {
        return false
    }

}

export function setPaymentMethod(order) {
    const authorization = getAuthorization(order)
    if (!authorization || authorization.payment_method === PAYMENT_METHODS.OTHER) {
        order.payment_method = PAYMENT_METHODS.CARD
    } else {
        order.payment_method = authorization.payment_method.toUpperCase()
        order.wallet = authorization.wallet
    }
    return order
}

export function updatePaymentListWithLocalUpdates(paymentIndexPayments, locallyUpdatedPayments) {
    return paymentIndexPayments.map(p => {
        let hit = locallyUpdatedPayments.get(p.id)
        return hit || p
    })
}

export function removeExpiredLocalUpdates(paymentIndexPayments, locallyUpdatedPayments) {

    const expireds = paymentIndexPayments.filter(p => {
        const hit = locallyUpdatedPayments.get(p.id)
        const cacheDelay = 10 * 60 * 1000
        if (hit) {
            const localUpdatedPlusCacheDelay = new Date(hit.updated).getTime() + cacheDelay
            return new Date(localUpdatedPlusCacheDelay) < new Date(p.updated)
        }

    }).map(p => p.id)

    const result = locallyUpdatedPayments.filter((v, k) => {
        return !expireds.includes(k)
    })
    return result
}

export function getAuthRetrievalReferenceNumber(order) {
    return getRrrn(order, AUTHORIZATION_OPERATIONS)
}


export function getRefundRetrievalReferenceNumber(order) {
    let operations = [OPERATIONS.REFUND]
    return getRrrn(order, operations)
}

function getRrrn(order, operations) {
    const operation = order.operations.find((x) => operations.includes(x.type))
    return operation && operation.retrieval_reference_number ? operation.retrieval_reference_number : null
}

export const removeFailedOperations = (order) => {
    if (order.state === PAYMENT_ROUTER_STATES.REJECTED) {
        return order
    }

    order.operations = order.operations.filter((x) => x.success !== false)
    return order
}

export const getMerchantReferenceOrder = (order) => {
    if (order.merchant_reference) {
        return order.merchant_reference
    }
    return '-'
}

export const getMerchantReferenceAuthorization = (authorization) => {
    if (authorization.merchant_reference) {
        return authorization.merchant_reference
    }
    return authorization.retrieval_reference_number || '-'
}

export const getRefundedAmountForOrderLine = (order, orderLineId) => {
    const refunds = getRefunds(order)
    return refunds.reduce((mem, refund) => {
        if (refund.order_lines) {
            const refundedLine = refund.order_lines.find(orderLine => orderLine.id === orderLineId)
            if (refundedLine && !isNaN(refundedLine.amount)) {
                return mem + refundedLine.amount
            }
        }
        return mem
    }, 0)
}


const getAvailableQuantityForOrderLine = (order, unique_id, totalQuantity) => {
    return totalQuantity - getRefundedQuantityForOrderLine(order, unique_id)
}
const getRefundedQuantityForOrderLine = (order, unique_id) => {

    const refunds = getRefunds(order)
    const refundQuantity = refunds.reduce((mem, refund) => {
        if (refund.order_lines_to_refund && refund.order_lines_to_refund.length > 0) {
            const refundedLine = refund.order_lines_to_refund.find(orderLine => orderLine.unique_id === unique_id)
            if (refundedLine && !isNaN(refundedLine.quantity)) {
                return mem + refundedLine.quantity
            }
        }
        else if (refund.order_lines) {
            const refundedLine = refund.order_lines.find(orderLine => orderLine.unique_id === unique_id)
            if (refundedLine && !isNaN(refundedLine.amount)) {
                const quantity = Math.round(refundedLine.amount / refundedLine.unitpriceinclvat)
                return mem + quantity
            }
        }
        return mem
    }, 0)
    return refundQuantity
}

export const getCardType = (authorization) => {
    const cardType = authorization.wallet || authorization.payment_type

    if (!cardType) return ''
    if (['americanexpress', 'american express'].includes(cardType)) return 'Amex'
    const lc = cardType.toLowerCase()
    const provider = lc.charAt(0).toUpperCase() + lc.slice(1)
    return provider
}


export const formatCardType = (cardType) => {
    if (!cardType) return ''
    if (['americanexpress', 'american express'].includes(cardType)) return 'Amex'
    const lowerCaseCardType = cardType.toLowerCase()
    const formattedCardType = lowerCaseCardType.charAt(0).toUpperCase() + lowerCaseCardType.slice(1)
    return formattedCardType
}

export const calculateState = (order) => {
    const hasCapture = order.captured_amount > 0
    const hasRefund = order.refunded_amount > 0
    const isFullyCaptured = hasCapture && order.authorized_amount <= order.captured_amount

    if (order.canceled) {
        return ORDER_STATES.CANCELED
    }
    if (order.rejected) {
        return ORDER_STATES.REJECTED
    }
    if (order.authorized_amount == 0) {
        return ORDER_STATES.ZERO_AUTH
    }
    if (!hasCapture) {
        return ORDER_STATES.AUTHORIZED
    }
    if (isFullyCaptured && !hasRefund) {
        return ORDER_STATES.CAPTURED
    }
    if (isFullyCaptured && order.captured_amount <= order.refunded_amount) {
        return ORDER_STATES.REFUNDED
    }
    if (hasCapture && !isFullyCaptured && !hasRefund) {
        return ORDER_STATES.PARTLY_CAPTURED
    }
    if (hasCapture && !isFullyCaptured && hasRefund) {
        return ORDER_STATES.PARTLY_CAPTURED_PARTLY_REFUNDED
    }
    if (isFullyCaptured && hasRefund && order.captured_amount >= order.refunded_amount) {
        return ORDER_STATES.PARTLY_REFUNDED
    }
    if (hasRefund && order.captured_amount >= order.refunded_amount) {
        return ORDER_STATES.PARTLY_REFUNDED
    }
}

export const addUniqueIdToOrderlineItem = (order) => {
    const createUniqueId = (item) => {
        return `${item.id}-${item.unitpriceinclvat}-${item.description}`
    }

    order.operations.forEach((operation) => {
        operation.order_lines_to_capture && operation.order_lines_to_capture.forEach((item) => {
            item.unique_id = createUniqueId(item)
        })
        operation.order_lines_to_refund && operation.order_lines_to_refund.forEach((item) => {
            item.unique_id = createUniqueId(item)
        })
        const orderInformation = operation.order_information

        if(orderInformation) {
            orderInformation.orderLines && orderInformation.orderLines.forEach((item) => {
                item.unique_id = createUniqueId(item)
            })
        }
    })
    return order
}
