import React, { Component } from 'react'
import {FormattedMessage} from 'react-intl'
import { handleScroll } from '../../../common/stickyScroll'

class OrdersListHeaders extends Component {
    constructor(props) {
        super(props)
        this.handleScroll = handleScroll.bind(this, '.online-sales .list-group .header-row', '.online-sales .list-group')
    }
    componentDidMount () {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll)
    }

    render() {
        return (
            <div className="list-group">
                <div className="header-row">
                    <div className="row-wrapper">
                        <span className="header-column date-time">
                            <FormattedMessage
                            id="order-list-header-date"
                            defaultMessage={'Date'}
                            description="Date list header"
                            />
                        </span>
                        <span className="header-column merchant-reference">
                            <FormattedMessage
                                id="online-list-header-order-id"
                                defaultMessage={'Order ID'}
                                description="Order ID list header (online)"
                            />
                        </span>
                        <span className="header-column payment-method">
                            <FormattedMessage
                                id="online-list-header-payment-method"
                                defaultMessage={'Payment'}
                                description="Payment method list header (online)"
                            />
                        </span>
                        <span className="header-column currency">
                            <FormattedMessage
                                id="online-list-header-currency"
                                defaultMessage={'Currency'}
                                description="Currency list header (online)"
                            />
                        </span>
                        <span className="header-column amount">
                            <FormattedMessage
                                id="online-list-header-amount"
                                defaultMessage={'Amount'}
                                description="Amount list header (online)"
                            />
                        </span>
                        <span className="header-column refunded">
                            <FormattedMessage
                                id="online-list-header-refunded"
                                defaultMessage={'Refunded'}
                                description="Refunded list header (online)"
                            />
                        </span>
                        <span className="header-column captured">
                            <FormattedMessage
                                id="online-list-header-captured"
                                defaultMessage={'Captured'}
                                description="Captured list header (online)"
                            />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrdersListHeaders
