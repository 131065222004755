import { ADJUSTMENT_TEXT, ADJUSTMENT_TYPE} from '../constants'

export function getTextForAdjustmentFee(item){
    const textId = getTextIdForAdjustmentFee(item)
    const text = ADJUSTMENT_TEXT[textId] || ADJUSTMENT_TEXT['adjustment']
    return text
}

function getTextIdForAdjustmentFee(item){

    //when the item constains type attr, means the item has been aggreate already, get the coresponding text id.
    if(item.type){
        const serviceChargeTypes = ['msc_daily', 'msc_monthly', 'msc']
        const isServiceCharge = serviceChargeTypes.includes(item.type)
        if(isServiceCharge) return 'service_charge'
        else if( !ADJUSTMENT_TYPE.includes(item.type) ) return item.type
    }
    if(item.subType){
        return `SUBTYPE_${item.subType}`
    }
    if(item.reason_code){
        return `reason${item.reason_code}`
    }

    return 'adjustment'
}


