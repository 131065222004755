import * as t from './actionTypes'
import { Map } from 'immutable'

export const initState = Map({
    filter: {
        acquiring: null,
        instore: null,
        online: null
    },
    views: {
        showAcquiringView: false,
        showInstoreView: false,
        showOnlineView: false
    },
    activeView: null
})

const reducer = (sales=initState, action) => {
    switch(action.type) {
        case t.SHOW_SELECTED_SALES_VIEW:
            return sales.setIn(['views'], action.payload.views)
                        .setIn(['activeView'], action.payload.activeView)
      case t.SET_ACTIVE_VIEW:
            return sales.set('activeView', action.payload.activeView)
        default:
            return sales
    }
}

export default reducer
