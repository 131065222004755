import { Map } from 'immutable'
import * as t from './actionTypes'

export const initState = Map({
    availableReports: [],
    activeReport: Map(),
    isFetching: false,
    reportType: 'invoice',
    reportFormat: 'pdf',
    error: null,
    activeReportJson: Map()
})

const reducer = (invoice = initState, action) => {
    switch (action.type) {
        case t.LIST_AVAILABLE_INVOICE_STATEMENTS_START:
            return invoice.set('isFetching', true)
        case t.LIST_AVAILABLE_INVOICE_STATEMENTS_SUCCESS: // eslint-disable-line
            const sortedAvailableReports = sortByDate(action.payload.availableReports.data) // eslint-disable-line
            return invoice.set('isFetching', false)
                .set('availableReports', sortedAvailableReports)
        case t.LIST_AVAILABLE_INVOICE_STATEMENTS_ERROR:
            return invoice.set('isFetching', false)
                .set('error', true)
        case t.REQUEST_INVOICE_REPORT_START:
            return invoice.setIn(['activeReport', 'isFetching'], true)
                .setIn(['activeReport', 'serialNumber'], action.payload.serialNumber)
        case t.REQUEST_INVOICE_REPORT_SUCCESS:
            return invoice.setIn(['activeReport', 'isFetching'], false)
        case t.REQUEST_INVOICE_REPORT_ERROR:
            return invoice.set('isFetching', false)
                .set('error', action.payload.error)
        case t.REQUEST_INVOICE_JSON_START:
            return invoice.setIn(['activeReportJson', 'isFetching'], true)
        case t.REQUEST_INVOICE_JSON_SUCCESS:
            return invoice.setIn(['activeReportJson', 'isFetching'], false)
                .setIn(['activeReportJson', 'data'], action.payload.data)
        case t.REQUEST_INVOICE_JSON_ERROR:
            return invoice.setIn(['activeReportJson', 'isFetching'], false)
            .setIn(['activeReportJson', 'error'], action.payload.error)
        default:
            return invoice
    }
}

export const sortByDate = (invoices) => {
    return invoices.sort((a, b) => new Date(b.period) - new Date(a.period))
}

export default reducer
