import { getFirstInMonthUTC } from '../common/dateHelpers'
import { getMonthlyDates } from '../reports/actions'

export const getMonthRange = (fromDate, toDate) => {
    let currentMonth = getFirstInMonthUTC(fromDate)
    const toDateMonthTime = getFirstInMonthUTC(toDate).getTime()

    const months = []
    while (currentMonth.getTime() < toDateMonthTime) {
        months.unshift(new Date(currentMonth))
        currentMonth.setUTCMonth(currentMonth.getUTCMonth() + 1)
    }

    return months
}

export const getTimeDifferenceInSeconds = (end, start) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const diff = Math.abs((startDate.valueOf() - endDate.valueOf()) / 1000)
    return Math.floor(diff)
}

export const formatMillisecondsToReadableTime = (milliseconds) => {
    return formatSecondsToReadableTime(milliseconds / 1000)
}

// TODO remove this second and only keep formatMillisecondsToReadableTime
export const formatSecondsToReadableTime = (seconds) => {
    if (seconds < 0) {
        return '< 1m'
    }

    const milliseconds = seconds * 1000
    let time = new Date(milliseconds).toISOString()
    let hours = parseInt(time.substring(13, 11))
    let minutes = parseInt(time.substring(16, 14))

    if (!minutes && !hours) {
        return '< 1m'
    }

    if (!hours && minutes) {
        return `~${minutes}m`
    }

    if (hours && !minutes) {
        return `~${hours}h`
    }

    if (hours && minutes) {
        return `~${hours}h ${minutes}m`
    }

    return null
}

export const getEstimatedDuration = (task) => {
    const created_at = new Date(task.created_at)
    const estimated_time_of_completion = new Date(task.estimated_time_of_completion)

    const offset = task.age_in_milliseconds
    const duration = estimated_time_of_completion.getTime() - created_at.getTime()
    return [offset, offset > duration ? 0 : duration]
}

export const getReportKey = (props, date) => {
    if (!date) {
        return `${props.from_date}-${props.to_date}-${props.type}-${props.report_format ? props.report_format : props.format}`
    }

    const [from_date, to_date] = getMonthlyDates(date)
    return `${from_date}-${to_date}-${props.type}-${props.report_format ? props.report_format : props.format}`
}

export const groupDatesByYear = (dates) => {
    const by_year = {}

    for (const date of dates) { // eslint-disable-line
        const year = date.getUTCFullYear().toString()
        if (!by_year[year]) {
            by_year[year] = []
        }

        by_year[year].push(date)
    }

    return by_year
}
