import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { SALES_TYPES_MAPPING_TO_STATE, SALES_TYPES } from '../constants'
import { injectIntl } from 'react-intl'
import * as actions from '../actions'
import { getMerchant } from '../../../common/helpers/findMerchant'
import { channelNameIsValid } from '../../../common/helpers/channelNameIsValid'
import SalesListContainer from './salesListContainer'
import SalesFilterContainer from './salesFilterContainer'
import { load } from '../actions'
import ToggleButton from '../../../common/components/toggleButton'
import { determineChannelOptions } from '../../../dashboard/helpers/channels'
import { WINDOW_SIZE_COMBILED_FILTER } from '../constants'
import SelectDropdown from '../../../common/components/selectDropdown'
import * as userActions from '../../../user/actions'

import ErrorBoundary from '../../../common/components/errorBoundary'

class SalesContainer extends Component {
    constructor(props) {
        super(props)
        const defaultChannel = this._defaultChannel()
        this.state = {
            views: {
                showAcquiringView: false,
                showInstoreView: false,
                showOnlineView: false
            },
            activeView: defaultChannel,
            defaultChannel: defaultChannel,
            isMobile: false,
            widthForFilterDropdownForFilterDropdown: 0
        }
        if (!this.props.activeView && defaultChannel) {
            this.props.dispatch(actions.setActiveView(defaultChannel))
        }
        this.setMobile = this.setMobile.bind(this)
    }

    render() {
        //render the page after default channel decided
        if (this.state.defaultChannel) {
            return (
                <div className="sales-wrapper">
                    <ErrorBoundary>
                        {this.renderSubMenu()}
                    </ErrorBoundary>
                    <ErrorBoundary>
                        {this.renderSalesLists()}
                    </ErrorBoundary>
                </div>
            )
        }
        return <div></div>
    }

    componentDidMount() {
        this.setMobile()
        window.addEventListener('resize', this.setMobile, false)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setMobile, false)
    }

    componentDidUpdate() {
        if (this.state.defaultChannel) {
            return
        }

        const defaultChannel = this.props.defaultChannel

        if (defaultChannel) {
            const { channel, status } = defaultChannel.toJS()
            if (status === 'done' && !this.state.defaultChannel) {
                this.setState({ defaultChannel: channel })
                if (this.props.activeView !== channel) this.props.dispatch(actions.setActiveView(channel))
            }
        }
    }

    //end of react lifecycle

    setMobile() {
        const merchant = getMerchant(this.props.match.params.merchantId, this.props.merchant)
        const salesTypes = merchant.extra_data.sales_types

        const windowWidth = window.innerWidth > 1300 ? 1300 : window.innerWidth

        const widthForFilterDropdown = windowWidth - Object.keys(salesTypes).length * 100 - 620
        const isMobile = widthForFilterDropdown <= WINDOW_SIZE_COMBILED_FILTER
        this.setState({ isMobile, widthForFilterDropdown })
    }

    _defaultChannel() {
        let merchantObject = getMerchant(this.props.match.params.merchantId, this.props.merchant)
        const availableChannels = determineChannelOptions(merchantObject.extra_data.sales_types)

        let merchantSettings = this.props.merchantSettings
        let selectedSalesChannel

        if (merchantSettings && merchantSettings.salesChannel) {
            selectedSalesChannel = merchantSettings.salesChannel
        }

        if (selectedSalesChannel && channelNameIsValid(selectedSalesChannel, availableChannels)) {
            return selectedSalesChannel
        }
        else {
            if (selectedSalesChannel && !channelNameIsValid(selectedSalesChannel, availableChannels)) {
                // Remove incorrect key from local storage
                delete merchantSettings['salesChannel']
                this.setUserPreference(merchantSettings)
            }

            if (availableChannels && availableChannels[0]) return availableChannels[0]
            return null
        }
    }

    resetPaginationWhenSwitchingActiveView(activeView) {
        const resetPaginationAction = load(activeView)
        this.props.dispatch(resetPaginationAction.setDefaultPagination())
    }

    selectSalesTypeDropdown(selectedOptions) {
        const viewName = selectedOptions[0].text
        this.selectView(viewName)

        // Persist Sales channel/view selection in local storage
        let merchantSettings = this.props.merchantSettings || {}
        merchantSettings.salesChannel = viewName

        this.setUserPreference(merchantSettings)
    }

    selectView(activeView) {
        let view = SALES_TYPES_MAPPING_TO_STATE[activeView]

        this.resetPaginationWhenSwitchingActiveView(activeView)

        if (view) {
            for (let activeView in this.state.views) { // eslint-disable-line
                this.state.views[activeView] = false
            }

            this.state.views[view] = true
            this.state.activeView = activeView
            this.setState(this.state.views)
            this.props.dispatch(actions.setActiveView(activeView))
            this.props.dispatch(
                actions.showSalesView(
                    this.state.views,
                    activeView
                )
            )

            // Persist Sales channel/view selection in local storage
            let merchantSettings = this.props.merchantSettings || {}
            merchantSettings.salesChannel = activeView

            this.setUserPreference(merchantSettings)
        }
    }


    setUserPreference(merchantSettings) {
        const merchantID = this.props.match.params.merchantId

        this.props.dispatch(
            userActions.setUserPreference({
                value: merchantSettings,
                type: merchantID
            })
        )
    }

    renderSalesType(salesTypes) {
        if (this.state.isMobile) return this._renderSalesTypeMenuButtonDropdonw(salesTypes)
        else return this._renderSalesTypeMenuButton(salesTypes)
    }

    _renderSalesTypeMenuButtonDropdonw(salesTypes) {
        const options = Object.keys(salesTypes)
            .filter(key => {
                return salesTypes[key]
            }).map(key => {
                return {
                    text: key
                }
            })

        if (options.length <= 1) return null

        const activeView = this.props.sales.get('activeView') || this.state.activeView

        const selectedOptions = [{ text: activeView }]

        return (
            <SelectDropdown
                options={options}
                className="dropdown-channel"
                selectedOptions={selectedOptions}
                mode={'single'}
                onChange={this.selectSalesTypeDropdown.bind(this)}
                align="left" />
        )
    }

    _renderSalesTypeMenuButton(salesTypes) {

        const data = Object.keys(salesTypes).filter(key => {
            return salesTypes[key]
        }).map(key => {
            const activeView = this.props.sales.get('activeView') || this.state.activeView
            const checked = activeView === key
            return {
                name: 'chanel',
                value: key,
                checked,
                onChange: this.selectView.bind(this, key),
                text: key
            }
        })

        return <ToggleButton data={data} className="channel-toggle" />
    }

    _shouldHideSalesListButton(salesTypes) {
        let count = 0
        Object.keys(salesTypes).map((key) => {
            if (salesTypes[key]) count++
        })

        return count <= 1
    }

    renderSubMenu() {
        const merchant = getMerchant(this.props.match.params.merchantId, this.props.merchant)
        const salesTypes = merchant.extra_data.sales_types

        const shouldHideSalesListButtonClass = this._shouldHideSalesListButton(salesTypes) ? 'hide' : ''

        return (
            <div className={`sub-menu-container sales small-bg ${salesTypes.online ? 'online' : ''}`}>
                <div className="sub-menu group">
                    <nav className={`sales-type-group ${shouldHideSalesListButtonClass}`}>
                        {this.renderSalesType(salesTypes)}
                    </nav>
                    <SalesFilterContainer metaData={merchant.extra_data} activeViewState={this.state.activeView} isMobile={this.state.isMobile} widthForFilterDropdown={this.state.widthForFilterDropdown} />
                </div>
            </div>
        )
    }

    renderNewViews(state) {
        if (state.activeView) {
            return <SalesListContainer {...this.props} salesType={SALES_TYPES[state.activeView]} featureEnabled={true} />
        } else {
            if (state.acquiring) {
                return <SalesListContainer {...this.props} salesType={SALES_TYPES.acquiring} />
            } else if (state.instore) {
                return <SalesListContainer {...this.props} salesType={SALES_TYPES.instore} />
            } else if (state.online) {
                return <SalesListContainer {...this.props} salesType={SALES_TYPES.online} />
            }
        }
    }

    renderSalesLists() {
        const views = this.props.sales.get('views')
        const activeView = this.props.sales.get('activeView') || this.state.activeView
        const acquiring = this.state.views.showAcquiringView || views && views.showAcquiringView
        const online = this.state.views.showOnlineView || views && views.showOnlineView
        const instore = this.state.views.showInstoreView || views && views.showInstoreView

        const state = { activeView, acquiring, online, instore }

        return this.renderNewViews(state)
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        activeView: state.getIn([activeMerchant, 'sales', 'activeView']),
        merchant: state.get('merchant'),
        merchantSettings: state.get('user').getIn(['preference', activeMerchant]),
        sales: state.getIn([activeMerchant, 'sales']),
        loans: state.getIn([activeMerchant, 'loans'])
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(SalesContainer)))
