import React, { useContext, useState } from 'react'
import { getEpayCheckoutsQuery } from './epayQueries'
import { Dropdown, Paper } from '@frontrunners/ui-components'
import { ApplicationContext } from '../../common/reactContext'
import { useIntl } from 'react-intl'
import { SETTINGS_ONLINE as T } from './intlMessageKeys.js'
import Spinner from '../../common/components/spinner'
import { ActivateMerchant } from './activateMerchant'
import { ApiKeyManagement } from './apiKeyManagement'
import { CheckoutLogoUpload } from './checkoutLogoUpload'
import { CheckoutRename } from './checkoutRename'
import { CheckoutTestMode } from './checkoutTestMode'
import { CheckoutTransactionFeeDropdown } from './checkoutTransactionFeeDropdown'
import { STATES } from '../../merchant/constants'

import './online.scss'
import OnlineRedirect from './onlineRedirect'

export const Online = () => {
    const { formatMessage: fm } = useIntl()
    const { config, activeMerchant } = useContext(ApplicationContext)
    const [activeCheckout, _setActiveCheckout] = useState(null)
    const [checkoutDropdownRenderKey, setCheckoutDropdownRenderKey] = useState(0)

    const {
        data: checkoutList
    } = getEpayCheckoutsQuery(config.apiHost, activeMerchant, {
        onSuccess: (checkouts) => setActiveCheckout(checkouts[0])
    })

    const setActiveCheckout = (newActiveCheckout) => {
        _setActiveCheckout(newActiveCheckout)
        setCheckoutDropdownRenderKey((value) => value + 1)
    }

    if (STATES.TRIAL_NOT_ACTIVATED === activeMerchant.state) {
        return <Paper className="online">
            <ActivateMerchant activeMerchant={activeMerchant} />
        </Paper>
    }

    if (!activeCheckout) return <Spinner waiting={true} />

    return <>
        <Paper className="online-link">
            <OnlineRedirect />
        </Paper>

        <Paper className="online-checkout">
            <h2 className="settings-paper-header">
                {fm(T.header)}
            </h2>
            {
                checkoutList.length > 1 &&
                <>
                    {fm(T.select_checkout)}
                    <Dropdown
                        key={checkoutDropdownRenderKey}
                        label="Single"
                        multiSelect={false}
                        items={checkoutList}
                        minSelectedItems={1}
                        defaultSelectedItems={[activeCheckout]}
                        onChange={(item) => setActiveCheckout(item)}
                    />
                </>
            }
            <CheckoutRename
                key={`rename-${activeCheckout.id}`}
                activeCheckout={activeCheckout}
                onRename={(checkout) => setActiveCheckout(checkout)}
            />
            <CheckoutLogoUpload
                key={`upload-${activeCheckout.id}`}
                activeCheckout={activeCheckout}
                onLogoChanged={(checkout) => setActiveCheckout(checkout)}
            />
            <CheckoutTestMode />
            {
                merchantHasAddressInCountry({ activeMerchant, countries: ['Denmark'] })
                && <CheckoutTransactionFeeDropdown
                    key={`txn-fee-${activeCheckout.id}`}
                    activeCheckout={activeCheckout}
                    onChange={(checkout) => setActiveCheckout(checkout)}
                />
            }
        </Paper>
        <Paper className="online-api-keys">
            { <ApiKeyManagement /> }
        </Paper>
    </>
}

export const merchantHasAddressInCountry = ({ activeMerchant, countries }) => {
    const billingAddressHasCoutries = countries.includes(activeMerchant?.billingAddress?.country)

    const salesLocationsHasCountries = activeMerchant?.salesLocations
        ?.filter(salesLocation => countries.includes(salesLocation?.visiting_address?.country)) || []

    return !!billingAddressHasCoutries || salesLocationsHasCountries?.length > 0
}

