import * as t from './actionTypes'
import * as constants from './constants'
import { setLocalizationInLocalStorage } from './helpers'

// Using localization as an umbrella term for both locale and language.
// Locale defines nummeration, dates etc.
// Language sets the text that is used

export function setLocalization(code) {
    setLocalizationInLocalStorage(code)  // persist to local storage

    const locale = constants.LOCALES[code]
    const enMessage = Object.assign({}, constants.LOCALES['en'].messages)
    const messages = locale ? Object.assign(enMessage, locale.messages) : enMessage

    return {
        type: t.SET_LOCALIZATION,
        payload: {
            code,
            messages
        }
    }
}

