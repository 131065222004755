import * as t from './actionTypes'
import { PAYMENTS_BASE, INSTORE_BASE, ONLINE_BASE, SETTLEMENTS_BASE, FRODA_PROXY_BASE } from '../common/constants'
import { runRequest } from '../common/requestActionHelper'
import { createSearch } from '../common/locationHelpers'
import { formatAggregateData } from './helpers'
import moment from 'moment'
import { formatDateToLocalIsoString, getFirstDateOfMonth, getFirstDateOfNextMonth, getFirstDateOfNextYear, getFirstDateOfYear, getMinimumTransactionDate } from '../common/dateHelpers'

const BASE_URLS = {
    'acquiring': PAYMENTS_BASE,
    'instore': INSTORE_BASE,
    'online': ONLINE_BASE
}

const listPayoutAggregatesStart = () => {
    return {
        type: t.LIST_PAYOUT_AGGREGATES_START
    }
}

const listPayoutAggregatesSuccess = (aggregates) => {
    return {
        type: t.LIST_PAYOUT_AGGREGATES_SUCCESS,
        payload: aggregates
    }
}

const listPayoutAggregatesError = (err) => {
    return {
        type: t.LIST_PAYOUT_AGGREGATES_ERROR,
        err
    }
}

export const listPayoutAggregates = (merchantId) => {
    const from_date = moment().startOf('year').format('YYYY-MM-DD')
    const to_date = moment().format('YYYY-MM-DD') // .add('days', 1) ?
    const url = `${SETTLEMENTS_BASE}/intervals?from_date=${from_date}&to_date=${to_date}&aggregate_by=day`

    return runRequest({
        startAction: listPayoutAggregatesStart,
        successAction: (aggregates) => listPayoutAggregatesSuccess(aggregates),
        errorAction: listPayoutAggregatesError,
        requestObj: {
            url,
            merchantId
        }
    })
}

const listPaymentAggregatesStart = () => { // eslint-disable-line
    return {
        type: t.LIST_SALES_AGGREGATES_START
    }
}

const listPaymentAggregatesSuccess = (aggregates) => {
    return {
        type: t.LIST_SALES_AGGREGATES_SUCCESS,
        payload: {
            aggregates
        }
    }
}

const listPaymentAggregatesError = () => { // eslint-disable-line
    return {
        type: t.LIST_SALES_AGGREGATES_ERROR
    }
}
export const listPaymentAggregates = (merchantId, channel, yearly, date, filters = {}) => {

    const requestDate = new Date(date)
    const fromDate = yearly ? getFirstDateOfYear(requestDate) : getFirstDateOfMonth(requestDate)
    const toDate = yearly ? getFirstDateOfNextYear(requestDate) : getFirstDateOfNextMonth(requestDate)
    const minTransactionFromDate = getMinimumTransactionDate(fromDate)

    filters.from_date = formatDateToLocalIsoString(minTransactionFromDate)
    filters.to_date = formatDateToLocalIsoString(toDate)

    const urlParam = yearly ? 'monthly' : 'daily'

    channel = channel ? channel : 'instore'

    let url = `${BASE_URLS[channel]}/aggregates/sales/${urlParam}${createSearch(filters)}`

    if (channel === 'acquiring') {
        url = `${BASE_URLS[channel]}/intervals${createSearch(filters)}&aggregate_by=day`
    }

    return runRequest({
        startAction: listPaymentAggregatesStart,
        successAction: (aggregates) => listPaymentAggregatesSuccess(formatAggregateData(aggregates, date, yearly, channel, filters.currency), yearly),
        errorAction: listPaymentAggregatesError,
        requestObj: {
            url,
            cache: true,
            merchantId
        }
    })
}

const listCreditStart = () => {
    return {
        type: t.LIST_LOAN_CREDIT_START
    }
}

const listCreditSuccess = (credit) => {
    return {
        type: t.LIST_LOAN_CREDIT_SUCCESS,
        payload: credit.data
    }
}

const listCreditError = () => {
    return {
        type: t.LIST_LOAN_CREDIT_ERROR
    }
}

export const listCredit = (merchantId) => {
    const url = `${FRODA_PROXY_BASE}/loan/credit`
    return runRequest({
        startAction: listCreditStart,
        successAction: (credit) => listCreditSuccess(credit),
        errorAction: listCreditError,
        requestObj: {
            url,
            merchantId
        }
    })
}
