import React from 'react'
import MonetaryAmountKmb from '../../common/components/monetaryAmountKmbFormat'
import { DASHBOARD_TRANSLATIONS } from '../translations'
import Spinner from '../../common/components/spinner'
import {getAmountStringWithCurrency} from '../../common/amountHelpers'

const AmountWidget = ({ item, currency, noAvailableMsg }) => {
    const currencyObj = item.get(currency)
    return (
        <span title={currencyObj && getAmountStringWithCurrency(currencyObj)}>
            {currencyObj ? <MonetaryAmountKmb amount={currencyObj.amount} currency={currency} amount_exponent={currencyObj.exponent} className="fg-darkest" /> : noAvailableMsg}
        </span>
    )
}

const PayoutWidget = ({ latest, monthly, yearly, currency, isFetching, formatMessage, redirectLink }) => {
    if (isFetching) {
        return (
            <div className="widget loading">
                <div className="payouts">
                    <div className="payout header">
                        <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['payouts_in'])} {currency}</div>
                        <div className="link" onClick={() => redirectLink()}>{formatMessage(DASHBOARD_TRANSLATIONS['payouts'])}</div>
                    </div>
                </div>
                <Spinner waiting={true} />
            </div>
        )
    }
    const noAvailableMsg = formatMessage(DASHBOARD_TRANSLATIONS['payouts_not_available'])

    return (
        <div className="widget">
            <div className="payouts">
                <div className="payout header">
                    <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['payouts_in'])} {currency}</div>
                    <div className="link" onClick={() => redirectLink()}>{formatMessage(DASHBOARD_TRANSLATIONS['payouts'])}</div>
                </div>
                <div className="payout">
                    <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['latest_payout'])}</div>
                    <div className="amount">
                        <AmountWidget item={latest} currency={currency} noAvailableMsg={noAvailableMsg} />
                    </div>
                </div>
                <div className="payout">
                    <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['payouts_this_month'])}</div>
                    <div className="amount">
                        <AmountWidget item={monthly} currency={currency} noAvailableMsg={noAvailableMsg} />
                    </div>
                </div>
                <div className="payout year">
                    <div className="description">{formatMessage(DASHBOARD_TRANSLATIONS['payouts_this_year'])}</div>
                    <div className="amount">
                        <AmountWidget item={yearly} currency={currency} noAvailableMsg={noAvailableMsg} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayoutWidget
