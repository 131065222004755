import { localStorageGetItem } from './localstorageHelpers'
import { isTheOffsetOutOfRange } from './locationHelpers'
import { redirectToLogout } from './redirectActions'
import { TTL } from './constants'

import { showErrorPage } from '../errors/actions'

// string enums for flow
export const METHOD_TYPES = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
    HEAD: 'HEAD',
    OPTIONS: 'OPTIONS'
}

export function generateActions(actionName) {
    const action = (type) => (payload = {}) => {
        return { type, payload }
    }

    return {
        start: () => ({ type: `${actionName}_START` }),
        success: (payload) => action(`${actionName}_SUCCESS`)(payload),
        error: (payload) => action(`${actionName}_ERROR`)(payload)
    }
}

export function runRequestWrapper(actionName, requestObj, customResponseHandler) {
    const action = generateActions(actionName)
    return runRequest({
        startAction: () => action.start(),
        successAction: (response) => action.success(response),
        errorAction: (error) => action.error(error),
        requestObj,
        customResponseHandler
    })
}

// simiplified then-able fetch wrapper
export const fetchJson = (dispatch, getState, requestObj, customResponseHandler) => {
    requestObj.url = replaceUrl(getState, requestObj)
    const options = getRequestOptions(requestObj)
    const cacheKey = getCacheKey(requestObj, options)

    return cachedFetch(cacheKey, requestObj.url, options)
        .then(response => {
            return handleJsonResponse(dispatch, response, requestObj, customResponseHandler, getState)
        }).then(json => {
            addJsonResponseToCahe(cacheKey, json)
            return Promise.resolve(json)
        })

}

export const runRequest = ({ startAction, successAction, errorAction, outOfRangeAction, requestObj, customResponseHandler }) => {

    return (dispatch, getState) => {
        requestObj.url = replaceUrl(getState, requestObj)
        if (startAction) {
            dispatch(startAction())
        }

        const options = getRequestOptions(requestObj)
        const cacheKey = getCacheKey(requestObj, options)
        const searchQueryStr = requestObj.url.split('?')[1]
        return cachedFetch(cacheKey, requestObj.url, options)
            .then((response) => {
                return handleJsonResponse(dispatch, response, requestObj, customResponseHandler, getState)
            })
            .then(json => {
                addJsonResponseToCahe(cacheKey, json)
                if (outOfRangeAction && isTheOffsetOutOfRange(searchQueryStr, json.total)) {
                    return dispatch(outOfRangeAction())
                }
                return dispatch(successAction(json))
            })
            .catch(() => {
                if(errorAction) {
                    return dispatch(errorAction())
                }
                return
            })
    }
}

const replaceUrl = (getState, requestObj) => {
    let url = requestObj.url
    const merchantId = requestObj.merchantId
    if (url.includes('<merchant>') && !merchantId) {
        throw ({
            message: 'Merchant not set for merchant specific request',
            url: url,
            state: getState()
        })
    }
    if (merchantId) {
        url = url.replace('<merchant>', merchantId)
    }
    const apiHost = getState().getIn(['config', 'apiHost'])
    const backendHost = getState().getIn(['config', 'backendHost'])
    url = url.replace('<apihost>', apiHost)
    url = url.replace('<backendhost>', backendHost)
    return url
}

// takes a requestObject defined in an action, and returns request options
export const getRequestOptions = ({ headers, body, method = METHOD_TYPES.GET, allowedMerchants, merchantId, ignoreGlobalMerchant = false }) => {
    // The default behaviour is to only include the logged in merchant in the
    // header. To enable trial - prod switching we need to override this and include
    // all merchants.
    const merchants = allowedMerchants || [merchantId]

    let _headers = new Headers()
    _headers.append('Accept', 'application/json')
    _headers.append('Content-Type', 'application/json')
    if (headers) {
        for (var [key, value] of Object.entries(headers)) {
            _headers.append(key, String(value))
        }
    }

    if (merchants && !ignoreGlobalMerchant) {
        _headers.append('Merchants', JSON.stringify(merchants))
    }

    const accessToken = localStorageGetItem('accessToken')
    if (accessToken) {
        _headers.append('Authorization', `Bearer ${accessToken}`)
    }

    return {
        method: method,
        headers: _headers,
        body: body
    }
}

// a wrapper around fetch for caching
const cachedFetch = (cacheKey, url, options) => {
    return getFromCache(cacheKey) || fetch(url, options)
}

// used to handle response in a .then chained onto fetch() or cachedFetch()
function handleJsonResponse(dispatch, response, requestObj, customResponseHandler, getState) {
    // override the default handler if a custom response handler is set
    if (customResponseHandler) {
        const customStatusHandler = customResponseHandler[response.status.toString()]
        if (customStatusHandler) {
            return customStatusHandler(dispatch, response, requestObj, getState)
        }
    }
    switch (response.status) {
        case 200:
        case 201:
            return response.json()
        case 204:
            // No JSON to parse because of "No content"
            return response
        case 401:
            return dispatch(redirectToLogout(window.location.pathname))
        default:
            throw new Error(`Error: ${response.url}`)
    }
}

const CACHE = {}

function getCacheKey(requestObj, options) {
    if (requestObj.cache) {
        const keyObject = {
            url: requestObj.url,
            method: options.method,
            body: options.body,
            merchantHeaders: options.headers.get('Merchants')
        }
        return JSON.stringify(keyObject)
    }
}

function getFromCache(key) {
    if (!key) return
    const hit = CACHE[key]
    const now = new Date().valueOf()
    if (hit && now < hit.expires) {
        // returns a promise that behaves in the same way as a fetch() response
        return Promise.resolve({
            status: 200,
            json: () => Promise.resolve(hit.data)
        })
    }
}

function addJsonResponseToCahe(key, data) {
    if (key) {
        const expires = new Date().valueOf() + TTL
        CACHE[key] = {
            data,
            expires
        }
    }
}

export function customLoanError() {
    return {
        message: 'Something went wrong when making request to Froda'
    }
}

export function merchantError(dispatch, errorResponse) {
    console.error(errorResponse)
    dispatch(showErrorPage())
}
