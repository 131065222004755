import { defineMessages } from 'react-intl'

export const NAME = 'reports'

export const fakeDownloadReportTotal = 100

export const fakeDownloadReportIncreasement = 5

export const REPORT_BUTTON = defineMessages({
    order: {
        id: 'monthly-reports-order-button',
        defaultMessage: 'Order',
        description: 'Text of order button'
    },
    download: {
        id: 'monthly-reports-download-button',
        defaultMessage: 'Download',
        description: 'Text of download button'
    }
})

export const REPORT_TITLE = defineMessages({
    detailPDF: {
        id: 'dashboard-reports-dropdown-report-yedetailed-pdf',
        defaultMessage: 'Detailed',
        description: 'Reports dropdown menu detailed pdf repor)'
    },
    multimidPDF: {
        id: 'dashboard-reports-dropdown-report-multimid-pdf',
        defaultMessage: 'PDF',
        description: 'Reports dropdown menu multimid pdf report'
    },
    summaryPDF: {
        id: 'dashboard-reports-dropdown-report-summary-pdf',
        defaultMessage: 'Summary',
        description: 'Reports dropdown menu summary pdf report'
    },
    multimidExcel: {
        id: 'dashboard-reports-dropdown-report-multimid-excel',
        defaultMessage: 'Excel',
        description: 'Reports dropdown menu multimid excel report'
    },
    summaryExcel: {
        id: 'dashboard-reports-dropdown-report-summary-execel',
        defaultMessage: 'Excel',
        description: 'Reports dropdown menu summary execel report'
    }
})

export const REPORT_HOVERTEXT = defineMessages({
    download: {
        id: 'dashboard-reports-dropdown-report-detailed-pdf-hover-tex',
        defaultMessage: 'Download',
        description: 'Reports dropdown menu report hover text'
    },
    error: {
        id: 'report-failed-notification-text',
        defaultMessage: 'Could not generate report. Contact support',
        description: 'Error message displayed when a report fails to download'
    }
})

export const REPORT_EMPTY = defineMessages({
    header: {
        id: 'reports-page-empty-header',
        defaultMessage: 'No reports',
        description: 'Empty monthly reports header'
    }
})
