import React, { useContext, useState } from 'react'
import { getRemoveCheckoutLogoMutation, getUploadCheckoutLogoMutation } from './epayQueries'
import { ButtonV2, Dropzone, Icon } from '@frontrunners/ui-components'
import { ApplicationContext } from '../../common/reactContext'
import { useQueryClient } from 'react-query'
import { useIntl } from 'react-intl'
import { CHECKOUT_LOGO_UPLOAD as T } from './intlMessageKeys.js'
import Spinner from '../../common/components/spinner'
import PromiseFileReader from 'promise-file-reader'

import './checkoutLogoUpload.scss'

const ALLOWED_IMG_SIZE_IN_KB = 2048
const ALLOWED_IMG_FILE_EXTENSIONS = ['png', 'gif', 'jpg', 'jpeg']
const ALLOWED_IMG_HEIGHT_IN_PX = 1600
const ALLOWED_IMG_WIDTH_IN_PX = 1200

export const CheckoutLogoUpload = ({ activeCheckout, onLogoChanged }) => {

    const queryClient = useQueryClient()
    const { config, activeMerchant, loggedInUser } = useContext(ApplicationContext)


    const {
        mutateAsync: uploadCheckoutLogo,
        isLoading: isUploadingCheckoutLogo,
        isError: uploadError
    } = getUploadCheckoutLogoMutation(queryClient, config.apiHost, activeMerchant, activeCheckout)

    const {
        mutateAsync: removeCheckoutLogo,
        isLoading: isRemovingCheckoutLogo,
        isError: removeError
    } = getRemoveCheckoutLogoMutation(queryClient, config.apiHost, activeMerchant, activeCheckout)

    const isLoading = isUploadingCheckoutLogo || isRemovingCheckoutLogo

    const hasError = uploadError || removeError

    return LogoUpload({
        activeCheckout,
        loggedInUser,
        isLoading,
        hasError,
        uploadCheckoutLogo,
        removeCheckoutLogo,
        onLogoChanged
    })
}

export const LogoUpload = ({ activeCheckout, loggedInUser, isLoading, hasError, uploadCheckoutLogo, removeCheckoutLogo, onLogoChanged }) => {
    const { formatMessage: fm } = useIntl()
    const [showInactiveForSupportMessage, setShowInactiveForSupportMessage] = useState(false)
    const [invalidErrorMessage, setInvalidErrorMessage] = useState(null)

    const hasLogo = !!activeCheckout.checkout.logourl

    return <div className="checkout-logo-upload">
        <h4>{fm(T.checkout_logo_header)}</h4>
        <p className="dropzone-description">{fm(T.checkout_logo_description)}</p>
        <Dropzone
            accept="image/*"
            onDrop={async (file) => {
                if (loggedInUser.isSupport) {
                    setShowInactiveForSupportMessage(true)
                    return
                }

                try {
                    setInvalidErrorMessage(null)
                    const validIMage = await getValidImageOrThrowFormattedError({ file, formatMessage: fm })
                    const checkout = await uploadCheckoutLogo(validIMage).catch(e => { }) // eslint-disable-line no-unused-vars
                    onLogoChanged(checkout)
                } catch (e) {
                    setInvalidErrorMessage(e.message)
                }
            }}
        >

            {isLoading && <Spinner waiting={true} />}

            {
                !isLoading &&
                <>
                    {
                        hasLogo &&
                        <img src={activeCheckout.checkout.logourl} alt="checkout logo" />
                    }

                    {
                        !hasLogo &&
                        <>
                            <Icon iconName="upload" />
                            <p className="upload-text-desktop">{fm(T.dropzone_empty_desktop)}</p>
                            <p className="upload-text-mobile">{fm(T.dropzone_empty_mobile)}</p>
                        </>
                    }
                </>
            }
        </Dropzone>
        <div className="dropzone-footer">
            {hasError && fm(T.upload_failed)}
            {invalidErrorMessage && invalidErrorMessage}
            {showInactiveForSupportMessage && 'Inactive for supportal agents'}

            <ButtonV2
                label="Remove"
                buttonStyle="turquoise"
                disabled={!hasLogo}
                onClick={async () => {
                    if (loggedInUser.isSupport) {
                        setShowInactiveForSupportMessage(true)
                        return
                    }
                    setInvalidErrorMessage(null)
                    const checkout = await removeCheckoutLogo()
                    onLogoChanged(checkout)
                }}
            />
        </div>
    </div>
}

export const getValidImageOrThrowFormattedError = async ({ file, formatMessage: fm }) => {
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase()
    if (!ALLOWED_IMG_FILE_EXTENSIONS.includes(fileExtension)) {
        throw new Error(fm(T.invalid_image_type))
    }

    if (file?.size / 1024 > ALLOWED_IMG_SIZE_IN_KB) {
        throw new Error(fm(T.invalid_image_size, { maxSize: '2MB' }))
    }

    const image = await loadImage(file)
    if (image.naturalHeight > ALLOWED_IMG_HEIGHT_IN_PX || image.naturalWidth > ALLOWED_IMG_WIDTH_IN_PX) {
        throw new Error(fm(T.invalid_image_size, { maxSize: '2MB' }))
    }

    return Promise.resolve(image)
}

const loadImage = async (file) => {
    return new PromiseFileReader
        .readAsDataURL(file)
        .then(src => {
            return new Promise(resolve => {
                const img = new Image()
                img.onload = () => resolve(img)
                img.src = src
            })
        })
}
