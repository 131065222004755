import React from 'react'
import { Checkbox, Card } from '@frontrunners/ui-components'
import { localStorageSetItem } from '../../common/localstorageHelpers'
import './developerModeDropdown.scss'
import { featureToggleDisplayNames } from '../../common/helpers/featureToggle'

export const DeveloperModeDropdown = ({ developerModeState, setDeveloperModeState }) => {
    const { developerMode, featureToggle } = developerModeState

    const setFeatureToggleState = (feature, value) => {
        const newMode = { ...developerModeState }
        newMode.featureToggle[feature] = value
        localStorageSetItem('developerMode', JSON.stringify(newMode))
        setDeveloperModeState(newMode)
    }

    return <>
        <div
            data-testid="developer-mode-handler"
            className="developer-mode-handler"
        ></div>
        <div className={`developer-mode ${!developerMode ? 'disabled' : ''}`}>
            <Card>
                <div>Developer mode
                    <Checkbox
                        initialValue={developerMode}
                        onClick={(value) => {
                            const newMode = { ...developerModeState }
                            newMode.developerMode = value
                            localStorageSetItem('developerMode', JSON.stringify(newMode))
                            setDeveloperModeState(newMode)
                        }}
                        slider />
                </div>
                {
                    Object.entries(featureToggle).map(([feature, initialValue]) =>
                        <div key={feature}>
                            <span>{getDisplayName(feature)}</span>
                            <Checkbox
                                initialValue={initialValue}
                                onClick={(value) => setFeatureToggleState(feature, value)}
                                slider />
                        </div>
                    )
                }
            </Card>
        </div>
    </>
}

const getDisplayName = (feature) => {
    return featureToggleDisplayNames?.[feature] ?? feature.replace(/([A-Z])/g, ' $1').trim()
}
