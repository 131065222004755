import React, { useContext } from 'react'
import { Button, Icon, Badge } from '@frontrunners/ui-components'

import './onlineRedirect.scss'
import { getEpayRedirect } from './epayQueries'
import { SETTINGS_ONLINE } from './intlMessageKeys'
import { getUser } from '../../common/userQueries'
import { ApplicationContext } from '../../common/reactContext'
import { useIntl } from 'react-intl'
import { FEATURE_DISABLED } from '../../common/translations'

const OnlineRedirect = () => {
    const { formatMessage: fm } = useIntl()
    const { config, activeMerchant, loggedInUser } = useContext(ApplicationContext)

    const { data: user } = getUser(config.apiHost, activeMerchant.id, loggedInUser.sub)
    const { data: link } = getEpayRedirect(config.apiHost, activeMerchant, loggedInUser, user, { enabled: !!user})

    return (
        <div className="online-page">
            <div className="online-icon">
                <Icon iconName="tap"/>
            </div>
            <div className="group">
                <h2 className="settings-paper-header">
                    {fm(SETTINGS_ONLINE['checkout_redirect_header'])}
                </h2>
                { loggedInUser.loggedInUserIsSupport && <Badge className="orange">{fm(FEATURE_DISABLED.disabled_for_support_agents)}</Badge> }
            </div>
            <div className="description">
                <div className="text">{fm(SETTINGS_ONLINE['checkout_redirect_paragraph'])}</div>
                <Button
                    className="turquoise"
                    onClick={() => window.open(link?.redirect_link, '_blank')}
                    label={fm(SETTINGS_ONLINE['checkout_redirect_header'])}
                    iconName="newWindow"
                    disabled={!link || loggedInUser.loggedInUserIsSupport}>
                </Button>
            </div>
        </div>
    )
}

export default OnlineRedirect
