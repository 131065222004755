import types from './actionTypes'
import { Map, List, Set, fromJS } from 'immutable'
import * as paginationHelpers from '../../common/components/paginationLoadMore'
import { PAGE_OFFSET, PAGE_LIMIT } from '../../common/constants'

export const initState = Map({
    list: Map({
        transactions: List()
    }),
    current: Map(),
    error: Map(),
    filters: Map({
        offset: PAGE_OFFSET,
        limit: PAGE_LIMIT,
        return_total_count: false
    }),
    url: '',
    filterExpanded: false
})

var initialFilterState = {
    selected: Set(),
    opened: false
}


export default function acquiringReducer(acquiring = initState, action) {
    var updatedAcquiring
    switch (action.type) {
        case types.LIST_START:
            return acquiring.setIn(['url'], action.payload.url)
                .setIn(['list', 'isFetching'], true)
                .setIn(['list', 'isError'], false)
        case types.LIST_SUCCESS:
            var prevPage = acquiring.getIn(['list', 'transactions'])
            var nextPage = action.payload.transactions.data
            var updatedList = paginationHelpers.appendNextPage(prevPage, nextPage, action.payload.search)
            var currentOffset = acquiring.getIn(['filters', 'offset'])
            return acquiring.setIn(['list', 'transactions'], List(updatedList))
                .setIn(['list', 'isFetching'], false)
                .setIn(['list', 'emptyList'], nextPage.length == 0)
                .setIn(['list', 'unixtime'], new Date().valueOf())
                .setIn(['list', 'url'], action.payload.url)
                .setIn(['filters', 'offset'], currentOffset + PAGE_LIMIT)
        case types.LIST_ERROR:
            return acquiring.setIn(['list', 'isError'], action.isError).setIn(['list', 'isFetching'], action.isFetching)
        case types.LIST_NEXT_PAGE_SUCCESS:
            return acquiring.setIn(['list', 'nextPage'], action.payload.transactions)
        case types.LIST_OUT_OF_RANGE:
            return acquiring.setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isOutOfRange'], true)
        case types.CHANGE_FILTER:
            var filters = Set(action.payload.filterValue)
            updatedAcquiring = acquiring
                .setIn(['filters', action.payload.filterType, 'selected'], filters).setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)

            if (action.payload.filterType === 'source_merchant_id' && action.payload.filterValue) {
                var numberOfSourceIds = updatedAcquiring.getIn(['filters', 'source_merchant_id']).get('selected').size
                if (numberOfSourceIds > 0) {
                    return updatedAcquiring.setIn(['filters', 'source'], 'Elixir')
                }
                return updatedAcquiring.deleteIn(['filters', 'source'])

            }

            return updatedAcquiring
        case types.TOGGLE_FILTER:
            var filterSetToggleFilter = fromJS(initialFilterState)
            if (acquiring.getIn(['filters', action.payload.filterType])) {
                return acquiring.setIn(['filters', action.payload.filterType, 'opened'], action.payload.opened)
            } else {
                return acquiring.setIn(['filters', action.payload.filterType], filterSetToggleFilter)
                    .setIn(['filters', action.payload.filterType, 'opened'], action.payload.opened)
            }
        case types.CLEAR_FILTER:
            if (isDateFilter(action.payload.filterType)) {
                return acquiring.removeIn(['filters', 'to_datetime'])
                    .removeIn(['filters', 'from_datetime'])
                    .setIn(['filters', 'offset'], PAGE_OFFSET)
                    .setIn(['filters', 'limit'], PAGE_LIMIT)
            }
            return acquiring.setIn(['filters', action.payload.filterType, 'selected'], Set())
                .setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
                .removeIn(['filters', 'source'])

        case types.CLEAR_ALL_FILTERS:
            return initState
        case types.APPLY_DATE_FILTER:
            return acquiring.setIn(['filters', action.payload.filterType], action.payload.filterValue)
                .setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.SET_CURRENT:
            return acquiring.setIn(['current', 'data'], action.payload.transaction)
        case types.RESET_PAGINATION:
            return acquiring.setIn(['filters', 'offset'], PAGE_OFFSET).setIn(['filters', 'limit'], PAGE_LIMIT)
        default:
            return acquiring
    }
}


export const isDateFilter = (filterType) => {
    return filterType === 'date'
}
