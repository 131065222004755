export const FAQ_EXTERNAL_URLS = {
    'DK': 'https://www.bambora.com/da/dk/customer-service/faq/',
    'FI': 'https://www.bambora.com/fi/fi/customer-service/faq/',
    'NO': 'https://www.bambora.com/no/no/customer-service/faq/',
    'SE': 'https://www.bambora.com/sv/se/customer-service/faq/',
    'GLOBAL': 'https://www.bambora.com/customer-service/faq/',
    getURL: (countryCode) => {
        return FAQ_EXTERNAL_URLS[countryCode] || FAQ_EXTERNAL_URLS.GLOBAL
    }
}

export const CONTRACT_TERMS_AND_CONDITIONS_EXTERNAL_URLS = {
    'AU': 'https://www.bambora.com/en/au/terms-conditions/',
    'CA': 'https://www.bambora.com/en/ca/terms-conditions/',
    'DK': 'https://www.bambora.com/da/dk/vilkar-og-betingelser/',
    'FI': 'https://www.bambora.com/fi/fi/maaraykset-ja-yleiset-ehdot/',
    'NO': 'https://www.bambora.com/no/no/vilkar-og-forskrifter/',
    'SE': 'https://www.bambora.com/sv/se/villkor-och-foreskrifter/',
    'GLOBAL': 'https://www.bambora.com/terms-and-conditions/',
    getURL: function(country_code) {
        return this[country_code] || this['GLOBAL']
    }
}
