import React from 'react'
import { connect } from 'react-redux'
import { redirectToLogout } from '../../common/redirectActions'
import { injectIntl } from 'react-intl'
import { ERROR } from '../constants'
import { Redirect } from 'react-router-dom'
import './errorContainer.scss'

const ErrorContainer = ({ intl, dispatch, error }) => {
    const formatMessage = intl.formatMessage

    if (error && error.show && error.type === 'error_page') return <div className="error-page">
        <h2>{formatMessage(ERROR.title)}</h2>
        <p>{formatMessage(ERROR.message)} <a href="mailto: support.nordics@worldline.com">{formatMessage(ERROR.link)}</a></p>
        <div data-testid="error-page-btn" className="btn btn-primary" onClick={() => {
            dispatch(redirectToLogout())
        }}>{formatMessage(ERROR['try-again'])}</div>
    </div>

    else return <Redirect to="/" />
}

const mapStateToProps = (state) => {
    return {
        error: state.get('errors')
    }
}

export default connect(mapStateToProps)(injectIntl(ErrorContainer))
