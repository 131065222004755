import React, { Component } from 'react'
import BamboraSvgIcon from './bamboraSvgIcon'

class Spinner extends Component  {

    render(){

        if(this.props.error) {
            return (
                <div className="spinner spinner-full spinner-error">
                    <div className="spinner-left"></div>
                    <div className="spinner-right"></div>
                    <BamboraSvgIcon icon="close" className="fill-error in-spinner" />
                </div>
            )
        }

        if (this.props.waiting) {
            return (
                <div className="spinner" role="alert" aria-busy="true">
                    <div className="spinner-left"></div>
                    <div className="spinner-right"></div>
                </div>
            )
        }

        if(this.props.success) {
            return (
                <div className="spinner spinner-full spinner-success">
                    <div className="spinner-left"></div>
                    <div className="spinner-right"></div>
                    <BamboraSvgIcon icon="checkmark" className="fill-success in-spinner" />
                </div>
            )
        }

        return false
    }
}

export default Spinner
