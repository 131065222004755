import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import uuid from 'uuid'

import { SETTLEMENT_DETAIL_PANEL_TEXT } from '../constants'
import adjustmentGroupByEmid from '../helpers/adjustmentGroupByEmid'
import { groupBy, arrayMergeAndUnique } from '../../common/helpers/arrayHelper'
import SettlementDetailByElixir from './settlementDetailByElixir'
import * as reportActions from '../../reports/actions'
import FileComponent from '../../reports/components/fileComponent'

import {getReportsArr} from '../../reports/helpers'

const showMoreLink = (limit, numberOfEmid) => {
    return (limit && numberOfEmid > limit)
}

const prioritizeAndConcat = (adjustment_set, adjustment_aggregates) => {
    if (!adjustment_set) adjustment_set = []
    if (!adjustment_aggregates) adjustment_aggregates = []

    const unique_reasons = [...new Set(adjustment_set.map(item => item.reason_code))]
    const mergedAggregates = adjustment_set.concat(adjustment_aggregates.filter((a) => !unique_reasons.includes(a.reason_code)))
    return mergedAggregates
}

const SettlementDetail = ({ settlement, limit, merchant, formatMessage, match }) => {
    const mergedAggregates = prioritizeAndConcat(settlement.adjustment_set, settlement.adjustment_aggregates)
    const adjustmentSet = adjustmentGroupByEmid(mergedAggregates)
    const chargebackSet = groupBy(settlement.chargeback_aggregates, 'source_merchant_id')
    let elixirIds = arrayMergeAndUnique([Object.keys(adjustmentSet), Object.keys(chargebackSet)])

    const isShowMoreLink = showMoreLink(limit, elixirIds.length)
    const link = formatMessage(SETTLEMENT_DETAIL_PANEL_TEXT['settlement-detail-more-details-link'])
    const saleslocations = merchant.saleslocation_set
    const wrapperClass = elixirIds.length > 1 ? '' : 'settlement-detail__wrapper--single'
    if (limit) elixirIds = elixirIds.slice(0, limit)


    return (
        <div className="settlement-detail">

            <div className={`settlement-detail__wrapper ${wrapperClass}`}>
                {elixirIds.map(elixirId =>
                    <SettlementDetailByElixir
                        adjustmentSetByElixir={adjustmentSet[elixirId] || []}
                        chargebackByElixir={chargebackSet[elixirId] || []}
                        elixirId={elixirId}
                        numberOfElixirMids={elixirIds.length}
                        saleslocations={saleslocations}
                        formatMessage={formatMessage}
                        key={uuid()}
                        elixirIdsNo={elixirIds.length}
                        settlement={settlement}
                    />
                )}
            </div>
            {isShowMoreLink &&
                <Link
                    className="settlement-detail__more-details"
                    to={`${match.url}/${settlement.id}`} >
                    {link} {'>'}
                </Link>
            }
        </div>
    )
}

const SettlementDetailFooter = ({ settlement, formatMessage }) => {
    const footer1 = formatMessage(SETTLEMENT_DETAIL_PANEL_TEXT['settlement-detail-footer-1'])
    const footer2 = formatMessage(SETTLEMENT_DETAIL_PANEL_TEXT['settlement-detail-footer-2'])

    const { transfer_date, beneficiary_account } = settlement

    return (
        <div className="settlement-detail__footer">
            {`${footer1} ${beneficiary_account}. ${footer2} ${transfer_date}.`}
        </div>
    )
}



class SettlementDetails extends Component {
    constructor(props) {
        super(props)
    }
    downloadReport(date, report, ref) { // eslint-disable-line
        if (report.loading) return
        const locale = this.props.localization.get('localeCode')
        const merchantId = this.props.match.params.merchantId

        this.props.dispatch(
            reportActions.requestSettlementReport(
                merchantId,
                report.settlementId,
                report.format,
                report.type,
                locale
            )
        )
    }

    componentWillUnmount() {
        const { settlement } = this.props
        const merchantId = this.props.match.params.merchantId
        const merchant = this.props.merchant.get('merchants').find(x => x.id === merchantId)
        const reports = getReportsArr(this.props.reports, settlement, merchant)
        reports.forEach((report) => {
            if (report && report.report) {
                this.props.dispatch(reportActions.requestSettlementReportReset(report.report.settlementId, report.report.format, report.report.type, false))
            }
        })
    }

    render() {
        const { limit, settlement, match } = this.props
        const { formatMessage } = this.props.intl
        const merchantId = this.props.match.params.merchantId
        const merchant = this.props.merchant.get('merchants').find(x => x.id === merchantId)
        const reports = getReportsArr(this.props.reports, settlement, merchant)
        return (
            <div>
                <SettlementDetail
                    limit={limit}
                    settlement={settlement}
                    match={match}
                    merchant={merchant}
                    formatMessage={formatMessage}
                />
                <div className="flex-container settlement-detail__footer-wrapper">
                    <SettlementDetailFooter
                        settlement={settlement}
                        formatMessage={formatMessage}
                    />
                    <div className="settlement-detail__downloads flex-container">
                        {
                            reports.map(({ ref, report }) => <FileComponent
                                key={`settlement-report-${settlement.id}-${report.format}-${report.type}`}
                                ref={ref}
                                report={report}
                                formatMessage={formatMessage}
                                downloadReport={this.downloadReport.bind(this)}
                                date={null}
                                disabled={this.props.disableDownloadBtn}
                                disabledTooltipText={formatMessage(SETTLEMENT_DETAIL_PANEL_TEXT['settlement-detail-disabled-button-tooltip'])}
                            />)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        merchant: state.get('merchant'),
        localization: state.get('localization'),
        reports: state.getIn([activeMerchant, 'reports', 'settlementReports'])
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(SettlementDetails)))
