import { defineMessages } from 'react-intl'

export const ADD_USER_MODAL = defineMessages({
    'modal_header': {
        id: 'add-user-modal-header',
        defaultMessage: 'Add new user',
        description: 'Header for add user modal'
    },
    'modal_description': {
        id: 'add-user-modal-description',
        defaultMessage: 'Fill in the details below. An email will be sent with further instructions.',
        description: 'Description for add user modal'
    },
    'input_first_name': {
        id: 'first-name',
        defaultMessage: 'First name',
        description: 'Input label for first name'
    },
    'input_last_name': {
        id: 'last-name',
        defaultMessage: 'Last name',
        description: 'Input label for last name'
    },
    'input_email': {
        id: 'email',
        defaultMessage: 'Email',
        description: 'Input label for email'
    },
    'input_email_invalid': {
        id: 'merchant-profile-add-user-email-invalid',
        defaultMessage: 'Invalid email',
        description: 'Label for invalid email'
    },
    'input_role': {
        id: 'set-role',
        defaultMessage: 'Set Role',
        description: 'Input label for role'
    },
    'button_add_user': {
        id: 'merchant-profile-merchant-users-add-user-button',
        defaultMessage: 'Add user',
        description: 'Button label for add user'
    }
})

export const RENAME_USER_MODAL = defineMessages({
    'modal_header': {
        id: 'rename-user-modal-header',
        defaultMessage: 'Set user name',
        description: 'Header for rename user modal'
    },
    'modal_description': {
        id: 'rename-user-modal-description',
        defaultMessage: 'Fill in a first name and last name.',
        description: 'Description for rename user modal'
    },
    'input_first_name': {
        id: 'first-name',
        defaultMessage: 'First name',
        description: 'Input label for first name'
    },
    'input_last_name': {
        id: 'last-name',
        defaultMessage: 'Last name',
        description: 'Input label for last name'
    },
    'button_save': {
        id: 'Save',
        defaultMessage: 'Save',
        description: 'Button label for save'
    }
})

export const REMOVE_USER_MODAL = defineMessages({
    'modal_header': {
        id: 'remove-user-modal-header',
        defaultMessage: 'Remove user',
        description: 'Header for remove user modal'
    },
    'modal_description': {
        id: 'remove-user-modal-description',
        defaultMessage: 'The selected user will no longer be able to access this merchant.',
        description: 'Description for remove user modal'
    },
    'button_cancel': {
        id: 'cancel',
        defaultMessage: 'Cancel',
        description: 'Button label for cancel'
    },
    'button_remove': {
        id: 'remove',
        defaultMessage: 'Remove',
        description: 'Button label for remove'
    }
})
