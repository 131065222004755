import moment from 'moment-timezone'


export const parseLocalizedIsoString = (isoString) => {
    // TLDR: this method normalizes all iso datetimes to
    // the clients local datetime, but transforms iso UTC
    // datetimes into Europe/Stockholm first.

    // -----

    // Currently there is one dimension "missing" in the
    // dataset we get from the payment and settlement services.
    // A payment has the datetime of the transaction,
    // but the accompaning timezone of the payment location
    // is stored in the merchant service saleslocation, and is not
    // present in the payment object.

    // If a payment is made at local time 2017-01-19T09:00:00 in
    // Stockholm, we want to show the time of the transaction as
    // 09:00 to the end user regardless of the timezone of
    // the end user. If the end user is in the US some other timezone
    // they still see a transaction that happened at 09:00 (in Stockholm).

    // Currently all datetimes are converted into localized datetimes in the
    // client, therfore this method returns the time of the isoString as a
    // datetime in the clients local timezone. Yes, this is a bit weird...
    // Eg. the .getHours() of the returned date object will always
    // be 9 for a transaction witch happend at 2017-01-19T09:00:00
    // in the timezone of the payment.

    const splitted = isoString.split('+')
    if (splitted[1] && splitted[1] !== '00:00'){
        // If we get a datetime with proper (non UTC) timezone,
        // we discard the timezone offset and return a normalized
        // local datetime in the clients timezone.
        // Eg. an isoString like  2016-01-19T09:00:00+01:00 should
        // be showed to the end user as 09:00.
        return new Date(
            isoString.substr(0, 4),
            isoString.substr(5, 2) - 1,
            isoString.substr(8, 2),
            isoString.substr(11, 2),
            isoString.substr(14, 2),
            isoString.substr(17, 2)
        )
    }


    // Since the microservices for payment and settlement data reutrns
    // datetimes as UTC without any timezone information, We have to
    // assume that all datetimes wihtout UTC timezones should be
    // shown to the end user as if they occureed in Europe/Stockholm.
    // Eg.for the payement in the example above, the service would give
    // us the following iso datetime 2017-01-19T08:00:00+00:00, so we
    // convert that into 2017-01-19T09:00:00+01:00, and reurn a local
    // datetime with the same properties as the Europe/Stockholm datetime.

    const sthlmIso = moment.tz(isoString, 'Europe/Stockholm').format()
    return new Date(
        sthlmIso.substr(0,4),
        sthlmIso.substr(5,2) - 1,
        sthlmIso.substr(8,2),
        sthlmIso.substr(11,2),
        sthlmIso.substr(14,2),
        sthlmIso.substr(17,2)
    )
}

export const getFirstInNextMonthUTC = (date) => {
    const nextMonth = new Date(date)
    nextMonth.setUTCMonth(date.getUTCMonth() + 1)
    return getFirstInMonthUTC(nextMonth)
}

export const getFirstInMonthUTC = (date) => {
    let first = new Date(date)
    first.setUTCDate(1)
    first.setUTCHours(0, 0, 0, 0)
    return first
}

export const withinDateSpan = (nowDate, startDate, endDate) => {
    nowDate = new Date(nowDate)
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    const startMonth = new Date(startDate).getMonth()
    const endMonth = new Date(endDate).getMonth()
    const nowMonth = nowDate.getMonth()
    const nowYear = nowDate.getFullYear()
    const startMonths = months.slice(startMonth, 12)
    const endMonths = months.slice(0, endMonth + 1)
    let startYear = nowYear
    let endYear = nowYear

    // Check if date span stretches over new year
    if (endMonth < startMonth && (startMonths.includes(nowMonth) || endMonths.includes(nowMonth))) {
        startYear = startMonths.includes(nowMonth) ? nowYear : nowYear - 1
        endYear = endMonths.includes(nowMonth) ? nowYear : nowYear + 1
    }
    startDate = startDate.replace(', ', `, ${startYear} `)
    endDate = endDate.replace(', ', `, ${endYear} `)

    const start = new Date(startDate).getTime()
    const end = new Date(endDate).getTime()
    const now = nowDate.getTime()
    return now >= start && now <= end
}

export const dateIsBetweenDates = (date, startDate, endDate) => {
    return date.getTime() >= startDate.getTime() && date.getTime() < endDate.getTime()
}

const millisecondsPerMinute = 60000
export const getFirstDateOfYear = (date) => new Date(date.getFullYear(), 0, 1)
export const getFirstDateOfNextYear = (date) => new Date(date.getFullYear() + 1, 0, 1)
export const getFirstDateOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1)
export const getFirstDateOfNextMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 1)
export const formatDateToLocalIsoString = (date) => new Date(date.valueOf() - (date.getTimezoneOffset() * millisecondsPerMinute)).toISOString().substring(0, 10)
export const formatDatetimeToUtcIsoString = (date) => date.toISOString()
export const formatDateToUtcIsoString = (date) => date.toISOString().substring(0, 10)

export class DataAvailableFromDate {
    static instance = DataAvailableFromDate.instance ? DataAvailableFromDate.instance : new DataAvailableFromDate()
    constructor() {
        this._dataAvailableFromDate = null
    }

    setDate(from_date) {
        if (from_date && this._dataAvailableFromDate === null) {
            this._dataAvailableFromDate = new Date(from_date)
        }
    }

    getDate() {
        return this._dataAvailableFromDate
    }

    getDateIsoString() {
        return formatDateToLocalIsoString(this._dataAvailableFromDate)
    }

    getDatetimeIsoStringUTC() {
        return formatDatetimeToUtcIsoString(this._dataAvailableFromDate)
    }
}
export const getMinimumTransactionDate = (date) => date < DataAvailableFromDate.instance.getDate() ? DataAvailableFromDate.instance.getDate() : date
export const isDateAfterMinimumTransactionDate = (date) => DataAvailableFromDate.instance.getDate() < date

export const getTodayMidnight = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return today
}

export const createDateRange = (from, to) => {
    return { from, to }
}

export const isMaintenanceMode = (merchantId) => {
    const whitelist = [
        'ae90e8e3-2eda-47bb-aac8-03d92c91bf31', // Hua Long
        'f7fb5d49-9b4f-4bfc-8275-26225d96e1c5', // Epay
        'ed361245-a717-457b-8616-aa9feb818fdf' // acquiring@backoffice.com
    ]

    if (whitelist.includes(merchantId)) {
        return false
    }

    const activationTime = new Date('August 28, 2024, 10:00:00 GMT+0:00')
    const now = new Date()
    return now >= activationTime
}
