import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import iso4217 from 'currency-codes'

import * as actions from '../../actions'
import * as helpers from '../../helpers'
import BamboraSvgIcon from '../../../../common/components/bamboraSvgIcon'
import { getExponentedAmountString, stringWithExponentToInteger, validateExponent, cleanInput } from '../../../../common/amountHelpers'
import { refundInvoice, setRefundInvoiceExponent, setRefundInvoiceAmount, setRefundInvoiceChecked,
        setRefundInvoiceAmountValid, clearRefundInvoiceState } from '../../actions'
import Spinner from '../../../../common/components/spinner'
import NotFoundState from '../../../../common/components/notFoundState'

export class OnlineInnerInvoiceRefundModal extends Component {

    constructor(props) {
        super(props)

        if(props.authorization &&  props.authorization.order_information){
            const currencyCode = iso4217.code(props.authorization.order_information.currency)
            this.props.dispatch(clearRefundInvoiceState())
            this.props.dispatch(setRefundInvoiceExponent(currencyCode.digits))
        }
    }

    componentDidUpdate(prevState) {
        if (this.props.refundInvoice.get('isSuccess') && !prevState.refundInvoice.get('isSuccess')){
            this.timeout = setTimeout(()=>{
                this.hideOperationsModal()
            }, 500)
        }
    }

    // UI METHODS

    hideOperationsModal() {
        clearTimeout(this.timeout)
        this.props.dispatch(clearRefundInvoiceState())
        this.props.dispatch(actions.hideOperationsModal())
    }


    checkAll(checked){
        const orderLines = this.props.authorization.order_information.orderLines
        orderLines.forEach((line) => {
            if(this.getRefundableAmount(line) > 0){
                if(checked){
                    this.check(line)
                } else {
                    this.uncheck(line)
                }

            }
        })
    }

    check(line){
        this.checkOrderline(line)

        if(!this.getAmount(line.id)){
            const refundableAmount = this.getRefundableAmountString(line)
            this.props.dispatch(setRefundInvoiceAmount(line.id, refundableAmount))
        }

        this.makeOrderlineValid(line)
    }

    uncheck(line){
        this.uncheckOrderline(line)
        this.clearOrderLineValue(line)
        this.makeOrderlineValid(line)
    }

    refundSelected(){
        const operationBody = this.getOperationBody()
        const operationId = helpers.getOperationId(this.props.order)
        this.props.dispatch(
            refundInvoice(this.props.order.merchant_id, operationBody, this.props.operations.get('orderId'), operationId)
        )
    }

    // HELPER METHODS

    clearOrderLineValue(line){
        this.setOrderLineValue(line, '')
    }

    setOrderLineValue(line, value) {
        this.props.dispatch(setRefundInvoiceAmount(line.id, value))
    }

    makeOrderlineInValid(line) {
        this.props.dispatch(setRefundInvoiceAmountValid(line.id, false))

    }
    makeOrderlineValid(line) {
        this.props.dispatch(setRefundInvoiceAmountValid(line.id, true))
    }

    getAmount(id, key='amount'){
        return this.props.refundInvoice.getIn(['amounts', id, key])
    }

    checkOrderline(line) {
        this.props.dispatch(setRefundInvoiceChecked(line.id, true))
    }

    uncheckOrderline(line) {
        this.props.dispatch(setRefundInvoiceChecked(line.id, false))
    }

    isChecked(id){
        return this.props.refundInvoice.getIn(['checked', id])
    }


    validateInput(line, input) {
        const cleaned = cleanInput(input)
        const handled = stringWithExponentToInteger(cleaned, this.props.refundInvoice.get('exponent'))
        const regex = /^\d*[\.]?\d+$/
        const isValid = (regex.test(cleaned) && parseFloat(cleaned) > 0
                                             && validateExponent(cleaned, this.props.refundInvoice.get('exponent'))
                                             && this.getRefundableAmount(line) >= handled)
        return isValid

    }

    areAllOrderlinesValid(){
        const amounts = this.props.refundInvoice.get('amounts').toJS()
        for(const key in amounts){ // eslint-disable-line
            const isChecked = this.isChecked(key)
            const isValid = amounts[key]['isValid']
            if(!isValid && isChecked){
                return false
            }
        }
        return true
    }

    isButtonDisabled(){
        return !this.areAllOrderlinesValid() || this.props.refundInvoice.get('total') <= 0
    }

    areAllOrdelinesChecked(orderLines){
        for (const line of orderLines){ // eslint-disable-line
            const isRefundable = this.getRefundableAmount(line) > 0
            const isChecked = this.props.refundInvoice.getIn(['checked', line.id])

            if(!isChecked && isRefundable)
                return false
        }
        return true
    }

    getRefundableAmount(line){
        const refundedAmount = this.getRefundedAmountForOrderLine(line.id)
        return line.unitPrice * line.quantity - refundedAmount
    }

    getRefundableAmountString(line){
        const amount = this.getRefundableAmount(line)
        const str = getExponentedAmountString(amount, this.props.refundInvoice.get('exponent'))
        return str || ''
    }


    getRefundedAmountForOrderLine(id){
        if(this.props.order){
            return helpers.getRefundedAmountForOrderLine(this.props.order, id)
        }
        return 0
    }

    getOperationBody() {
        let orderLines = []
        this.props.refundInvoice.get('amounts').map((item, id) => {
            const amount = stringWithExponentToInteger(item.get('amount'), this.props.refundInvoice.get('exponent'))
            if(amount){
                orderLines.push({
                    amount,
                    id,
                    reason: 'Refund'
                })
            }
        })

        const paymentRouterObject = this.props.operations.get('order')
        const currencyCode = helpers.getCurrency(paymentRouterObject)
        let operationBody = {
            amount:  this.props.refundInvoice.get('total'),
            currency: currencyCode,
            order_lines: orderLines
        }
        return operationBody
    }

    setAmount(line, value){

        if(value){
            this.checkOrderline(line)
            this.setOrderLineValue(line, value)
        } else {
            this.uncheckOrderline(line)
            this.clearOrderLineValue(line)
        }

        const isValid = this.validateInput(line, value)

        if(isValid) {
            this.makeOrderlineValid(line)
        } else {
            this.makeOrderlineInValid(line)
        }
    }

    setToRefundableAmountIfEmpty(line) {
        const amount = this.getAmount(line.id)
        if(!amount){
            const refundableAmountString = this.getRefundableAmountString(line)
            this.setAmount(line, refundableAmountString)
        }
    }

    // RENDER METHODS

    renderWrapper(content){
        const waiting = this.props.refundInvoice.get('isFetching')
        return <div className="modal-wrapper">
            <div className="backdrop" onClick={() => waiting || this.hideOperationsModal()}></div>
            <div className="operations operations-modal invoice" role="dialog">
                <span className="close-operation" onClick={() => waiting || this.hideOperationsModal()}><BamboraSvgIcon icon="close" /></span>
                {content}
            </div>
        </div>
    }

    renderOrderlines(orderLines) {
        return (
            <div>
                { orderLines.map((line) => {
                    const refundableAmountString = this.getRefundableAmountString(line)
                    const refundableAmount = this.getRefundableAmount(line)
                    const isRefundable = refundableAmount > 0
                    const isValid = this.getAmount(line.id, 'isValid')
                    const isChecked =  this.props.refundInvoice.getIn(['checked', line.id])
                    const cssClass = !isValid && isChecked ? 'invalid' : ''
                    const htmlId = `checkbox-${line.id}`
                    const amount = this.getAmount(line.id)
                    return (
                        <div className="orderline" key={line.id}>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="checkbox">
                                { isChecked ?
                                    <input disabled={!isRefundable} checked type="checkbox" id={htmlId} onChange={() => {
                                        this.uncheck(line)
                                    }} /> :
                                    <input disabled={!isRefundable}  type="checkbox" id={htmlId} onChange={() => {
                                        this.check(line)
                                    }
                                }/>
                            }
                                <label htmlFor={htmlId} className="orderline-description">
                                    {`${line.quantity} x ${line.description}`}
                                </label>
                                    <input className={`element-column amount ${cssClass}`}
                                        type="text"
                                        disabled={!isRefundable}
                                        placeholder={refundableAmountString}
                                        onClick={() => this.setToRefundableAmountIfEmpty(line)}
                                        maxLength="15"
                                        onChange={(e) => { this.setAmount(line, e.target.value)}}
                                        defaultValue=""
                                        value={amount}
                                    />
                            </div>

                        </form>
                    </div>
                    )}
                    )}
            </div>
        )
    }

    render() {
        if(!this.props.authorization || !this.props.authorization.order_information){
            return null
        }
        const orderLines = this.props.authorization.order_information.orderLines
        const success = this.props.refundInvoice.get('isSuccess')
        const waiting = this.props.refundInvoice.get('isFetching')
        const allChecked = this.areAllOrdelinesChecked(orderLines)
        const exponent = this.props.refundInvoice.get('exponent')
        const total = this.props.refundInvoice.get('total')
        const totalValid = this.areAllOrderlinesValid()
        const totalAsString = getExponentedAmountString(total, exponent)

        if(waiting || success){
            return this.renderWrapper(
                <div className="operations-state-change action">
                    <Spinner waiting={waiting} success={success} />
                </div>
            )
        }

        const error = this.props.refundInvoice.get('isError')

        if(error){
            return this.renderWrapper(
                <NotFoundState>
                    <div className="text-center">
                    <FormattedMessage
                            id="invoice-modal-refund-error"
                            defaultMessage="Refund failed"
                            description="Refund failed error message"
                        />
                    </div>
                </NotFoundState>
            )
        }

        return this.renderWrapper(
            <div className="orderline-modal">
                <h3><FormattedMessage
                    id="order-operation-modal-refund-invoice-title"
                    defaultMessage="Refund"
                    description="Refund invoice amount modal title"
                /></h3>
                { this.renderOrderlines(orderLines, exponent) }
                {!totalValid &&
                    <label htmlFor="order-operation-amount" className="invalid-message">
                        <FormattedMessage
                            id="invoice-refund-modal-input-invalid"
                            defaultMessage="Invalid amount. Try again."
                            description="Input validation error message for capture/refund amount"
                        />
                    </label>
                }
                <div className="divider"></div>
                <div className="orderline-total">
                    <div className="orderline-total-title">
                        <FormattedMessage
                            id="invoice-refund-modal-total-refund"
                            defaultMessage={'Total Refund'}
                            description={'Invoice refund modal total refund title'}
                        />
                        </div>
                    <div className="orderline-total-amount">{totalValid ? totalAsString: ''}</div>
                </div>
                <div className="orderline-check-all">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="checkbox refund-all">
                            <input type="checkbox" id="refund-all" className="refund-all-checkbox" onChange={(e) => { this.checkAll(e.target.checked)}} checked={allChecked}/>
                            <label className="test" htmlFor="refund-all">
                                    <FormattedMessage
                                    id="invoice-refund-modal-select-all-label"
                                    defaultMessage="Refund all items"
                                    description="Refund all items checkbox label"
                                />
                            </label>
                        </div>
                    </form>
                </div>
                <button className="btn btn-small pull-right" disabled={this.isButtonDisabled()} onClick={() => {
                    this.refundSelected()
                }}><FormattedMessage
                    id="invoice-refund-modal-refund-selected-button"
                    defaultMessage="Refund selected"
                    description="Refund selected items button text"
                /></button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        operations: state.getIn([activeMerchant, 'online','operations']),
        orders: state.getIn([activeMerchant, 'online']),
        order: state.getIn([activeMerchant, 'online', 'current', 'data']),
        refundInvoice: state.getIn([activeMerchant, 'online', 'refundInvoice'])
    }
}


class OnlineInvoiceRefundModal extends Component {
    // The reason for this is to make it easier to test
    render() {
        return <OnlineInnerInvoiceRefundModal {...this.props} />
    }
}

export default connect(mapStateToProps)(OnlineInvoiceRefundModal)
