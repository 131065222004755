import { useInfiniteQuery, useQuery } from 'react-query'
import { callApi } from './reactQuery'

export const getTerminalsQuery = (apiHost, merchantId, pageSize, withPassword = false) => {
    const resource = withPassword ? 'terminalswithpassword' :  'terminals'

    return useInfiniteQuery(
        'terminals',
        async ({ pageParam = 0 }) => {
            return await callApi({ url: `${apiHost}/v1/merchants/${merchantId}/instore/${resource}?limit=${pageSize}&offset=${pageParam}` })
                .then(response => response.data)
        },
        { getNextPageParam: (_, pages) => pages.length }
    )
}

export const getTerminalModelsQuery = (apiHost, merchantId, modelId) => {
    return useQuery(
        'terminalModels',
        async () => {
            return await callApi({ url: `${apiHost}/v1/merchants/${merchantId}/instore/terminals?model_id=${modelId}` })
                .then(response => response.data)
        }
    )
}
