import { defineMessages } from 'react-intl'

export const DASHBOARD_TRANSLATIONS = defineMessages({
    'year': {
        id: 'dashboard-year',
        defaultMessage: 'Year',
        description: 'year'
    },
    'month': {
        id: 'dashboard-month',
        defaultMessage: 'Month',
        description: 'month'
    },
    'payouts': {
        id: 'dashboard-payouts',
        defaultMessage: 'Payouts',
        description: 'Payouts'
    },
    'payouts_in': {
        id: 'dashboard-payouts-in',
        defaultMessage: 'Payouts in',
        description: 'Payouts in (payouts in SEK)'
    },
    'latest_payout': {
        id: 'dashboard-latest-payout',
        defaultMessage: 'Latest payout',
        description: 'Latest payout'
    },
    'payouts_this_month': {
        id: 'dashboard-this-month',
        defaultMessage: 'Payouts this month',
        description: 'Payouts this month'
    },
    'payouts_this_year': {
        id: 'dashboard-this-year',
        defaultMessage: 'Payouts this year',
        description: 'Payouts this year'
    },
    'payouts_not_available': {
        id: 'dashboard-payouts-not-available',
        defaultMessage: 'Not available',
        description: 'Not available'
    },
    'loan_not_activated': {
        id: 'loan_not_activated',
        defaultMessage: 'Not activated',
        description: 'Not activated'
    },
    'loans': {
        id: 'dashboard-loan-header',
        defaultMessage: 'Loans',
        description: 'Loans'
    },
    'available_credit': {
        id: 'dashboard-available-credit',
        defaultMessage: 'Available credit',
        description: 'Available credit'
    },
    'used_credit': {
        id: 'dashboard-used-credit',
        defaultMessage: 'Used credit',
        description: 'Used credit'
    },
    'loan_paragraph_no_loan': {
        id: 'loan_paragraph_no_loan',
        defaultMessage: 'With our credit solution you will get easy access to money with fixed price and are automatically paid back',
        description: 'growth finance paragraph'
    },
    'loan_paragraph_active_loan': {
        id: 'loan_paragraph_active_loan',
        defaultMessage: 'Your Growth Finance loan',
        description: 'Your growth finance'
    }
})
export const DROPDOWNS = defineMessages({
    'filter_placeholder': {
        id: 'dropdown_filter_placeholder_text',
        defaultMessage: 'Search',
        description: 'Dropdown filter placeholder text'
    },
    'clear_selections': {
        id: 'dropdown_clear_selections',
        defaultMessage: 'Clear',
        description: 'Clear multi-selections in Dropdowns'
    }
})
