import React from 'react'
import uuid from 'uuid'
import MonetaryAmount from '../../../common/components/monetaryAmount'
import { ORDER_STATES, MODAL_OPERATIONS } from '../constants'
import RoleToggle from '../../../common/containers/roleToggle'
import { ROLES } from '../../../merchant/constants'
import { getMerchantReferenceOrder } from '../helpers'
import { ONLINE_TRANSACTION_STATES_TEXT, STATE_CAPTURE_INACTIVE_VIEW } from '../../sales/constants'
import { calculateState } from '../helpers'
import { getIconKey } from '../../sales/helpers'
import moment from 'moment'
import { getLastFourDigits } from '../../../common/cardHelpers'
import { isNewOperationFlow } from '../helpers'


const CAN_EDIT = [ROLES.OWNER, ROLES.ADMIN]

const NONE_ACTIONABLE_STATES = [ORDER_STATES.DECLINED, ORDER_STATES.REJECTED, ORDER_STATES.CANCELED]

const OnlineListItem = ({ order, formatMessage, onClick, showOperationsModal, merchant }) => {
    const merchantReference = getMerchantReferenceOrder(order)
    const icon = order.wallet || order.payment_type
    const amountClass = NONE_ACTIONABLE_STATES.includes(calculateState(order)) ? ' canceled-line' : ''
    const state = calculateState(order)
    const cardNumber = order.card_number
    let lastFourDigits = ''

    if (cardNumber) {
        lastFourDigits = getLastFourDigits(cardNumber)
    }

    return (
        <div className="online-list-item" key={uuid.v4()} onClick={onClick}>
            <span className="element-column date-time">
                {moment(order.created_local).format('YYYY-MM-DD HH:mm:ss')}
            </span>
            <span className="element-column merchant-reference">
                {merchantReference}
            </span>
            <span className="element-column payment-method">
                <div className={`new-card-icons ${icon && getIconKey(icon.toLowerCase())}`}></div>
                {lastFourDigits}
            </span>
            <span className="element-column currency">
                {order.currency}
            </span>
            <span className={`element-column amount ${amountClass}`}>
                <MonetaryAmount amount={order.authorized_amount} amount_exponent={order.amount_exponent} className="fg-darkest" hideInsignificantDecimals={false} />
            </span>
            <span className={`element-column refunded ${amountClass}`}>
                <MonetaryAmount amount={order.refunded_amount} amount_exponent={order.amount_exponent} className="fg-darkest" hideInsignificantDecimals={false} />
            </span>
            {state === ORDER_STATES.ZERO_AUTH ?
                <span className={`element-column refunded ${amountClass}`}>
                    <MonetaryAmount
                        amount={order.authorized_amount}
                        amount_exponent={order.amount_exponent}
                        className="fg-darkest"
                        hideInsignificantDecimals={false} />
                </span> :
                renderCaptureActionState(state, order, formatMessage, showOperationsModal, merchant)
            }
        </div>
    )
}

const renderCaptureActionState = (state, order, formatMessage, showOperationsModal, merchant) => {
    const actionClass = 'captured'
    const view = STATE_CAPTURE_INACTIVE_VIEW[state]
    if (NONE_ACTIONABLE_STATES.includes(state)) {
        return (
            <span className="element-column state">
                {formatMessage(ONLINE_TRANSACTION_STATES_TEXT[state.toLowerCase()])}
            </span>
        )
    }

    return renderColumnWithAction(actionClass, view, order, MODAL_OPERATIONS.CAPTURE, showOperationsModal, formatMessage, merchant)
}

const renderColumnWithAction = (actionClass, view, order, operation, showOperationsModal, formatMessage, merchant) => {
    let displayButton = view.button ? 'show' : 'hide'
    let displayAmount = view.amount ? 'show' : 'hide'

    const shouldToggle = isNewOperationFlow(order.acquirer_name) ? false : view.shouldToggle
    const isLindorff = order && order.acquirer_name && order.acquirer_name.toLowerCase() === 'lindorff'

    if (shouldToggle) {
        displayButton = displayButton + ' toggle'
        displayAmount = displayAmount + ' toggle'
    }

    const isSupportalUser = merchant.loggedInUserIsSupport

    return (
        <span className={`element-column ${actionClass}`}>
            <span className="action-button">
                {!isSupportalUser && !isLindorff && <RoleToggle allow={CAN_EDIT}>
                    <button
                        style={{float: 'right'}}
                        className={`action rebranded-secondary-btn ${displayButton}`}
                        onClick={(e) => { showOperationsModal(e, order, operation) }}>
                        {formatMessage(ONLINE_TRANSACTION_STATES_TEXT[actionClass])}
                    </button>
                </RoleToggle>}
            </span>
            <span className={`action-amount ${displayAmount}`}>
                {order[actionClass + '_amount'] ?
                    <MonetaryAmount
                        amount={order[actionClass + '_amount']}
                        amount_exponent={order.amount_exponent}
                        className="fg-darkest"
                        hideInsignificantDecimals={false}
                    /> :
                    '-'
                }
            </span>
        </span>
    )
}

export default OnlineListItem
