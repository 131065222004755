
function checkInStickyBounds(headersContainer, top=75) {
    const bounds = headersContainer.getBoundingClientRect()
    return top > bounds.top && bounds.bottom < 105
}

export function handleScroll(headerSelector, headerContainerSelector, top) {
    try {
        let headers = document.querySelector(headerSelector)
        let headersContainer = document.querySelector(headerContainerSelector)

        const headersClassArr = headers.className.split(' sticky')
        const isSticky = headersClassArr.length > 1

        top = Number.isInteger(top)?top:75

        const inStickyBounds = checkInStickyBounds(headersContainer, top)

        if (inStickyBounds && !isSticky) {
            headers.className = headers.className + ' sticky'
        }
        if (!inStickyBounds && isSticky) {
            headers.className = headersClassArr[0]
        }
    } catch (error) {
        return
    }
}
