import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ORDER_OPERATION_MODAL } from '../translations'
import Spinner from '../../../common/components/spinner'
import NotFoundState from '../../../common/components/notFoundState'
import OrderOperationComponent from './OrderOperationComponent'
import BamboraSvgIcon from '../../../common/components/bamboraSvgIcon'
import './orderOperationModal.scss'

/* NOTE: This is use for the "new" CollectorBank API */

const OperationContent = (props) => {
    const { isSuccess, isFetching, isError } = props

    if (isFetching || isSuccess) {
        return <Spinner waiting={isFetching} success={isSuccess} />
    }

    if (isError) {
        return <NotFoundState>
            {props.formatMessage(ORDER_OPERATION_MODAL[`${props.operationName}_error`])}
        </NotFoundState>
    }

    return <OrderOperationComponent {...props} />
}

const OrderOperationModal = (props) => {
    useEffect(() => {
        if (props.isSuccess) {
            setTimeout(() => {
                props.onHide()
            }, 500)
        }
    }, [props.isSuccess])

    const currentOperationAction = props.operations.get('action')

    return (
        <Modal
            className="order-operation-modal"
            show={currentOperationAction && currentOperationAction.toLowerCase() === props.operationName}
            onHide={props.onHide}
        >
            <Modal.Header>
                <h3>{props.formatMessage(ORDER_OPERATION_MODAL[props.operationName])}</h3>
                <div className="close-modal" onClick={props.onHide}>
                    <BamboraSvgIcon className="fill-inherit" icon={'close-thin'} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <OperationContent {...props} />
            </Modal.Body>
        </Modal>
    )
}

export default OrderOperationModal
