import { defineMessages } from 'react-intl'

export const NAME = 'settlements'

export const NUMBER_OF_DEFAULT_FILTERS = 5
export const SETTLEMENT_DETAILS_LIMIT = 4
export const SALES_ADJUSTMENT_TYPE = '2'

export const TRANSACTION_STATES = {
    ORIGINAL_CREDIT: 'Original credit',
    CASH_ADVANCE: 'Cash advance',
    SALE: 'Sale',
    INVALID: 'Invalid',
    AUTHORIZED: 'Authorized',
    CAPTURED: 'Captured',
    REFUNDED: 'Refunded',
    REJECTED: 'Rejected',
    PARTL_CAPTURE_REFUND: 'Part captured/refunded'
}

export const TRANSACTION_TABLE_HEADER = defineMessages({
    'date': {
        id: 'transaction-list-header-date',
        defaultMessage: 'Date',
        description: 'Settlements list header'
    },
    'time': {
        id: 'transaction-list-header-time',
        defaultMessage: 'Time',
        description: 'Settlements list header'
    },
    'ref': {
        id: 'transaction-list-header-ref',
        defaultMessage: 'Reference',
        description: 'Settlements list header'
    },
    'payment-method': {
        id: 'acquiring-list-header-payment-method',
        defaultMessage: 'Payment',
        description: 'Payment method list header (acquiring)'
    },
    'status': {
        id: 'transaction-list-header-status',
        defaultMessage: 'Status',
        description: 'Acquiring list header for status'
    },
    'amount': {
        id: 'transaction-list-header-amount',
        defaultMessage: 'Amount',
        description: 'Settlements list header'
    },
    'currency': {
        id: 'transaction-list-header-currency',
        defaultMessage: 'Currency',
        description: 'Transaction list header currency'
    },
    'type': {
        id: 'transaction-list-header-type',
        defaultMessage: 'Type',
        description: 'Settlements list header'
    }
})

export const TRANSACTION_TYPE = defineMessages({
    'sale': {
        id: 'transaction-list-type-sale',
        defaultMessage: 'Purchase',
        description: 'Transacion list, type of transaction, sale'
    },
    'refund': {
        id: 'transaction-list-type-refund',
        defaultMessage: 'Refund',
        description: 'Transacion list, type of transaction, refund'
    },
    'original-credit': {
        id: 'transaction-list-original-credit',
        defaultMessage: 'Advance',
        description: 'Transacion list, type of transaction, original credit'
    },
    'cash-advance': {
        id: 'transaction-list-cash-advance',
        defaultMessage: 'Original credit',
        description: 'Transacion list, type of transaction, cash advance'
    },
    'invalid': {
        id: 'transaction-list-sale-rejected',
        defaultMessage: 'Rejected',
        description: 'Transacion list, type of transaction, rejected'
    }
})

export const TRANSACTION_STATUS = defineMessages({
    'paid': {
        id: 'acquiring-list-status-paid',
        defaultMessage: 'Paid',
        description: 'Acquiring list status'
    },
    'pending': {
        id: 'acquiring-list-status-pending',
        defaultMessage: 'pending',
        description: 'Acquiring list status'
    }
})

export const TEXT = defineMessages({
    transactions: {
        id: 'TRANSACTIONS',
        defaultMessage: 'Transactions',
        description: 'Transactions'
    }
})

export const SETTLEMENT_TYPES = {
    GROSS_TRANSACTIONLESS: 'gross_withdrawal',
    GROSS_SETTLEMENT: 'gross_settlment',
    NET_SETTLEMENT: 'net_settlment'
}

export const SETTLEMENT_FILTER_MAPPING = {
    'saleslocation': {
        key: 'source_merchant_ids',
        selected: 'selectedSourceMerchantIds',
        elem: 'saleslocationMenuOpened',
        hidden: 'currencyFilterOpened'
    },
    'currencies': {
        key: 'currencies',
        elem: 'currencyFilterOpened',
        selected: 'selectedCurrencies',
        hidden: 'saleslocationMenuOpened'
    }
}

export const ADJUSTMENT_TYPE = ['adjustment', 'adjustments']

export const ADJUSTMENT_MAP = {
    '1': 'msc_daily', // Commission
    '14': 'monthly_fee', // Fixed monthly charge
    '15': 'msc_monthly', // Commission, monthly
    '34': 'setup_fee' // Setup fee
}

export const MSC_FEE_TYPES = [
    'msc_monthly',
    'msc_daily',
    'msc',
    'monthly_fee'
]

//groups of reason_code or subType with same UI description
export const reasonGroups = [
    ['3', '16', '22', '37'], // Chargeback
    ['4', '7', '8', '9', '10', '11', '20', '21'], // Correction
    ['5', '17', '38'], // Service fees correction- Chargeback
    ['1', '39'], // Service fees
    ['18', '19'], // Settlement DCC
    ['35', '36'], // Settlement fee
    ['33', '44', '45', '46', '47', 'SUBTYPE_VAT_SWE', 'SUBTYPE_VAT_DNK', 'SUBTYPE_VAT_FIN', 'SUBTYPE_VAT_NOR'] // VAT 25%
]

export const LONG_REF_STYLE_THRESHOLD = 14

export const SUBTYPE_MAPPING = {
    subtype_transaction_fee: 'SUBTYPE_TRX_FEE',
    subtype_hardware_fee_wireless: 'SUBTYPE_TERMINAL_FEE_WIRELESS',
    subtype_hardware_fee_wired: 'SUBTYPE_TERMINAL_FEE_WIRED',
    subtype_hardware_fee_integrated: 'SUBTYPE_TERMINAL_FEE_INTEGRATED',
    subtype_paper_statement: 'SUBTYPE_PAPER_FEE',
    subtype_minimum_fee_acq: 'SUBTYPE_MIN_FEE',
    subtype_minimum_fee_pci: 'SUBTYPE_MIN_FEE_PCI',
    subtype_monthly_fee: 'SUBTYPE_MONTHLY_FEE_CHECKOUT',
    subtype_chargeback_fee: 'SUBTYPE_CB_FEE',
    subtype_integrity_fee: 'SUBTYPE_INT_FEE',
    subtype_discount_fee: 'SUBTYPE_DISCOUNT',
    subtype_discount_startup_fee: 'SUBTYPE_DISCOUNT_STARTUP',
    subtype_vat_swe: 'SUBTYPE_VAT_SWE',
    subtype_vat_dnk: 'SUBTYPE_VAT_DNK',
    subtype_vat_nor: 'SUBTYPE_VAT_NOR',
    subtype_vat_fin: 'SUBTYPE_VAT_FIN',
    subtype_growth_finance_froda: 'SUBTYPE_GROWTH_FINANCE_FRODA',
    subtype_growth_finance_external_provider_payout: 'SUBTYPE_GROWTH_FINANCE_EXTERNAL_PROVIDER_PAYOUT',
    subtype_growth_finance_repayment: 'SUBTYPE_GROWTH_FINANCE_REPAYMENT',
    subtype_monthly_fee_swish: 'SUBTYPE_MONTHLY_FEE_COSWISH',
    subtype_txn_fee_swish: 'SUBTYPE_TRX_FEE_COSWISH',
    subtype_txn_fee_mobilepay: 'SUBTYPE_TRX_FEE_MOBILEPAY',
    subtype_txn_fee_vipps: 'SUBTYPE_TRX_FEE_VIPPS',
    subtype_bambora_connect_terminal_fee_wireless: 'SUBTYPE_BAMBORA_CONNECT_TERMINAL_FEE_WIRELESS',
    subtype_bambora_connect_terminal_fee_wired: 'BAMBORA_CONNECT_TERMINAL_FEE_WIRED',
    subtype_bambora_connect_freight: 'BAMBORA_CONNECT_FREIGHT',
    subtype_referral_bonus: 'REFERRAL_BONUS',
    subtype_discount_checkout: 'DISCOUNT_CHECKOUT',
    subtype_discount_checkout_fee: 'DISCOUNT_CHECKOUT_FEE',
    subtype_txn_fee_checkout_dankort: 'TRX_FEE_CHECKOUT_DANKORT',
    subtype_txn_fee_checkout_moto: 'TRX_FEE_CHECKOUT_MOTO',
    subtype_txn_fee_checkout_paymentreq: 'TRX_FEE_CHECKOUT_PAYMENTREQ',
    subtype_txn_fee_checkout_googlepay: 'TRX_FEE_CHECKOUT_GOOGLEPAY',
    subtype_txn_fee_checkout_applepay: 'TRX_FEE_CHECKOUT_APPLEPAY',
    subtype_txn_checkout: 'TRX_FEE_CHECKOUT_TRX',
    subtype_txn_fee_checkout_declined: 'TRX_FEE_CHECKOUT_DECLINED_TRX',
    subtype_txn_fee_instoreswish: 'TRX_FEE_INSTORESWISH',
    subtype_api_fee_checkout_apireq: 'API_FEE_CHECKOUT_APIREQ',
    subtype_monthly_fee_checkout_directbanking: 'MONTHLY_FEE_CHECKOUT_DIRECTBANKING',
    subtype_monthly_fee_checkout_mobilepay: 'MONTHLY_FEE_CHECKOUT_MOBILEPAY',
    subtype_monthly_fee_checkout_other: 'MONTHLY_FEE_CHECKOUT_OTHER',
    subtype_monthly_fee_checkout_paybylink: 'MONTHLY_FEE_CHECKOUT_PAYBYLINK',
    subtype_monthly_fee_checkout_token: 'MONTHLY_FEE_CHECKOUT_TOKEN',
    subtype_monthly_fee_checkout_url: 'MONTHLY_FEE_CHECKOUT_URL',
    subtype_monthly_fee_checkout_users: 'MONTHLY_FEE_CHECKOUT_USERS',
    subtype_monthly_fee_checkout_vipps: 'MONTHLY_FEE_CHECKOUT_VIPPS',
    subtype_sch_fee_mc_integrity: 'SCH_FEE_MC_INTEGRITY',
    subtype_sch_fee_mc_mrp:'SCH_FEE_MC_MRP',
    subtype_sch_fee_mc_reattempts: 'SCH_FEE_MC_REATTEMPTS',
    subtype_sch_fee_mc_singletap: 'SCH_FEE_MC_SINGLETAP',
    subtype_sch_fee_visa_integrity: 'SCH_FEE_VISA_INTEGRITY',
    subtype_sch_fee_visa_prm: 'SCH_FEE_VISA_PRM',
    subtype_sch_fee_visa_reattempts: 'SCH_FEE_VISA_REATTEMPTS',
    subtype_txn_fee_checkout_cardverification: 'TXN_FEE_CHECKOUT_CARDVERIFICATION',
    subtype_txn_fee_checkout_other: 'TXN_FEE_CHECKOUT_OTHER',
    subtype_txn_fee_checkout_subscription: 'TXN_FEE_CHECKOUT_SUBSCRIPTION',
    subtype_txn_fee_checkout_token: 'TXN_FEE_CHECKOUT_TOKEN',
    subtype_txn_fee_directbanking: 'TXN_FEE_DIRECTBANKING',
    subtype_sch_fee_visa_low_funds: 'SCH_FEE_VISA_LOW_FUNDS',
    subtype_sch_fee_mc_abu_mdes: 'SCH_FEE_MC_ABU_MDES'
}

export const ADJUSTMENT_TEXT = defineMessages({
    chargebacks: {
        id: 'fee-title-component-chargebacks',
        defaultMessage: 'Chargebacks',
        description: 'adjustment description for chargebacks'
    },
    monthly_fee: {
        id: 'fee-title-component-monthly',
        defaultMessage: 'Monthly fee',
        description: 'adjustment description for monly fee'
    },
    set_up: {
        id: 'fee-title-component-setup',
        defaultMessage: 'Set up',
        description: 'adjustment description for service charge'
    },
    service_charge: {
        id: 'fee-title-component-service-chages',
        defaultMessage: 'Service charges',
        description: 'adjustment description for service charge'
    },
    reason1: {
        id: 'reason-1',
        defaultMessage: 'Service fees',
        description: 'adjustment description 1'
    },
    reason2: {
        id: 'reason-2',
        defaultMessage: 'Settlement',
        description: 'adjustment description 2'
    },
    reason3: {
        id: 'reason-3',
        defaultMessage: 'Chargeback',
        description: 'adjustment description 3'
    },
    reason4: {
        id: 'reason-4',
        defaultMessage: 'Correction',
        description: 'adjustment description 4'
    },
    reason5: {
        id: 'reason-5',
        defaultMessage: 'Service fees correction - Chargeback',
        description: 'adjustment description 5'
    },
    reason6: {
        id: 'reason-6',
        defaultMessage: 'Service fees correction',
        description: 'adjustment descriptionn 6'
    },
    reason7: {
        id: 'reason-7',
        defaultMessage: 'Correction',
        description: 'adjustment description 7'
    },
    reason8: {
        id: 'reason-8',
        defaultMessage: 'Correction',
        description: 'adjustment description 8'
    },
    reason9: {
        id: 'reason-9',
        defaultMessage: 'Correction',
        description: 'adjustment description 9'
    },
    reason10: {
        id: 'reason-10',
        defaultMessage: 'Correction',
        description: 'adjustment description 10'
    },
    reason11: {
        id: 'reason-11',
        defaultMessage: 'Correction',
        description: 'adjustment description 11'
    },
    reason12: {
        id: 'reason-12',
        defaultMessage: 'Safety reserve - Withheld funds',
        description: 'adjustment description 12'
    },
    reason13: {
        id: 'reason-13',
        defaultMessage: 'Safety reserve - Repaid funds',
        description: 'adjustment description 13'
    },
    reason14: {
        id: 'reason-14',
        defaultMessage: 'Monthly fee',
        description: 'adjustment description 14'
    },
    reason15: {
        id: 'reason-15',
        defaultMessage: 'Monthly service fees',
        description: 'adjustment description 15'
    },
    reason16: {
        id: 'reason-16',
        defaultMessage: 'Chargeback',
        description: 'adjustment description 16'
    },
    reason17: {
        id: 'reason-17',
        defaultMessage: 'Service fees correction - Chargeback',
        description: 'adjustment description 17'
    },
    reason18: {
        id: 'reason-18',
        defaultMessage: 'Settlement DCC',
        description: 'adjustment description 18'
    },
    reason19: {
        id: 'reason-19',
        defaultMessage: 'Settlement DCC',
        description: 'adjustment description 19'
    },
    reason20: {
        id: 'reason-20',
        defaultMessage: 'Correction',
        description: 'adjustment description 20'
    },
    reason21: {
        id: 'reason-21',
        defaultMessage: 'Correction',
        description: 'adjustment description 21'
    },
    reason22: {
        id: 'reason-22',
        defaultMessage: 'Chargeback',
        description: 'adjustment description 22'
    },
    reason23: {
        id: 'reason-23',
        defaultMessage: 'DCC Commission',
        description: 'adjustment description 23'
    },
    reason24: {
        id: 'reason-24',
        defaultMessage: 'Monthly fee SEB payment service',
        description: 'adjustment description 24'
    },
    reason26: {
        id: 'reason-26',
        defaultMessage: 'Monthly fee Defender',
        description: 'adjustment description 26'
    },
    reason28: {
        id: 'reason-28',
        defaultMessage: 'Monthly fee Nordic currencies',
        description: 'adjustment description 28'
    },
    reason30: {
        id: 'reason-30',
        defaultMessage: 'Monthly fee internet bank payments',
        description: 'adjustment description 30'
    },
    reason32: {
        id: 'reason-32',
        defaultMessage: 'Service fee SEB Payment service',
        description: 'adjustment description 32'
    },
    reason33: {
        id: 'reason-33',
        defaultMessage: 'VAT 25%',
        description: 'adjustment description 33'
    },
    reason34: {
        id: 'reason-34',
        defaultMessage: 'Setup fee',
        description: 'adjustment description 34'
    },
    reason35: {
        id: 'reason-35',
        defaultMessage: 'Settlement fee',
        description: 'adjustment description 35'
    },
    reason36: {
        id: 'reason-36',
        defaultMessage: 'Settlement fee',
        description: 'adjustment description 36'
    },
    reason37: {
        id: 'reason-37',
        defaultMessage: 'Chargeback',
        description: 'adjustment description 37'
    },
    reason38: {
        id: 'reason-38',
        defaultMessage: 'Service fees correction - Chargeback',
        description: 'adjustment description 38'
    },
    reason39: {
        id: 'reason-39',
        defaultMessage: 'Service fees ',
        description: 'adjustment description 39'
    },
    SUBTYPE_TRX_FEE: {
        id: 'subtype_transaction_fee',
        defaultMessage: 'Transaction fee Bambora Grow',
        description: 'adjustment subtype is transaction fee'
    },
    SUBTYPE_TERMINAL_FEE_WIRELESS: {
        id: 'subtype_hardware_fee_wireless',
        defaultMessage: 'Wireless terminal',
        description: 'adjustment subtype is transaction fee wireless'
    },
    SUBTYPE_TERMINAL_FEE_WIRED: {
        id: 'subtype_hardware_fee_wired',
        defaultMessage: 'Wired terminal',
        description: 'adjustment subtype is transaction fee wired'
    },
    SUBTYPE_TERMINAL_FEE_INTEGRATED: {
        id: 'subtype_hardware_fee_integrated',
        defaultMessage: 'Integrated terminal',
        description: 'adjustment subtype is transaction fee Integrated'
    },
    SUBTYPE_PAPER_FEE: {
        id: 'subtype_paper_statement',
        defaultMessage: 'Paper statement fee',
        description: 'adjustment subtype is paper fee'
    },
    SUBTYPE_MIN_FEE: {
        id: 'subtype_minimum_fee_acq',
        defaultMessage: 'Minimum fee',
        description: 'adjustment subtype is min fee'
    },
    SUBTYPE_MIN_FEE_PCI: {
        id: 'subtype_minimum_fee_pci',
        defaultMessage: 'Minimum fee PCI',
        description: 'adjustment subtype is min fee PCI'
    },
    SUBTYPE_MONTHLY_FEE_CHECKOUT: {
        id: 'subtype_monthly_fee',
        defaultMessage: 'Checkout Monthly fee',
        description: 'adjustment subtype is checkout Monthly fee'
    },
    SUBTYPE_CB_FEE: {
        id: 'subtype_chargeback_fee',
        defaultMessage: 'Chargeback fee',
        description: 'adjustment subtype is chargeback fee'
    },
    SUBTYPE_INT_FEE: {
        id: 'subtype_integrity_fee',
        defaultMessage: 'Integrity fee',
        description: 'adjustment subtype is integrity fee'
    },
    SUBTYPE_DISCOUNT: {
        id: 'subtype_discount_fee',
        defaultMessage: 'Discount',
        description: 'adjustment subtype is discount'
    },
    SUBTYPE_DISCOUNT_STARTUP: {
        id: 'subtype_discount_startup_fee',
        defaultMessage: 'Discount startup fee',
        description: 'adjustment subtype is discount startup fee'
    },
    SUBTYPE_VAT_SWE: {
        id: 'subtype_vat_swe',
        defaultMessage: 'VAT 25%',
        description: 'adjustment subtype is VAT swe'
    },
    SUBTYPE_VAT_DNK: {
        id: 'subtype_vat_dnk',
        defaultMessage: 'VAT 25%',
        description: 'adjustment subtype is VAT dnk'
    },
    SUBTYPE_VAT_NOR: {
        id: 'subtype_vat_nor',
        defaultMessage: 'VAT 25%',
        description: 'adjustment subtype is VAT nor'
    },
    SUBTYPE_VAT_FIN: {
        id: 'subtype_vat_fin',
        defaultMessage: 'VAT 25%',
        description: 'adjustment subtype is VAT fin'
    },
    SUBTYPE_GROWTH_FINANCE_FRODA: {
        id: 'subtype_growth_finance_froda',
        defaultMessage: 'Growth Finance',
        description: 'adjustment subtype is growth finance froda'
    },
    SUBTYPE_GROWTH_FINANCE_EXTERNAL_PROVIDER_PAYOUT: {
        id: 'subtype_growth_finance_external_provider_payout',
        defaultMessage: 'Growth Finance payout',
        description: 'adjustment subtype is growth finance external provider payout'
    },
    SUBTYPE_GROWTH_FINANCE_REPAYMENT: {
        id: 'subtype_growth_finance_repayment',
        defaultMessage: 'Growth Finance',
        description: 'adjustment subtype is growth finance repayment'
    },
    SUBTYPE_MONTHLY_FEE_COSWISH: {
        id: 'subtype_monthly_fee_swish',
        defaultMessage: 'Monthly fee Swish',
        description: 'adjustment subtype is monthly fee Swish'
    },
    SUBTYPE_TRX_FEE_COSWISH: {
        id: 'subtype_txn_fee_swish',
        defaultMessage: 'Transaction fee Swish',
        description: 'adjustment subtype is transaction fee Swish'
    },
    SUBTYPE_TRX_FEE_MOBILEPAY: {
        id: 'subtype_txn_fee_mobilepay',
        defaultMessage: 'Transaction fee MobilePay',
        description: 'adjustment subtype is transaction fee MobilePay'
    },
    SUBTYPE_TRX_FEE_VIPPS: {
        id: 'subtype_txn_fee_vipps',
        defaultMessage: 'Transaction fee Vipps',
        description: 'adjustment subtype is transaction fee Vipps'
    },
    adjustment: {
        id: 'adjustment',
        defaultMessage: 'Adjustment',
        description: 'adjustment description'
    },
    SUBTYPE_BAMBORA_CONNECT_TERMINAL_FEE_WIRED: {
        id: 'subtype_bambora_connect_terminal_fee_wired',
        defaultMessage: 'Connect Terminal Fee, Wired',
        description: 'Bambora connect terminal fee wired'
    },
    SUBTYPE_BAMBORA_CONNECT_TERMINAL_FEE_WIRELESS: {
        id: 'subtype_bambora_connect_terminal_fee_wireless',
        defaultMessage: 'Connect Terminal Fee, Wireless',
        description: 'Bambora connect terminal fee wireless'
    },
    SUBTYPE_BAMBORA_CONNECT_FREIGHT: {
        id: 'subtype_bambora_connect_freight',
        defaultMessage: 'Connect Freight',
        description: 'Bambora connect freight'
    },
    SUBTYPE_REFERRAL_BONUS: {
        id: 'subtype_referral_bonus',
        defaultMessage: 'Referral bonus',
        description: 'Referral bonus'
    },
    SUBTYPE_DISCOUNT_CHECKOUT: {
        id: 'subtype_discount_checkout',
        defaultMessage: 'Discount Checkout',
        description: 'Discount for Checkout product'
    },
    SUBTYPE_DISCOUNT_CHECKOUT_FEE: {
        id: 'subtype_discount_checkout_fee',
        defaultMessage: 'Discount Checkout fee',
        description: 'Discount on the Checkout fee'
    },
    SUBTYPE_TRX_FEE_CHECKOUT_DANKORT: {
        id: 'subtype_txn_fee_checkout_dankort',
        defaultMessage: 'Transaction fee - Checkout Dankort',
        description: 'Transaction fee - Dankort (checkout)'
    },
    SUBTYPE_TRX_FEE_CHECKOUT_MOTO: {
        id: 'subtype_txn_fee_checkout_moto',
        defaultMessage: 'Transaction fee - Checkout Moto',
        description: 'Transaction fee for MOTO'
    },
    SUBTYPE_TRX_FEE_CHECKOUT_PAYMENTREQ: {
        id: 'subtype_txn_fee_checkout_paymentreq',
        defaultMessage: 'Transaction fee - Checkout PaymentReq',
        description: 'Transaction fee for PaymentREQ'
    },
    SUBTYPE_TRX_FEE_CHECKOUT_GOOGLEPAY: {
        id: 'subtype_txn_fee_checkout_googlepay',
        defaultMessage: 'Transactino fee Checkout GooglePay',
        description: 'Transaction fee for Google pay'
    },
    SUBTYPE_TRX_FEE_CHECKOUT_APPLEPAY: {
        id: 'subtype_txn_fee_checkout_applepay',
        defaultMessage: 'Transaction fee - Checkout ApplePay',
        description: 'Transaction fee for Apple Pay'
    },
    SUBTYPE_TRX_FEE_CHECKOUT_TRX: {
        id: 'subtype_txn_checkout',
        defaultMessage: 'Transaction fee - Checkout transactions',
        description: 'Transaction fee - Checkout transactions'
    },
    SUBTYPE_TRX_FEE_CHECKOUT_DECLINED_TRX: {
        id: 'subtype_txn_fee_checkout_declined',
        defaultMessage: 'Transaction fee - Checkout declined transactions',
        description: 'Transaction fee for declined checkout transactions'
    },
    SUBTYPE_TRX_FEE_INSTORESWISH: {
        id: 'subtype_txn_fee_instoreswish',
        defaultMessage: 'Transaction fee Instore Swish',
        description: 'Transaction Fee for checkout transactions'
    },
    API_FEE_CHECKOUT_APIREQ: {
        id: 'api-fee-checkout-apireq',
        defaultMessage: 'API Fee - Checkout API requests',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_DIRECTBANKING: {
        id: 'monthly-fee-checkout-directbanking',
        defaultMessage: 'Monthly fee - Checkout Mobilepay',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_MOBILEPAY: {
        id: 'monthly-fee-checkout-mobilepay',
        defaultMessage: 'Monthly fee - Checkout Mobilepay',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_OTHER: {
        id: 'monthly-fee-checkout-other',
        defaultMessage: 'Monthly fee - Checkout other',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_PAYBYLINK: {
        id: 'monthly-fee-checkout-paybylink',
        defaultMessage: 'Monthly fee - Checkout Paybylink',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_TOKEN: {
        id: 'monthly-fee-checkout-token',
        defaultMessage: 'Monthly fee - Checkout token',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_URL: {
        id: 'monthly-fee-checkout-url',
        defaultMessage: 'Monthly fee - Checkout extra url',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_USERS: {
        id: 'monthly-fee-checkout-users',
        defaultMessage: 'Monthly fee - Checkout users',
        description: '-'
    },
    MONTHLY_FEE_CHECKOUT_VIPPS: {
        id: 'monthly-fee-checkout-vipps',
        defaultMessage: 'Monthly fee - Checkout Vipps',
        description: '-'
    },
    SCH_FEE_MC_INTEGRITY: {
        id: 'sch-fee-mc-integrity',
        defaultMessage: 'Integrity fee, MC',
        description: '-'
    },
    SCH_FEE_MC_MRP: {
        id: 'sch-fee-mc-mrp',
        defaultMessage: 'Registration Program, MC',
        description: '-'
    },
    SCH_FEE_MC_REATTEMPTS: {
        id: 'sch-fee-mc-reattempts',
        defaultMessage: 'Reattempts fee, MC',
        description: '-'
    },
    SCH_FEE_MC_SINGLETAP: {
        id: 'sch-fee-mc-singletap',
        defaultMessage: 'Single tap fee, MC',
        description: '-'
    },
    SCH_FEE_VISA_INTEGRITY: {
        id: 'sch-fee-visa-integrity',
        defaultMessage: 'Integrity fee, VISA',
        description: '-'
    },
    SCH_FEE_VISA_PRM: {
        id: 'sch-fee-visa-prm',
        defaultMessage: 'Program Request Mgmt, VISA',
        description: '-'
    },
    SCH_FEE_VISA_REATTEMPTS: {
        id: 'sch-fee-visa-reattempts',
        defaultMessage: 'Reattempts fee, VISA',
        description: '-'
    },
    TXN_FEE_CHECKOUT_CARDVERIFICATION: {
        id: 'txn-fee-checkout-cardverification',
        defaultMessage: 'Transaction fee - Checkout card verification',
        description: '-'
    },
    TXN_FEE_CHECKOUT_OTHER: {
        id: 'txn-fee-checkout-other',
        defaultMessage: 'Transaction fee - Checkout other',
        description: '-'
    },
    TXN_FEE_CHECKOUT_SUBSCRIPTION: {
        id: 'txn-fee-checkout-subscription',
        defaultMessage: 'Transaction fee - Checkout subscriptions',
        description: '-'
    },
    TXN_FEE_CHECKOUT_TOKEN: {
        id: 'txn-fee-checkout-token',
        defaultMessage: 'Transaction fee - Checkout token',
        description: '-'
    },
    TXN_FEE_DIRECTBANKING: {
        id: 'txn-fee-directbanking',
        defaultMessage: 'Transaction fee - Checkout Dir Bank',
        description: '-'
    },
    SCH_FEE_VISA_LOW_FUNDS: {
        id: 'sch-fee-visa-low-funds',
        defaultMessage: 'Low Funds, No Partial Auth, VISA',
        description: '-'
    },
    SCH_FEE_MC_ABU_MDES: {
        id: 'sch-fee-mc-abu-mdes',
        defaultMessage: 'Updated Credentials, MC',
        description: '-'
    }
})

export const SETTLEMENT_TABLE_HEADER = defineMessages({
    date: {
        id: 'settlements-list-group-component-date',
        defaultMessage: 'Date',
        description: 'Settlements list header'
    },
    reference: {
        id: 'settlements-list-group-component-reference',
        defaultMessage: 'Reference',
        description: 'Settlements list header'
    },
    merchant_id: {
        id: 'settlements-list-group-component-mids',
        defaultMessage: 'Merchant ID',
        description: 'Settlements list merchant ID'
    },
    currency: {
        id: 'settlements-list-group-component-currency',
        defaultMessage: 'Currency',
        description: 'Settlements list header'
    },
    sales: {
        id: 'settlements-list-group-component-sales',
        defaultMessage: 'Sales',
        description: 'Settlements list header'
    },
    fees_adj: {
        id: 'settlements-list-group-component-adjustments',
        defaultMessage: 'Adjustments',
        description: 'Settlements list header'
    },
    payout: {
        id: 'settlements-list-group-component-payout',
        defaultMessage: 'Payout',
        description: 'Settlements list header'
    }
})

export const SETTLEMENT_DETAIL_PANEL_TEXT = defineMessages({
    'settlement-detail-payout-subtotal': {
        id: 'settlement-detail-payout-subtotal',
        defaultMessage: 'Payout subtotal',
        description: 'Settlements detail payout subtotal'
    },
    'settlement-detail-footer-1': {
        id: 'settlement-detail-footer-1',
        defaultMessage: 'Payment sent to your bank account',
        description: 'Settlements detail footer 1'
    },
    'settlement-detail-footer-2': {
        id: 'settlement-detail-footer-2',
        defaultMessage: 'Transferred initiated',
        description: 'Settlements detail footer 2'
    },
    'settlement-detail-footer-3': {
        id: 'settlement-detail-footer-3',
        defaultMessage: 'and will be on your bank account a few days after',
        description: 'Settlements detail footer 3'
    },
    'settlement-detail-more-details-link': {
        id: 'settlement-detail-more-details-link',
        defaultMessage: 'See more settlement details',
        description: 'settlement detail more details link'
    },
    'settlement-detail-download-report': {
        id: 'settlement-detail-download-report',
        defaultMessage: 'Download',
        description: 'Download button for small screens'
    },
    'settlement-reference': {
        id: 'settlement-details-reference',
        defaultMessage: 'Settlement reference',
        description: 'Settlement reference in expanded view'
    },
    'settlement-detail-disabled-button-tooltip': {
        id: 'settlement-detail-disabled-button-tooltip',
        defaultMessage: 'Awaiting transaction data. The report will become available for download when all transaction data has arrived.',
        description: 'Tooltip when download button is disabled'
    }
})

export const SETTLEMENT_FILTER_TEXT = defineMessages({
    'settlement-filter-from-date': {
        id: 'settlement-filter-from-date',
        defaultMessage: 'From date',
        description: 'From date button when filtering on dates'
    },
    'settlement-filter-to-date': {
        id: 'settlement-filter-to-date',
        defaultMessage: 'To date',
        description: 'To date button when filtering on dates'
    },
    'settlement-filter-clear-all': {
        id: 'settlement-filter-clear-all',
        defaultMessage: 'Clear all',
        description: 'Text for clearing all filters'
    },
    'settlement-filter-sales-location': {
        id: 'settlement-filter-sales-location',
        defaultMessage: 'Sales location',
        description: 'Sales location'
    },
    'settlement-filter-saleslocation-apply': {
        id: 'settlement-filter-saleslocation-apply',
        defaultMessage: 'Apply',
        description: 'Apply button for saleslocation'
    },
    'settlement-filter-saleslocation-clear': {
        id: 'settlement-filter-saleslocation-clear',
        defaultMessage: 'Clear',
        description: 'Clear button for saleslocation'
    },
    'settlement-filter-saleslocation-full-screen-clear': {
        id: 'ssettlement-filter-saleslocation-full-screen-clear',
        defaultMessage: 'Clear',
        description: 'Clear button for saleslocation'
    },
    'settlement-filters': {
        id: 'settlement-filters',
        defaultMessage: 'Filters',
        description: 'Filters button for opening all filters in one view'
    },
    'settlement-filters-full-screen': {
        id: 'settlement-filters-full-screen',
        defaultMessage: 'Filters',
        description: 'Filters button for opening all filters in one view'
    },
    'settlement-filters-full-screen-saleslocation': {
        id: 'settlement-filters-full-screen-saleslocation',
        defaultMessage: 'Sales locations',
        description: 'Header for saleslocation'
    },
    'settlement-filters-full-screen-currencies': {
        id: 'settlement-filters-full-screen-currencies',
        defaultMessage: 'Currencies',
        description: 'Header for saleslocation'
    },
    'settlement-filters-full-screen-currencies-clear': {
        id: 'settlement-filters-full-screen-currencies-clear',
        defaultMessage: 'Clear',
        description: 'Clear header for currencies'
    },
    'settlement-filters-dates-clear': {
        id: 'settlement-filters-dates-clear',
        defaultMessage: 'Clear',
        description: 'Clear text for cleaing just the dates'
    },
    'settlement-filter-currency': {
        id: 'settlement-filter-currency',
        defaultMessage: 'Currency',
        description: 'Currency'
    },
    'settlement-filter-currency-apply': {
        id: 'settlement-filter-currency-apply',
        defaultMessage: 'Apply',
        description: 'Apply button for currency'
    },
    'settlement-filter-currency-clear': {
        id: 'settlement-filter-currency-clear',
        defaultMessage: 'Clear',
        description: 'Clear button for currency'
    },
    'settlement-filter-reference': {
        id: 'settlement-filter-reference',
        defaultMessage: 'Reference',
        description: 'Placeholder for searching reference'
    }
})

export const SETTLEMENT_TRANSACTION_TEXT = defineMessages({
    'settlement-details-navbar-back': {
        id: 'settlement-details-navbar-back',
        defaultMessage: 'Back',
        description: 'Back button in navbar, that lets the user go back to the previous browser location'
    },
    'settlement-details-transaction-list-error-title': {
        id: 'settlement-details-transaction-list-error-title',
        defaultMessage: 'Bummer, we couldn\'t find your settlement transactions...',
        description: 'Failed getting settlements list title.'
    },
    'settlement-details-transaction-list-error-body': {
        id: 'settlement-details-transaction-list-error-body',
        defaultMessage: 'Please try to refresh this page or log out and in again.',
        description: 'Failed getting settlements list body.'
    },
    'settlemment-details-no-transactions': {
        id: 'settlemment-details-no-transactions',
        defaultMessage: 'This settlement does not contain any transactions.',
        description: 'Settlement details message if the specified settlement contains no transactions'
    }
})

export const SETTLEMENT_PAGINATION_BUTTON = defineMessages({
    'pagination-btn-load-more-state': {
        id: 'pagination-btn-load-more-state',
        defaultMessage: 'Load more',
        description: 'Pagination button in load more state'
    },
    'pagination-btn-loading-state': {
        id: 'pagination-btn-loading-state',
        defaultMessage: 'Loading...',
        description: 'Pagination button in loading state'
    }
})
