export const PAGE_LIMIT = 20
export const PAGE_OFFSET = 0
export const NEXT_PAGE_OFFSET = PAGE_OFFSET + PAGE_LIMIT
export const RETURN_TOTAL_COUNT_QUERY = '&return_total_count=False'
export const EXCLUDE_PSP_MAPPED = '&include_psp_mapped=False'
export const INCLUDE_PSP_MAPPED = '&include_psp_mapped=True'
export const PAGE_DEFAULT_NEXT_PAGE = `?offset=${NEXT_PAGE_OFFSET}&limit=${PAGE_LIMIT}`
export const MERCHANTS_BASE = '<apihost>/v1/merchants'
export const MERCHANT_BASE = '<apihost>/v1/merchants/<merchant>'
export const ONLINE_BASE = '<apihost>/v1/merchants/<merchant>/online/payments'
export const INSTORE_BASE = '<apihost>/v1/merchants/<merchant>/instore/payments'
export const PAYMENTS_BASE = '<apihost>/v1/merchants/<merchant>/payments'
export const PAYMENTS_BASE_HEADERS = { 'API-Version': 1 }
export const REPORTS_BASE = '<apihost>/v1/merchants/<merchant>/reports'
export const REPORTS_BASE_V2 = '<apihost>/v2/merchants/<merchant>/reports'
export const SETTLEMENTS_BASE = '<apihost>/v1/merchants/<merchant>/settlements'
export const USER_BASE = '<apihost>/v1/users'
export const FRODA_PROXY_BASE = '<apihost>/v1/merchants/<merchant>/adapters/froda'
export const USERS_BASE = '<apihost>/v1/merchants/<merchant>/users'
export const ZERO_API_BASE = '<apihost>/v1/merchants/<merchant>/adapters/epay/apikeys'
export const ZERO_CHECKOUT_BASE = '<apihost>/v1/merchants/<merchant>/adapters/epay/checkout'
export const LOAN_BASE = '<apihost>/v1/merchants/<merchant>/loan'
export const ENVIRONMENTS = {
    TEST: 'test',
    PROD: 'prod',
    LOCAL: 'local'
}
export const MERCHANT_SPECIFIC_ACTIONS = [
    'transactions', 'settlements', 'invoice', 'reports', 'terminals',
    'orders', 'loans', 'sales', 'instore', 'acquiring', 'online', 'dashboard'
]

export const CUSTOMER_TYPE_DACH = 'dach'
export const MERCHANTS = {
    HUALONG: 'ae90e8e3-2eda-47bb-aac8-03d92c91bf31',
    INSTORE: '877370dc-2d8e-416b-a9a9-4f768fc7b613',
    ONLINE: '4afe57bf-904d-48db-bd95-bccd307afff9',
    ACQUIRING: '18b27d95-701a-448c-bfa8-c12766513f3f'
}
export const TTL = 1000 * 60 * 10 // ten minutes

export const countriesEnableChat = ['denmark', 'danmark']

export const USER_PREFERENCE = {
    LOAN_POP_VISITED: 'loanPopVisitedv2',
    REPORTS_FEATURE_HIGHLIGHT_POP_VISITED: 'reportsFeatureHighLightPopVisited'
}

export const preservedLocalStorageKeyWhenLogout = ['userPreference', 'localization', 'developerMode']

export const ITEM_FILTER_VISIBILITY_THRESHOLD = 8 // Specifies when to activate item search filters in Dropdown and similar
