import * as t from './actionTypes'

export const showMerchantDataResponseErrorMessage = () => ({
    type: t.SHOW_REQUEST_FAILED_MESSAGE,
    meta: {
        mixpanel: {
            event: 'Failed to get merchant data, showing request failed error message'
        }
    }
})

export const showNoMerchantsErrorMessage = () => ({
    type: t.SHOW_NO_MERCHANTS_MESSAGE,
    meta: {
        mixpanel: {
            event: 'User has no merchants, showing no merchants error message'
        }
    }
})

export const showErrorPage = () => ({
    type: t.SHOW_ERROR_PAGE,
    meta: {
        mixpanel: {
            event: 'show error page'
        }
    }
})