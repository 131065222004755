import React, { useState, useRef } from 'react'

import './monthlyReportNotification.scss'

export const MonthlyReportNotification = (props) => {

    const [primaryView, setPrimaryView] = useState(true)
    const [isClosing, setIsClosing] = useState(false)

    const previousView = useRef(true)
    const fadeInAnimation = useRef('')

    const hasSecondaryView = !!(props.secondaryHeader && props.secondaryHeader)
    const slideOutAnimation = isClosing ? 'slide-out' : ''

    if (primaryView !== previousView.current) {
        previousView.current = primaryView
        fadeInAnimation.current = primaryView ? 'fade-in-primary' : 'fade-in-secondary'
    }

    const handleCloseIcon = () => {
        if (hasSecondaryView) {
            setPrimaryView(false)
        } else {
            props.closeIconOnClick()
            setIsClosing(true)
        }
    }

    return (
        <div className={`report-notification slide-in ${slideOutAnimation}`} data-testid="report-notification">
            <div className={fadeInAnimation.current}>
                <div className="header">
                    <div className="icon">
                        {primaryView ? props.icon : props.secondaryIcon}
                    </div>
                    <div className="text">
                        {primaryView ? props.header : props.secondaryHeader}
                    </div>
                    <div className="close-icon" onClick={() => handleCloseIcon()} data-testid="notification-close-btn">
                        {primaryView ? props.closeIcon : null}
                    </div>
                </div>
                <div className="body">
                    {primaryView ? props.body : props.secondaryBody}
                </div>
                <div className="footer">
                    {primaryView ?
                        <PrimaryFooter
                            downloadText={props.downloadText}
                            triggerDownload={props.triggerDownload}
                            downloadTask={props.downloadTask}
                            setIsClosing={setIsClosing}
                        /> :
                        <SecondaryFooter
                            dismissDownloadText={props.dismissDownloadText}
                            dismissDownload={props.dismissDownload}
                            cancelDismissalText={props.cancelDismissalText}
                            setPrimary={setPrimaryView}
                            setIsClosing={setIsClosing}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

const PrimaryFooter = ({downloadText, triggerDownload, setIsClosing}) => {
    const download = () => {
        triggerDownload()
        setIsClosing(true)
    }
    return (
        <div className="download-link" onClick={download} data-testid="notification-download-btn">
            {downloadText}
        </div>
    )
}

const SecondaryFooter = ({cancelDismissalText, setPrimary, setIsClosing, dismissDownload, dismissDownloadText}) => {
    const dismiss = () => {
        dismissDownload()
        setIsClosing(true)
    }
    return (
        <div className="confirm">
            <div className="back" onClick={() => setPrimary(true)}>
                {cancelDismissalText}
            </div>
            <div className="dismiss" onClick={() => dismiss()} data-testid="notification-dismiss-btn">
                {dismissDownloadText}
            </div>
        </div>
    )
}
