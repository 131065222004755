import * as types from './actionTypes'

export default (state = {}, action) => {
    switch (action.type) {

        case types.SHOW_REQUEST_FAILED_MESSAGE:
            return Object.assign({}, state, {
                show: true,
                type: 'request-failed'
            })

        case types.SHOW_NO_MERCHANTS_MESSAGE:
            return Object.assign({}, state, {
                show: true,
                type: 'no-merchants'
            })

        case types.HIDE_LOGGED_OUT_MESSAGE:
            return Object.assign({}, state, {
                show: false
            })
        case types.SHOW_ERROR_PAGE:
            return Object.assign({}, state, {
                show: true,
                type: 'error_page'
            })

        default:
            return state
    }
}
