import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import { LOAN_INFO_BOX } from '../translations'
import { thousandSeperator } from '../amountHelpers'
import mixpanel from 'mixpanel-browser'
import { mixpanelPrefix } from '../../common/helpers/mixpanelPrefix'
import { TEXT } from '../translations'
import { ROLES } from '../../merchant/constants'
import { listCredit } from '../../dashboard/actions'
import { setUserPreference } from '../../user/actions'
import { USER_PREFERENCE } from '../constants'

import img from '../../styles/img/welcome/overview.svg'
import './loanInfoBox.scss'

const LOAN_WELCOME_COUNTRIES = ['sweden', 'finland', 'norway']

const LoanInfoBox = ({ availableCredit, history, activeMerchant, dispatch }) => {
    const [show, setShow] = useState(true)
    const { formatMessage } = useIntl()

    const close = () => {
        setShow(false)
        dispatch(setUserPreference({ value: true, type: USER_PREFERENCE.LOAN_POP_VISITED }))
    }

    const onModalClose = () => {
        mixpanel.track(`${mixpanelPrefix.LOAN} Close loan pop-up`)
        close()
    }
    const redirectToLoan = () => {
        close()
        history.push(`/merchants/${activeMerchant.id}/loans`)
    }

    useEffect(() => {
        if (activeMerchant?.id) {
            dispatch(listCredit(activeMerchant.id))
        }
    }, [activeMerchant])

    const title = availableCredit ? formatMessage(LOAN_INFO_BOX.title, { amount: thousandSeperator(Math.round(availableCredit.amount)), currency: availableCredit.currency }) : formatMessage(LOAN_INFO_BOX['title_no_number'])
    const text = formatMessage(LOAN_INFO_BOX.text2)
    mixpanel.track(`${mixpanelPrefix.LOAN} Open loan pop-up`)
    return (
        <div>
            <Modal className="bootstrap-modal loan-info-box-modal" animation={false} show={show} onHide={onModalClose} centered={true} backdrop={'static'}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <div className="loan-info-box">
                        <div className="img-wrapper">
                            <img src={img} width="160" alt="" />
                        </div>
                        <h2 className="">{title}</h2>
                        {text && <p>{text}</p>}
                        <button className="rebranded-primary-btn"
                             style={{margin: '0 auto'}}
                             onClick={redirectToLoan}>
                                 {formatMessage(TEXT['read_more'])}
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default withRouter(LoanInfoBox)

export const showLoanModal = (applicationContext) => {
    const { activeMerchant, loggedInUser } = applicationContext

    const showModal =
        activeMerchant?.id
        && [ROLES.ADMIN, ROLES.OWNER].includes(loggedInUser?.role)
        && LOAN_WELCOME_COUNTRIES.includes(activeMerchant?.billingAddress?.country?.toLowerCase())
        && !loggedInUser?.portalPreferences.loanPopVisited
        && activeMerchant?.loanEligible
        && activeMerchant?.availableCredit?.amount > 0
        && !window.location.pathname.includes('/welcome')
    return showModal
}
