import React from 'react'

import { SALES_DETAILS_TEXTS } from '../../sales/constants'
import { getExponentedAmountString } from '../../../common/amountHelpers'

import FormattedDate from '../../../common/components/formattedDate'
import FormattedTime from '../../../common/components/formattedTime'

const AcquiringPaymentDetails = ({payment, settlement, formatMessage}) => {
    return (
        <div className="col-2 left">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_transaction'])}</div>
            <div className="detail-wrapper">
            <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_date'])}</div>
                        <div className="element">
                            <FormattedDate elementClass="date" date={payment.date_time_local}></FormattedDate>
                            <FormattedTime date={payment.date_time_local}></FormattedTime>
                        </div>
                    </div>
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_type'])}</div>
                        <div className="element">{payment.type}</div>
                    </div>
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_amount'])}</div>
                        <div className="element">{getExponentedAmountString(payment.amount, payment.amount_exponent)} {payment.currency}</div>
                    </div>
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_reference'])}</div>
                        <div className="element">{payment.reference}</div>
                    </div>
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_payout_reference'])}</div>
                        <div className="element">{settlement.get('reference') || '-'}</div>
                    </div>
                    { renderAdditionalReferences(payment, formatMessage) }
            </div>
        </div>
    )
}

const renderAdditionalReferences = (payment, formatMessage) => {
    if(!payment.reference_additional_1 && !payment.reference_additional_2 && !payment.reference_additional_3) {
        return
    }
    return (
        <div className="row list">
            <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_additional_reference'])}</div>
            <div className="reference-group">
                { payment.reference_additional_1 && <div className="element ref">{payment.reference_additional_1}</div>}
                { payment.reference_additional_2 && <div className="element ref">{payment.reference_additional_2}</div>}
                { payment.reference_additional_3 && <div className="element ref">{payment.reference_additional_3}</div>}
            </div>
        </div>
    )
}

export default AcquiringPaymentDetails
