import React, { useEffect, useState, useRef } from 'react'
import uuid from 'uuid'
import { injectIntl } from 'react-intl'
import truncateText from '../../common/helpers/truncate-text'
import './numSelectDropDown.scss'


const NumSelectDropDown = (props) => {
    /** props:
     *   - total: total number of dropdown
     *   - selected: selected number
     */

    const [dropDownOpen, setDropDownOpen] = useState(false)
    const { isDisabled = false, total, selected } = props
    const wrapperRef = useRef()

    const handleClickOutside = (wrapperRef, event) => {
        if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            toggleDropdownOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside.bind(undefined, wrapperRef))
        // returned function will be called on component unmount
        return () => {
            document.addEventListener('mousedown', handleClickOutside.bind(undefined, wrapperRef))
        }
    }, [])


    if (!total || total < 1) return null
    let options = [...Array(total + 1).keys()]

    const buttonText = selected ? selected.toString() : '0'
    const dropdownClassName = `dropdownmenu ${dropDownOpen ? 'dropdownmenu--open' : ''} `


    const toggleDropdownOpen = (isOpen) => {
        const newDropdownOpen = isOpen !== undefined ? isOpen : !dropDownOpen
        setDropDownOpen(newDropdownOpen)
    }

    const toggleSelect = (option) => {
        props.onChange(option)
        toggleDropdownOpen()
    }

    const dropdownBtnClick = () => {
        toggleDropdownOpen()
    }

    return <div className={`select-dropdown num-select-dropdown ${props.className ? props.className : ''}`}>
        <div className="select-dropdown__wrapper" ref={wrapperRef}>
            <button
                className="toggle-button btn-small"
                disabled={isDisabled}
                onClick={dropdownBtnClick}>
                {buttonText}
                <div className="arrow-down-selected"></div>
            </button>
            <div className={dropdownClassName}>
                <ul className="mids">
                    {options.map(option =>
                        <li
                            key={uuid.v4()}
                            onClick={() => toggleSelect(option)}
                        >
                            <div className="text-content">
                                <div title={option.toString()} className="info">{truncateText(option.toString(), 30)}</div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
}

export default injectIntl(NumSelectDropDown)
