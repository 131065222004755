import { Map, List } from 'immutable'
import * as t from './actionTypes'


const initState = Map({
    isFetching: false,
    hasFetchedMerchantDetails: false,
    merchants: List(),
    isFetchingUsers: true,
    loggedInUser: {},
    users: List(),

    showAddUserModal: false,
    isAddingUser: false,

    showRenameUserModal: false,

    showSelectMerchantModal: false
})

export default function (merchant = initState, action) {

    switch (action.type) {

        // MERCHANTS
        case t.SET_MERCHANT:
            return merchant.set('data', action.payload.merchant)
                .set('hasFetchedMerchantDetails', false)
        case t.GET_MERCHANTS_FOR_USER_START:
            return merchant.set('isFetching', action.payload.isFetching)
        case t.GET_MERCHANTS_FOR_USER:
            return merchant.set('merchants', action.payload.merchants).set('isFetching', false)
        case t.GET_MERCHANTS_FOR_USER_ERROR:
            return merchant.set('isFetching', false)
        case t.UPDATE_MERCHANTS_LIST:
            var merchants = merchant.get('merchants').map(merchant =>
                merchant.id === action.payload.merchant.id ?
                    action.payload.merchant :
                    merchant)

            return merchant.set('merchants', merchants)
                .set('hasFetchedMerchantDetails', true)

        // LIST USERS
        case t.LIST_USERS_START:
            return merchant.set('isFetchingUsers', action.payload.isFetching)
        case t.LIST_USERS_ERROR:
            return merchant
                .set('isFetchingUsers', false)
                .set('isError', true)
        case t.LIST_USERS_SUCCESS:
            return merchant.set('users', List(action.payload.users))
                .set('isFetchingUsers', action.payload.isFetching)
                .set('loggedInUser', action.payload.currentUser)

        // ADD USERS
        case t.TOGGLE_ADD_USER_MODAL:
            return merchant
                .set('showAddUserModal', !merchant.get('showAddUserModal'))
        case t.ADD_USER_START:
            return merchant
                .set('showAddUserModal', false)
                .set('isAddingUser', true)
        case t.ADD_USER_ERROR:
            return merchant
                .set('isAddingUser', false)
        case t.ADD_USER_SUCCESS:
            return merchant.set('users',
                merchant.get('users').push(action.payload.user)
            ).set('isAddingUser', false)

        // CHANGE USERS
        case t.CHANGE_USER_ROLE_START:
            return merchant.set('users',
                merchant.get('users').map(u => {
                    if (u.id === action.payload.user.id) {
                        u.isChanging = true
                    }
                    return u
                })
            )
        case t.CHANGE_USER_ROLE_ERROR:
            return merchant.set('users',
                merchant.get('users').map(u => {
                    if (u.id === action.payload.user.id) {
                        u.isChanging = false
                    }
                    return u
                })
            )
        case t.CHANGE_USER_ROLE_SUCCESS:
            return merchant.set('users',
                merchant.get('users').map(u => {
                    if (u.id === action.payload.user.id) {
                        u.isChanging = false
                        u.role = action.payload.role
                    }
                    return u
                })
            )

        // RENAME USER
        case t.TOGGLE_RENAME_USER_MODAL:
            return merchant
                .set('showRenameUserModal', !merchant.get('showRenameUserModal'))
        case t.UPDATE_USER_START:
            return merchant.set('users',
                merchant.get('users').map(u => {
                    if (u.id === action.payload.user.id) {
                        u.isRenaming = true
                    }
                    return u
                })
            ).set('showRenameUserModal', false)
        case t.UPDATE_USER_ERROR:
            return merchant.set('users',
                merchant.get('users').map(u => {
                    if (u.id === action.payload.user.id) {
                        u.isRenaming = false
                    }
                    return u
                })
            )
        case t.UPDATE_USER_SUCCESS:
            return merchant.set('users',
                merchant.get('users').map(u => {
                    if (u.id === action.payload.user.id) {
                        u.isRenaming = false
                        u.givenName = action.payload.user.givenName
                        u.familyName = action.payload.user.familyName
                    }
                    return u
                })
            )

        // REMOVE USER
        case t.TOGGLE_REMOVE_USER_MODAL:
            return merchant
                .set('showRemoveUserModal', !merchant.get('showRemoveUserModal'))
                .set('userToBeRemoved', action.payload.user)
        case t.REMOVE_USER_START:
            return merchant
                .set('showRemoveUserModal', false)
                .set('isRemovingUser', true)
        case t.REMOVE_USER_SUCCESS:
            return merchant.set('users',
                merchant.get('users').filter(u => u.id !== action.payload.user.id)
            )
        case t.SET_ACTIVE_MERCHANT:
            return merchant.set('activeMerchant', action.payload.merchantId)
        case t.REMOVE_USER_ERROR:
        default:
            return merchant
    }
}
