import { defineMessages } from 'react-intl'

export const ACCOUNTS_TEXT = defineMessages({
        'show-inactive': {
            id: 'show-inactive',
            defaultMessage: 'Show inactive',
            description: 'Toggle show or hide inactive accounts'
        },
        'inactive': {
            id: 'inactive',
            defaultMessage: 'Inactive',
            description: 'Marks that an account is inactive'
        },
        'address': {
            id: 'address',
            defaultMessage: 'Address',
            description: 'The merchants visiting address'
        }
})
