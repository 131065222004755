import { defineMessages } from 'react-intl'

export const ORDER_OPERATION_MODAL = defineMessages({
    refund_full_order: {
        id: 'order-operation-modal-refund-full-order',
        defaultMessage: 'Refund full order',
        description: 'order operation moda refund full order'
    },
    amount_to_refund: {
        id: 'order-operation-modal-amount-to-refund',
        defaultMessage: 'Amount to refund',
        description: 'order operation moda amount to refund'
    },
    amount: {
        id: 'order-operation-modal-amount',
        defaultMessage: 'Amount',
        description: 'order operation moda amount'
    },
    items: {
        id: 'order-operation-modal-items',
        defaultMessage: 'Items',
        description: 'order operation modal items'
    },
    capture_full_order: {
        id: 'order-operation-modal-capture-full-order',
        defaultMessage: 'Capture full order',
        description: 'order operation moda capture full order'
    },
    amount_to_capture: {
        id: 'order-operation-modal-amount-to-capture',
        defaultMessage: 'Amount to capture',
        description: 'order operation modal amount to capture'
    },
    refund_error: {
        id: 'invoice-modal-refund-error',
        defaultMessage: 'Refund failed',
        description: 'Refund failed error message'
    },
    capture_error: {
        id: 'order-operation-modal-capture-error',
        defaultMessage: 'Capture failed',
        description: 'Capture failed error message'
    },
    refund: {
        id: 'order-operation-modal-refund-invoice-title',
        defaultMessage: 'Refund',
        description: 'Refund invoice amount modal title'
    },
    amount_available: {
        id: 'order-operation-modal-available-amount',
        defaultMessage: 'Amount available',
        description: 'Avaliable amount text for capture/refund amount'
    },
    capture: {
        id: 'order-operation-modal-capture-title',
        defaultMessage: 'Capture',
        description: 'Capture amount modal title'
    },
    cancel: {
        id: 'order-operation-modal-cancel',
        defaultMessage: 'Cancel',
        description: 'Button that closes the order operation modal without action'
    }
})
