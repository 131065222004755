import * as t from './actionTypes'
import { runRequest } from '../common/requestActionHelper'
import { SETTLEMENTS_BASE, PAYMENTS_BASE, PAGE_LIMIT, PAGE_OFFSET } from '../common/constants'
import { sumAmounts } from '../common/amountHelpers'
import { SALES_ADJUSTMENT_TYPE } from './constants'
import { mapTransaction } from './helpers'
import { hasNextPage, getNextPageUrl, createSearch } from '../common/locationHelpers'
import mixpanel from 'mixpanel-browser'
import { mixpanelPrefix } from '../common/helpers/mixpanelPrefix'
import getActivePayoutsFilterTypes from './helpers/getActivePayoutsFilterTypes.js'
import moment from 'moment'
import { DataAvailableFromDate } from '../common/dateHelpers'

export const getDetails = (id) => {
    return {
        type: t.LIST_DETAILS,
        payload: {
            id: id
        },
        meta: {
            mixpanel: {
                event: 'Opened settlement details',
                props: {
                    id
                }
            }
        }
    }
}

export const updateFilters = (merchantId, filters) => {
    return {
        type: t.UPDATE_FILTERS,
        payload: {
            merchantId,
            filters
        }
    }
}

export const changeFilter = (filterType, filterValue) => {
    return {
        type: t.CHANGE_FILTER,
        payload: {
            filterType,
            filterValue
        }
    }
}

export const clearAllFilters = (merchantId, params) => {
    return {
        type: t.CLEAR_ALL_FILTERS,
        payload: {
            merchantId,
            params
        }
    }
}

export const loadMore = (merchantId, params) => {
    return {
        type: t.LOAD_MORE,
        payload: {
            merchantId,
            params
        }
    }
}

export const loadMoreTransactions = (merchantId, params) => {
    return {
        type: t.LOAD_MORE_TRANSACTIONS,
        payload: {
            merchantId,
            params
        }
    }
}

export const resetTransationPagination = (merchantId, params) => {
    return {
        type: t.RESET_TRANSACTION_PAGINATION,
        payload: {
            merchantId,
            params
        }
    }
}

export const resetPagination = () => {
    return {
        type: t.RESET_PAGINATION
    }
}

export const calculateEndDate = (endDate) => {
    endDate = moment(endDate)
    endDate = endDate.add(1, 'days')
    return endDate.format('YYYY-MM-DD')
}

export const getSettlements = (merchantId, search = null) => {
    const defaults = {
        offset: PAGE_OFFSET,
        limit: PAGE_LIMIT,
        include_parts: false,
        exclude_sales_adjustments: false,
        from_date: DataAvailableFromDate.instance.getDateIsoString(),
        return_total_count: false
    }

    const mergedSearch = Object.assign({}, defaults, search)
    if (mergedSearch && mergedSearch.to_date) {
        mergedSearch.to_date = calculateEndDate(mergedSearch.to_date)
    }

    const fullSearch = createSearch(mergedSearch)
    const url = `${SETTLEMENTS_BASE}/details${fullSearch}`

    return runRequest({
        startAction: () => listSettlementsStart(merchantId, search),
        successAction: (settlements) => {
            return listSettlements(settlements, url, merchantId, mergedSearch, search)
        },
        outOfRangeAction: () => listSettlementsOutOfRange(merchantId),
        errorAction: listSettlementsError,
        requestObj: { url: url, cache: true, merchantId }
    })
}

export const getSettlement = (merchantId, settlementId) => {
    return runRequest({
        startAction: () => getSettlementStart(),
        successAction: (settlement) => {
            return getSettlementSuccess(settlement['data']['settlement'])
        },
        errorAction: listSettlementsError,
        requestObj: { url: `${SETTLEMENTS_BASE}/${settlementId}`, cache: false, merchantId }
    })
}

const getSettlementStart = () => {
    return {
        type: t.UPDATE_SINGLE_START
    }
}

const getSettlementSuccess = (settlement) => {
    return {
        type: t.UPDATE_SINGLE_SUCCESS,
        payload: {
            settlement
        }
    }
}

export const getSettlementReference = (merchantId, id) => {
    return runRequest({
        startAction: () => fetchSettlementReferenceStart(merchantId),
        successAction: (settlement) => fetchSettlementReferenceSuccess(merchantId, settlement),
        errorAction: fetchSettlementReferenceError,
        requestObj: { url: `${SETTLEMENTS_BASE}/${id}`, merchantId }
    })
}

export function getTransactionsForEmid(merchantId, settlementId, emid, search) {
    const defaults = {
        offset: PAGE_OFFSET,
        limit: PAGE_LIMIT,
        settlement_id: settlementId,
        source_merchant_id: emid,
        source: 'Elixir'
    }
    const mergedSearch = Object.assign({}, defaults, search)
    const fullSearch = createSearch(mergedSearch)
    const url = `${PAYMENTS_BASE}${fullSearch}`

    return runRequest({
        startAction: () => listTransactionsStart(merchantId),
        successAction: (transactions) => {
            return listTransactions(transactions, settlementId, merchantId, emid, mergedSearch)
        },
        errorAction: listTransactionError,
        requestObj: {
            url,
            cache: true,
            merchantId
        }
    })
}

// List single settlement
export const fetchSettlementReferenceStart = (merchantId) => {
    return {
        type: t.FETCH_SETTLEMENT_REFERENCE_START,
        payload: {
            isFetching: true,
            merchantId
        }
    }
}

export const fetchSettlementReferenceSuccess = (merchantId, settlement) => {
    var s = settlement.data.settlement
    return {
        type: t.FETCH_SETTLEMENT_REFERENCE_SUCCESS,
        payload: {
            isFetching: false,
            merchantId,
            s
        }
    }
}

const fetchSettlementReferenceError = () => {
    return {
        type: t.FETCH_SETTLEMENT_REFERENCE_ERROR,
        payload: new Error(),
        error: true
    }
}

export const setSettlementRefEmpty = (merchantId) => {
    return {
        type: t.SET_SETTLEMENT_REFERENCE_EMPTY,
        payload: {
            merchantId
        }
    }
}

// List all settlements
const listSettlementsStart = (merchantId, filters) => {
    let mixpanel_event

    const activeFilters = getActivePayoutsFilterTypes(filters)

    if (activeFilters !== null) {
        mixpanel_event = {
            event: `${mixpanelPrefix.PAYOUTS} Starting to list payouts with filter(s) applied.`,
            props: activeFilters
        }
    } else {
        mixpanel_event = { event: `${mixpanelPrefix.PAYOUTS} Starting to list payouts.` }
    }

    const offset = filters && filters.offset

    return {
        type: t.LIST_START,
        payload: {
            isFetching: true,
            merchantId,
            offset
        },
        meta: {
            mixpanel: mixpanel_event
        }
    }
}

const listSettlementsOutOfRange = (merchantId) => {
    return {
        type: t.LIST_OUT_OF_RANGE,
        payload: {
            merchantId: merchantId
        }
    }
}

const listSettlements = (settlements, url, merchantId, search, filters) => {
    settlements.data.forEach(s => sumFees(s))
    const total = settlements.total

    const activeFilters = getActivePayoutsFilterTypes(filters)

    if (activeFilters !== null) {
        mixpanel.track(
            `${mixpanelPrefix.PAYOUTS} Successfully listed payouts with filter(s) applied.`,
            activeFilters
        )
    } else {
        mixpanel.track(`${mixpanelPrefix.PAYOUTS} Successfully listed payouts.`)
    }

    return dispatch => {
        dispatch({
            type: t.LIST,
            payload: {
                settlements,
                isFetching: false,
                total,
                url,
                search,
                merchantId
            }
        })

        dispatch(getNextPageSettlements(merchantId, search))
    }
}

const getNextPageSettlements = (merchantId, search) => {
    const defaults = {
        offset: PAGE_OFFSET + PAGE_LIMIT,
        limit: PAGE_LIMIT,
        include_parts: false,
        exclude_sales_adjustments: false,
        from_date: DataAvailableFromDate.instance.getDateIsoString(),
        return_total_count: false
    }
    const mergedSearch = Object.assign({}, defaults, search)
    const nextPageUrl = getNextPageUrl(createSearch(mergedSearch), defaults)

    const url = `${SETTLEMENTS_BASE}/details${nextPageUrl}`
    return runRequest({
        successAction: (settlements) => {
            return getNextPageSettlementsSuccess(merchantId, settlements)
        },
        errorAction: listSettlementsError,
        requestObj: { url: url, cache: true, merchantId }
    })
}

const getNextPageSettlementsSuccess = (merchantId, settlements = { data: [] }) => {
    if (settlements && settlements.data) settlements.data.forEach(s => sumFees(s))

    return {
        type: t.LIST_SETTLEMENTS_NEXT_PAGE_SUCCESS,
        payload: {
            settlements,
            merchantId
        }
    }
}

const listSettlementsError = () => {
    return {
        type: t.LIST_ERROR,
        payload: new Error(),
        error: true,
        meta: {
            mixpanel: {
                event: 'Fetched settlement list error'
            }
        }
    }
}

const listTransactionsStart = (merchantId) => {
    return {
        type: t.LIST_TRANSACTIONS_START,
        payload: {
            isFetching: true,
            merchantId
        }
    }
}

const listTransactions = (transactions, settlementId, merchantId, emid, search) => {
    transactions.data = transactions.data.map(mapTransaction)
    const total = transactions.total
    return dispatch => {
        dispatch({
            type: t.LIST_TRANSACTIONS,
            payload: {
                transactions,
                merchantId,
                total,
                isFetching: false,
                search
            }
        })

        dispatch(getNextPageTransactions(settlementId, merchantId, emid, total, search))
    }
}

const listTransactionError = () => {
    return {
        type: t.LIST_TRANSACTIONS_ERROR,
        meta: {
            mixpanel: {
                event: 'Fetched settlment transactions error'
            }
        }
    }
}

function filterAdjustments(settlement) {
    if(!settlement.adjustment_aggregates) {
        return settlement.adjustment_set.filter(a => a.reason_code !== SALES_ADJUSTMENT_TYPE)
    } else {
        return settlement.adjustment_aggregates.filter(a => a.reason_code !== SALES_ADJUSTMENT_TYPE)
    }
}

function getSales(settlement) {
    if(!settlement.adjustment_aggregates) {
        return settlement.adjustment_set.filter(a => a.reason_code == SALES_ADJUSTMENT_TYPE)
    } else {
        return settlement.adjustment_aggregates.filter(a => a.reason_code == SALES_ADJUSTMENT_TYPE)
    }
}


export function sumFees(settlement_element) {
    let settlement = settlement_element.settlement
    const sales = getSales(settlement)
    const adjustments = filterAdjustments(settlement)
    const chargebacks = settlement.chargeback_aggregates ? settlement.chargeback_aggregates : settlement.chargeback_set
    const fees = [...adjustments, ...chargebacks]
    settlement.sales_total_sum = sumAmounts(sales)
    settlement.fees_total_sum = sumAmounts(fees)
    return settlement
}

const getNextPageTransactions = (settlementId, merchantId, emid, total, search) => {
    const defaults = {
        offset: PAGE_OFFSET + PAGE_LIMIT,
        limit: PAGE_LIMIT,
        settlement_id: settlementId,
        source_merchant_id: emid,
        source: 'Elixir'
    }
    const mergedSearch = Object.assign({}, defaults, search)
    const nextPageUrl = getNextPageUrl(createSearch(mergedSearch), defaults)
    if (!hasNextPage(createSearch(search), total)) {
        return dispatch => {
            dispatch(getNextPageTransactionsSuccess(merchantId))
        }
    }

    const url = `${PAYMENTS_BASE}${nextPageUrl}`

    return runRequest({
        successAction: (transactions) => {
            return getNextPageTransactionsSuccess(merchantId, transactions)
        },
        requestObj: {
            url,
            cache: true,
            merchantId
        }
    })
}


const getNextPageTransactionsSuccess = (merchantId, transactions) => {
    return {
        type: t.LIST_TRANSACTIONS_NEXT_PAGE_SUCCESS,
        payload: {
            merchantId,
            transactions
        }
    }
}

export const setExpandedSettlement = (merchantId, expandedIndex) => {
    return {
        type: t.SET_EXPANDED_SETTLEMENT_INDEX,
        payload: {
            merchantId,
            expandedIndex
        }
    }
}
