import { localStorageGetItem } from './localstorageHelpers'

export const callApi = ({ method = 'GET', url, body, additionalHeaders, parseJson=true }) => {
  const options = {
    method,
    headers: getRequestHeaders(additionalHeaders)
  }

  if (body) {
    options.body = (typeof body === 'string' || body instanceof String) ? body : JSON.stringify(body)
  }

  return fetch(url, options)
    .then(redirectIfUnauthorized)
    .then(((response) => {
      if (!response.ok) {
        const body = {
          request: options,
          response: response?.json?.()
        }
        return Promise.reject(body)
      }

      if (!parseJson) {
        return response
      }

      const jsonResponse = response?.json?.()
      if (jsonResponse) {
        return jsonResponse
      }
    }))
}

export const getRequestHeaders = (additionalHeaders) => {
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${localStorageGetItem('accessToken')}`)

  if (additionalHeaders) {
    Object.entries(additionalHeaders).forEach(([key, value]) => {
      headers.append(key, typeof value === 'string' ? value : JSON.stringify(value))
    })
  }

  return headers
}

export const redirectIfUnauthorized = (response) => {
  if (response.status === 401) {
    const url = `/login?redirectUrl=${window.location.pathname}`
    window.location.replace(url)
  }
  return response
}
