import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Icon } from '@frontrunners/ui-components'
import { SETTINGS_TEXTS } from '../../merchant/constants'
import { USER_PROFILE_TEXTS } from './intlMessageKeys'
import { Paper, Badge } from '@frontrunners/ui-components'
import { RenameUserModal } from '../general/userAdministration/userModals'
import { ApplicationContext } from '../../common/reactContext'
import { getRoleDisplayText } from '../../common/userRoles'
import { getRenameUserMutation, getUser } from '../../common/userQueries'
import { useQueryClient } from 'react-query'
import { FEATURE_DISABLED as T } from '../../common/translations'
import Spinner from '../../common/components/spinner'
import './userDetails.scss'

export const UserDetails = () => {
    const { formatMessage } = useIntl()
    const queryClient = useQueryClient()
    const { activeMerchant, config, loggedInUser } = useContext(ApplicationContext)
    const [showRenameUserModal, setShowRenameUserModal] = useState(false)
    const {
        isLoading: userLoading,
        data: user
    } = getUser(config.apiHost, activeMerchant.id, loggedInUser.sub, true)

    const renameUserMutation = getRenameUserMutation(queryClient, config.apiHost)
    const userName = loggedInUser.isSupport ? 'N/A' : getUserName(user)
    const userRole = getRoleDisplayText(formatMessage, loggedInUser.role)
    const userEmail = loggedInUser.isSupport ? 'N/A' : loggedInUser.email

    if (userLoading) return <Spinner waiting={true} />
    return (
        <Paper className="user-details">
            <div className="user-details-header">
                <h2 className="settings-paper-header">
                    {formatMessage(USER_PROFILE_TEXTS['merchant-profile-user-preferences-header'])}
                </h2>
                { loggedInUser.isSupport &&
                    <Badge className="orange">{formatMessage(T.disabled_for_support_agents)}</Badge>
                }
            </div>

            <UserDetail
                header={formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_name'])}
                value={userName}
                editable
                onClickAction={() => setShowRenameUserModal(true)}
                disabled={loggedInUser.isSupport}
                className="user-name"
            />
            <UserDetail
                header={formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_access'])}
                value={userRole}
                disabled={loggedInUser.isSupport}
                className="user-role"
            />
            <UserDetail
                header={formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_email'])}
                value={userEmail}
                disabled={loggedInUser.isSupport}
                className="user-email"
            />
            {
                showRenameUserModal &&
                <RenameUserModal
                    user={user}
                    onClose={() => setShowRenameUserModal(false)}
                    onConfirm={(user) => {
                        renameUserMutation.mutateAsync(user)
                        setShowRenameUserModal(false)
                    }}
                />
            }
        </Paper>
    )
}

const UserDetail = ({ header, value, editable, onClickAction, disabled, className }) => {
    const { formatMessage } = useIntl()
    const classNameEditable = editable ? 'editable' : ''
    const classNameDisabled = disabled ? 'disabled' : ''
    return (
        <div className={`detail ${className || ''}`} >
            <div className={`settings-paper-subheader header ${classNameEditable} ${classNameDisabled}`}>
                {header}
            </div>
            <div
                className={`value ${classNameEditable} ${classNameDisabled}`}
                onClick={disabled ? () => { } : onClickAction}
            >
                {value}
                {editable &&
                    <span className="edit">
                        <Icon iconName="pen" scale="0.8" />
                        {formatMessage(SETTINGS_TEXTS['edit'])}
                    </span>
                }
            </div>
        </div>
    )
}

const getUserName = (user) => {
    if (!user) return ''
    else if (!user.givenName && !user.familyName) return 'N/A'
    const upperCaseFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)
    return [upperCaseFirstLetter(user.givenName), upperCaseFirstLetter(user.familyName)].join(' ')
}
