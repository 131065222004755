import { INACTIVE_ACQUIRING_STATUS } from '../constants'
import { getSystemIdToMid, getSaleslocationFromAcquiringMid } from '../helpers'


const getMidsFromSaleslocations = (merchant) => {
    const midList = []

    merchant.saleslocation_set?.forEach(saleslocation => {
        saleslocation?.mid_set.forEach(mid => {
            midList.push({
                id: mid.mid,
                visiting_address: saleslocation.visiting_address,
                description: saleslocation.name,
                channel: mid.acquirer === 'Elixir' ? 'elixir' : '',
                status: mid.status
            })
        })
    })
    return midList
}

const getMidsForInstoreOnline = (merchant) => {
    let systemData = []

    merchant?.system_set?.forEach(item => {
        const system_id_to_mid = getSystemIdToMid(merchant, item.system, item.system_id)
        const saleslocation = system_id_to_mid ? getSaleslocationFromAcquiringMid(merchant, system_id_to_mid.acquiring_mid) : undefined
        const mid = saleslocation?.mid_set?.find(mid => mid.mid === system_id_to_mid.acquiring_mid)

        systemData.push({
            id: item.system_id,
            channel: item.system,
            description: saleslocation?.name || '',
            visiting_address: saleslocation?.visiting_address || '',
            status: mid?.status || ''
        })
    })
    return systemData
}
export const getInactiveAccountListData = (merchant) => {
    const mids = getMidsFromSaleslocations(merchant)
    const inactiveAcquiringMids = mids.filter(mid => mid.status === INACTIVE_ACQUIRING_STATUS)

    return inactiveAcquiringMids
}
export const getActiveAccountListData = (merchant) => {

    //get acquiring data
    const mids = getMidsFromSaleslocations(merchant)
    const activeAcquiringMids = mids.filter(mid => mid.status !== INACTIVE_ACQUIRING_STATUS)

    //get instore/online data
    const systemMids = getMidsForInstoreOnline(merchant)

    const data = [...activeAcquiringMids, ...systemMids]

    return data
}

export const getAccountsData = (merchant) => {

    // Renaming merchant properties in order to maintain compatability with older legacy
    // code that is not using the applicationContext
    merchant.saleslocation_set = merchant.salesLocations
    merchant.system_set = merchant.systemSet
    merchant.extra_data = merchant.extraData

    return [...getMidsFromSaleslocations(merchant), ...getMidsForInstoreOnline(merchant)]
}
