import React, { Component } from 'react'
import { handleScroll } from '../../../common/stickyScroll'
import { injectIntl } from 'react-intl'
import { SALES_DETAILS_TEXTS, FILTER_BUTTON_TEXT } from '../../sales/constants'

class InstoreListHeaders extends Component {
    constructor(props) {
        super(props)
        this.handleScroll = handleScroll.bind(this, '.instore-sales .list-group .header-row', '.instore-sales .list-group')
    }
    componentDidMount () {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.handleScroll)
    }

    render() {
        const { formatMessage } = this.props.intl
        return (
            <div className="list-group">
                <div className="header-row">
                    <div className="row-wrapper">
                        <span className="header-column date-time">
                            {formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_date'])}
                        </span>
                        <span className="header-column merchant-reference">
                            {formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_reference'])}
                        </span>
                        <span className="header-column payment-type">
                            {formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_detail_header'])}
                        </span>
                        <span className="header-column type">
                            {formatMessage(FILTER_BUTTON_TEXT['type'])}
                        </span>
                        <span className="header-column state">
                            {formatMessage(FILTER_BUTTON_TEXT['state'])}
                        </span>
                        <span className="header-column currency">
                            {formatMessage(FILTER_BUTTON_TEXT['currency'])}
                        </span>
                        <span className="header-column amount">
                            {formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_amount'])}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(InstoreListHeaders)
