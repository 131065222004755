import moment from 'moment'
import { SALES_TYPES } from '../sales/sales/constants'
import { pairMidAndSaleslocation } from '../sales/sales/helpers'
import { getSystemIdToMid, getSaleslocationFromAcquiringMid } from '../merchant/helpers'
export const isFuture = (date) => {
    return moment(date) > moment()
}

export const isCurrent = (date, isAggregatePeriodYearly) => {
    const now = moment()
    const then = moment(date)

    const isSameMonth = then.year() === now.year() && then.month() === now.month()

    return isAggregatePeriodYearly ? isSameMonth : isSameMonth && then.date() === now.date()
}

export const isCurrentAmountZero = (data, isAggregatePeriodYearly, now) => {
    //get if the current amount value is 0
    const dataset = getDataForDottedLine(data, isAggregatePeriodYearly, now)

    return dataset && dataset.length > 0 && dataset[dataset.length - 1].amount === 0
}

export const getDataForDottedLine = (data, isAggregatePeriodYearly, now) => {
    //get the dataset with previous month/day and current month/day
    if (!now) now = moment()

    //When is it 1st date or month, then there is no previous month/date value
    const current = isAggregatePeriodYearly ? now.month() : now.date()
    const previous = moment(now).subtract(1, isAggregatePeriodYearly ? 'M' : 'd')

    const dataset = data.filter(d => {
        if (isAggregatePeriodYearly) {
            return (
                moment(d.date).year() === now.year() && (
                    moment(d.date).month() === now.month() ||
                    (current > 0 && moment(d.date).month() === previous.month())
                )
            )
        } else {
            return (
                moment(d.date).month() === now.month() && (
                    moment(d.date).date() === now.date() ||
                    (current > 1 && moment(d.date).date() === previous.date())
                )
            )
        }
    })
    return dataset
}

//number: how many days/months before current
export const getData = (data, isAggregatePeriodYearly, number = 0, now) => {
    if (!now) now = moment()
    const endDate = now.subtract(number, isAggregatePeriodYearly ? 'M' : 'd').format('YYYY-MM-DD')

    const newData = data.filter(d =>
        moment(d.date).format('YYYY-MM-DD') <= endDate
    )
    return newData
}

export const getMaximumValueForGraph = (value, ticks = 5, exponent = 2) => {

    if (value === undefined) return
    const defaultValue = ticks * Math.pow(10, exponent)
    if (value < defaultValue) return defaultValue // To make the graph work with empty datasets or values too low for the graph
    const stringValue = value.toString()

    const digits = stringValue.length < 2 ? 1 : 2

    let maxValue = parseInt(stringValue.substring(0, digits))
    maxValue = 10 + Math.round(maxValue / 10) * 10

    const lengthOfNumber = value === 0 ? stringValue.length : stringValue.length - digits

    const magicValue = maxValue + '0'.repeat(lengthOfNumber)
    return parseInt(magicValue)
}

export const getCurrencyForChannel = (merchantObject, channel) => {
    const salesTypes = merchantObject.extra_data.sales_types

    const settlement_currencies = new Set(merchantObject.extra_data.settlement_currencies)
    const instore_currencies = new Set(merchantObject.extra_data.instore_payment_currencies)
    const online_currencies = new Set(merchantObject.extra_data.online_payment_currencies)

    const mergedCurrencySet = new Set([...settlement_currencies, ...instore_currencies, ...online_currencies])

    switch (channel) {
        case 'acquiring' && salesTypes.acquiring:
            return settlement_currencies
        case 'instore' && salesTypes.instore:
            return instore_currencies
        case 'online' && salesTypes.online:
            return online_currencies
        default:
            return [].concat(Array.from(mergedCurrencySet))

    }
}

const aggregatePaymentIndexInterval = (aggregates, filteredDate, date, channel, preSelectedCurrency, yearly) => {
    let totalAmount = 0
    let paymentCount = 0
    let currency = ''
    let exponent = 2
    let aggregateList = []

    aggregates.data.forEach(aggregate => {
        let aggregateDate

        if (yearly) {
            aggregateDate = getMonthForPayment(aggregate.date)
        } else {
            aggregateDate = aggregate.date
        }

        if (date === aggregateDate) {
            totalAmount += aggregate.amount
            exponent = aggregate.amount_exponent || aggregate.exponent
            currency = aggregate.currency
            paymentCount += aggregate.payment_count
        }
    })

    let monthAsString = date.toString()

    let formattedDate = yearly ? `${moment(filteredDate).format('YYYY')}-${monthAsString.length === 1 ? '0' + monthAsString : monthAsString}-01` : date


    aggregateList.push({
        amount: totalAmount,
        currency: currency,
        payment_count: paymentCount,
        exponent: exponent,
        date: formattedDate
    })

    return aggregateList
}

const aggregateInterval = (aggregates, filteredDate, date, channel, preSelectedCurrency, yearly) => {
    let totalAmount = 0
    let totalRefund = 0
    let paymentCount = 0
    let currency = ''
    let exponent = 2
    let aggregateList = []

    if (channel === 'acquiring') {
        aggregates = modifyAggregatesForAcquiring(aggregates, preSelectedCurrency)
        currency = preSelectedCurrency
    }

    aggregates.data.forEach(aggregate => {
        let aggregateDate

        if (yearly) {
            aggregateDate = getMonthForPayment(aggregate.date)
        } else {
            aggregateDate = aggregate.date
        }

        if (date === aggregateDate) {
            if (channel === SALES_TYPES.acquiring || aggregate.payment_category === 'Sale' && ['Authorized', 'Captured', 'Cleared'].includes(aggregate.state)) {
                totalAmount += aggregate.amount
                exponent = aggregate.amount_exponent || aggregate.exponent
                currency = aggregate.currency

                if (channel === SALES_TYPES.online) {
                    if (aggregate.state == 'Captured') {
                        paymentCount += aggregate.payment_count
                    }
                } else {
                    paymentCount += aggregate.payment_count
                }

            }

            if (aggregate.payment_category === 'Refund') {
                totalAmount = totalAmount - aggregate.amount
            }
        }
    })

    let monthAsString = date.toString()

    let formattedDate = yearly ? `${moment(filteredDate).format('YYYY')}-${monthAsString.length === 1 ? '0' + monthAsString : monthAsString}-01` : date


    aggregateList.push({
        amount: totalAmount,
        totalRefund: totalRefund,
        currency: currency,
        payment_count: paymentCount,
        exponent: exponent,
        date: formattedDate
    })

    return aggregateList
}

export const formatAggregateData = (aggregates, filteredDate, yearly, channel, currency) => {
    let aggregateList = []
    let items = []
    let aggregateObject = {}

    if (yearly) {
        items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    } else {
        items = getDaysInMonth(filteredDate)
    }
    items.forEach((date) => {
        if (channel === 'acquiring') {
            aggregateList = aggregateList.concat(aggregateInterval(aggregates, filteredDate, date, channel, currency, yearly))
        }
        else {
            aggregateList = aggregateList.concat(aggregatePaymentIndexInterval(aggregates, filteredDate, date, channel, currency, yearly))
        }
    })

    aggregateObject.data = aggregateList

    aggregateObject.totalCount = sumAmounts(aggregateList, 'payment_count')
    aggregateObject.totalAmount = sumAmounts(aggregateList, 'amount')

    aggregateObject.currency = aggregateList[0].currency
    aggregateObject.exponent = aggregateList[0].amount_exponent

    return aggregateObject
}

const sumAmounts = (list, key) => {
    return list.reduce((val, i) => {
        val += i[key]
        return val
    }, 0)
}

export const getDaysInMonth = (selectedMonth) => {
    var daysInMonth = moment(selectedMonth).daysInMonth()
    var arrDays = []

    while (daysInMonth) {
        var current = moment(selectedMonth).date(daysInMonth)
        arrDays.push(current.format('YYYY-MM-DD'))
        daysInMonth--
    }

    return arrDays.reverse()
}

const modifyAggregatesForAcquiring = (aggregates, preSelectedCurrency) => {

    if (aggregates.data.length === 0) {
        return { data: [] }
    }

    const filteredAggregates = aggregates.data.filter((aggregate) => aggregate.currency === preSelectedCurrency)
    const agg = filteredAggregates[0].intervals.map((aggregate) => {
        aggregate.date = aggregate.interval
        aggregate.amount = aggregate.amount_sum
        aggregate.payment_count = aggregate.count
        aggregate.currency = aggregate.currency // eslint-disable-line no-self-assign
        return aggregate
    })
    return { data: agg }
}

const getMonthForPayment = (paymentDate) => {
    return moment(paymentDate).month() + 1
}

export const getPayoutAggregatesForInterval = (aggregates, interval = 'latest') => {
    const aggregatesForinterval = {}
    let filteredAggregates = []

    aggregates.forEach((aggregateByCurrency) => {
        if (interval === 'latest') {
            filteredAggregates = aggregateByCurrency.intervals.filter(agg => agg.amount_sum !== 0)
            filteredAggregates = filteredAggregates.reverse()
            filteredAggregates = [filteredAggregates[0]]
        } else {
            filteredAggregates = aggregateByCurrency.intervals.filter((agg) => {
                return agg.interval >= interval
            })
        }

        aggregatesForinterval[aggregateByCurrency.currency] = {
            currency: aggregateByCurrency.currency,
            exponent: aggregateByCurrency.intervals[0].amount_exponent,
            amount: sumAmounts(filteredAggregates, 'amount_sum')
        }
    })

    return aggregatesForinterval
}


export const pairSalesLocationToSystemIds = (merchant, system_type) => {
    let saleslocations = []

    merchant.system_set.filter(system => system.system == system_type).forEach(system => {
        let system_id_to_mid = getSystemIdToMid(merchant, system.system, system.system_id)

        if (system_id_to_mid) {
            let acquiring_mid = system_id_to_mid['acquiring_mid']
            let saleslocation = getSaleslocationFromAcquiringMid(merchant, acquiring_mid)

            if (saleslocation) {
                let mid = saleslocation.mid_set.find(mid => mid.mid === acquiring_mid)
                saleslocations.push({
                    name: saleslocation.name,
                    mid: system.system_id,
                    status: mid.status
                })
            }
            else {
                saleslocations.push(system.system_id)
            }
        }
        else {
            saleslocations.push(system.system_id)
        }
    })

    return saleslocations
}


export const getAvailableSystemIds = (channels, merchant) => {
    let systemIds = {}

    if (channels.includes('acquiring')) {
        systemIds.elixirIds = pairMidAndSaleslocation(merchant)
    }

    if (channels.includes('instore')) {
        systemIds.instoreIds = pairSalesLocationToSystemIds(merchant, 'samport')
    }

    if (channels.includes('online')) {
        systemIds.onlineIds = pairSalesLocationToSystemIds(merchant, 'zero')
    }

    return systemIds
}
