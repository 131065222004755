import types from './actionTypes'
import { Map, List, fromJS, Set } from 'immutable'
import * as paginationHelpers from '../../common/components/paginationLoadMore'
import { PAGE_OFFSET, PAGE_LIMIT } from '../../common/constants'
import { isDateFilter } from '../acquiring/reducer'

export const initState = Map({
    list: Map({
        instorePayments: List()
    }),
    current: Map(),
    error: Map(),
    url: '',
    filters: Map({
        offset: PAGE_OFFSET,
        limit: PAGE_LIMIT
    })
})

var initialFilterState = {
    selected: Set(),
    opened: false
}


export default function instoreReducer(instore = initState, action) {
    switch (action.type) {
        case types.LIST_START:
            var modifiedState = instore.setIn(['url'], action.payload.url)
                .setIn(['list', 'isFetching'], true)
                .setIn(['list', 'isError'], false)
                .setIn(['list', 'isOutOfRange'], false)
            if (action.payload.hasActiveFilters) {
                const stateWithActiveFilters = modifiedState.setIn(['list', 'instorePayments'], List([]))
                    .setIn(['list', 'isFetching'], true)
                    .setIn(['list', 'isError'], false)
                return stateWithActiveFilters
            }
            return modifiedState
        case types.LIST_SUCCESS:
            var prevPage = instore.getIn(['list', 'instorePayments'])
            var nextPage = action.payload.transactions.data
            var currentOffset = instore.getIn(['filters', 'offset'])
            var updatedList = paginationHelpers.appendNextPage(prevPage, nextPage, action.payload.search)
            return instore.setIn(['list', 'instorePayments'], List(updatedList))
                .setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isError'], false)
                .setIn(['list', 'isOutOfRange'], false)
                .setIn(['list', 'emptyList'], nextPage.length == 0)
                .setIn(['list', 'total'], action.payload.transactions.total)
                .setIn(['list', 'unixtime'], new Date().valueOf())
                .setIn(['list', 'url'], action.payload.url)
                .setIn(['filters', 'offset'], currentOffset + PAGE_LIMIT)
        case types.LIST_NEXT_PAGE_SUCCESS:
            return instore.setIn(['list', 'nextPage'], action.payload.transactions)
        case types.LIST_ERROR:
            return instore.setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isError'], true)
        case types.LOAD_MORE:
            var prevSearch = instore.getIn(['list', 'search'])
            var newSearch = action.payload.params
            var mergedSearch = Object.assign({}, prevSearch, newSearch)
            return instore.setIn(['list', 'search'], mergedSearch)
        case types.LIST_OUT_OF_RANGE:
            return instore.setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isOutOfRange'], true)
        case types.LIST_ONLINE_OUT_OF_RANGE:
            return instore.setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isOutOfRange'], true)
        case types.GET_DETAILS_START:
            return instore.setIn(['current', 'isFetching'], true)
                .setIn(['current', 'isError'], false)
        case types.GET_DETAILS_SUCCESS:
            return instore.setIn(['current', 'data'], action.payload.payment)
                .setIn(['current', 'isFetching'], false)
                .setIn(['current', 'isError'], false)
        case types.GET_DETAILS_ERROR:
            return instore.setIn(['current', 'isError'], true)
        case types.CHANGE_FILTER:
            var filters = Set(action.payload.filterValue)
            return instore
                .setIn(['filters', action.payload.filterType, 'selected'], filters).setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.TOGGLE_FILTER:
            var filterSetToggleFilter = fromJS(initialFilterState)
            if (instore.getIn(['filters', action.payload.filterType])) {
                return instore.setIn(['filters', action.payload.filterType, 'opened'], action.payload.opened)
            } else {
                return instore.setIn(['filters', action.payload.filterType], filterSetToggleFilter)
                    .setIn(['filters', action.payload.filterType, 'opened'], action.payload.opened)
            }
        case types.CLEAR_FILTER:
            if (isDateFilter(action.payload.filterType)) {
                return instore.removeIn(['filters', 'to_datetime'])
                    .removeIn(['filters', 'from_datetime'])
                    .setIn(['filters', 'offset'], PAGE_OFFSET)
                    .setIn(['filters', 'limit'], PAGE_LIMIT)
            }
            return instore.setIn(['filters', action.payload.filterType, 'selected'], Set())
                .setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.CLEAR_ALL_FILTERS:
            return initState
        case types.SET_CURRENT:
            return instore.setIn(['current', 'data'], action.payload.transaction)
        case types.APPLY_DATE_FILTER:
            return instore.setIn(['filters', action.payload.filterType], action.payload.filterValue)
                .setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.RESET_PAGINATION:
            return instore.setIn(['filters', 'offset'], PAGE_OFFSET).setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.GET_SETTLED_INFORMATION_START:
            return instore.setIn(['current', 'isFetching'], true)
        case types.GET_SETTLED_INFORMATION_SUCCESS:
            var payment = action.payload.payments.data

            if (payment.length > 0) {
                payment = action.payload.payments.data[0]
            } else {
                payment = null
            }

            return instore.setIn(['current', 'settled_information'], payment)
        case types.GET_SETTLED_INFORMATION_ERROR:
            return instore.setIn(['current', 'isError'], true)
                .setIn(['current', 'isFetching'], true)
        default:
            return instore
    }
}
