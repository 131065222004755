import React from 'react'

const FrodaComponent = (props) => {
    return (
        <div className="content">
            <iframe src={`${props.url}/partner/landing/bambora?alias=${props.merchantId}&token=${props.token}&locale=${props.locale}`}
                    width="100%"
                    frameBorder="0"></iframe>
        </div>
    )
}

export default FrodaComponent
