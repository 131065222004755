import React from 'react'
import { SALES_DETAILS_TEXTS } from '../../sales/constants'
import { getAuthorizationOperation } from '../../sales/helpers'
import { getSaleslocationNameFromMid } from '../../../settlements/helpers'


const SalesInstorePOS = ({payment, saleslocations, formatMessage}) => {
    const mid = payment.source_merchant_id
    const authObject = getAuthorizationOperation(payment)
    const saleslocation = getSaleslocationNameFromMid(saleslocations, authObject.acquiring_mid)
    return (
        <div className="acquiring">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS['details_point_of_sale_header'])}</div>
            <div className="detail-wrapper">
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['details_instore_id'])}</div>
                    <div className="element">{mid}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['details_acquiring_id'])}</div>
                    <div className="element">{authObject.acquiring_mid}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['details_saleslocation'])}</div>
                    <div className="element">{saleslocation}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_instore_terminal_id'])}</div>
                    <div className="element">{authObject.terminal_id}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_instore_cashier_id'])}</div>
                    <div className="element">{authObject.cashier_identity}</div>
                </div>
            </div>
        </div>
    )
}

export default SalesInstorePOS
