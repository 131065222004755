import React from 'react'
import { MONTHLY_REPORTS_TEXT } from './constants'
import { STATES as REPORT_TASK_STATES } from './constants'
import { Icon } from '@frontrunners/ui-components'
import moment from 'moment'
import mixpanel from 'mixpanel-browser'
import { mixpanelPrefix } from '../common/helpers/mixpanelPrefix'
import { MonthlyReportNotification } from './monthlyReportNotification'
import { getAvailableReportTypes } from './reportTypes'

const MonthlyReportsNotificationsContainer = ({
        merchant,
        activeReportTasks,
        dismissReportTask,
        downloadFile,
        notifiableTaskIds,
        locale,
        intl
    }) => {

    const finishedNotifiableReportTasks = activeReportTasks.filter(
        task => task.status === REPORT_TASK_STATES.done && notifiableTaskIds.has(task.id))
    const failedNotifiableReportTasks = activeReportTasks.filter(
        task => task.status === REPORT_TASK_STATES.failed && notifiableTaskIds.has(task.id))

    const downloadNotificationBody = (reportTask) => {
        const reportDate = new Date(reportTask.from_date)
        const monthYear = moment(reportDate).locale(locale).format('MMMM YYYY')
        return intl.formatMessage(MONTHLY_REPORTS_TEXT['pending_download_notification_body'], {
            monthYear: monthYear,
            reportType: getAvailableReportTypes(merchant, intl).find((reportType) => reportType.id === reportTask.report_type_id).description
        })
    }

    return (
        <div className="notifications-container">
            { failedNotifiableReportTasks.map((reportTask) => <MonthlyReportNotification
                key={reportTask.id}
                header={intl.formatMessage(MONTHLY_REPORTS_TEXT['report_notification_header'])}
                body={intl.formatMessage(MONTHLY_REPORTS_TEXT['report_notification_body'], {cancelIcon: <div key={reportTask.id} className="inline-icon"></div>})}
                icon={<Icon iconName={'warning'} scale={1.1}/>}
                closeIcon={<Icon iconName="closeThin" scale={1.2}/>}
                closeIconOnClick={() => {
                    mixpanel.track(`${mixpanelPrefix.MONTHLY_REPORTS} Dismissed failed report via notification`)
                    dismissReportTask(reportTask)
                }
            }
            /> )}
            { finishedNotifiableReportTasks.map((reportTask) => <MonthlyReportNotification
                key={reportTask.id}
                header={intl.formatMessage(MONTHLY_REPORTS_TEXT['pending_download_notification_header'])}
                secondaryHeader={intl.formatMessage(MONTHLY_REPORTS_TEXT['pending_download_notification_header_dismiss'])}
                body={downloadNotificationBody(reportTask)}
                secondaryBody={intl.formatMessage(MONTHLY_REPORTS_TEXT['pending_download_notification_body_dismiss'])}
                downloadText={intl.formatMessage(MONTHLY_REPORTS_TEXT['pending_download_notification_footer_download'])}
                dismissDownloadText={intl.formatMessage(MONTHLY_REPORTS_TEXT['pending_download_notification_footer_dismiss'])}
                cancelDismissalText={intl.formatMessage(MONTHLY_REPORTS_TEXT['pending_download_notification_footer_back'])}
                icon={<div className="capture"><Icon iconName={'capture'} scale={0.8} /></div>}
                secondaryIcon={<Icon iconName={'questionMark'} scale={1.1}/>}
                closeIcon={<Icon iconName="closeThin" scale={1.2} />}
                dismissDownload={() => {
                    mixpanel.track(`${mixpanelPrefix.MONTHLY_REPORTS} Dismissed report via notification`)
                    dismissReportTask(reportTask)
                }}
                triggerDownload={() => {
                    mixpanel.track(`${mixpanelPrefix.MONTHLY_REPORTS} Download report via notification`)
                    downloadFile(reportTask)}
                }
            /> ) }
        </div>
    )
}

export default MonthlyReportsNotificationsContainer
