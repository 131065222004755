
//monthly report
export const GET_AVAILABLE_MONTHLY_REPORTS = 'reports/GET_AVAILABLE_MONTHLY_REPORTS'
export const GET_MONTHLY_REPORT_TASK_START = 'reports/GET_MONTHLY_REPORT_TASK_START'
export const GET_MONTHLY_REPORT_TASK_ERROR = 'reports/GET_MONTHLY_REPORT_TASK_ERROR'
export const GET_MONTHLY_REPORT_TASK_SUCCESS = 'reports/GET_MONTHLY_REPORT_TASK_SUCCESS'
export const GET_MONTHLY_REPORT_TASK_PROCESS = 'reports/GET_MONTHLY_REPORT_TASK_PROCESS'
export const GET_MONTHLY_REPORT_TASK_RESET = 'reports/GET_MONTHLY_REPORT_TASK_RESET'

//sales report
export const GET_SALES_REPORT_TASK_START = 'reports/GET_SALES_REPORT_TASK_START'
export const GET_SALES_REPORT_TASK_ERROR = 'reports/GET_SALES_REPORT_TASK_ERROR'
export const POLL_SALES_REPORT_TASK_SUCCESS = 'reports/POLL_SALES_REPORT_TASK_SUCCESS'
export const GET_SALES_REPORT_TASK_PROCESS = 'reports/GET_SALES_REPORT_TASK_PROCESS'
export const GET_SALES_REPORT_TASK_RESET = 'reports/GET_SALES_REPORT_TASK_RESET'

//settlement report
export const GET_SETTLEMENT_REPORT_TASK_RESET = 'reports/GET_SETTLEMENT_REPORT_TASK_RESET'
export const GET_REPORT_TASK_START = 'reports/GET_REPORT_TASK_START'
export const GET_REPORT_TASK_ERROR = 'reports/GET_REPORT_TASK_ERROR'
export const GET_REPORT_TASK_DONE = 'reports/GET_REPORT_TASK_DONE'
