import React, { Component } from 'react'
import iso4217 from 'currency-codes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import { SALES_DETAILS_TEXTS, SALES_TYPES, ACTIVE_TABS } from '../constants'
import { PAYMENT_METHODS, OPERATIONS, MODAL_OPERATIONS, PAYMENT_ROUTER_STATES } from '../../online/constants'
import { getExponentedAmountString } from '../../../common/amountHelpers'
import CommonErrorComponent from '../../../common/commonErrorComponent'
import * as onlineHelpers from '../../online/helpers'
import * as onlineActions from '../../online/actions'
import * as instoreActions from '../../instore/actions'

import { SalesAcquiringCardDetails, SalesAcquiringPaymentDetails, SalesAcquiringPOS } from '../../acquiring/components'
import { SalesInstorePOS, SalesInstoreCardDetails, SalesInstorePaymentDetails, InstoreEventHistory } from '../../instore/components'
import { OnlinePaymentAuthorization, OnlinePaymentDetails, OnlinePaymentOrderlines, OnlinePaymentStatus, OnlineEventHistory, OnlinePointOfSale } from '../../online/components'
import { CardHeader, OnlineCancelModal, SubMenu } from '../../sales/components'
import OperationCapture from '../../online/containers/operationCapture'
import OperationRefund from '../../online/containers/operationRefund'
import OnlineOperationModal from '../../online/components/operations/onlineOperationModal'

import MonetaryAmount from '../../../common/components/monetaryAmount'
import Spinner from '../../../common/components/spinner'
import { getMerchant } from '../../../reports/helpers'
import { getAuthorizationOperation } from '../helpers'
import { load } from '../actions'
import { getSettlementReference } from '../../../settlements/actions'
import ErrorBoundary from '../../../common/components/errorBoundary'
import { ROLES } from '../../../merchant/constants'
import InstoreRefund from './salesInstoreRefund'
import { useState } from 'react'
import RoleToggle from '../../../common/containers/roleToggle'


const loadSalesActions = (activeView) => {
    const actions = load(activeView)
    return actions
}


class SalesDetailsContainer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            refund: {
                displayRemainingAmount: false
            },
            capture: {
                displayRemainingAmount: false
            },
            tabs: {
                isDetailActive: true,
                isOrderlinesActive: false,
                isHistoryActive: false
            },
            localOperations: new Set(),
            paymentId: this.props.match.params.paymentId
        }

        this.toggleAmountRemaining = this.toggleAmountRemaining.bind(this)
        this.showOperationsModal = this.showOperationsModal.bind(this)
        this.goBack = this.goBack.bind(this)
        this.showCancelModal = this.showCancelModal.bind(this)
        this.initialFetch = this.initialFetch.bind(this)
        this.handleTabsOnResize = this.handleTabsOnResize.bind(this)
        this._loadActions()
    }

    initialFetch() {
        const activeView = this._determineActiveView()
        if (activeView === SALES_TYPES.online) {
            this.props.dispatch(onlineActions.getDetails(
                this.props.match.params.merchantId,
                this.props.match.params.paymentId
            ))
        } else if (activeView === SALES_TYPES.instore) {
            this.props.dispatch(instoreActions.getDetails(
                this.props.match.params.merchantId,
                this.props.match.params.paymentId
            ))
        } else if (activeView === SALES_TYPES.acquiring) {
            if (!this.props.currentAcquiringPayment.get('data')) {
                return this._redirectToListView()
            }
            const payment = this.props.currentAcquiringPayment.get('data')
            this.props.dispatch(getSettlementReference(
                this.props.match.params.merchantId,
                payment.settlement_id
            ))
        }
    }

    addLocalOperations(operation) {
        this.setState({localOperations: this.state.localOperations.add(operation)})
    }

    _loadActions() {
        const activeView = this._determineActiveView()
        if (activeView) {
            this.actions = loadSalesActions(activeView)
        }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.match.params.paymentId !== this.props.match.params.paymentId) {
            this.initialFetch()
        }
        this._loadActions()
    }

    componentDidMount() {
        this.handleTabsOnResize()
        this._loadActions()
        window.scrollTo(0, 0)
        window.addEventListener('resize', this.handleTabsOnResize, false)
        this.initialFetch()
    }

    componentWillUnmount() {
        this.props.dispatch(onlineActions.removeCurrentPayment())
        window.removeEventListener('resize', this.handleTabsOnResize, false)
    }

    handleTabsOnResize() {
        const activeView = this._determineActiveView()
        const isSmallScreen = window.matchMedia('(max-width: 1100px)').matches
        if (!isSmallScreen && [SALES_TYPES.instore, SALES_TYPES.online].includes(activeView)) {
            let tabState = this.state.tabs

            if (tabState[ACTIVE_TABS.history] && tabState[ACTIVE_TABS.detail]) return

            tabState[ACTIVE_TABS.history] = true
            tabState[ACTIVE_TABS.detail] = !tabState[ACTIVE_TABS.orderlines]

            this.setState(tabState)
        } else {
            if (this.state.tabs.isHistoryActive && !this.state.tabs.isDetailActive) {
                this.switchTab(ACTIVE_TABS.history)
            } else {
                this.switchTab(ACTIVE_TABS.detail)
            }
        }
    }

    switchTab(tab, isInvoice = false) {
        let currentTabState = this.state.tabs
        const isSmallScreen = window.matchMedia('(max-width: 1100px)').matches

        for (var key in this.state.tabs) {
            currentTabState[key] = tab === key
        }

        if (isInvoice && !isSmallScreen) {
            currentTabState['isHistoryActive'] = true
        }

        this.setState({ tabs: currentTabState })
    }

    goBack() {
        this.props.history.goBack()
    }

    _redirectToListView() {
        this.props.history.push({ pathname: `/merchants/${this.props.match.params.merchantId}/sales` })
    }

    isInvoice(payment, authorization) {
        return authorization.order_information && payment.payment_method === PAYMENT_METHODS.INVOICE
    }

    isCardPayment(payment) {
        return payment.payment_method === PAYMENT_METHODS.CARD
    }

    showCancelModal(payment) {
        if (payment.state === PAYMENT_ROUTER_STATES.CANCELED) {
            return false
        }
        this.props.dispatch(onlineActions.showCancelModal(
            this.props.match.params.merchantId,
            this.props.match.params.paymentId
        ))
    }

    showOperationsModal(event, payment, operation) {
        event.stopPropagation()
        if (operation === OPERATIONS.CAPTURE && onlineHelpers.getCapturableAmount(payment) === 0 || payment.state === PAYMENT_ROUTER_STATES.CANCELED) {
            return false
        }

        if (operation === OPERATIONS.REFUND && onlineHelpers.getRefundableAmount(payment) === 0 || payment.state === PAYMENT_ROUTER_STATES.CANCELED) {
            return false
        }

        if ([OPERATIONS.CAPTURE, OPERATIONS.REFUND].includes(operation)) {
            this.toggleAmountRemaining(operation)
        }
        this.props.dispatch(onlineActions.getDetails(this.props.match.params.merchantId, payment.external_id))
        this.props.dispatch(onlineActions.showOperationsModal(
            this.props.match.params.merchantId,
            operation,
            payment.external_id,
            true
        ))
    }

    toggleAmountRemaining(actionType) {
        const isDisplayed = this.state[actionType.toLowerCase()].displayRemainingAmount
        return this.setState({ [actionType.toLowerCase()]: { displayRemainingAmount: !isDisplayed } })
    }

    renderOnlineView() {
        const activeView = 'online'
        const { formatMessage } = this.props.intl

        if (this.props.currentOnlinePayment.get('isFetching')) {
            return (
                <div className="text-center online-spinner">
                    <Spinner waiting={true} />
                </div>
            )
        }

        if (this.props.currentOnlinePayment.get('isError')) {
            return <CommonErrorComponent />
        }

        const merchant = getMerchant(this.props.match.params.merchantId, this.props.merchant.get('merchants'))

        const paymentState = this.props.currentOnlinePayment.toJS()
        const currentPayment = paymentState['data']
        let authorization = onlineHelpers.getAuthorization(currentPayment)
        const isInvoice = this.isInvoice(currentPayment, authorization)

        const displayRefundableAmount = this.state.refund.displayRemainingAmount
        const displayCapturableAmount = this.state.capture.displayRemainingAmount
        const activeDetailClass = this.state.tabs.isDetailActive ? 'active' : ''
        const activeOrderlineClass = this.state.tabs.isOrderlinesActive ? 'active' : ''
        const activeHistoryClass = this.state.tabs.isHistoryActive ? 'active' : ''
        const isInvoiceModalOpened = this.props.displayOperationsModal && currentPayment.payment_method === PAYMENT_METHODS.INVOICE
        const cardNumber = authorization.card ? authorization.card.cardNumber : ''
        const paymentType = authorization.payment_type
        const currency = iso4217.code(authorization.currency)
        authorization.amount_exponent = currency.digits
        return (
            <div className={`${activeView} details`}>
                <ErrorBoundary>
                    <SubMenu formatMessage={formatMessage}
                        merchantId={this.props.match.params.merchantId}
                        merchants={this.props.merchant.get('merchants')}
                        goBack={this.goBack}
                        className={`${activeView}`}
                    />
                </ErrorBoundary>
                <ErrorBoundary>
                    <div className="content-wrapper main-width-2 list-container">
                        <div className="row">
                            <div className="col-2 left online-card-section">
                                {<CardHeader cardNumber={cardNumber} paymentType={paymentType} />}
                                <OnlineActions
                                    showOperationsModal={this.showOperationsModal}
                                    displayCapturableAmount={displayCapturableAmount}
                                    displayRefundableAmount={displayRefundableAmount}
                                    toggleAmountRemaining={this.toggleAmountRemaining}
                                    toggleCancelModal={this.showCancelModal}
                                    payment={currentPayment}
                                    merchant={merchant} />
                            </div>
                            <div className="col-2 right online-history-wrapper--big-screen"> {this.state.tabs.isHistoryActive && <OnlineEventHistory payment={paymentState} formatMessage={formatMessage} />}</div>
                        </div >
                        {isInvoice ? <nav className="tabs">
                            <div className={`${activeDetailClass}`} onClick={() => this.switchTab(ACTIVE_TABS.detail, isInvoice)}>{formatMessage(SALES_DETAILS_TEXTS.details_tab)}</div>
                            <div className={`${activeOrderlineClass}`} onClick={() => this.switchTab(ACTIVE_TABS.orderlines, isInvoice)}>{formatMessage(SALES_DETAILS_TEXTS.purchased_items_tab)}</div>
                            <div className={`${activeHistoryClass} history hide`} onClick={() => this.switchTab(ACTIVE_TABS.history, isInvoice)}>{formatMessage(SALES_DETAILS_TEXTS.history_tab)}</div>
                        </nav> : <nav className="tabs online hide">
                                <div className={`${activeDetailClass}`} onClick={() => this.switchTab(ACTIVE_TABS.detail)}>{formatMessage(SALES_DETAILS_TEXTS.details_tab)}</div>
                                <div className={`${activeHistoryClass}`} onClick={() => this.switchTab(ACTIVE_TABS.history)}>{formatMessage(SALES_DETAILS_TEXTS.history_tab)}</div>
                            </nav>
                        }
                        <div className="online-history-wrapper--small-screen"> {this.state.tabs.isHistoryActive && <OnlineEventHistory payment={paymentState} formatMessage={formatMessage} />}</div>
                        {this.state.tabs.isDetailActive && <OnlineDetails authorization={authorization} merchant={merchant} isInvoice={isInvoice} payment={currentPayment} formatMessage={formatMessage} />}
                        {this.state.tabs.isOrderlinesActive && <PurchasedItems authorization={authorization} formatMessage={formatMessage} />}
                    </div>
                </ErrorBoundary>
                <ErrorBoundary>
                    <div className="order-details-container">
                        {this.props.displayCancelModal &&
                            <OnlineCancelModal merchantId={this.props.match.params.merchantId} authorized_amount={authorization.amount} />
                        }
                        {this.props.displayOperationsModal && !onlineHelpers.isNewOperationFlow(currentPayment.acquirer_name) &&
                            <OnlineOperationModal orderDetails={true} merchantId={this.props.match.params.merchantId} authorized_amount={authorization.amount} amount_exponent={authorization.amount_exponent} />
                        }

                        {isInvoiceModalOpened &&
                            this.props.action === MODAL_OPERATIONS.REFUND &&
                            onlineHelpers.isNewOperationFlow(currentPayment.acquirer_name) &&
                            <OperationRefund
                                authorization={authorization}
                                merchantId={this.props.match.params.merchantId}
                                order={currentPayment}
                            />
                        }

                        {isInvoiceModalOpened &&
                            this.props.action === MODAL_OPERATIONS.CAPTURE &&
                            onlineHelpers.isNewOperationFlow(currentPayment.acquirer_name) &&
                            <OperationCapture
                                authorization={authorization}
                                merchantId={this.props.match.params.merchantId}
                                order={currentPayment}
                            />
                        }
                        {isInvoiceModalOpened &&
                            this.props.action === MODAL_OPERATIONS.CAPTURE &&
                            !onlineHelpers.isNewOperationFlow(currentPayment.acquirer_name) &&
                            <OnlineOperationModal orderDetails={true} merchantId={this.props.match.params.merchantId} authorized_amount={authorization.amount} amount_exponent={authorization.amount_exponent} />
                        }
                    </div>
                </ErrorBoundary>
            </div>
        )
    }

    renderAcquiringView() {
        if (!this.props.currentAcquiringPayment.get('data')) {
            return null
        }

        const payment = this.props.currentAcquiringPayment.get('data')
        const activeView = 'acquiring'
        const paymentType = payment.card_scheme
        const cardNumber = payment.card_number
        const merchant = getMerchant(this.props.match.params.merchantId, this.props.merchant.get('merchants'))
        const { formatMessage } = this.props.intl
        const settlement = this.props.settlements.get('current')

        return (
            <div className={`${activeView} details`}>
                <ErrorBoundary>
                    <SubMenu formatMessage={formatMessage}
                        merchantId={this.props.match.params.merchantId}
                        merchants={this.props.merchant.get('merchants')}
                        paymentId={payment.reference}
                        goBack={this.goBack}
                        className={`sales ${activeView}`}
                    />
                </ErrorBoundary>
                <ErrorBoundary>
                    <div className="content-wrapper main-width-2 list-container">
                        <div className="row">
                            <CardHeader cardNumber={cardNumber} paymentType={paymentType} />
                            <div className="state acquiring">
                                <div className="total amount">
                                    <MonetaryAmount amount={payment.amount} amount_exponent={payment.amount_exponent} className="fg-darkest" currency={payment.currency} excludeCurrency={false} hideInsignificantDecimals={false} />
                                </div>
                            </div>
                        </div>
                        <SalesAcquiringPaymentDetails settlement={settlement} payment={payment} formatMessage={formatMessage} />
                        <SalesAcquiringPOS merchant={merchant} payment={payment} formatMessage={formatMessage} />
                        <SalesAcquiringCardDetails payment={payment} formatMessage={formatMessage} localeCode={this.props.localeCode} />
                    </div>
                </ErrorBoundary>
            </div>
        )
    }

    _determineActiveView() {
        // This will be done by checking "this.props.match.params.view"
        // But since it's feature toggled for now we can't modify the routes
        // So we do this check for now.

        const path = this.props.match.path
        if (path.includes(SALES_TYPES.acquiring)) {
            return SALES_TYPES.acquiring
        } else if (path.includes(SALES_TYPES.online)) {
            return SALES_TYPES.online
        } else if (path.includes(SALES_TYPES.instore)) {
            return SALES_TYPES.instore
        }
    }

    render() {
        const activeView = this._determineActiveView()
        if (activeView == SALES_TYPES.acquiring) {
            return this.renderAcquiringView()
        } else if (activeView == SALES_TYPES.online) {
            return this.renderOnlineView()
        } else if (activeView == SALES_TYPES.instore) {
            return this.renderInstoreView()
        }
    }

    renderInstoreView() {
        if (this.props.currentInstorePayment.get('isError')) {
            return <CommonErrorComponent />
        }

        const payment = this.props.currentInstorePayment.get('data')
        const paymentSettledInformation = this.props.currentInstorePayment.get('settled_information')

        if (!payment) {
            return null
        }

        if (this.props.currentInstorePayment.get('isFetching')) {
            return (
                <div className="text-center ">
                    <Spinner waiting={true} />
                </div>
            )
        }

        const paymentId = this.props.match.params.paymentId
        const activeView = 'instore'
        const { formatMessage } = this.props.intl
        const activeDetailClass = this.state.tabs.isDetailActive ? 'active' : ''
        const activeHistoryClass = this.state.tabs.isHistoryActive ? 'active' : ''
        const authorizationObject = getAuthorizationOperation(payment)
        const merchant = getMerchant(this.props.match.params.merchantId, this.props.merchant.get('merchants'))
        const config = this.props.config
        const isSupportalUser = merchant.loggedInUserIsSupport

        return (
            <div className={`${activeView} details`}>
                <ErrorBoundary>
                    <SubMenu formatMessage={formatMessage}
                        merchantId={this.props.match.params.merchantId}
                        merchants={this.props.merchant.get('merchants')}
                        paymentId={paymentId}
                        goBack={this.goBack}
                        className={`sales ${activeView}`}
                    />
                </ErrorBoundary>
                <ErrorBoundary>
                    <div className="content-wrapper main-width-2 list-container">
                        <div className="row">
                            <div className="col-2 left">
                                <CardHeader cardNumber={authorizationObject.card_number} paymentType={payment.payment_type} />
                                <div className="state acquiring">
                                    <div className="total amount">
                                        <MonetaryAmount amount={authorizationObject.amount} amount_exponent={authorizationObject.currency_exponent} className="fg-darkest" currency={authorizationObject.currency_code} excludeCurrency={false} hideInsignificantDecimals={false} />
                                    </div>
                                        <RoleToggle allow={[ROLES.OWNER, ROLES.ADMIN]}>
                                            <InstoreActions addLocalOperations={this.addLocalOperations.bind(this)} merchantId={this.props.match.params.merchantId} payment={payment} formatMessage={formatMessage} config={config} isSupportalUser={isSupportalUser}/>
                                        </RoleToggle>
                                </div>

                            </div>
                            <div className="order-history-wrapper--big-screen">
                                {
                                    this.state.tabs.isHistoryActive &&
                                    <InstoreEventHistory payment={payment}
                                                         localOperations={this.state.localOperations}
                                                         authorization={authorizationObject}
                                                         formatMessage={formatMessage}
                                                         paymentSettledInformation={paymentSettledInformation}
                                    />
                                }
                            </div>
                        </div>
                        <nav className="tabs instore hide">
                            <div className={`${activeDetailClass}`} onClick={() => this.switchTab(ACTIVE_TABS.detail)}>{formatMessage(SALES_DETAILS_TEXTS.details_tab)}</div>
                            <div className={`${activeHistoryClass}`} onClick={() => this.switchTab(ACTIVE_TABS.history)}>{formatMessage(SALES_DETAILS_TEXTS.history_tab)}</div>
                        </nav>
                        <div className="order-history-wrapper--small-screen">
                            {
                                this.state.tabs.isHistoryActive &&
                                <InstoreEventHistory payment={payment}
                                                     localOperations={this.state.localOperations}
                                                     authorization={authorizationObject}
                                                     formatMessage={formatMessage}
                                                     paymentSettledInformation={paymentSettledInformation}
                            />}
                        </div>
                        {this.state.tabs.isDetailActive && <InstoreDetails merchant={merchant} payment={payment} formatMessage={formatMessage} />}

                    </div>
                </ErrorBoundary>
            </div>
        )
    }

}

export const InstoreActions = ({ payment, formatMessage, config, merchantId, addLocalOperations, isSupportalUser}) => {
    const [instoreLocalRefundedAmount, updateInstoreLocalRefundedAmount] = useState(0)
    const authorizationObject = getAuthorizationOperation(payment)
    const isPaymentRefundable = payment.transaction_type_id === 'Sale' && ( payment.state === 'Cleared' || payment.state === 'Captured' || payment.state === 'Authorized')
    const disableRefundButton = payment.refundable_amount - instoreLocalRefundedAmount <= 0 || isSupportalUser
    return ( isPaymentRefundable ? <InstoreRefund
        instoreLocalRefundedAmount={instoreLocalRefundedAmount}
        updateInstoreLocalRefundedAmount={updateInstoreLocalRefundedAmount}
        merchantId={merchantId}
        formatMessage={formatMessage}
        config={config}
        payment={payment}
        exponent={authorizationObject.currency_exponent}
        currency={authorizationObject.currency_code}
        addLocalOperations={addLocalOperations}
        disableRefundButton={disableRefundButton}
    /> : null )
}

const OnlineDetails = ({ merchant, payment, formatMessage, isInvoice }) => {
    return (
        <div>
            <div className="col-2 left">
                <OnlinePaymentAuthorization formatMessage={formatMessage} payment={payment} />
                <OnlinePaymentDetails formatMessage={formatMessage} payment={payment} isInvoice={isInvoice} />
            </div>
            <div className="col-2 right"><OnlinePaymentStatus formatMessage={formatMessage} payment={payment} />
                <OnlinePointOfSale merchant={merchant} formatMessage={formatMessage} payment={payment} /></div>
        </div>
    )
}

const InstoreDetails = ({ payment, merchant, formatMessage }) => {
    return (
        <div>
            <div className="col-2 left">
                <SalesInstorePaymentDetails merchant={merchant} payment={payment} formatMessage={formatMessage} />
                <SalesInstoreCardDetails payment={payment} formatMessage={formatMessage} />
            </div>
            <div className="col-2 right"> <SalesInstorePOS payment={payment} saleslocations={merchant.saleslocation_set} formatMessage={formatMessage} /></div>
        </div>
    )
}

const PurchasedItems = ({ authorization, formatMessage }) => {
    return (
        <div className="col-2 left">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS.purchased_items_header)}</div>
            <OnlinePaymentOrderlines formatMessage={formatMessage} authorization={authorization} />
        </div>
    )
}

export const OnlineActions = ({ payment, showOperationsModal, toggleAmountRemaining, displayCapturableAmount, displayRefundableAmount, toggleCancelModal, merchant }) => {
    const currencyCode = onlineHelpers.getCurrency(payment)
    const currency = iso4217.code(currencyCode)
    const exponent = currency.digits
    const authorizedAmount = payment.operations[0].amount
    const capturedAmount = onlineHelpers.reduceOperationsAmounts(payment, [OPERATIONS.CAPTURE])
    const cancelButtonClass = capturedAmount !== 0 ? 'hide' : ''
    const refundButtonClass = capturedAmount === 0 ? 'hide' : ''
    const role = merchant.loggedInUserRole
    const allowed = (role === ROLES.OWNER || role === ROLES.ADMIN) && !merchant.loggedInUserIsSupport
    const captureDisabled = !allowed || onlineHelpers.getCapturableAmount(payment) === 0 || (payment && onlineHelpers.isNewOperationFlow(payment.acquirer_name) && capturedAmount > 0) ? 'disabled' : ''
    const refundDisabled = !allowed || onlineHelpers.getRefundableAmount(payment) === 0 ? 'disabled' : ''
    const cancelledDisabled = !allowed ? 'disabled' : ''
    const isCancelled = [PAYMENT_ROUTER_STATES.CANCELED, PAYMENT_ROUTER_STATES.REJECTED].includes(payment.state) ? 'disabled' : ''

    const isLindorff = payment.acquirer_name && payment.acquirer_name.toLowerCase() === 'lindorff'

    return (
        <div className="state">
            <div className="total amount">
                <MonetaryAmount amount={authorizedAmount} amount_exponent={exponent} className="fg-darkest" currency={currencyCode} excludeCurrency={false} hideInsignificantDecimals={false} />
            </div>
            <div className="actions">
                <div className="actions-buttons">
                    <button
                        data-testid="operation-action-btn-capture"
                        disabled={captureDisabled || isLindorff}
                        onClick={(e) => showOperationsModal(e, payment, OPERATIONS.CAPTURE)}
                        onMouseLeave={() => toggleAmountRemaining(OPERATIONS.CAPTURE)}
                        onMouseEnter={() => toggleAmountRemaining(OPERATIONS.CAPTURE)}
                        className={`action-btn rebranded-primary-btn capture ${isCancelled} ${captureDisabled}`}>Capture</button>
                    <button
                        data-testid="operation-action-btn-refund"
                        disabled={refundDisabled || isLindorff}
                        onClick={(e) => showOperationsModal(e, payment, OPERATIONS.REFUND)}
                        onMouseLeave={() => toggleAmountRemaining(OPERATIONS.REFUND)}
                        onMouseEnter={() => toggleAmountRemaining(OPERATIONS.REFUND)}
                        className={`action-btn rebranded-primary-btn refund ${refundDisabled} ${isCancelled} ${refundButtonClass}`}>Refund</button>
                    <button
                        data-testid="operation-action-btn-cancel"
                        disabled={cancelledDisabled || isLindorff}
                        onClick={() => toggleCancelModal(payment)}
                        className={`action-btn rebranded-secondary-btn cancel ${isCancelled} ${cancelButtonClass} ${cancelledDisabled}`}>Cancel</button>
                </div>

                <AmountRemaining displayCapturableAmount={displayCapturableAmount}
                    displayRefundableAmount={displayRefundableAmount}
                    payment={payment}
                    exponent={exponent}
                    currency={currencyCode} />
            </div>
        </div>
    )
}

const AmountRemaining = ({ payment, exponent, currency, displayCapturableAmount, displayRefundableAmount }) => {
    if (payment.state === PAYMENT_ROUTER_STATES.CANCELED) return false

    let operation
    let amount

    const elemClass = displayCapturableAmount || displayRefundableAmount ? '' : 'hide'

    if (displayCapturableAmount) {
        operation = OPERATIONS.CAPTURE
        amount = payment && onlineHelpers.isNewOperationFlow(payment.acquirer_name) ? 0 : onlineHelpers.getCapturableAmount(payment)
    } else if (displayRefundableAmount) {
        operation = OPERATIONS.REFUND
        amount = onlineHelpers.getRefundableAmount(payment)
    }

    const amountAsString = getExponentedAmountString(amount, exponent)

    return (
        <div className={`remaining amount capture ${elemClass}`}>
            {`${amountAsString} ${currency} left to ${operation}`}
        </div>
    )
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        merchant: state.get('merchant'),
        config: state.get('config'),
        settlements: state.getIn([activeMerchant, 'settlements']),
        currentOnlinePayment: state.getIn([activeMerchant, 'online', 'current']),
        currentAcquiringPayment: state.getIn([activeMerchant, 'acquiring', 'current']),
        currentInstorePayment: state.getIn([activeMerchant, 'instore', 'current']),
        displayOperationsModal: state.getIn([activeMerchant, 'online', 'operations', 'displayModal']),
        displayCancelModal: state.getIn([activeMerchant, 'online', 'cancel', 'displayModal']),
        action: state.getIn([activeMerchant, 'online', 'operations', 'action']),
        localeCode: state.getIn(['localization', 'localeCode'])
    }
}

export default connect(mapStateToProps)(injectIntl(withRouter(SalesDetailsContainer)))
