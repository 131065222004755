export const SHOW_SELECTED_SALES_VIEW = 'sales/SHOW_SELECTED_SALES_VIEW'
export const SET_ACTIVE_VIEW = 'sales/SET_ACTIVE_VIEW'

export const LIST_START = 'LIST_START'
export const LIST_SUCCESS = 'LIST_SUCCESS'
export const LIST_ERROR = 'LIST_ERROR'
export const LIST_OUT_OF_RANGE = 'LIST_OUT_OF_RANGE'

export const LIST_NEXT_PAGE_SUCCESS = 'LIST_NEXT_PAGE_SUCCESS'

export const GET_DETAILS_START = 'GET_DETAILS_START'
export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS'
export const GET_DETAILS_ERROR = 'GET_DETAILS_ERROR'
export const LOAD_MORE = 'LOAD_MORE'
export const RESET_PAGINATION = 'RESET_PAGINATION'

export const CHANGE_FILTER = 'CHANGE_FILTER'
export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'

export const SET_CURRENT = 'SET_CURRENT'
export const APPLY_DATE_FILTER = 'APPLY_DATE_FILTER'
