import React from 'react'
import { SALES_DETAILS_TEXTS } from '../../sales/constants'
import { formatCardNumber } from '../../../common/cardHelpers'
import { getAuthorizationOperation } from '../../sales/helpers'

const SalesInstoreCardDetails = ({payment, formatMessage}) => {
    const authObject = getAuthorizationOperation(payment)

    return (
        <div className="buffer">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_detail_header'])}</div>
            <div className="detail-wrapper">
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_type'])}</div>
                    <div className="element">{authObject.card_group_name} {payment.card_type}</div>
                </div>
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_number'])}</div>
                    <div className="element">{formatCardNumber(authObject.card_number)}</div>
                </div>
            </div>
        </div>
    )
}

export default SalesInstoreCardDetails
