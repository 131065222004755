import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import * as actions from '../actions'
import { SETTLEMENT_FILTER_TEXT } from '../constants'
import SelectDropdown from '../../common/components/selectDropdown'
import getFlagSrc from '../../common/helpers/flagSrc'
import FilterModal from '../../common/components/filterModal'
import { pairMidAndSaleslocation } from '../../sales/helpers'
import { SETTINGS_TEXTS, INACTIVE_ACQUIRING_STATUS } from '../../merchant/constants'

class Filters extends Component {
    constructor(props) {
        super(props)

        this.state = {
            saleslocationMenuOpened: false,
            currencyFilterOpened: false,
            combineFilterOpened: false,
            search: null
        }
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClick, false)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick, false)
    }

    _shouldRenderFilter(filterOptions) {
        return filterOptions.length > 1
    }

    handleClick(e) {
        if (!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState({ saleslocationMenuOpened: false, currencyFilterOpened: false })
        }
    }

    getMerchant() {
        const merchantId = this.props.match.params.merchantId
        const merchants = this.props.merchant.get('merchants')

        return merchants.find((merchant) => {
            if (merchant.id === merchantId) return merchant
        })
    }

    fetchList() {
        this.props.dispatch(
            actions.getSettlements(
                this.props.match.params.merchantId,
                this.props.search
            )
        )
    }

    changeFilter(filterType, filterValue) {
        this.props.dispatch(
            actions.changeFilter(
                filterType,
                filterValue
            )
        )
    }

    onFilterChange(filterType, selectedItem) {
        this.changeFilter(filterType, selectedItem.map(item => item.id ? item.id : item.text))
        setTimeout(() => {
            this.fetchList()
        })
    }

    getOptionsForMid(formatMessage) {
        const merchant = this.getMerchant()
        const saleslocations = pairMidAndSaleslocation(merchant)
        if (!saleslocations || !saleslocations.length) return []

        const statusText = (status) => status === INACTIVE_ACQUIRING_STATUS ? formatMessage(SETTINGS_TEXTS[`merchant_profile_status_${status}`]) : ''
        return saleslocations.map(item => ({
            text: item.name,
            subtext: `${item.mid} ${statusText(item.status)}`,
            id: item.mid
        }))
    }

    getOptionsCurrency() {
        const merchant = this.getMerchant()
        const currencies = merchant.extra_data.settlement_currencies
        if (!currencies || !currencies.length) return []
        return currencies.map(item => ({
            text: item,
            img: getFlagSrc(item)
        }))
    }

    getSelectOptions(options, filterType) {
        return options.filter(item => {
            const selected = this.props.search && this.props.search[filterType]
            const key = item.id ? item.id : item.text
            return selected && selected.includes(key)
        })
    }

    toggleCombineFilter() {
        this.setState({ combineFilterOpened: !this.state.combineFilterOpened })
    }
    getAvailableFilters() {
        const { formatMessage } = this.props.intl
        const midOptions = this.getOptionsForMid(formatMessage)
        const currencyOptions = this.getOptionsCurrency()
        const availableFilters = [{
            filterType: 'source_merchant_ids',
            options: midOptions,
            selectedOptions: this.getSelectOptions(midOptions, 'source_merchant_ids'),
            title: formatMessage(SETTLEMENT_FILTER_TEXT['settlement-filter-sales-location'])
        }, {
            filterType: 'currencies',
            options: currencyOptions,
            selectedOptions: this.getSelectOptions(currencyOptions, 'currencies'),
            title: formatMessage(SETTLEMENT_FILTER_TEXT['settlement-filter-currency'])
        }]

        return availableFilters.filter(item => this._shouldRenderFilter(item.options))

    }
    renderCombinedFilters() {
        const availableFilters = this.getAvailableFilters()

        if (!availableFilters || !availableFilters.length) return this.props.renderDateFilter('right')

        return <FilterModal
            onFilterChange={this.onFilterChange.bind(this)}
            toggleCombineFilter={this.toggleCombineFilter.bind(this)}
            opened={this.state.combineFilterOpened}
            filters={this.props.search}
            availableFilters={availableFilters}
            isMobile={true}
            renderDateFilter={this.props.renderDateFilter}
            renderClearAll={this.props.renderClearAll}
            isDateSelected={this.props.isDateSelected}
        />
    }

    renderStandAloneFilters() {
        const availableFilters = this.getAvailableFilters()
        return (
            <div className="standalone-filter">
                {availableFilters.map(item => <SelectDropdown
                    key={item.filterType}
                    {...item}
                    className="dropdown-filter"
                    onChange={this.onFilterChange.bind(this, item.filterType)}
                    buttonText={item.title}
                    align="right" />)}
            </div>
        )
    }

    render() {
        return (
            this.props.combineFilters ? this.renderCombinedFilters() : this.renderStandAloneFilters()
        )
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        merchant: state.get('merchant'),
        settlements: state.getIn([activeMerchant, 'settlements']),
        search: state.getIn([activeMerchant, 'settlements', 'list', 'search']) || {}
    }
}

export default connect(mapStateToProps)(injectIntl(withRouter(Filters)))
