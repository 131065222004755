import mixpanel from 'mixpanel-browser'

export const handleRuntimeErrors = () => {
    window.onerror = (message, source, lineno, colno) => {
        let data = {
            message,
            source,
            lineno,
            colno
        }
        mixpanel.track('Unhandled exception in client browser', data)
    }
}
