import React from 'react'
import { SetPasswordComponent } from '@frontrunners/wl-cognito-login'
import { getPoolData } from './helpers'
import { getPasswordPolicy } from './cognitoQueries'


export const CognitoSetPasswordContainer = ({ store }) => {
    const poolData = getPoolData(store)

    const localeCode = store.getState().getIn(['localization', 'localeCode'])
    const apiHost = store.getState().getIn(['config', 'apiHost'])

    const { data: passwordPolicy, isLoading} = getPasswordPolicy(apiHost)

    const onSuccessCallback = (result) => {
        const idToken = result?.idToken?.jwtToken
        if (idToken) {
            window.location.replace(`/?#access_token=${idToken}&id_token=${idToken}`)
        }else {
            window.location.replace('/')
        }
    }

    return (
        passwordPolicy && !isLoading && <SetPasswordComponent
            poolData={poolData}
            passwordPolicyObject={passwordPolicy}
            locale={localeCode}
            onSetPasswordSuccessCallback={onSuccessCallback} />
    )
}
