import React from 'react'
import { MerchantAddressInformation } from './merchantAddressInformation'
import { UserAdministration } from './userAdministration/userAdministration'
import { Contracts } from './contracts/contracts'
import { Paper } from '@frontrunners/ui-components'
import './general.scss'


export const General = () => {

    return (
        <div className="settings-general">
            <Paper>
                <MerchantAddressInformation />
            </Paper>
            <Paper>
                <UserAdministration />
            </Paper>
            <Paper>
                <Contracts />
            </Paper>
        </div>
    )
}
