import React from 'react'
import uuid from 'uuid'
import InstoreListItem from './instoreListItem'
import InstoreListHeaders from './instoreListHeaders'
import Spinner from '../../../common/components/spinner'
import EmptyState from '../../../common/components/emptyState'
import { SALES_EMPTY_FILTER_TITLE, SALES_EMPTY_FILTER_BODY, SALES_TYPES } from '../../sales/constants'
import CommonErrorComponent from '../../../common/commonErrorComponent'


const InstoreList = (props) => {
    if (!props.list.get('instorePayments')) {
        return null
    }

    let instorePayments = props.list.get('instorePayments')

    if (props.list.get('isFetching')) {
        return (
            <div>
                <Spinner waiting={true} />
            </div>
        )
    }

    if(props.list.get('isError')) {
        return <CommonErrorComponent/>
    }

    if (props.list.get('emptyList') || props.list.get('isError')) {
        return (
            <EmptyState>
                <h2 className="text-center">
                    {props.formatMessage(SALES_EMPTY_FILTER_TITLE['empty_filter'])}
                  </h2>
                  <p className="text-center">
                      {props.formatMessage(SALES_EMPTY_FILTER_BODY[props.salesType])}
                  </p>
              </EmptyState>
        )
    }

    return (
        <div className="instore-sales">
            <InstoreListHeaders />
            {instorePayments.map((instorePayment) =>
                   <InstoreListItem
                        key={uuid.v4()}
                        formatMessage={props.formatMessage}
                        onClick={props.pickPage.bind(null, SALES_TYPES.instore, instorePayment)}
                        instorePayment={instorePayment}/>
                    )}
          </div>
    )
}

export default InstoreList
