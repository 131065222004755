import { Map, List, fromJS, Set } from 'immutable'
import types from './actionTypes'
import * as paginationHelpers from '../../common/components/paginationLoadMore'
import { PAGE_OFFSET, PAGE_LIMIT } from '../../common/constants'
import { isDateFilter } from '../acquiring/reducer'
import { getUpdatedOrderList, mapPaymentRouterToIndexObject, removeFailedOperations, addUniqueIdToOrderlineItem } from './helpers'
import { stringWithExponentToInteger } from '../../common/amountHelpers'

export const initState = Map({
    list: Map({
        orders: List()
    }),
    current: Map({
        data: Map({
            operations: []
        }),
        settled_transactions: Map({
            isError: false,
            isFetching: false,
            transactions: List()
        }),
        isError: false,
        isFetching: true
    }),
    operations: Map({
        action: null,
        displayModal: false,
        order: null,
        orderId: null,
        isFetching: false,
        isError: false
    }),
    refundInvoice: Map({
        displayModal: false,
        order: null,
        orderId: null,
        isFetching: false,
        isError: false,
        checked: Map(),
        amounts: Map(),
        total: 0,
        isValid: true
    }),
    cancel: Map({
        displayModal: false,
        order: null,
        orderId: null,
        isFetching: false,
        isError: false
    }),
    error: Map(),
    url: '',
    filterExpanded: false,
    locallyUpdatedPayments: Map({}),
    filters: Map({
        offset: PAGE_OFFSET,
        limit: PAGE_LIMIT
    })
})

var initialFilterState = {
    selected: Set(),
    opened: false
}


export default function onlineReducer(online = initState, action) {
    switch (action.type) {
        case types.LIST_START:
            var modifiedState = online.setIn(['url'], action.payload.url)
                .setIn(['list', 'isFetching'], true)
                .setIn(['list', 'isError'], false)
                .setIn(['list', 'isOutOfRange'], false)
            if (action.payload.hasActiveFilters) {
                const stateWithActiveFilters = modifiedState.setIn(['list', 'orders'], List([]))
                    .setIn(['list', 'isFetching'], true)
                    .setIn(['list', 'isError'], false)
                return stateWithActiveFilters
            }
            return modifiedState
        case types.LIST_SUCCESS:
            var prevPage = online.getIn(['list', 'orders'])
            var nextPage = action.payload.transactions.data
            var currentOffset = online.getIn(['filters', 'offset'])
            var updatedList = paginationHelpers.appendNextPage(prevPage, nextPage, action.payload.search)
            return online.setIn(['list', 'orders'], List(updatedList))
                .setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isError'], false)
                .setIn(['list', 'isOutOfRange'], false)
                .setIn(['list', 'emptyList'], nextPage.length == 0)
                .setIn(['list', 'total'], action.payload.transactions.total)
                .setIn(['list', 'unixtime'], new Date().valueOf())
                .setIn(['list', 'url'], action.payload.url)
                .setIn(['filters', 'offset'], currentOffset + PAGE_LIMIT)
        case types.LIST_NEXT_PAGE_SUCCESS:
            return online.setIn(['list', 'nextPage'], action.payload.transactions)
        case types.LIST_ERROR:
            return online.setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isError'], true)
        case types.LOAD_MORE:
            var prevSearch = online.getIn(['list', 'search'])
            var newSearch = action.payload.params
            var mergedSearch = Object.assign({}, prevSearch, newSearch)
            return online.setIn(['list', 'search'], mergedSearch)
        case types.LIST_SETTLED_START:
            return online.setIn(['current', 'settled_transactions', 'isFetching'], true)
                .setIn(['current', 'settled_transactions', 'isError'], false)
                .setIn(['current', 'settled_transactions', 'transactions'], [])
        case types.LIST_SETTLED_SUCCESS:
            {
                return online.setIn(['current', 'settled_transactions', 'transactions'], fromJS(action.payload.transactions.data))
            }

        case types.LIST_SETTLED_ERROR:
            return online.setIn(['current', 'settled_transactions', 'transactions'], null)
                .setIn(['current', 'settled_transactions', 'isFetching'], false)
                .setIn(['current', 'settled_transactions', 'isError'], true)

        case types.REMOVE_CURRENT_PAYMENT:
            return online.setIn(['current', 'settled_transactions', 'transactions'], List())

        case types.LIST_OUT_OF_RANGE:
            return online.setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isOutOfRange'], true)
        case types.LIST_ONLINE_OUT_OF_RANGE:
            return online.setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isOutOfRange'], true)
        case types.GET_DETAILS_START:
            return online.setIn(['current', 'isFetching'], true)
                .setIn(['current', 'isError'], false)
        case types.GET_DETAILS_SUCCESS:
            return online.setIn(['current', 'data'], addUniqueIdToOrderlineItem(action.payload.order))
                .setIn(['current', 'isFetching'], false)
                .setIn(['current', 'isError'], false)
        case types.GET_DETAILS_ERROR:
            return online.setIn(['current', 'isError'], true)
        case types.CHANGE_FILTER:
            var filters = Set(action.payload.filterValue)
            return online
                .setIn(['filters', action.payload.filterType, 'selected'], filters).setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.TOGGLE_FILTER:
            var filterSetToggleFilter = fromJS(initialFilterState)
            if (online.getIn(['filters', action.payload.filterType])) {
                return online.setIn(['filters', action.payload.filterType, 'opened'], action.payload.opened)
            } else {
                return online.setIn(['filters', action.payload.filterType], filterSetToggleFilter)
                    .setIn(['filters', action.payload.filterType, 'opened'], action.payload.opened)
            }
        case types.CLEAR_FILTER:
            if (isDateFilter(action.payload.filterType)) {
                return online.removeIn(['filters', 'to_datetime'])
                    .removeIn(['filters', 'from_datetime'])
                    .setIn(['filters', 'offset'], PAGE_OFFSET)
                    .setIn(['filters', 'limit'], PAGE_LIMIT)
            }
            return online.setIn(['filters', action.payload.filterType, 'selected'], Set())
                .setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.CLEAR_ALL_FILTERS:
            return initState
        case types.SET_CURRENT:
            return online.setIn(['current', 'data'], action.payload.transaction)
        case types.APPLY_DATE_FILTER:
            return online.setIn(['filters', action.payload.filterType], action.payload.filterValue)
                .setIn(['filters', 'offset'], PAGE_OFFSET)
                .setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.RESET_PAGINATION:
            return online.setIn(['filters', 'offset'], PAGE_OFFSET).setIn(['filters', 'limit'], PAGE_LIMIT)
        case types.LIST_UPDATE_ITEM:
            return online.setIn(
                ['list', 'orders'],
                getUpdatedOrderList(
                    online.getIn(['list', 'orders']),
                    action.payload
                )
            ).setIn(['locallyUpdatedPayments', action.payload.id], mapPaymentRouterToIndexObject(removeFailedOperations(action.payload.order)))
        case types.SHOW_OPERATIONS_MODAL:
            return online.setIn(['operations', 'displayModal'], true)
                .setIn(['operations', 'isFetching'], true)

        case types.SET_OPERATIONS_MODAL_STATE:
            return online.setIn(['operations', 'order'], action.payload.order)
                .setIn(['operations', 'orderId'], action.payload.id)
                .setIn(['operations', 'action'], action.payload.action)
                .setIn(['operations', 'isFetching'], false)

        case types.HIDE_OPERATIONS_MODAL:
            return online.set('operations', initState.get('operations'))

        case types.CAPTURE_ORDER_START:
            return online.setIn(['operations', 'isFetching'], true)

        case types.OPERATIONS_MODAL_ERROR:
            return online.setIn(['operations', 'isFetching'], false)
                .setIn(['operations', 'isError'], true)

        case types.CAPTURE_ORDER_SUCCESS:
            return online.setIn(['operations', 'displayModal'], true)
                .setIn(['operations', 'isFetching'], false)
                .setIn(['operations', 'isSuccess'], action.payload.success)
                .setIn(['refundInvoice', 'isError'], !action.payload.success)
                .mergeDeepIn(['current', 'data'], fromJS(action.payload.order))

        case types.REFUND_ORDER_START:
            return online.setIn(['operations', 'isFetching'], true)

        case types.REFUND_INVOICE_START:
            return online.setIn(['refundInvoice', 'isFetching'], true)

        case types.REFUND_ORDER_SUCCESS:
            return online.setIn(['operations', 'displayModal'], true)
                .setIn(['operations', 'isFetching'], false)
                .setIn(['operations', 'isSuccess'], true)
                .setIn(['operations', 'isSuccess'], action.payload.success)
                .setIn(['operations', 'isError'], !action.payload.success)
                .mergeDeepIn(['current', 'data'], fromJS(removeFailedOperations(action.payload.order)))

        case types.REFUND_INVOICE_SUCCESS:
            return online.setIn(['refundInvoice', 'displayModal'], true)
                .setIn(['refundInvoice', 'isFetching'], false)
                .setIn(['refundInvoice', 'isSuccess'], action.payload.success)
                .setIn(['refundInvoice', 'isError'], !action.payload.success)
                .mergeDeepIn(['current', 'data'], fromJS(removeFailedOperations(action.payload.order)))
        case types.REFUND_INVOICE_SET_AMOUNT:
            return online.setIn(['refundInvoice', 'amounts', action.payload.key, 'amount'], action.payload.value)

        case types.REFUND_INVOICE_CALCULATE_TOTAL:
            return online.setIn(['refundInvoice', 'total'], calculateTotal(online.getIn(['refundInvoice', 'amounts']), online.getIn(['refundInvoice', 'exponent'])))

        case types.REFUND_INVOICE_SET_AMOUNT_VALID:
            return online.setIn(['refundInvoice', 'amounts', action.payload.key, 'isValid'], action.payload.value)

        case types.REFUND_INVOICE_SET_CHECKED:
            return online.setIn(['refundInvoice', 'checked', action.payload.key], action.payload.value)

        case types.REFUND_INVOICE_SET_EXPONENT:
            return online.setIn(['refundInvoice', 'exponent'], action.payload.exponent)
        case types.REFUND_INVOICE_SET_VALID:
            return online.setIn(['refundInvoice', 'isValid'], action.payload.valid)
        case types.REFUND_INVOICE_CLEAR_STATE:
            return online.setIn(['refundInvoice', 'checked'], Map())
                .setIn(['refundInvoice', 'amounts'], Map())
                .setIn(['refundInvoice', 'total'], 0)
                .setIn(['refundInvoice', 'isError'], false)
                .setIn(['refundInvoice', 'isSuccess'], false)
        case types.SHOW_CANCEL_ORDER_MODAL:
            return online.setIn(['cancel', 'isFetching'], true)
                .setIn(['cancel', 'displayModal'], true)

        case types.HIDE_CANCEL_ORDER_MODAL:
            return online.set('cancel', initState.get('cancel'))

        case types.CANCEL_ORDER_ERROR:
            return online.setIn(['cancel', 'isError'], true)
                .setIn(['cancel', 'isFetching'], false)

        case types.CANCEL_ORDER_START:
            return online.setIn(['cancel', 'isFetching'], true)

        case types.CANCEL_ORDER_SUCCESS:
            return online.setIn(['cancel', 'displayModal'], false)
                .setIn(['cancel', 'isFetching'], false)
                .mergeDeepIn(['current', 'data'], fromJS(action.payload.order))

        case types.SET_CANCEL_ORDER_MODAL_STATE:
            return online.setIn(['cancel', 'isFetching'], false)
                .setIn(['cancel', 'order'], fromJS(action.payload.order))
                .setIn(['cancel', 'orderId'], fromJS(action.payload.id))
                .setIn(['cancel', 'isError'], false)



        default:
            return online
    }
}


const calculateTotal = (amounts, exponent) => {
    return amounts.reduce((mem, item) => {
        if (!item) return mem
        let amount = item.get('amount').toString()
        amount = stringWithExponentToInteger(amount, exponent)
        return mem + amount
    }, 0)
}
