import React, { useContext, useState } from 'react'
import { getUpdateCheckoutMutation } from './epayQueries'
import { ButtonV2, Textfield } from '@frontrunners/ui-components'
import { ApplicationContext } from '../../common/reactContext'
import { useQueryClient } from 'react-query'
import { useIntl } from 'react-intl'
import { CHECKOUT_RENAME as T } from './intlMessageKeys.js'

import './checkoutRename.scss'

export const CheckoutRename = ({ activeCheckout, onRename }) => {
    const { formatMessage: fm } = useIntl()
    const { config, activeMerchant, loggedInUser } = useContext(ApplicationContext)
    const queryClient = useQueryClient()
    const [newCheckoutName, setNewCheckoutName] = useState('')
    const [showInactiveForSupportMessage, setShowInactiveForSupportMessage] = useState(false)
    const { mutateAsync: renameCheckout } = getUpdateCheckoutMutation(queryClient, config.apiHost, activeMerchant.id, activeCheckout)

    return <div className="checkout-rename">
        <h4>{fm(T.checkout_rename_header)}</h4>
        <p>{fm(T.checkout_rename_description)}</p>
        <Textfield
            key={activeCheckout.id}
            className="compact"
            value={activeCheckout.checkout.companyname}
            onChange={(_, value) => setNewCheckoutName(value)}
        />
        <div className="checkout-checkout-rename-footer">
            {showInactiveForSupportMessage && 'Inactive for supportal agents'}
            <ButtonV2
                buttonStyle="turquoise"
                label={fm(T.checkout_rename)}
                name="save"
                disabled={newCheckoutName === '' || newCheckoutName === activeCheckout.checkout.companyname}
                onClick={async () => {
                    if (loggedInUser.isSupport) {
                        setShowInactiveForSupportMessage(true)
                        return
                    }
                    const checkout = await renameCheckout({ checkoutName: newCheckoutName })
                    setNewCheckoutName('')
                    onRename(checkout)
                }}
            />
        </div>
    </div>
}
