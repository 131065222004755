import React from 'react'
import uuid from 'uuid'
import AcquiringlistItem from './acquiringListItem'
import AcquiringListHeaders from './acquiringListHeaders'
import Spinner from '../../../common/components/spinner'
import EmptyState from '../../../common/components/emptyState'
import { SALES_TYPES, SALES_EMPTY_FILTER_TITLE, SALES_EMPTY_FILTER_BODY } from '../../sales/constants'
import CommonErrorComponent from '../../../common/commonErrorComponent'


const AcquiringList = (props) => {
    if (!props.list.get('transactions')) {
        return null
    }

    let transactions = props.list.get('transactions')

    if (props.list.get('isFetching')) {
        return (
            <div className="transaction-list">
                <Spinner waiting={true} />
            </div>
        )
    }

    if(props.list.get('isError')) {
        return <CommonErrorComponent/>
    }

    if (props.list.get('emptyList')) {
        return (
            <EmptyState>
                <h2 className="text-center">
                    {props.formatMessage(SALES_EMPTY_FILTER_TITLE['empty_filter'])}
                  </h2>
                  <p className="text-center">
                      {props.formatMessage(SALES_EMPTY_FILTER_BODY[props.salesType])}
                  </p>
              </EmptyState>
        )
    }

    return (
        <div className="acquiring-sales">
            <AcquiringListHeaders formatMessage={props.formatMessage}/>
            {transactions.map((transaction) =>
                <AcquiringlistItem formatMessage={props.formatMessage}
                                   transaction={transaction}
                                   merchant={props.merchant}
                                   key={uuid.v4()}
                                   onClick={props.pickPage.bind(null, SALES_TYPES.acquiring, transaction)}
                />
            )}
          </div>
    )
}

export default AcquiringList
