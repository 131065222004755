import React from 'react'
import { MONTHLY_REPORTS_TEXT } from './constants'

const MonthlyReportDescription = ({type, intl}) => {

    const description = type === 'combined_monthly_excel' ?
        MONTHLY_REPORTS_TEXT['multimid_v2_description_1'] :
        MONTHLY_REPORTS_TEXT['multimid_detailed_description_1']

    return (
        <div className="monthly-reports-description">
            <p>{intl.formatMessage(description)}</p>
        </div>
    )
}


export default MonthlyReportDescription
