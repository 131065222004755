import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'
import BamboraSvgIcon from '../../common/components/bamboraSvgIcon'
import { injectIntl } from 'react-intl'

import Spinner from '../../common/components/spinner'
import EmptyState from '../../common/components/emptyState'
import { SETTLEMENT_TRANSACTION_TEXT, TEXT as TRANSACTION_TEXT } from '../constants'
import TransactionTableWrapper from '../components/transactionTableWrapper'
import { hasNextPage, createSearch } from '../../common/locationHelpers'

import { withRouter } from 'react-router-dom'
import { setPagination, DEFAULT_PAGINATION } from '../../common/components/paginationLoadMore'
import CommonErrorComponent from '../../common/commonErrorComponent'
import ErrorBoundary from '../../common/components/errorBoundary'
import './settlementTransactionByEmidContainer.scss'


const BackBtn = ({ backText }) => {
    return (
        <div>
            <BamboraSvgIcon icon="arrow-left" className="fill-inherit back-icon" />
            <span className="after-rotate">{backText}</span>
        </div>
    )
}

class SettlementTransactionByEmidContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tableSize: 20
        }
    }

    increaseTableSize(newSize) {
        this.setState({tableSize: newSize})
    }

    render() {
        const { formatMessage } = this.props.intl
        const backText = formatMessage(SETTLEMENT_TRANSACTION_TEXT['settlement-details-navbar-back'])
        const backDisabledClass = this.canGoBack() ? '' : 'disabled'
        let settlements = this.props.settlements.toJS()

        if (this.props.settlements) {
            settlements = this.props.settlements.toJS()
        }
        return (
            <div className="main-width-3">
                <ErrorBoundary>
                    <div className="secondary-nav">
                        <div className="pull-left">
                            <a className={`nav-link ${backDisabledClass}`} onClick={this.goBack.bind(this)}>
                                <BackBtn backText={backText} />
                            </a>
                            <div className="line-vertical"></div>
                            <h1>{formatMessage(TRANSACTION_TEXT.transactions)}</h1>
                        </div>
                    </div>
                </ErrorBoundary>
                <ErrorBoundary>
                    <div>
                        {settlements.current ? this.renderTransactionslist(settlements) : <div></div>}
                    </div>
                </ErrorBoundary>
            </div>
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.initialFetch()
    }

    componentWillUnmount() {
        this.props.dispatch(
            actions.resetTransationPagination(
                this.props.match.params.merchantId,
                { offset: 0, limit: 20 }
            )
        )
    }


    initialFetch() {
        const merchantId = this.props.match.params.merchantId
        this.props.dispatch(
            actions.getTransactionsForEmid(
                merchantId,
                this.props.match.params.settlementId,
                this.props.match.params.emid
            )
        )

        if (!this.props.settlements) {
            this.props.dispatch(
                actions.getSettlements(
                    merchantId
                )
            )
        }

    }

    getMerchant(merchantId) {
        return this.props.merchant.get('merchants').find(x => x.id === merchantId)
    }

    loadMore() {
        const merchantId = this.props.match.params.merchantId
        const emid = this.props.match.params.emid
        const settlementId = this.props.match.params.settlementId

        this.preloadNextPage(merchantId)
        this.props.dispatch(
            actions.getTransactionsForEmid(
                merchantId,
                settlementId,
                emid,
                this.props.search || DEFAULT_PAGINATION
            )
        )
    }

    preloadNextPage(merchantId) {
        let search = setPagination(this.props.search)
        this.props.dispatch(actions.loadMoreTransactions(
            merchantId,
            search
        ))
    }


    canGoBack() {
        return (this.props.history.location.state && this.props.history.location.state.fromSettlement)
    }
    goBack() {
        if (this.canGoBack()) this.props.history.goBack()
    }

    renderTransactionslist(settlements) {
        const transactions = settlements.current.transactions || []
        const merchantId = this.props.match.params.merchantId
        const merchant = this.getMerchant(merchantId)
        const nextPageAvailable = hasNextPage(createSearch(settlements.current.search), settlements.current.total)
        const { formatMessage } = this.props.intl

        if (settlements.current.isFetching) {
            return (
                <div className="settlement-details-transaction-list list-container">
                    <Spinner waiting={settlements.current.isFetching} />
                </div>
            )
        }

        if (settlements.current.isTransactionsError) {
            return (
                <CommonErrorComponent />
            )
        }

        if (transactions.length === 0) {
            return (
                <EmptyState>
                    <p className="text-center">
                        {formatMessage(SETTLEMENT_TRANSACTION_TEXT['settlemment-details-no-transactions'])}
                    </p>
                </EmptyState>
            )
        }

        return (
            <div className="settlement-details-transaction-list">
                <div className="transactions">
                    <TransactionTableWrapper nextPageAvailable={nextPageAvailable} increaseTableSize={this.increaseTableSize.bind(this)} tableSize={this.state.tableSize} loadMore={this.loadMore.bind(this)} formatMessage={formatMessage} transactions={transactions} merchant={merchant} />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        settlements: state.getIn([activeMerchant, 'settlements']),
        search: state.getIn([activeMerchant, 'settlements', 'current', 'search']),
        merchant: state.get('merchant')
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(SettlementTransactionByEmidContainer)))
