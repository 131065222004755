import React from 'react'
import * as onlineHelpers from '../../helpers'
import { SALES_DETAILS_TEXTS } from '../../../sales/constants'
import { formatCardNumber } from '../../../../common/cardHelpers'

const OnlinePaymentDetails = ({formatMessage, payment, isInvoice}) => {
    const authorization = onlineHelpers.getAuthorization(payment)
    const card = onlineHelpers.getCardType(authorization)
    const secure = onlineHelpers.determine3dSecure(authorization)
    let cardNumber = ''

    if(isInvoice) {
        return <InvoicePaymentDetails authorization={authorization} formatMessage={formatMessage} />
    }

    if(authorization.card) {
        cardNumber = formatCardNumber(authorization.card.cardNumber)
    }

    return (
        <div className="detail-wrapper buffer">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS.payment_acquiring_card_detail_header)}</div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS.payment_detail_identifer)}</div>
                <div className="element">{cardNumber}</div>
            </div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_card_type'])}</div>
                <div className="element">{card}</div>
            </div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS.payment_detail_3d_secure)}</div>
                <div className="element">
                    {secure ? formatMessage(SALES_DETAILS_TEXTS.payment_detail_3d_secure_yes) : formatMessage(SALES_DETAILS_TEXTS.payment_detail_3d_secure_no)}</div>
            </div>
            { authorization.card && authorization.card.eci &&
                <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['eci'])}</div>
                    <div className="element">
                        {authorization.card.eci}
                    </div>
                </div>
            }
        </div>
    )
}

const InvoicePaymentDetails = ({authorization, formatMessage}) => {
    return (
        <div className="detail-wrapper buffer">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS.payment_acquiring_card_detail_header)}</div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS.payment_detail_payment_type)}</div>
                <div className="element upper">{authorization.payment_method}</div>
            </div>
            <div className="row list">
                <div>{formatMessage(SALES_DETAILS_TEXTS.payment_detail_provider)}</div>
                <div className="element upper">{authorization.payment_type}</div>
            </div>
        </div>
    )
}

export default OnlinePaymentDetails
