import { REPORT_TITLE } from '../reports/constants'

export const getAvailableReportTypes = (merchant, intl) => {
    const merchantType = merchant.customer_type
    let reportTypes = [
        {
            createReportTaskPath: `/v2/merchants/${merchant.id}/reports/combined/monthly/excel`,
            description: intl.formatMessage(REPORT_TITLE['detailPDF']),
            fileFormat: 'excel',
            id: merchantType === 'dach' ? 'combined_monthly_dach_excel' : 'combined_monthly_excel'
        }
    ]

    if (!merchant.device) {
        reportTypes.unshift({
            createReportTaskPath: `/v2/merchants/${merchant.id}/reports/settlements/monthly/pdf`,
            description: intl.formatMessage(REPORT_TITLE['summaryPDF']),
            fileFormat: 'pdf',
            id: merchantType === 'dach' ? 'settlements_monthly_dach_pdf' : 'settlements_monthly_pdf'
        })
    }

    return reportTypes
}
