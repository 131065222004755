import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { load } from '../../sales/sales/actions'
import TransactionTable from './transactionTable'

import './transactiontable.scss'


class TransactionTableWrapper extends React.Component {

    navigateToPayment(transaction) {
        const actions = load('acquiring')
        this.props.dispatch(actions.setCurrent(transaction))
        this.props.history.push('/merchants/' + this.props.match.params.merchantId + '/sales/acquiring/' + transaction.id)
    }

    render() {
        return (
            <TransactionTable loadMore={this.props.loadMore}
                              transactions={this.props.transactions}
                              navigateToPayment={this.navigateToPayment.bind(this)}
                              formatMessage={this.props.formatMessage}
                              merchant={this.props.merchant}
                              increaseTableSize={this.props.increaseTableSize}
                              tableSize={this.props.tableSize}
                              nextPageAvailable={this.props.nextPageAvailable}
            />
        )
    }
}


const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        settlements: state.getIn([activeMerchant, 'settlements']),
        merchant: state.get('merchant')
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(TransactionTableWrapper)))
