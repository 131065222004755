import {parseHash} from './locationHelpers'
import {localStorageSetItem, localStorageGetItem} from './localstorageHelpers'
export function logOutView () {
    return window.location.pathname === '/logout'
}

export function sessionExists () {
  // TODO: Even if we have a token, it might still have expired!
  // Which will cause a 401 from the API Gateway
    const token = localStorageGetItem('accessToken')
    return !!token && token.length > 10
}

export function isLoginRedirect () {
    const query = parseHash()
    return 'access_token' in query && 'id_token' in query
}

export function setAccessToken () {
    const query = parseHash()

    if ('access_token' in query) {
        localStorageSetItem('accessToken', query.access_token)
    } else {
        localStorageSetItem('accessToken', '')
    }
}


export function injectAccessToken(injectedAccessToken) {
    localStorageSetItem('accessToken', injectedAccessToken)
}
