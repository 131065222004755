import { Map } from 'immutable'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import MixpanelMiddleware from './integrations/mixpanel/mixpanel'
import { ENVIRONMENTS, MERCHANT_SPECIFIC_ACTIONS } from './common/constants'
import settlements from './settlements'
import invoice from './invoice'
import reports from './reports'
import loans from './loans'
import sales from './sales/sales'
import instore from './sales/instore'
import acquiring from './sales/acquiring'
import online from './sales/online'
import dashboard from './dashboard'
import { getRootReducer } from './rootReducer'
import { datadogRum } from '@datadog/browser-rum'

//enable redux devtool extension when not in prod enviroment, https://github.com/zalmoxisus/redux-devtools-extension#usage
function getComposeEnhancers(config) {
    let composeEnhancers
    if (config && (config.ENVIRONMENT !== ENVIRONMENTS.PROD)) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    } else {
        composeEnhancers = compose
    }
    return composeEnhancers
}
const middlewares = [thunk]


export const prefixActionsWithMerchantMiddleWare = () => {
    return function(store) {
        return function(next) {
            return function(action) {
                const activeMerchant = store.getState().getIn(['merchant', 'activeMerchant'])
                for (const actionPrefix of MERCHANT_SPECIFIC_ACTIONS) { // eslint-disable-line
                    if (activeMerchant && action.type.startsWith(actionPrefix)) {
                        // Add merchant id as prefix to the action name so the action can be recognised by the dynamic reducers
                        action.type = `${activeMerchant}/${action.type}`
                    }
                }
                return next(action)
            }
        }
    }
}

const initDatadogRUM = (config) => {
    if (config.ENVIRONMENT !== ENVIRONMENTS.TEST) {
        console.log('Datadog RUM is disabled in non-test environments')
        return
    }

    datadogRum.init({
        applicationId: config.DATADOG_APP_ID,
        clientToken: config.DATADOG_CLIENT_ID,
        site: 'datadoghq.com',
        service: 'merchant-portal',
        env: 'test',
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
    })
}

const store = function(config, mixpanel) {
    if (config && config.MIXPANEL_TOKEN) {
        const mixpanelMiddleware = new MixpanelMiddleware(mixpanel)
        middlewares.push(mixpanelMiddleware)
    }

    initDatadogRUM(config)

    middlewares.push(prefixActionsWithMerchantMiddleWare())

    let finalCreateStore = getComposeEnhancers(config)(
        applyMiddleware(...middlewares)
    )(createStore)
    const rootReducer = getRootReducer()
    return finalCreateStore(rootReducer, Map())
}


export const injectDynamicReducers = (store, merchants) => {
    function asyncReducer(merchantId, state = Map(), action) {

        for (const actionPrefix of MERCHANT_SPECIFIC_ACTIONS) { // eslint-disable-line
            if (action.type.startsWith(`${merchantId}/${actionPrefix}`)) {
                // Remove merchant id prefix so the reducer can handle the action as usual
                action.type = action.type.substring(37)
                return Map({
                    ...state,
                    [settlements.constants.NAME]: settlements.reducer(state.get('settlements'), action),
                    [invoice.constants.NAME]: invoice.reducer(state.get('invoice'), action),
                    [reports.constants.NAME]: reports.reducer(state.get('reports'), action),
                    [instore.constants.NAME]: instore.reducer(state.get('instore'), action),
                    [loans.constants.NAME]: loans.reducer(state.get('loans'), action),
                    [sales.constants.NAME]: sales.reducer(state.get('sales'), action),
                    [acquiring.constants.NAME]: acquiring.reducer(state.get('acquiring'), action),
                    [online.constants.NAME]: online.reducer(state.get('online'), action),
                    [dashboard.constants.NAME]: dashboard.reducer(state.get('dashboard'), action)
                })
            }
        }

        return state

    }
    let asyncReducers = {}
    merchants.forEach((merchant) => {
        asyncReducers[merchant] = asyncReducer.bind(null, merchant)
    })
    const reducer = getRootReducer(asyncReducers)
    store.replaceReducer(reducer)
}

export default store
