import React from 'react'
import { useIntl } from 'react-intl'
import { Icon } from '@frontrunners/ui-components'
import { Logout } from '../../nav/containers/navItems'
import './notPortalEligible.scss'

export const NotPortalEligible = () => {
    const { formatMessage: fm } = useIntl()

    const message = {
        id: 'merchant-not-portal-eligible-details'
    }

    return (
        <div className="not-portal-eligible">
            <Icon iconName="wlHorizontal" className="wl-icon" />
            <h3>
                {fm(message)}
            </h3>
            <Logout />
        </div>
    )
}
