import { useQuery } from 'react-query'
import { callApi } from './reactQuery'

export const getContracts = (apiHost, merchantId, source) => {
    const sourceQuery = source ? `&source=${source}` : ''
    return useQuery(
        'contracts',
        async () => {
            return await callApi({ url: `${apiHost}/v1/merchants/${merchantId}/contracts?limit=5000${sourceQuery}`})
                .then((contracts) => {
                    return {
                        ...contracts
                    }
                })
        }
    )
}

export const downloadContractPdf = (apiHost, merchantId, contractId) => {
    return callApi({ url: `${apiHost}/v1/merchants/${merchantId}/adapters/wlx/contract/${contractId}/pdf`, parseJson: false})
        .then(async response => {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            window.open(url, '_blank')
            window.URL.revokeObjectURL(url)
        })
}
