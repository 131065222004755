import b64 from 'js-base64'
import { parseHash } from './locationHelpers'
import { preservedLocalStorageKeyWhenLogout } from './constants'
import { IMPERSONATE_USER_ID } from '../config/constants'

const GLOBAL_MERCHANT = 'globalMerchant'

export function getGlobalMerchant() {
    let globalMerchant = localStorageGetItem(GLOBAL_MERCHANT)
    if (globalMerchant) {
        return globalMerchant.replace(/"/g, '')
    }
    return null
}

export function setGlobalMerchant(globalMerchant) {
    localStorageSetItem(GLOBAL_MERCHANT, globalMerchant)
}

export function removeGlobalMerchant() {
    localStorageRemoveItem(GLOBAL_MERCHANT)
}

export function removeInvalidGlobalMerchant() {
    const profileData = localStorageGetItem('userProfile')
    const userProfile = profileData ? JSON.parse(profileData) : null
    if (!userProfile) return
    const allowedMerchants = userProfile.merchants || [userProfile.merchant]
    const selectedMerchant = getGlobalMerchant()
    if (selectedMerchant && allowedMerchants) {
        const is_allowed = allowedMerchants.includes(selectedMerchant)
        if (!is_allowed) {
            removeGlobalMerchant()
        }
    }
}

export function getUserPreference() {
    let preference = localStorageGetItem('userPreference')
    preference = preference ? JSON.parse(preference) : {}
    return preference
}

export function setUserPreference(userPreference) {
    userPreference = userPreference || {}
    localStorageSetItem('userPreference', JSON.stringify(userPreference))
}

export function setImpersonateUserId(deepLinkPath) {
    if (deepLinkPath[IMPERSONATE_USER_ID]) {
        localStorageRemoveItem(IMPERSONATE_USER_ID)
        const impersonateUserId = deepLinkPath[IMPERSONATE_USER_ID]
        if (impersonateUserId) localStorageSetItem(IMPERSONATE_USER_ID, impersonateUserId)
    }
}

export function setUserProfile(injectedQuery) {
    let userProfile, idToken
    const query = injectedQuery || parseHash()
    if ('id_token' in query) {
        idToken = query['id_token']
        const idTokenBody = idToken.split('.')[1]
        userProfile = JSON.parse(b64.Base64.decode(idTokenBody))
    } else {
        userProfile = {}
    }
    localStorageSetItem('userProfile', JSON.stringify(userProfile))
    localStorageSetItem('idToken', idToken)
}

// These methods wraps around the html localStorage api with a simple dict based
// in session fallback to  handle browses that does not use local storage
// like safari and mobile safari in private mode

let hasLocalStorage = true
let localStorageMock = {}
export function localStorageSetItem(key, value) {
    try {
        if (!hasLocalStorage) throw ('no storage')
        window.localStorage.setItem(key, value)
    } catch (e) {
        hasLocalStorage = false
        localStorageMock[key] = value
    }
}

export function localStorageGetItem(key) {
    return hasLocalStorage ? window.localStorage.getItem(key) : localStorageMock[key]
}

export function localStorageRemoveItem(key) {
    if (hasLocalStorage) {
        window.localStorage.removeItem(key)
    } else {
        delete localStorageMock[key]
    }
}

export function localStorageClear() {
    try {
        if (!hasLocalStorage) throw ('no storage')
        Object.keys(window.localStorage).map(key => {
            if (!preservedLocalStorageKeyWhenLogout.includes(key)) window.localStorage.removeItem(key)
        })
    } catch (e) {
        hasLocalStorage = false
        localStorageMock = {}
    }
}

