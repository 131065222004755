import React, { useState, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import errors from './errors'
const { LoggedOutMessage } = errors
import Spinner from './common/components/spinner'
import Routes from './routes'
import SupportModal from './common/components/supportModal'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import ErrorContainer from './errors/container/errorContainer'
import ErrorBoundary from './common/components/errorBoundary'
import { DeveloperModeContext, getInitialDeveloperModeState, isDeveloper, isProd } from './common/helpers/featureToggle'
import { ApplicationContext } from './common/reactContext'
import { isMultiMerchant } from './merchant/helpers'
import { DeveloperModeDropdown } from './components/DeveloperModeDropdown/developerModeDropdown'
import { trackEnterPageEvent } from './common/helpers/mixpanelTrackers'
import mixpanel from 'mixpanel-browser'
import { getActiveZeroMerchants } from './settings/online/epayQueries'
import { getMerchant } from './common/helpers/findMerchant'
import { NotPortalEligible } from './common/containers/notPortalEligible'
import { hasAccessToMerchant } from './common/helpers/hasAccessToMerchant'
import LoanInfoBox, { showLoanModal } from './common/components/loanInfoBox'
import { setActiveMerchant } from './merchant/actions'
import { listAvailableInvoiceStatements } from './invoice/actions'
import { NavContainer } from './nav/containers/navContainer'
import { DeveloperModeIndicator } from './components/DeveloperModeDropdown/developerModeIndicator'


const Content = ({ languageClassName, showSpinner, error }) => {
    const { activeMerchant } = useContext(ApplicationContext)
    const { developerMode, featureToggle } = useContext(DeveloperModeContext)
    const useLeftNav = developerMode && featureToggle.leftNav

    return <div className={languageClassName}>
        <meta name="viewport" content="user-scalable=no, initial-scale=0.8" />
        {showSpinner ?
            <div className="padding-top-200 text-center">
                <Spinner waiting={true} />
            </div>
            :
            <div className={`main-container ${useLeftNav ? 'navbar-left': ''}`}>
                <ErrorBoundary>
                    {activeMerchant.id &&
                        <Switch>
                            <Route path="/merchants/:merchantId" component={NavContainer} />
                            <Route path="/" component={NavContainer} />
                        </Switch>
                    }
                </ErrorBoundary>
                <ErrorBoundary>
                    <div className="main-content-container">
                        <Routes />
                    </div>
                </ErrorBoundary>
                <ErrorBoundary>
                    <SupportModal />
                </ErrorBoundary>
            </div>

        }
        {
            error && error.show && error.type === 'error_page' && <Redirect to="/error" />
        }
        <LoggedOutMessage />
    </div>
}


const Main = ({
    merchant,
    localization,
    error,
    user,
    env,
    config,
    invoiceReports,
    loanVisited,
    loanVisitedv2,
    terminalSwapBannerVisited,
    availableCredit,
    dispatch
}) => {
    const [developerModeState, setDeveloperModeState] = useState(getInitialDeveloperModeState())
    const languageMessages = localization.get('languageMessages')
    const localeCode = localization.get('localeCode')
    const languageClassName = 'lang-' + localization.get('localeCode')
    const showSpinner = () => {
        const isFetchingMerchantData = merchant && merchant.get('isFetching')
        const merchantList = merchant && merchant.get('merchants')
        return (isFetchingMerchantData || merchantList.length === 0)
    }
    const activeMerchant = merchant.get('merchants').find(m => m.id === merchant.get('activeMerchant'))
    const isPortalEligable = !!(activeMerchant?.portal_eligible !== false && activeMerchant?.id)
    const isLoanEligible = activeMerchant?.extra_data?.loan_eligible
    const userHasMultipleMerchants = isMultiMerchant(merchant.get('merchants'))
    const merchantId = window.location.pathname.split('/')[2]

    // Prefetch data here
    getActiveZeroMerchants(config.apiHost, activeMerchant?.id)

    useEffect(() => {
        if (merchantId && merchantId !== activeMerchant?.id) {
            dispatch(setActiveMerchant(merchantId))
            dispatch(listAvailableInvoiceStatements(merchantId, false))
        }
    }, [merchantId])

    const applicationContext = {
        config: {
            apiHost: config.apiHost,
            cognitoClientId: config.cognitoClientId,
            cognitoPoolId: config.cognitoPoolId,
            dispatch: dispatch,
            environment: config.environment,
            gitCommitVersion: config.gitHead
        },
        loggedInUser: {
            email: activeMerchant?.loggedInUserEmail,
            hasAccessToMerchant: (merchantId) => getMerchant(merchantId, merchant) !== undefined,
            hasMultipleMerchants: userHasMultipleMerchants,
            isChatEligible: activeMerchant?.extra_data?.chat_eligible,
            isSupport: activeMerchant?.loggedInUserIsSupport,
            locale: localization.get('localeCode'),
            role: activeMerchant?.loggedInUserRole,
            sub: user.profile.sub,
            portalPreferences: {
                loanBannerVisited: loanVisited,
                loanPopVisited: loanVisitedv2,
                terminalSwapBannerVisited: terminalSwapBannerVisited
            }
        },
        activeMerchant: {
            activationLink: activeMerchant?.activation_link,
            availableCredit: availableCredit,
            billingAddress: activeMerchant?.billing_address,
            extraData: activeMerchant?.extra_data,
            hasAccessToProdMerchant: !!hasAccessToMerchant(merchant.get('merchants'), activeMerchant?.prod_merchant_id),
            hasAcquiring: activeMerchant?.extra_data?.sales_types?.acquiring || false,
            hasInstore: activeMerchant?.extra_data?.sales_types?.instore || false,
            hasInvoices: invoiceReports?.length > 0,
            hasOnline: activeMerchant?.extra_data?.sales_types?.online || false,
            id: !activeMerchant?.extra_data ? null : activeMerchant?.id,
            isDeviceMerchant: activeMerchant?.device,
            isTrialMerchant: !!activeMerchant?.activation_link,
            loanEligible: isLoanEligible,
            name: activeMerchant?.name,
            orgNumber: activeMerchant?.number,
            phoneNumber: activeMerchant?.phone,
            prodMerchantId: activeMerchant?.prod_merchant_id,
            salesLocations: activeMerchant?.saleslocation_set || [],
            state: activeMerchant?.state,
            systemSet: activeMerchant?.system_set,
            trialMerchantId: activeMerchant?.trial_merchant_id
        }
    }

    if (activeMerchant && !isPortalEligable) {
        return (
            <ApplicationContext.Provider value={applicationContext}>
                <IntlProvider locale={localeCode} key={localeCode} messages={languageMessages}>
                    <NotPortalEligible />
                </IntlProvider>
            </ApplicationContext.Provider>
        )
    }

    return (
        <ApplicationContext.Provider value={applicationContext}>
            <DeveloperModeContext.Provider value={developerModeState}>
                <IntlProvider locale={localeCode} key={localeCode} messages={languageMessages}>
                    <div data-testid="main">

                        {(isDeveloper(user.profile.sub) || !isProd(env)) &&
                            <DeveloperModeDropdown
                                developerModeState={developerModeState}
                                setDeveloperModeState={setDeveloperModeState}
                            />
                        }
                        {developerModeState.developerMode && developerModeState.featureToggle.devModeIndicator &&
                            <DeveloperModeIndicator />
                        }

                        <MixpanelDebug mixpanel={mixpanel} />

                        <BrowserRouter>
                            <TrackEnterPage />
                            <Switch>
                                <Route path="/error" component={ErrorContainer} />
                                <Route path="/">
                                    {merchant && <Content
                                        showSpinner={showSpinner()}
                                        error={error}
                                        languageClassName={languageClassName}
                                    />}
                                </Route>
                                <Redirect to={'/'} />
                            </Switch>

                            {showLoanModal(applicationContext) &&
                                <LoanInfoBox
                                    availableCredit={availableCredit}
                                    activeMerchant={activeMerchant}
                                    dispatch={dispatch}
                                />
                            }

                        </BrowserRouter>

                    </div>
                </IntlProvider>
            </DeveloperModeContext.Provider>
        </ApplicationContext.Provider>
    )
}

const MixpanelDebug = ({ mixpanel }) => {
    const { developerMode, featureToggle } = useContext(DeveloperModeContext)
    useEffect(() => {
        function wrapperFunction(func) {
            return function (...args) {
                if (developerMode && featureToggle.mixpanel) {
                    console.log(`mixpanel.track: ${args[0]}`, args[1])
                }
                return func.apply(this, args)
            }
        }
        mixpanel.track = wrapperFunction(mixpanel.track)
    }, [developerMode, featureToggle])

    return null
}

const TrackEnterPage = () => {
    const appContext = useContext(ApplicationContext)
    const pageLocation = useLocation()

    useEffect(() => {
        // We need to wait for the active merchant to be fetched
        // before we fire off the tracking event
        if (appContext?.activeMerchant?.id) {
            trackEnterPageEvent(pageLocation, appContext)
        }
    }, [window.location.pathname, appContext?.activeMerchant?.id])

    return null
}

function mapStateToProps(state) {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        localization: state.get('localization'),
        merchant: state.get('merchant'),
        error: state.get('errors'),
        env: state.getIn(['config', 'environment']),
        config: state.get('config').toJS(),
        user: state.get('user').toJS(),
        invoiceReports: state.getIn([activeMerchant, 'invoice', 'availableReports']),
        loanVisited: state.get('user').get('preference').get('loanVisited'),
        loanVisitedv2: state.get('user').get('preference').get('loanPopVisitedv2'),
        terminalSwapBannerVisited: state.get('user').get('preference').get('terminalSwapBannerVisited'),
        availableCredit: state.getIn([activeMerchant, 'dashboard', 'loan', 'available_credit'])
    }
}

export default connect(mapStateToProps)(Main)

