import { defineMessages } from 'react-intl'

export const NAME = 'terminals'


export const TERMINALS_TEXTS = defineMessages({
    terminal_settle_time: {
        id: 'terminal_settle_time',
        defaultMessage: 'End of day',
        description: 'End of day (dagsavslut)'
    },
    terminal_error_header: {
        id: 'terminal_error_header',
        defaultMessage: 'Oops! A problem occured!',
        description: 'Terminal error header'
    },
    terminal_error_body: {
        id: 'terminal_error_body',
        defaultMessage: 'There was a problem loading your card reader information',
        description: 'Terminal error body'
    },
    terminal_model:{
        id: 'terminal-model',
        defaultMessage: 'Terminal Model',
        description: 'Terminal Model'
    },
    account_id:{
        id: 'account-id',
        defaultMessage: 'Account ID',
        description: 'Account ID'
    },
    terminal_header: {
        id: 'terminal-header',
        defaultMessage: 'Your card readers',
        description: 'Your card readers (terminals)'
    },
    on: {
        id: 'settings-enabled-on',
        defaultMessage: 'On',
        description: 'On (on/off)'
    },
    off: {
        id: 'settings-disabled-off',
        defaultMessage: 'Off',
        description: 'Off (on/off)'
    },
    show_password: {
        id: 'settings-show-password',
        defaultMessage: 'Show password',
        description: 'Show password'
    },
    tip: {
        id: 'settings-terminal-tip',
        defaultMessage: 'Tip',
        description: 'tip'
    },
    contactless: {
        id: 'settings-terminal-contractless',
        defaultMessage: 'Contactless',
        description: 'Contactless'
    },
    direct_capture: {
        id: 'settings-terminal-direct-capture',
        defaultMessage: 'Direct capture',
        description: 'Direct capture'
    },
    password: {
        id: 'settings-terminal-password',
        defaultMessage: 'Password',
        description: 'Password'
    },
    show_more: {
        id: 'settings-terminal-show-more',
        defaultMessage: 'Show more',
        description: 'Expand terminal details'
    },
    show_less: {
        id: 'settings-terminal-show-less',
        defaultMessage: 'Show less',
        description: 'Collaps terminal details'
    },
    copy: {
        id: 'settings-terminal-copy-password',
        defaultMessage: 'Copy password',
        description: 'Copy password to clipboard'
    },
    copied_ok: {
        id: 'settings-terminal-password-was-copied',
        defaultMessage: 'Copied',
        description: 'Password was copied to the clipboard'
    },
    terminals: {
        id: 'merchant-profile-settings-terminals',
        defaultMessage: 'Terminals',
        description: 'Terminals'
    },
    merchant_user_name: {
        id: 'merchant_profile_merchant_user_name',
        defaultMessage: 'Name',
        description: 'name'
    },
    terminal_id: {
        id: 'payment-instore-terminal-id',
        defaultMessage: 'Terminal ID',
        description: 'Terminal ID'
    },
    no_terminals_available: {
        id: 'no-terminals-available',
        defaultMessage: 'You don\'t have any terminals associated with this account',
        description: 'Empty terminals view'
    }
})
