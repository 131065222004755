import { defineMessages } from 'react-intl'

export const NAME = 'merchant'
export const ROLES = {
    OWNER: 'merchant_admin',
    ADMIN: 'merchant_admin_non_owner',
    USER: 'merchant_user'
}

export const MERCHANT_ONLINE_ROLES_MAPPING = {
    'merchant_admin': 'role-reports-merchant-owner',
    'merchant_admin_non_owner': 'role-reports-merchant-admin',
    'merchant_user': 'role-reports-merchant-user'
}

export const INACTIVE_ACQUIRING_STATUS = 'closed'

export const ELEVATED_ROLE_PRIORITY = [ROLES.OWNER, ROLES.ADMIN, ROLES.USER]

const SYSTEMS = {
    ZERO: 'zero',
    PSP_SIMULATOR: 'psp_simulator'
}

export const STATES = {
    ACQUIRER: 'merchant_acquirer',
    TRIAL_NOT_ACTIVATED: 'merchant_trial_not_activated',
    TRIAL_ACTIVATED: 'merchant_trial_activated',
    PROD: 'merchant_prod'
}

export const MIXPANEL_ALWAYS_TRACK_EVENTS = ['Added user', 'Remove user']

export const MIXPANEL_OPT_OUT_USER = [
    '61c2d862-4cc2-4b78-a611-005bcfde57a5' //testmerchantpingdom @testmerchantpingdom.se
]

export const ONLINE_SYSTEMS = [SYSTEMS.ZERO, SYSTEMS.PSP_SIMULATOR]

export const CHECKOUT_STATES = [STATES.PROD, STATES.TRIAL_ACTIVATED, STATES.TRIAL_NOT_ACTIVATED]
export const TRIAL_STATES = [STATES.TRIAL_ACTIVATED, STATES.TRIAL_NOT_ACTIVATED]

export const NAV_ITEMS = [{
    name: 'accounts',
    url: '/accounts',
    translation: 'merchant_profile_menu_accounts_link',
    icon: 'new-business'
},
{
    name: 'terminals',
    url: '/terminals',
    translation: 'merchant_profile_settings_terminals',
    icon: 'new-readers'
},
{
    name: 'checkout',
    url: '/checkout',
    translation: 'merchant_profile_menu_checkout_settings_link',
    icon: 'new-checkout'
},
{
    name: 'api',
    url: '/api',
    translation: 'merchant_profile_menu_api_keys_link',
    icon: 'new-api',
    roleToggle: [ROLES.OWNER, ROLES.ADMIN]
},
{
    name: 'language',
    url: '/language',
    translation: 'merchant_profile_menu_language_link',
    icon: 'new-language'
},
{
    name: 'users',
    url: '/users',
    translation: 'merchant_profile_menu_users_link',
    icon: 'new-users'
}
]

export const SETTINGS_TEXTS = defineMessages({
    merchant_add_user_description: {
        id: 'merchant_add_user_description',
        defaultMessage: 'The user will get an email with all information sent to email adress you enter and they can set the password themselves.',
        description: 'Add user description'
    },
    merchant_profile_merchant_user_name: {
        id: 'merchant_profile_merchant_user_name',
        defaultMessage: 'Name',
        description: 'name'
    },
    merchant_profile_merchant_user_email: {
        id: 'merchant_profile_merchant_user_email',
        defaultMessage: 'Email',
        description: 'email'
    },
    merchant_profile_merchant_user_access: {
        id: 'merchant_profile_merchant_user_access',
        defaultMessage: 'Role',
        description: 'access'
    },
    merchant_profile_merchant_user_role_owner_title: {
        id: 'merchant-profile-merchant-user-role-owner-title',
        defaultMessage: 'Owner',
        description: 'Owner'
    },
    merchant_profile_merchant_user_role_admin_title: {
        id: 'merchant-profile-merchant-user-role-admin-title',
        defaultMessage: 'Admin',
        description: 'Admin'
    },
    merchant_profile_merchant_user_role_admin_description: {
        id: 'merchant-profile-merchant-user-role-admin-description',
        defaultMessage: 'Can manage users and change merchant settings',
        description: 'Admin user privilege description'
    },
    merchant_profile_merchant_user_role_user_title: {
        id: 'merchant-profile-merchant-user-role-user-title',
        defaultMessage: 'User',
        description: 'User'
    },
    merchant_profile_merchant_user_role_user_description: {
        id: 'merchant-profile-merchant-user-role-user-description',
        defaultMessage: 'Can view and download reports',
        description: 'User-role user privilege description'
    },
    merchant_api_keys_description: {
        id: 'merchant_api_keys_description',
        defaultMessage: 'The secret API key is only shown once when generated so make sure to copy it and store it somewhere safe.',
        description: 'API keys instructions'
    },
    merchant_profile_api_keys_list_item_open_delete: {
        id: 'merchant-profile-api-keys-list-item-open-delete',
        defaultMessage: 'Delete',
        description: 'Delete'
    },
    merchant_checkout_remove_logo: {
        id: 'merchant-checkout-remove-logo',
        defaultMessage: 'Remove',
        description: 'remove'
    },
    merchant_api_public_key: {
        id: 'merchant_api_public_key',
        defaultMessage: 'Public key',
        description: 'public key'
    },
    merchant_checkout_activation_status_text: {
        id: 'merchant_checkout_activation_status_text',
        defaultMessage: 'Activate account',
        description: 'activate account'
    },
    merchant_checkout_activation_status_button: {
        id: 'merchant_checkout_activation_status_button',
        defaultMessage: 'Only test transactions',
        description: 'only test transactions'
    },
    merchant_checkout_activation_description: {
        id: 'merchant_checkout_activation_description',
        defaultMessage: 'In order for you to accept real payments from your customer you need to activate your account. Enter your bank details and company information and you will be good to go. ',
        description: 'description'
    },
    merchant_checkout_activation_side_note: {
        id: 'merchant_checkout_activation_side_note',
        defaultMessage: 'Activate account by entering bank details and company information. ',
        description: '.'
    },
    merchant_checkout_deactivated: {
        id: 'merchant_checkout_deactivated',
        defaultMessage: 'Deactivated',
        description: 'deactivated'
    },
    merchant_checkout_testmode_description: {
        id: 'merchant_checkout_testmode_description',
        defaultMessage: 'You can activate test mode to play around with settings and data without transactions being completed, cards being charged etc.',
        description: 'test mode description'
    },
    merchant_checkout_activate_checkout: {
        id: 'merchant_checkout_activate_checkout',
        defaultMessage: 'Activate test mode',
        description: 'Activate test mode for Checkout'
    },
    merchant_checkout_reminder: {
        id: 'merchant_checkout_reminder',
        defaultMessage: 'Don’t forget to switch back when you are ready to start accepting live transactions into your store.',
        description: 'When activating account'
    },
    merchant_checkout_activated: {
        id: 'merchant_checkout_activated',
        defaultMessage: 'Activated',
        description: 'activated'
    },
    merchant_checkout_business_description: {
        id: 'merchant_checkout_business_description',
        defaultMessage: 'The name you enter here will be what is visible on the checkout title and purchase confirmation.',
        description: 'Information about changing name of business'
    },
    merchant_checkout_logo_description: {
        id: 'merchant_checkout_logo_description',
        defaultMessage: 'The logo will be used in the checkout payment view as well as the confirmation screens for purchases.',
        description: 'Information about changing name of logo'
    },
    merchant_checkout_logo: {
        id: 'merchant_checkout_logo',
        defaultMessage: 'Logo',
        description: 'Logo'
    },
    merchant_profile_checkout_settings_upload_button: {
        id: 'merchant-profile-checkout-settings-upload-button',
        defaultMessage: 'Upload file',
        description: 'Upload file'
    },
    merchant_checkout_upload: {
        id: 'merchant_checkout_upload',
        defaultMessage: 'Drag and drop your logo (PNG or JPG)',
        description: 'Drag and drop your logo (PNG or JPG)'
    },
    merchant_settings_error_header: {
        id: 'terminal_error_header',
        defaultMessage: 'Oops! A problem occured!',
        description: 'Terminal error header'
    },
    merchant_checkout_error_body: {
        id: 'merchant_checkout_error_body',
        defaultMessage: 'There was a problem loading your checkout settings.',
        description: '..'
    },
    merchant_general_title: {
        id: 'merchant-general-title',
        defaultMessage: 'General',
        description: 'General settings'
    },
    merchant_checkout_title: {
        id: 'merchant-checkout-title',
        defaultMessage: 'Checkout',
        description: 'Checkout settings, title for site with Checkout settings used to integrate with 3rd parties'
    },
    profile_paragraph_1: {
        id: 'profile_paragraph_1',
        defaultMessage: 'All accounts for',
        description: 'All accounts for'
    },
    profile_paragraph_2: {
        id: 'profile_paragraph_2',
        defaultMessage: 'org nr',
        description: 'org nr'
    },
    merchant_profile_business_acquiring: {
        id: 'merchant_profile_business_acquiring',
        defaultMessage: 'Acquiring',
        description: 'Acquiring'
    },
    merchant_profile_business_online: {
        id: 'merchant_profile_business_online',
        defaultMessage: 'Online',
        description: 'online'
    },
    merchant_profile_business_instore: {
        id: 'merchant_profile_business_instore',
        defaultMessage: 'Instore',
        description: 'instore'
    },
    merchant_profile_menu_business_link: {
        id: 'merchant-profile-menu-business-link',
        defaultMessage: 'Business',
        description: 'Business'
    },
    merchant_profile_menu_accounts_link: {
        id: 'merchant-profile-menu-accounts-link',
        defaultMessage: 'Accounts',
        description: 'Accounts'
    },
    merchant_profile_menu_accounts_my_profile_link: {
        id: 'merchant-profile-menu-accounts-my-profile-link',
        defaultMessage: 'Accounts (My Profile)',
        description: 'Accounts'
    },
    merchant_profile_settings_terminals: {
        id: 'merchant-profile-settings-terminals',
        defaultMessage: 'Terminals',
        description: 'Terminals'
    },
    merchant_profile_menu_api_keys_link: {
        id: 'merchant-profile-menu-api-keys-link',
        defaultMessage: 'API',
        description: 'API'
    },
    merchant_profile_menu_language_link: {
        id: 'merchant_profile_menu_language_link',
        defaultMessage: 'Language',
        description: 'Language'
    },
    merchant_profile_menu_test_mode_link: {
        id: 'merchant-profile-menu-test-mode-link',
        defaultMessage: 'Test mode',
        description: 'Test mode'
    },
    merchant_profile_menu_checkout_settings_link: {
        id: 'merchant-profile-menu-checkout-settings-link',
        defaultMessage: 'Your checkout',
        description: 'Your checkout'
    },
    merchant_profile_menu_users_link: {
        id: 'merchant-profile-menu-users-link',
        defaultMessage: 'Users',
        description: 'Link to users'
    },
    merchant_profile_org_number: {
        id: 'merchant-profile-org-number',
        defaultMessage: 'Organisation number',
        description: 'Organsionation number'
    },
    merchant_profile_company_name: {
        id: 'merchant-profile-company-name',
        defaultMessage: 'Company name',
        description: 'Company name'
    },
    merchant_profile_identifier: {
        id: 'merchant-profile-identifier',
        defaultMessage: 'Account ID',
        description: 'Account ID'
    },
    merchant_profile_acquiring_identifier: {
        id: 'merchant-profile-acquiring-identifier',
        defaultMessage: 'Acquiring ID',
        description: 'Acquiring ID'
    },
    merchant_profile_channel: {
        id: 'merchant-profile-channel',
        defaultMessage: 'Channel',
        description: 'channel'
    },
    merchant_profile_status: {
        id: 'merchant-profile-status',
        defaultMessage: 'Status',
        description: 'status'
    },
    merchant_profile_status_closed: {
        id: 'merchant-profile-status-closed',
        defaultMessage: 'closed',
        description: 'closed'
    },
    merchant_profile_status_pending: {
        id: 'merchant-profile-status-pending',
        defaultMessage: 'pending',
        description: 'pending'
    },
    merchant_profile_status_open: {
        id: 'merchant-profile-status-open',
        defaultMessage: 'Open',
        description: 'open'
    },
    merchant_profile_description: {
        id: 'merchant-profile-description',
        defaultMessage: 'Description',
        description: 'description'
    },
    merchant_profile_address: {
        id: 'merchant_profile_address',
        defaultMessage: 'Address',
        description: 'Address'
    },
    merchant_profile_country: {
        id: 'merchant_profile_country',
        defaultMessage: 'Country',
        description: 'Country'
    },
    merchant_profile_postalcode: {
        id: 'merchant_profile_postalcode',
        defaultMessage: 'Postal code',
        description: 'Postal code'
    },
    merchant_profile_city: {
        id: 'merchant_profile_city',
        defaultMessage: 'City',
        description: 'City'
    },
    merchant_profile_details: {
        id: 'merchant_profile_details',
        defaultMessage: 'Details',
        description: 'details'
    },
    merchant_profile_contracts: {
        id: 'merchant_profile_contracts',
        defaultMessage: 'Contracts',
        description: 'The contract header'
    },
    merchant_profile_product: {
        id: 'merchant_profile_product',
        defaultMessage: 'Product',
        description: 'The product of the contract'
    },
    merchant_profile_related_accounts: {
        id: 'merchant_profile_related_accounts',
        defaultMessage: 'Accounts',
        description: 'The related accounts of a contract'
    },
    merchant_profile_start_date: {
        id: 'merchant_profile_start_date',
        defaultMessage: 'Start date',
        description: 'Start date for a contract'
    },
    merchant_profile_termination_date: {
        id: 'merchant_profile_termination_date',
        defaultMessage: 'Termination date',
        description: 'Termination date for a contract'
    },
    merchant_profile_active: {
        id: 'merchant_profile_active',
        defaultMessage: 'Active',
        description: 'If a contract is active or not'
    },
    merchant_profile_hide: {
        id: 'merchant_profile_hide',
        defaultMessage: 'Hide',
        description: 'hide'
    },
    merchant_profile_no_details_found: {
        id: 'merchant_profile_no_details_found',
        defaultMessage: 'Sorry, there was a problem loading details about this saleslocation',
        description: 'Error message if failing to get information about a MID'
    },
    merchant: {
        id: 'merchant',
        defaultMessage: 'Merchant',
        description: 'Merchant'
    },
    merchant_org_number: {
        id: 'merchant-business-billing-compoent-org-number',
        defaultMessage: 'Org. number',
        description: 'Merchant organization number'
    },
    merchant_address: {
        id: 'merchant-business-billing-compoent-address',
        defaultMessage: 'Billing address',
        description: 'Merchants billing address'
    },
    merchant_phone_number: {
        id: 'merchant-business-billing-compoent-phone-number',
        defaultMessage: 'Phone number',
        description: 'Merchants phone number'
    },
    reset_user_preferences_headline: {
        id: 'reset_user_preferences_headline',
        defaultMessage: 'Reset User Preferences',
        description: 'Headline in the Reset settings section.'
    },
    reset_user_preferences_description: {
        id: 'reset_user_preferences_description',
        defaultMessage: 'Reset the user preference, such as channels and currency settings',
        description: 'Description in the Reset settings section'
    },
    reset_user_preferences_button: {
        id: 'reset_user_preferences_button',
        defaultMessage: 'Reset User Preferences',
        description: 'Text on button in the Reset settings section.'
    },
    reset_user_preferences_confirmation: {
        id: 'reset_user_preferences_confirmation',
        defaultMessage: 'Do you want to reset your user preferences?',
        description: 'Description in the Reset settings section.'
    },
    reset_user_preferences_yes: {
        id: 'reset_user_preferences_yes',
        defaultMessage: 'Yes',
        description: 'yes'
    },
    reset_user_preferences_no: {
        id: 'reset_user_preferences_no',
        defaultMessage: 'No',
        description: 'No'
    },
    inactive_accounts: {
        id: 'inactive_accounts',
        defaultMessage: 'Inactive accounts',
        description: 'Inactive accounts'
    },
    search: {
        id: 'dropdown_filter_placeholder_text',
        defaultMessage: 'Search',
        description: 'Search'
    },
    save: {
        id: 'merchant-profile-checkout-settings-form-save',
        defaultMessage: 'Save',
        description: 'Save'
    },
    cancel: {
        id: 'merchant-profile-checkout-settings-form-cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel'
    },
    invalid_image_type: {
        id: 'merchant-profile-checkout-settings-form-logo-invalid-type-label',
        defaultMessage: 'You have to use a .png .gif  or .jpeg image file.',
        description: 'Checkout settings, logo input label file type invalid message'

    },
    invalid_image_size: {
        id: 'merchant-profile-checkout-settings-form-logo-invalid-size-label',
        defaultMessage: 'Your image was too large. Max size is {maxSize} and recommended dimensions are 350px x 148px',
        description: 'Checkout settings, logo input label too large image message'
    },
    checkout_selector_description: {
        id: 'checkout-selector-description',
        defaultMessage: 'You have multiple checkouts, please select the one you want to view in the list above.',
        description: 'Checkout selector'
    },
    checkout_txn_fee_on_merchant: {
        id: 'merchant-profile-checkout-settings-form-transaction-fee-on-merchant',
        defaultMessage: 'Transaction fee is put on the merchant',
        description: 'Checkout settings, transaction fees are redacted from the merchant option'
    },
    checkout_txn_fee_on_customer: {
        id: 'merchant-profile-checkout-settings-form-transaction-fee-on-customer',
        defaultMessage: 'Transaction fee is put on the customer',
        description: 'Checkout settings, transaction fees are added to the amount the customer has to pay option'
    },
    checkout_logo_fail_upload: {
        id: 'checkout-logo-upload-fail',
        defaultMessage: 'Something went wrong when trying to upload your logo',
        description: 'Error message if uploading logo fails'
    },
    api_key_identifier: {
        id: 'api-key-identifier',
        defaultMessage: 'Identifier',
        description: 'API key identifier (name) for online merchants'
    },
    edit: {
        id: 'merchant-general-edit-details',
        defaultMessage: 'Edit',
        description: 'Open edit merchant details modal'
    },
    no_result: {
        id: 'merchant-profile-empty-state',
        defaultMessage: 'We could not find any accounts matching your search.',
        description: 'Empty state'
    },
    try_again: {
        id: 'merchant-profile-try-again',
        defaultMessage: 'Please try again.',
        description: 'Try again'
    }
})

export const UNAVAILABLE_TEXT = defineMessages({
    header: {
        id: 'merchant-unavailable-header',
        defaultMessage: 'Something went wrong!',
        description: 'Something went wrong'
    },
    body: {
        id: 'merchant-unavailable-body',
        defaultMessage: 'You do not have access to any merchants',
        description: 'You do not have access to any merchants'
    }
})

export const SETTINGS_USER_TEXT = defineMessages({
    header: {
        id: 'merchant-profile-merchant-users-header',
        defaultMessage: 'Users',
        description: 'Users'
    },
    add_user: {
        id: 'merchant-profile-merchant-users-add-user-button',
        defaultMessage: 'Add user',
        description: 'Add user'
    },
    no_users_exists: {
        id: 'merchant-profile-merchant-users-empty',
        defaultMessage: 'You don\'t have any users set up in your profile yet...',
        description: 'No users exists'
    },
    user_list_item_edit_name: {
        id: 'merchant-profile-merchant-user-list-item-edit-name',
        defaultMessage: 'Add your name',
        description: 'No users exists'
    },
    user_list_item_no_name: {
        id: 'merchant-profile-merchant-user-list-item-no-name',
        defaultMessage: 'Name not set',
        description: 'No users exists'
    }
})

export const messages = defineMessages({
    en: {
        id: 'language-option-english',
        defaultMessage: 'English',
        description: 'Language option for English'
    },
    sv: {
        id: 'language-option-swedish',
        defaultMessage: 'Swedish',
        description: 'Language option for Swedish'
    },
    no: {
        id: 'language-option-norwegian',
        defaultMessage: 'Norwegian',
        description: 'Language option for Norwegian'
    },
    fi: {
        id: 'language-option-finnish',
        defaultMessage: 'Finnish',
        description: 'Language option for Finnish'
    },
    da: {
        id: 'language-option-danish',
        defaultMessage: 'Danish',
        description: 'Language option for Danish'
    },
    fr: {
        id: 'language-option-french',
        defaultMessage: 'French',
        description: 'Language option for French'
    },
    de: {
        id: 'language-option-german',
        defaultMessage: 'German',
        description: 'Language option for German'
    },
    'it': {
        id: 'language-option-italian',
        defaultMessage: 'Italian',
        description: 'Language option for Italian'
    },
    'fr-BE': {
        id: 'language-option-french-belgium',
        defaultMessage: 'French (Belgium)',
        description: 'Language option for French (Belgium)'
    },
    'nl-NL': {
        id: 'language-option-dutch-netherlands',
        defaultMessage: 'Dutch (Netherlands)',
        description: 'Language option for Dutch (Netherlands)'
    }
})

export const LANGUAGES = [{
    name: 'en',
    locale: 'en',
    flag: require('flag-icon-css/flags/4x3/gb.svg')
},
{
    name: 'sv',
    locale: 'sv',
    flag: require('flag-icon-css/flags/4x3/se.svg')
},
{
    name: 'no',
    locale: 'nb',
    flag: require('flag-icon-css/flags/4x3/no.svg')
},
{
    name: 'fi',
    locale: 'fi',
    flag: require('flag-icon-css/flags/4x3/fi.svg')
},
{
    name: 'da',
    locale: 'da',
    flag: require('flag-icon-css/flags/4x3/dk.svg')
},
{
    name: 'fr',
    locale: 'fr',
    flag: require('flag-icon-css/flags/4x3/fr.svg')
},
{
    name: 'de',
    locale: 'de',
    flag: require('flag-icon-css/flags/4x3/de.svg')
},
{
    name: 'it',
    locale: 'it',
    flag: require('flag-icon-css/flags/4x3/it.svg')
},
{
    name: 'nl-NL',
    locale: 'nl-NL',
    flag: require('flag-icon-css/flags/4x3/nl.svg')
}
]

export const USER_PREFERENCE_TEXT = defineMessages({
    invoice_notification: {
        id: 'invoice-notification-description',
        defaultMessage: 'Receive an email when there is a new Invoice available on your account',
        description: 'Description notification invoice'
    },
    invoice_error_notification: {
        id: 'invoice-notification-error',
        defaultMessage: 'Failed to turn on/off Invoice notification. Try again',
        description: 'error notification invoice'
    },
    monthly_report_notification: {
        id: 'monthly-report-notification-description',
        defaultMessage: 'Receive an email when there is a new Monthly report available on your account',
        description: 'Description notification monthly report'
    },
    monthly_report_error_notification: {
        id: 'monthly-report-notification-error',
        defaultMessage: 'Failed to turn on/off Monthly report notification. Try again',
        description: 'error notification monthly report'
    },
    payout_notification: {
        id: 'payout-notification-description',
        defaultMessage: 'Receive an email when there is a new Payout available on your account',
        description: 'Description notification payout'
    },
    payout_error_notification: {
        id: 'payout-notification-error',
        defaultMessage: 'Failed to turn on/off Payout notification. Try again',
        description: 'error notification Payout'
    },
    report_notification_header: {
        id: 'report-notification-header',
        defaultMessage: 'Notifications',
        description: 'Notifications'
    },
    notification_name_invoice: {
        id: 'notification-name-invoice',
        defaultMessage: 'Invoice report',
        description: 'Invoice'
    },
    notification_name_payout: {
        id: 'notification-name-payout',
        defaultMessage: 'Payout report',
        description: 'payout'
    },
    notification_name_monthly_report: {
        id: 'notification-name-monthly-report',
        defaultMessage: 'Monthly report',
        description: 'Monthly Report'
    }
})
