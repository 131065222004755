export function getMerchantIds(settlement) {
    if (!settlement.adjustment_aggregates) return []

    const adjustment = settlement.adjustment_aggregates
    const merchantIds = adjustment.map((a) => a.source_merchant_id)
    return [...new Set(merchantIds)]
}


export function updateSearch(a, b) {
    if (!b) return a
    return Object.assign(b, a)
}


export const getSaleslocationNameFromMid = (saleslocations, elixirId) => {
    const saleslocation = saleslocations.find((sl) => {
        return sl.mid_set.find(mid => mid.mid === elixirId)
    })

    if (!saleslocation) return ''

    return saleslocation.name
}

export const hasActiveSearch = (search) => {
    return Object.keys(search).length > 2
}

export const mapTransaction = (t) => {
    // figuring out trasaction state, for now we use the
    // type field as it will work with acquiring data
    // and we don't really know how the paymenet depot
    // will expose the transaction "state" for online
    // payments

    // when changing this, remember to use/update/change
    // the TRANSACTION_STATES in constants.js

    t.state = t.type
    return t
}
