// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../styles/img/Worldline-Coconut-Horizontal.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../styles/img/Worldline-Coconut-Symbol.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".emptyNavBar{margin-top:0;height:58px;background-color:#3e3937}.logo-nav{position:relative;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center;width:135px;height:58px;background-position:50% 45%;background-size:100%}@media(max-width: 600px){.logo-nav{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");width:40px}}", "",{"version":3,"sources":["webpack://./src/common/components/emptyNavBar.scss"],"names":[],"mappings":"AAAA,aACI,YAAA,CACA,WAAA,CACA,wBAAA,CAGJ,UACI,iBAAA,CACA,wDAAA,CACA,2BAAA,CACA,0BAAA,CACA,WAAA,CACA,WAAA,CACA,2BAAA,CACA,oBAAA,CACA,yBATJ,UAUQ,wDAAA,CACA,UAAA,CAAA","sourcesContent":[".emptyNavBar{\n    margin-top: 0;\n    height: 58px;\n    background-color: #3E3937;\n}\n\n.logo-nav {\n    position: relative;\n    background-image: url(../../styles/img/Worldline-Coconut-Horizontal.png);\n    background-repeat: no-repeat;\n    background-position: center;\n    width: 135px;\n    height: 58px;\n    background-position: 50% 45%;\n    background-size: 100%;\n    @media (max-width: 600px) {\n        background-image: url(../../styles/img/Worldline-Coconut-Symbol.png);\n        width: 40px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
