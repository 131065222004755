import { combineReducers } from 'redux-immutable'
import config from './config'
import errors from './errors'
import localization from './localization'
import merchant from './merchant'
import user from './user'


export function getRootReducer(dynamicReducers) {
    return combineReducers({
        [config.constants.NAME]: config.reducer,
        [errors.constants.NAME]: errors.reducer,
        [localization.constants.NAME]: localization.reducer,
        [merchant.constants.NAME]: merchant.reducer,
        [user.constants.NAME]: user.reducer,
        ...dynamicReducers
    })
}
