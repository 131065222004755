import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import SelectMerchantContainer from './merchant/containers/selectMerchantContainer'
import MerchantRoutes from './merchantRoutes'

const Routes = () => {
    return (
        <Switch>
            <Route exact={true} path="/" component={SelectMerchantContainer} />
            <Route exact={true} path="/merchants" component={SelectMerchantContainer} />
            <Route path="/merchants/:merchantId" component={MerchantRoutes} />
            <Redirect to="/" />
        </Switch>
    )
}
export default Routes
