import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Paper, Accordion, Icon, Badge, Checkbox, Textfield } from '@frontrunners/ui-components'
import { SETTINGS_TEXTS, SETTINGS_TEXTS as ST } from '../../merchant/constants'
import { ACCOUNTS_TEXT as AT } from './intlMessageKeys'
import { ApplicationContext } from '../../common/reactContext'
import VisitAddress from '../../merchant/components/visitingAddressComponent'
import { getAccountsData } from '../../merchant/helpers/getAccountListData'
import './accounts2.scss'

export const Accounts = () => {

    const { formatMessage: fm } = useIntl()
    const { activeMerchant } = useContext(ApplicationContext)

    const [showInactive, setShowInactive] = useState(false)
    const [filterText, setFilterText] = useState(null)

    const accounts = getAccountsData(activeMerchant)
    const filteredAccounts = getFilteredAccounts(filterText, accounts)

    const acquiringAccounts = filteredAccounts.filter(account => account.channel === 'elixir')
    const instoreAccounts = filteredAccounts.filter(account => account.channel === 'samport')
    const onlineAccounts = filteredAccounts.filter(account => account.channel === 'zero')

    const showSearchBox = accounts.length >= 10
    const hasInactiveAccounts = filteredAccounts.some(account => !isAccountActive(account))

    return (
        <div className="accounts">

            {(showSearchBox || hasInactiveAccounts) &&
                <div className="accounts-toolbar">
                    <SearchBox
                        setFilterText={setFilterText}
                        isActive={showSearchBox}
                    />
                    <InactiveAccountsToggler
                        showInactive={showInactive}
                        setShowInactive={setShowInactive}
                        isActive={hasInactiveAccounts}
                    />
                </div>
            }

            {filteredAccounts.length == 0 && filterText && <EmptyState />}

            {acquiringAccounts &&
                <AccountChannel
                    channel={fm(ST['merchant_profile_business_acquiring'])}
                    accounts={acquiringAccounts}
                    showInactive={showInactive}
                />
            }

            {instoreAccounts &&
                <AccountChannel
                    channel={fm(ST['merchant_profile_business_instore'])}
                    accounts={instoreAccounts}
                    showInactive={showInactive}
                />
            }

            {onlineAccounts &&
                <AccountChannel
                    channel={fm(ST['merchant_profile_business_online'])}
                    accounts={onlineAccounts}
                    showInactive={showInactive}
                />
            }
        </div>
    )
}

const EmptyState = () => {
    const { formatMessage: fm } = useIntl()
    return (
        <div className="account-filter-empty">
            <Icon iconName="search" />
            <div className="text">
                <div>{fm(SETTINGS_TEXTS['no_result'])}</div>
                <div>{fm(SETTINGS_TEXTS['try_again'])}</div>
            </div>
        </div>
    )
}

const AccountChannel = ({ channel, accounts, showInactive }) => {
    const activeAccountAvailable = accounts.some(account => isAccountActive(account))
    const isInstore = accounts.some(account => account.channel === 'samport')

    const { formatMessage: fm } = useIntl()

    if ((!activeAccountAvailable && !showInactive) || accounts.length === 0) {
        return null
    }

    return (
        <Paper className="account-channel">
            <h2 className="settings-paper-header">
                {channel}
            </h2>

            <div className="columns settings-paper-subheader">
                <div className="identifier">
                    {fm(ST['merchant_profile_identifier'])}
                </div>
                <div className="description">
                    {fm(ST['merchant_profile_description'])}
                </div>
                <div className={`terminals ${isInstore ? '' : 'hidden'}`}>
                    {fm(ST['merchant_profile_settings_terminals'])}
                </div>
            </div>

            {accounts.map(account => {
                return (
                    !(!showInactive && !isAccountActive(account)) && <Account key={account.id} account={account} />
                )
            })}
        </Paper>
    )
}

const Account = ({ account }) => {
    const [isOpen, setIsOpen] = useState(false)

    const isInstore = account.channel === 'samport'

    return (
        <Accordion
            isOpen={isOpen}
            onToggleClick={() => setIsOpen(!isOpen)}
            header={
                <Header
                    accountId={account.id}
                    accountDescription={account.description || '-'}
                    isActive={isAccountActive(account)}
                    isInstore={isInstore}
                />
            }
            content={<VisitingAddress visitingAddress={account.visiting_address}/>}
        />
    )
}

const Header = ({ accountId, accountDescription, isActive, isInstore }) => {
    const { activeMerchant } = useContext(ApplicationContext)

    const { formatMessage: fm } = useIntl()

    return (
        <>
            <div className="toggler" data-testid="toggler">
                <Icon iconName="chevronDown" className="toggle-icon" />
            </div>

            <div className="account-id" data-testid="account-id">
                <div className="account-id-header">
                    {fm(ST['merchant_profile_identifier'])}
                </div>
                <div className="account-id-content">
                    {accountId}
                </div>
            </div>

            <div className="account-description" data-testid="account-description">
                <div className="account-description-header">
                    {fm(ST['merchant_profile_description'])}
                </div>
                <div className="account-description-content">
                    {accountDescription}
                </div>
            </div>

            <Badge className={`orange ${isActive ? 'hidden' : ''}`}>
                {fm(AT['inactive'])}
            </Badge>

            <div className={`account-terminals ${isInstore ? '' : 'hidden'}`}>
                <a
                    href={`/merchants/${activeMerchant.id}/settings/instore?filter=${accountId}`}
                >
                    {fm(ST['merchant_profile_settings_terminals'])}
                </a>
            </div>
        </>
    )
}

const VisitingAddress = ({ visitingAddress }) => {
    const { formatMessage: fm } = useIntl()
    return (
        <>
            <div className="visiting-address-header">
                {fm(AT['address'])}
            </div>
            {visitingAddress ?
                <VisitAddress address={visitingAddress} /> :
                <div className="visiting-address">-</div>
            }
        </>
    )
}

const InactiveAccountsToggler = ({ showInactive, setShowInactive, isActive }) => {
    const { formatMessage: fm } = useIntl()
    return (
        <div className={`show-inactive ${isActive ? '' : 'hidden'}`}>
            {fm(AT['show-inactive'])}
            <Checkbox
                slider
                initialValue={showInactive}
                isChecked={showInactive}
                onClick={() => setShowInactive(!showInactive)}
            />
        </div>
    )
}

const SearchBox = ({ setFilterText, isActive }) => {
    const { formatMessage: fm } = useIntl()

    const onChange = (_, value) => {
        setTimeout(() => {
            setFilterText(value)
        }, 2000)
    }

    return (
        <Textfield
            placeholder={fm(ST['search'])}
            onChange={onChange}
            className={`compact ${isActive ? '' : 'hidden'}`}
            icon={<Icon iconName="search" />}
        />
    )
}

const getFilteredAccounts = (filterText, accounts) => {
    const filteredAccounts = accounts.filter(account => {
        const descriptionMatch = account.description.toLowerCase().includes(filterText?.toLowerCase())
        const accountIdMatch = account.id.toLowerCase().includes(filterText?.toLowerCase())
        return descriptionMatch || accountIdMatch
    })
    return filterText ? filteredAccounts : accounts
}

const isAccountActive = (account) => ['open', '', undefined].includes(account.status)
