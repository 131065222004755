import * as t from './actionTypes'
import { getInitialLoalizationState } from './helpers'

export default function (localization = getInitialLoalizationState(), action) {
    switch (action.type) {
    case t.SET_LOCALIZATION:
      // setting langauge messges used by react-intl
        return localization.set('languageMessages', action.payload.messages).set('localeCode', action.payload.code)
    default:
        return localization
    }
}

