import React, { Component } from 'react'
import { handleScroll } from '../../../common/stickyScroll'
import { TRANSACTION_TABLE_HEADER } from '../../../settlements/constants'

class TransactionsListHeaders extends Component {
    constructor(props) {
        super(props)
        this.handleScroll = handleScroll.bind(this, '.acquiring-sales .list-group .header-row', '.acquiring-sales .list-group')
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    render() {
        return (
            <div className="list-group">
                <div className="header-row">
                    <div className="row-wrapper">
                        <span className="header-column date-time">
                            {this.props.formatMessage(TRANSACTION_TABLE_HEADER['date'])}
                        </span>
                        <span className="header-column merchant-reference">
                            {this.props.formatMessage(TRANSACTION_TABLE_HEADER['ref'])}
                        </span>
                        <span className="header-column payment-method">
                            {this.props.formatMessage(TRANSACTION_TABLE_HEADER['payment-method'])}
                        </span>
                        <span className="header-column type">
                            {this.props.formatMessage(TRANSACTION_TABLE_HEADER['type'])}
                        </span>
                        <span className="header-column status">
                            {this.props.formatMessage(TRANSACTION_TABLE_HEADER['status'])}
                        </span>
                        <span className="header-column currency">
                            {this.props.formatMessage(TRANSACTION_TABLE_HEADER['currency'])}
                        </span>
                        <span className="header-column amount">
                            {this.props.formatMessage(TRANSACTION_TABLE_HEADER['amount'])}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default TransactionsListHeaders
