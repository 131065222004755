import { Map, List, fromJS } from 'immutable'
import { DataAvailableFromDate } from '../common/dateHelpers'
import * as t from './actionTypes'
import { NUMBER_OF_DEFAULT_FILTERS } from './constants'

export const initState = Map({
    list: Map(),
    current: Map({})
})

export const reducer = (settlements = initState, action) => {
    switch (action.type) {
        case t.LIST_START:
            var data = action.payload.offset ? settlements.getIn(['list', 'data']) : List()
            return settlements
                .setIn(['list', 'isFetching'], action.payload.isFetching)
                .setIn(['list', 'isError'], false)
                .setIn(['list', 'isOutOfRange'], false)
                .setIn(['list', 'data'], data)

        case t.LIST:
            var nextPage = action.payload.settlements.data.map(item => item.settlement)
            var prevPage = settlements.getIn(['list', 'data'])
            var updatedList = loadMoreData(prevPage, nextPage, action.payload.search)
            return settlements.setIn(['list', 'data'], List(updatedList))
                .setIn(['list', 'isFetching'], false)
                .setIn(['list', 'emptyList'], action.payload.settlements.data.length == 0)
                .setIn(['list', 'hasActiveFilters'], checkActiveFilters(action.payload.search))
                .setIn(['list', 'total'], action.payload.settlements.total)
                .setIn(['list', 'unixtime'], new Date().valueOf())
                .setIn(['list', 'url'], action.payload.url)

        case t.UPDATE_SINGLE_START:
            return settlements.setIn(['list', 'isFetching'], true)

        case t.UPDATE_SINGLE_SUCCESS:
            var updatedSettlement = action.payload.settlement
            var settlement = settlements.getIn(['list', 'data']).find((settlement => settlement.id == updatedSettlement.id))
            for (var key in updatedSettlement) {
                settlement[key] = updatedSettlement[key]
            }
            return settlements.setIn(['list', 'isFetching'], false)

        case t.LIST_OUT_OF_RANGE:
            return settlements
                .setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isOutOfRange'], true)
                .setIn(['list', 'nextPage'], null)

        case t.LIST_ERROR:
            return settlements
                .setIn(['list', 'isFetching'], false)
                .setIn(['list', 'isError'], true)

        case t.LIST_SETTLEMENTS_NEXT_PAGE_SUCCESS:
            return settlements.setIn(['list', 'nextPage', 'data'], action.payload.settlements.data.map(item => item.settlement))

        case t.LIST_DETAILS:
            return settlements.setIn(['current', 'data'], fromJS(
                settlements.getIn(['list', 'data']).find(settlement => settlement.id === action.payload.id)
            ))

        case t.LIST_TRANSACTIONS_START:
            return settlements.setIn(['current', 'isFetching'], true)
                .setIn(['current', 'isTransactionsError'], false)

        case t.LIST_TRANSACTIONS:
            var nextPageTransactions = action.payload.transactions.data
            var prevPageTransactions = settlements.getIn(['current', 'transactions'])
            var updatedListTransactions = loadMoreData(prevPageTransactions, nextPageTransactions, action.payload.search)
            return settlements.setIn(['current', 'transactions'], updatedListTransactions)
                .setIn(['current', 'isFetching'], false)
                .setIn(['current', 'total'], action.payload.transactions.total)

        case t.LOAD_MORE_TRANSACTIONS:
            var prevSearchTransactions = settlements.getIn(['current', 'search'])
            var newSearchTransactions = action.payload.params
            var mergedSearchTransactions = Object.assign({}, prevSearchTransactions, newSearchTransactions)
            return settlements.setIn(['current', 'search'], mergedSearchTransactions)

        case t.RESET_TRANSACTION_PAGINATION:
            return settlements.setIn(['current', 'search'], action.payload.params)
                .setIn(['current', 'transactions'], [])
        case t.LIST_TRANSACTIONS_NEXT_PAGE_SUCCESS:
            return settlements.setIn(['current', 'nextPage'], action.payload.transactions)

        case t.SET_EXPANDED_SETTLEMENT_INDEX:
            return settlements.setIn(['list', 'expandedIndex'], action.payload.expandedIndex)

        case t.FETCH_SETTLEMENT_REFERENCE_START:
            return settlements.setIn(['current', 'isFetching'], true)
                .setIn(['current', 'reference'], null)

        case t.FETCH_SETTLEMENT_REFERENCE_SUCCESS:
            return settlements.setIn(['current', 'reference'], fromJS(action.payload.s.reference))
                .setIn(['current', 'isFetching'], false)

        case t.SET_SETTLEMENT_REFERENCE_EMPTY:
            return settlements.setIn(['current', 'reference'], null)

        case t.LIST_TRANSACTIONS_ERROR:
            return settlements.setIn(['current', 'isFetching'], false)
                .setIn(['current', 'isTransactionsError'], true)

        case t.UPDATE_FILTERS:
            return settlements.setIn(['list', 'search'], action.payload.filters)

        case t.CHANGE_FILTER:
            var filters = settlements.getIn(['list', 'search'])

            if (!filters) filters = {}
            if (action.payload.filterValue && action.payload.filterValue.length) {
                filters[action.payload.filterType] = action.payload.filterValue
            }
            else if (filters[action.payload.filterType]) {
                delete filters[action.payload.filterType]
            }

            return settlements.setIn(['list', 'search'], filters)

        case t.CLEAR_ALL_FILTERS:
            var defaultIndex = { expandedIndex: -1 }
            var existingSearch = settlements.getIn(['list', 'search'])
            var params = action.payload.params
            var filter = removeActiveFilters(params, existingSearch)

            if (Object.keys(filter).length === 0) {
                filter = defaultFilter()
            }
            return settlements.setIn(['list', 'search'], filter)
                .setIn(['list', 'expandedIndex'], defaultIndex)
        case t.LOAD_MORE:
            var defaultIndexMore = { expandedIndex: -1 }
            var prevSearch = settlements.getIn(['list', 'search'])
            var newSearch = action.payload.params
            var mergedSearch = Object.assign({}, prevSearch, newSearch)

            return settlements.setIn(['list', 'search'], mergedSearch)
                .setIn(['list', 'expandedIndex'], defaultIndexMore)
        case t.RESET_PAGINATION:
            var currentSearch = settlements.getIn(['list', 'search'])
            var resetPaginationSearch = Object.assign({}, currentSearch, defaultFilter())
            return settlements.setIn(['list', 'search'], resetPaginationSearch)
        default:
            return settlements
    }
}

const defaultFilter = () => {
    return {
        offset: 0,
        limit: 20
    }
}

const removeActiveFilters = (params, existingSearch) => {
    if (params.removeAllKeys || !existingSearch) {
        return defaultFilter()
    }

    let existingKeys = Object.keys(existingSearch)

    existingKeys.forEach((key) => {
        if (params.keysToRemove.includes(key)) {
            delete existingSearch[key]
        }
    })

    return existingSearch
}

const checkActiveFilters = (search) => {
    let flexibleDefaultFilters = 0
    if (search && search.from_date === DataAvailableFromDate.instance.getDateIsoString()) {
        flexibleDefaultFilters++
    }
    if (Object.keys(search).length > NUMBER_OF_DEFAULT_FILTERS + flexibleDefaultFilters) {
        return true
    }
    return false
}

const loadMoreData = (oldList, newList, search) => {
    if (search.offset == 0) {
        return newList
    }
    if (!oldList) oldList = []
    return oldList.concat(newList)
}

export default reducer
