import moment from 'moment'
import { OPERATIONS } from './instore/constants'
import { getMerchant } from '../common/helpers/findMerchant'
import { INSTORE_PAYMENT_STATES, INSTORE_PAYMENT_TYPES, ONLINE_PAYMENT_STATES } from './sales/constants'
import { pairSalesLocationToSystemIds } from '../dashboard/helpers'
import getFlagSrc from '../common/helpers/flagSrc'
import { FILTER_STATES } from './sales/constants'
import { INACTIVE_ACQUIRING_STATUS, SETTINGS_TEXTS } from '../merchant/constants'

export const getAuthorizationOperation = (payment) => {
    return payment.operations.find((operation) => {
        return operation.operation_type === OPERATIONS.AUTHORIZED
    })
}

export const pairMidAndSaleslocation = (merchant) => {
    let saleslocations = []
    merchant.saleslocation_set.forEach((sl) => {
        sl.mid_set.forEach((mid) => {
            saleslocations.push({ name: sl.name, mid: mid.mid, status: mid.status })
        })
    })
    //sort the order to show the active accounts first
    saleslocations = saleslocations.sort(a => (a && a.status !== INACTIVE_ACQUIRING_STATUS) ? -1 : 1)
    return saleslocations
}


export const flattenFilters = (filters) => {
    let flattenedFilters = {}
    for (let key of Object.keys(filters)) { // eslint-disable-line
        if (filters[key] !== null && filters[key] !== undefined) {
            if (filters[key].selected) {
                if (filters[key].selected.length > 0) {
                    flattenedFilters[key] = filters[key].selected.join(',')
                }
            } else {
                flattenedFilters[key] = filters[key]
            }
        }
    }
    if (flattenedFilters && flattenedFilters.search === '') delete flattenedFilters.search
    return flattenedFilters
}



export const getIconKey = (cardScheme) => {
    switch (cardScheme) {
        case 'amex':
        case 'americanexpress':
        case 'american express':
            return 'amex'
        case 'collector':
            return 'walley'
        case 'dankort':
            return 'dankort'
        case 'diners':
        case 'dinersclub':
        case 'diners club':
            return 'diners'
        case 'discover':
            return 'discover'
        case 'jcb':
        case 'japanesecreditbureau':
            return 'jcb'
        case 'invoice':
            return 'invoice'
        case 'lindorff':
            return 'lindorff'
        case 'maestro':
            return 'maestro'
        case 'mastercard':
        case 'masterpass':
            return 'mastercard'
        case 'mobilepay':
            return 'mobilepay'
        case 'privatelabel':
        case 'private label':
            return 'privatelabel'
        case 'resursbank':
        case 'resurs bank':
            return 'resursbank'
        case 'seb':
        case 'sebkort':
        case 'seb kort':
            return 'seb'
        case 'swish':
            return 'swish'
        case 'unionpay':
        case 'union pay':
            return 'unionpay'
        case 'visa':
            return 'visa'
        case 'vipps':
            return 'vipps'
        case 'wallet':
            return 'wallet'
        case 'bankaxept':
            return 'bankaxept'
        case 'viabill':
            return 'viabill'
        case 'walley':
            return 'walley'
        default:
            return null
    }
}


export const getMetaDataForView = (activeView, merchantId, merchantObject) => {
    const merchant = getMerchant(merchantId, merchantObject)
    switch (activeView) {
        case 'instore':
            return {
                payment_type: merchant.extra_data.instore_payment_types,
                payment_category: INSTORE_PAYMENT_TYPES,
                state: INSTORE_PAYMENT_STATES,
                currency: merchant.extra_data.instore_payment_currencies,
                //TODO: add cashier_identity back when BE ready
                //cashier_identity: merchant.extra_data.instore_cashier_identities,
                terminal_id: merchant.extra_data.instore_terminal_ids,
                source_merchant_id: pairSalesLocationToSystemIds(merchant, 'samport')
            }
        case 'online':
            return {
                currency: merchant.extra_data.online_payment_currencies,
                payment_type: merchant.extra_data.online_payment_types,
                state: ONLINE_PAYMENT_STATES
            }
        case 'acquiring':
            return {
                card_scheme: merchant.extra_data.card_schemes,
                type: ['Sale', 'Refund'],
                currency: merchant.extra_data.acquiring_currencies,
                source_merchant_id: pairMidAndSaleslocation(merchant)
            }
        default:
            return {
                currencies: []
            }
    }
}

export const addDayToISOstring = (dateString) => {
    let dateAsMoment, modifiedDate
    dateAsMoment = moment.utc(dateString, 'YYYY-MM-DDTHH:mm:ssSSSZ')
    modifiedDate = dateAsMoment.add(1, 'days')
    return modifiedDate.toISOString()
}

export const anyActiveFilters = (filters) => {
    const ignoredKeys = ['offset', 'limit', 'include_psp_mapped', 'return_total_count', 'source']
    const selectedFilters = Object.keys(filters).filter(key => !ignoredKeys.includes(key))
    const hasDateFilters = ['to_datetime', 'from_datetime'].some(x => selectedFilters.includes(x))
    if (hasDateFilters) return true
    return selectedFilters.some(filter => {
        if (filter === 'search') return filters[filter]
        return filters[filter].selected.length > 0
    })
}

const getReadableName = (item) => {
    return getIconKey(item.toLowerCase()) || item
}

export const getOptionsForFilters = (filterType, filterValues, formatMessage) => {
    let options
    const statusText = (status) => status === INACTIVE_ACQUIRING_STATUS ? formatMessage(SETTINGS_TEXTS[`merchant_profile_status_${status}`]) : ''
    switch (filterType) {
        case 'card_scheme':
        case 'payment_type':
            filterValues = filterValues.filter(filterValue => filterValue !== 'unspecified')
            options = filterValues.map(item => ({
                text: getReadableName(item),
                id: item,
                imgClass: `new-card-icons ${getIconKey(item.toLowerCase())}`
            }))
            break
        case 'source_merchant_id':
            options = filterValues.map(item => {
                if (item.mid) {
                    return {
                        text: item.name,
                        subtext: `${item.mid} ${statusText(item.status)}`,
                        id: item.mid
                    }
                }
                return { text: item, subtext: '', id: item}
            })
            break
        case 'currency':
            options = filterValues.map(item => ({
                text: item,
                img: getFlagSrc(item)
            }))
            break
        case 'state':
        case 'payment_category':
        case 'type':
            options = filterValues.map(item => ({
                text: formatMessage(FILTER_STATES[item.toLowerCase()]),
                id: item
            }))
            break
        default:
            options = filterValues.map(item => ({
                text: item
            }))
            break
    }
    return options
}
