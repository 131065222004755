import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '@frontrunners/ui-components'
import './languageSelector.scss'


const getLocalizedLanguageName = (language) => {
    const regionNames = new Intl.DisplayNames([language.locale], {type: 'language'})
    return {...language, localizedName: regionNames.of(language.locale)}
}

export const LanguageSelector = ({ onSelect, languages, selectedLocaleCode, showName = false }) => {
    const [isOpen, setIsOpen] = useState(false)
    const componentRef = useRef(null)

    const selectedLocale = languages.find(lang => lang.locale == selectedLocaleCode)
    const sortedLanguages = [...languages].map(getLocalizedLanguageName)
        .sort((lang1, lang2) => lang1.localizedName > lang2.localizedName ? 1: -1)

    const closeOnClickOutside = useCallback(
        event => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }, [componentRef]
    )

    useEffect(() => {
        document.addEventListener('click', closeOnClickOutside, true)
        return () => {
            document.removeEventListener('click', closeOnClickOutside, true)
        }
    })

    return (
        <div
            data-testid="language-dropdown"
            className="language-dropdown"
            ref={componentRef}
            onClick={() => setIsOpen(isOpen => !isOpen)}
        >
            <div className="language-dropdown-flag">
                <Flag locale={selectedLocale} />
                { showName &&
                    <div className="language-dropdown-name">
                        { getLocalizedLanguageName(selectedLocale).localizedName }
                    </div> }
                <Icon iconName="arrowDown" scale="0.9" color="rgb(255, 255, 255)" fill="255" rotationDegrees={isOpen ? 180 : 0} />
            </div>
            {
                isOpen && <div className="language-dropdown-items" data-testid="language-dropdown-items">
                    {sortedLanguages.map(language => (
                        <div key={language.locale}>
                            <div className="language-item" onClick={() => onSelect(language.locale)}>
                                <div className="language-dropdown-flag">
                                    <Flag locale={language} />
                                </div>
                                <div className="language-name">
                                    {language.localizedName}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

const Flag = ({ locale }) => {
    return <img src={locale.flag} alt={locale.name} />
}
