import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import { LanguageSelector as LanguageSelectorDropdown } from '../../nav/containers/languageSelector'
import { LANGUAGES } from '../../merchant/constants'
import { submitLanguageSelection } from '../../nav/helpers/languageSelectionHelpers'
import { USER_PROFILE_TEXTS } from './intlMessageKeys'
import { Paper } from '@frontrunners/ui-components'
import { ApplicationContext } from '../../common/reactContext'
import './language.scss'

const Language = ({ dispatch }) => {
    const { formatMessage } = useIntl()
    const { loggedInUser } = useContext(ApplicationContext)

    return (
        <Paper className="language">
            <h2 className="settings-paper-header">
                {formatMessage(USER_PROFILE_TEXTS['merchant-profile-language-header'])}
            </h2>

            <div className="description">
                {formatMessage(USER_PROFILE_TEXTS['merchant-profile-language-description'])}
            </div>

            <div className="selector">
                <LanguageSelectorDropdown
                    onSelect={
                        submitLanguageSelection(
                            loggedInUser.isChatEligible,
                            loggedInUser.locale,
                            loggedInUser.sub,
                            dispatch
                        )
                    }
                    languages={LANGUAGES}
                    selectedLocaleCode={loggedInUser.locale}
                    showName
                />
            </div>
        </Paper>
    )
}

const mapStateToProps = (state) => {
    return {
        dispatch: state.get('dispatch')
    }
}
export default connect(mapStateToProps)(injectIntl(Language))
