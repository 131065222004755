import React from 'react'
import { FormattedMessage } from 'react-intl'


const CommonErrorComponent = ({children}) => {
    if(!children) {
        return (
            <div className="error-page-within-component">
                <div className="not-found-icon"></div>
                <h2>
                    <FormattedMessage
                        id="default-error-message-header"
                        defaultMessage={'Oops! We couldn\'t load your data.'}
                        description="Error message header"
                    />
                </h2>
                <p>
                <FormattedMessage
                    id="default-error-message-body"
                    defaultMessage="Try to refresh this page or log in and out."
                    description="Error message body"
                />
                </p>
            </div>
        )
    }

    return (
        <div className="error-page-within-component">
            <div className="not-found-icon"></div>
            { children }
        </div>
    )
}

export default CommonErrorComponent
