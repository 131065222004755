import { defineMessages } from 'react-intl'

export const SETTINGS_ONLINE = defineMessages({
    header: {
        id: 'merchant-profile-menu-checkout-settings-link',
        defaultMessage: 'Your checkout',
        description: 'Your checkout'
    },
    select_checkout: {
        id: 'settings-online-select-checkout',
        defaultMessage: 'Choose checkout',
        description: 'Header text to select which checkout to manage'
    },
    checkout_redirect_header: {
        id: 'merchant-profile-checkout-redirect-header',
        defaultMessage: 'Merchant Online',
        description: 'Header for the business section'
    },
    checkout_redirect_paragraph: {
        id: 'merchant-profile-checkout-redirect-paragraph',
        defaultMessage: 'Handling of your checkout and API keys will soon be moved to an external portal where you will have a lot more features and settings available to you.',
        description: 'Header for the business section'
    }
})

export const CHECKOUT_RENAME = defineMessages({
    checkout_rename_header: {
        id: 'merchant-profile-menu-business-link',
        defaultMessage: 'Business',
        description: 'Header for the business section'
    },
    checkout_rename_description: {
        id: 'merchant_checkout_business_description',
        defaultMessage: 'The name you enter here will be what is visible on the checkout title and purchase confirmation.',
        description: 'Information about changing name of business'
    },
    checkout_rename: {
        id: 'rename',
        defaultMessage: 'Rename',
        description: 'Label to rename'
    }
})

export const CHECKOUT_LOGO_UPLOAD = defineMessages({
    checkout_logo_header: {
        id: 'merchant_checkout_logo',
        defaultMessage: 'Logo',
        description: 'Logo'
    },
    checkout_logo_description: {
        id: 'merchant_checkout_description',
        defaultMessage: 'The logo will be used in the checkout payment view as well as the confirmation screens for purchases.',
        description: 'Description text for logo upload dropzone'
    },
    dropzone_empty_desktop: {
        id: 'checkout-logo-upload-empty-desktop',
        defaultMessage: 'Drag and drop your logo here',
        description: 'Logo empty message'
    },
    dropzone_empty_mobile: {
        id: 'checkout-logo-upload-empty-mobile',
        defaultMessage: 'Tap to upload your logo',
        description: 'Logo empty message'
    },
    upload_failed: {
        id: 'checkout-logo-upload-fail',
        defaultMessage: 'Something went wrong when trying to upload your logo',
        description: 'Error message if uploading logo fails'
    },
    invalid_image_type: {
        id: 'merchant-profile-checkout-settings-form-logo-invalid-type-label',
        defaultMessage: 'You have to use a .png .gif  or .jpeg image file.',
        description: 'Checkout settings, logo input label file type invalid message'
    },
    invalid_image_size: {
        id: 'merchant-profile-checkout-settings-form-logo-invalid-size-label',
        defaultMessage: 'Your image was too large. Max size is {maxSize} and recommended dimensions are 350px x 148px',
        description: 'Checkout settings, logo input label too large image message'
    }
})

export const CHECKOUT_TEST_MODE = defineMessages({
    header: {
        id: 'merchant-profile-menu-test-mode-link',
        defaultMessage: 'Test mode',
        description: 'Test mode'
    },
    description: {
        id: 'merchant_checkout_testmode_description',
        defaultMessage: 'Enter the trial account to play around with settings and data without transactions being completed, cards being charged etc.',
        description: 'test mode description'
    }
})

export const CHECKOUT_TRANSACTION_FEE_DROPDOWN = defineMessages({
    header: {
        id: 'checkout-transaction-fee',
        defaultMessage: 'Transaction fees',
        description: 'Header for the transaction fee option'
    },
    txn_fee_on_merchant: {
        id: 'merchant-profile-checkout-settings-form-transaction-fee-on-merchant',
        defaultMessage: 'Transaction fee is put on the merchant',
        description: 'Checkout settings, transaction fees are redacted from the merchant option'
    },
    txn_fee_on_customer: {
        id: 'merchant-profile-checkout-settings-form-transaction-fee-on-customer',
        defaultMessage: 'Transaction fee is put on the customer',
        description: 'Checkout settings, transaction fees are added to the amount the customer has to pay option'
    }
})

export const API_KEY_MANAGEMENT = defineMessages({
    select_epay: {
        id: 'settings-online-select-epay',
        defaultMessage: 'Choose account',
        description: 'Header text to select which epay id to manage'
    },
    api_keys_header: {
        id: 'merchant-profile-api-keys-list-title',
        defaultMessage: 'API',
        description: 'API key, title for site with api keys used to integreate with 3rd parties'
    },
    api_keys_description: {
        id: 'merchant-profile-api-keys-list-what-is-it',
        defaultMessage: 'Your API key is used to authenticate your account. It carry many privileges, so be sure to keep this information safe.',
        description: 'API key, what is it text'
    },
    api_keys_description_2: {
        id: 'merchant-profile-api-keys-list-only-once',
        defaultMessage: 'The secret API key is only shown once when it’s generated.',
        description: 'API key, api secret is only shown once when created text'
    },
    api_keys_dropdown_description: {
        id: 'api-key-dropdown-description',
        defaultMessage: 'API keys belonging to',
        description: 'Describing text followed by an id'
    },
    api_keys_table_header_identifier: {
        id: 'api-key-identifier',
        defaultMessage: 'Identifier',
        description: 'API key identifier (name) for online merchants'
    },
    api_keys_table_header_public_key: {
        id: 'merchant_api_public_key',
        defaultMessage: 'Public key',
        description: 'public key'
    },
    api_keys_table_row_delete_button: {
        id: 'merchant-profile-api-keys-list-item-open-delete',
        defaultMessage: 'Delete',
        description: 'Delete button for api key'
    },
    api_keys_generate_new: {
        id: 'settings-online-api-keys-generate-new',
        defaultMessage: 'Generate API key',
        description: 'Button for generating new api key'
    },
    api_keys_empty: {
        id: 'merchant-profile-api-keys-checkout-empty-list',
        defaultMessage: 'Hey, it looks like you haven\'t created an API key yet.',
        description: 'API key, empty list text'
    },
    api_keys_create_modal_title: {
        id: 'api-create-key-modal-title',
        defaultMessage: 'Your API key',
        description: 'Create api key title'
    },
    api_keys_create_modal_description: {
        id: 'api-key-submit-warning',
        defaultMessage: 'The encoded API key and secret key is only shown once and we urge you to store this information in a safe place.',
        description: 'Create api key submission warning.'
    },
    api_keys_create_modal_key_name_placeholder: {
        id: 'settings-online-api-keys-create-modal-key-name-placeholder',
        defaultMessage: 'API key name',
        description: 'Create api key name placeholder.'
    },
    api_keys_create_modal_save: {
        id: 'Save',
        defaultMessage: 'Save',
        description: 'Confirm button after creating the api key.'
    },
    api_keys_delete_modal_title: {
        id: 'merchant-profile-api-keys-delete-title',
        defaultMessage: 'Delete {apiKeyName}',
        description: 'API key, delete title'
    },
    api_keys_delete_modal_title2: {
        id: 'merchant-profile-api-keys-delete-title2',
        defaultMessage: 'Delete API key',
        description: 'API key, delete title'
    },
    api_keys_delete_modal_description: {
        id: 'merchant-profile-api-keys-delete-message',
        defaultMessage: 'This will delete your API key. You will not be able to accept payments with this API key.',
        description: 'API key, delete message'
    },
    api_keys_delete_modal_description2: {
        id: 'merchant-profile-api-keys-delete-message2',
        defaultMessage: 'The API key will be removed permanently',
        description: 'API key, delete message'
    },
    api_keys_delete_modal_cancel_button: {
        id: 'delete-api-key-modal-abort',
        defaultMessage: 'Cancel',
        description: 'Answer to if you are sure you want to delete api key - no'
    },
    api_keys_delete_modal_confirm_button: {
        id: 'delete-api-key-modal-confirm',
        defaultMessage: 'Yes, Delete',
        description: 'Answer to if you are sure you want to delete api key - yes'
    }
})
