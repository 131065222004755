import * as d3 from 'd3'
import { isFuture, isCurrent, getMaximumValueForGraph, getDataForDottedLine, getData, isCurrentAmountZero } from '../helpers'
import { getExponentedAmountString, getExponentedAmountInt } from '../../common/amountHelpers'
import React from 'react'
import moment from 'moment'
import { GRAPH_DATE_TRANSLATIONS, TEXT as TEXT_SALES } from '../constants'
import { DataAvailableFromDate } from '../../common/dateHelpers'
import { TEXT as TEXT_TRANSACTIONS, TRANSACTION_TABLE_HEADER } from '../../settlements/constants'
import { DASHBOARD_TRANSLATIONS } from '../translations'


const DEFAULT_TICK_VALUE = 5

const Graph = ({ dataset, exponent, isAggregatePeriodYearly, currency, isScreen, locale, formatMessage }) => {
    const isDataPointBeforeMinimumDataPointDate = dataPointDate => dataPointDate >= DataAvailableFromDate.instance.getDate()
    const datasetWithoutDatesBeforeLimit = dataset.filter(d => isDataPointBeforeMinimumDataPointDate(new Date(d.date)))

    const containWidth = isScreen ? window.innerWidth - 52 : 1090
    const right = isScreen ? 10 : 90

    const margin = { top: 20, right, bottom: 30, left: 10 },
        width = containWidth - margin.left - margin.right,
        height = 260 - margin.top - margin.bottom


    const maxvalOfData = d3.max(dataset, (d) => d.amount)
    const maxvalOfGraph = getMaximumValueForGraph(maxvalOfData, DEFAULT_TICK_VALUE, exponent)

    const intervalSize = maxvalOfGraph / DEFAULT_TICK_VALUE
    const step = Math.ceil(intervalSize / 5) * 5
    const maxvalOfGraphExponented = getExponentedAmountString(maxvalOfGraph, exponent, 0)
    const maxvalOfGraphPlusStepExponented = getExponentedAmountString(maxvalOfGraph + step, exponent, 0)
    const stepExponented = getExponentedAmountString(step, exponent, 0)

    d3.timeFormatDefaultLocale(GRAPH_DATE_TRANSLATIONS[locale])
    let parseTime = d3.timeParse('%Y-%m-%d')

    d3.select('.graph').append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)


    const bisectDate = d3.bisector(function (d) { return d.date }).right
    const xDomain = d3.extent(dataset, (d) => parseTime(d.date))

    const x = d3.scaleTime().range([0, width]).domain(xDomain)

    const y = d3.scaleLinear().range([height, 0]).domain([0, maxvalOfGraphExponented])

    const xAxis = d3.axisBottom()
        .scale(x)
        .ticks(dataset.length)
        .tickSizeInner(-height)
        .tickSizeOuter(0)
        .tickPadding(DEFAULT_TICK_VALUE)
        .tickFormat(d3.timeFormat(isAggregatePeriodYearly ? '%b' : '%e'))


    const yAxis = d3.axisLeft()
        .scale(y)
        .ticks(DEFAULT_TICK_VALUE)
        .tickSizeInner(-width)
        .tickSizeOuter(0)
        .tickPadding(DEFAULT_TICK_VALUE)
        .tickValues(d3.range(0, maxvalOfGraphPlusStepExponented, stepExponented))
        .tickFormat(d3.format('.2s'))


    const xAxisScreen = d3.axisBottom()
        .scale(x)
        .ticks(isAggregatePeriodYearly ? d3.timeMonth : d3.timeDay, isAggregatePeriodYearly ? 3 : 6)
        .tickSizeInner(-height)
        .tickSizeOuter(0)
        .tickPadding(DEFAULT_TICK_VALUE)
        .tickFormat(d3.timeFormat(isAggregatePeriodYearly ? '%b' : '%e'))


    const yAxisScreen = d3.axisLeft()
        .scale(y)
        .ticks(DEFAULT_TICK_VALUE)
        .tickSizeInner(-width)
        .tickSizeOuter(0)
        .tickPadding(DEFAULT_TICK_VALUE)
        .tickValues(d3.range(0, maxvalOfGraphPlusStepExponented, stepExponented))
        .tickFormat(d3.format('.2s'))

    d3.selectAll('.svg-holder').remove()

    let svg = d3.select('.graph').append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('class', 'svg-holder')
        .append('g')
        .attr('transform', `translate(${margin.left + (isScreen ? 0 : 70)}, ${margin.top})`)

    const focus = svg.append('g')
        .style('display', 'none')
        .attr('class', 'hoverline')


    var clip = svg.append('clipPath')
        .attr('id', 'clip')
    var clipRect = clip.append('rect')
        .attr('width', 0)
        .attr('height', 1500)

    const animateLine = () => {
        let line = d3.selectAll('.line')
        if (line.node()) {
            let totalLength = line.node().getTotalLength()
            line.attr('stroke-dasharray', totalLength)
                .attr('stroke-dashoffset', totalLength)
                .attr('stroke-dashoffset', 0)
        }
    }

    const renderAxis = () => {
        svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0, ${height})`)
            .call(xAxis)

        svg.append('g')
            .attr('class', 'y axis')
            .call(yAxis)
    }

    const renderAxisScreen = () => {
        svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0, ${height})`)
            .call(xAxisScreen)

        svg.append('g')
            .attr('class', 'y axis')
            .call(yAxisScreen)
            .call(g => g.select('.domain').remove())
            .call(g => g.selectAll('.tick text')
                .attr('x', 40)
                .attr('dy', -4))
    }

    const gradientAreaBackground = () => {
        svg.append('linearGradient')
            .attr('id', 'temperature-gradient')
            .attr('gradientUnits', 'userSpaceOnUse')
            .attr('x1', 0).attr('y1', y([0]))
            .attr('x2', 0).attr('y2', y(d3.max(dataset, () => maxvalOfGraphExponented * 500) / 500))
            .selectAll('stop').data([
                { offset: '30%', opacity: 0.2, color: '#007B7C' },
                { offset: '80%', opacity: 1, color: '#007B7C' }
            ])
            .enter().append('stop')
            .attr('offset', (d) => d.offset)
            .attr('stop-opacity', (d) => d.opacity)
            .attr('stop-color', (d) => d.color)
    }

    const renderArea = () => {

        //if the current amount is zero, do not shading on current day/month
        const numberOfDateOrMonthUntilNow = !isCurrentAmountZero(dataset, isAggregatePeriodYearly) ? 0 : 1

        const area = d3.area()
            .x(d => x(parseTime(d.date)))
            .y0(height)
            .y1((d) => y(getExponentedAmountString(d.amount, exponent, 0)))

        svg.append('defs').append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('width', width)
            .attr('height', height)

        svg.append('path')
            .data([getData(datasetWithoutDatesBeforeLimit, isAggregatePeriodYearly, numberOfDateOrMonthUntilNow)])
            .attr('class', 'area')
            .attr('d', area)
            .attr('clip-path', 'url(#clip)')
    }


    const renderDottedLine = () => {

        //if the current amount is zero, then do not render the dotted line
        const isRender = !isCurrentAmountZero(dataset, isAggregatePeriodYearly)
        if (!isRender) return

        const dottedline = d3.line()
            .x((d) => x(parseTime(d.date)))
            .y((d) => y(getExponentedAmountString(d.amount, exponent, 0)))

        svg.append('path')
            .data([getDataForDottedLine(dataset, isAggregatePeriodYearly)])
            .attr('class', 'line')
            .style('stroke-dasharray', ('3, 3'))
            .attr('d', dottedline)
            .attr('clip-path', 'url(#clip)')
    }

    const renderLine = () => {
        const line = d3.line()
            .x((d) => x(parseTime(d.date)))
            .y((d) => y(getExponentedAmountString(d.amount, exponent, 0)))

        svg.append('path')
            .data([getData(datasetWithoutDatesBeforeLimit, isAggregatePeriodYearly, 1)])
            .attr('class', 'line')
            .style('stroke-dasharray', ('none'))
            .attr('d', line)
            .attr('clip-path', 'url(#clip)')
    }

    const renderCircle = () => {

        //if the current amount is zero, do not render circle on current day/month
        const numberOfDateOrMonthUntilNow = !isCurrentAmountZero(dataset, isAggregatePeriodYearly) ? 0 : 1

        svg.selectAll('circle')
            .data(getData(datasetWithoutDatesBeforeLimit, isAggregatePeriodYearly, numberOfDateOrMonthUntilNow))
            .enter()
            .append('circle')
            .attr('class', 'circle')
            .attr('r', 3)
            .attr('cx', function (d) { return x(parseTime(d.date)) })
            .attr('cy', function (d) { return y(getExponentedAmountString(d.amount, exponent, 0)) })
            .attr('clip-path', 'url(#clip)')
    }

    const renderTooltip = (sales, payment_count, date, formatMessage) => {

        date = isAggregatePeriodYearly ? moment(date).format('MMM') : moment(date).format('MMM DD')
        const dateText = isAggregatePeriodYearly ? formatMessage(DASHBOARD_TRANSLATIONS['month']) : formatMessage(TRANSACTION_TABLE_HEADER['date'])

        return (
            `<div>
                <div class='tooltip__row'>
                    <div>${dateText}</div>
                    <div>${date}</div>
                </div>
                <div class='tooltip__row'>
                    <div>${formatMessage(TEXT_SALES['sales-link'])}</div>
                    <div>${getExponentedAmountInt(sales, exponent)} ${currency}</div>
                </div>
                <div class='tooltip__row'>
                    <div>${formatMessage(TEXT_TRANSACTIONS['transactions'])}</div>
                    <div>${payment_count}</div>
                </div>
            </div>`
        )
    }

    const addHoverLineWithToolTip = (formatMessage) => {
        const div = d3.select('.tooltip')

        function mouseout() {
            focus.style('display', 'none')
            div.style('opacity', 0)
        }

        function mouseover() {
            focus.style('display', null)
            div.style('opacity', 0)
        }

        function mousemove(event) {
            var x0 = x.invert(d3.pointer(event)[0])
            var i = bisectDate(dataset, moment(x0).format('YYYY-MM-DD'), 1)
            var d0 = dataset[i - 1]
            var d1 = dataset[i]
            if (!d1) var d = d0
            else d = x0 - moment(d0.date) > moment(d1.date) - x0 ? d1 : d0
            const defaultX = isScreen ? -90 : -20
            const timeSeriesDatePoint = new Date(d.date)
            var defaultY = -80

            if (isFuture(d.date, isAggregatePeriodYearly) ||
                (isCurrent(d.date, isAggregatePeriodYearly) && isCurrentAmountZero(dataset, isAggregatePeriodYearly)) || !isDataPointBeforeMinimumDataPointDate(timeSeriesDatePoint)
            ) {

                div.style('opacity', 0)
                return
            }

            const timeSeriesDatePointXPos = x(timeSeriesDatePoint)

            let marginLeft = defaultX + timeSeriesDatePointXPos
            let graphWidth = document.getElementsByClassName('graph-container')[0].clientWidth
            if (timeSeriesDatePointXPos < 100) marginLeft = 0
            else if (timeSeriesDatePointXPos > graphWidth - 200) marginLeft = graphWidth - 200

            div.html(renderTooltip(d.amount, d.payment_count, d.date, formatMessage))
                .style('opacity', 1)
                .style('margin-left', `${marginLeft}px`)
                .style('margin-top', `${defaultY + y(getExponentedAmountString(d.amount, exponent, 0))}px`)

            focus.select('circle.y')
                .attr('transform', `translate(${x(parseTime(d.date))}, ${y(getExponentedAmountString(d.amount, exponent, 0))})`)

            focus.select('.x')
                .attr('transform', `translate(${x(parseTime(d.date))}, ${y(getExponentedAmountString(d.amount, exponent, 0))})`)
                .attr('y2', height - y(getExponentedAmountString(d.amount, exponent, 0)))
        }
        focus.append('line')
            .attr('class', 'x')
            .style('stroke', '#DCD5E5')
            .style('stroke-width', '8px')
            .style('opacity', 0.43)
            .attr('y1', 0)
            .attr('y2', height)
            .attr('r', 5)

        focus.append('line')
            .attr('class', 'y')
            .style('stroke', '#DCD5E5')
            .style('stroke-width', '8px')
            .style('opacity', 0.43)
            .attr('x1', width)
            .attr('x2', width)
            .attr('r', 5)

        svg.append('rect')
            .attr('width', width)
            .attr('height', height)
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .on('mouseover', mouseover)
            .on('mouseout', mouseout)
            .on('mousemove', mousemove)

        focus.append('circle')
            .attr('class', 'y dot')
            .attr('fill', 'white')
            .attr('r', isScreen ? 3 : 6)
    }

    const renderDesktopGraph = () => {
        renderAxis()
        renderArea()
        renderDottedLine()
        renderLine()
        renderCircle()
        gradientAreaBackground()
        animateLine()
        addHoverLineWithToolTip(formatMessage)
    }

    const renderScreenGraph = () => {
        renderAxisScreen()
        renderArea()
        renderDottedLine()
        renderLine()
        gradientAreaBackground()
        animateLine()
        addHoverLineWithToolTip(formatMessage)
    }

    if (dataset && dataset.length > 0) isScreen ? renderScreenGraph() : renderDesktopGraph()

    try {
        clipRect.transition()
            .duration(1000)
            .ease(d3.easeLinear)
            .attr('width', 1000)
    }
    catch (error) {
        console.log('Error in Graph:' + error)
    }

    return <div></div>
}

export default Graph
