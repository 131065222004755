import React from 'react'
import './banner.scss'

const Banner = ({ header, purpose, moreInfo, actionButtons, visible = false }) => {
    return (
        <React.Fragment>
            {
                visible && <div className="banner" data-testid="banner-test">
                    <div className="banner-container">
                        <h1>{header}</h1>
                        <p>{purpose}</p>
                        <p className="more-info" dangerouslySetInnerHTML={{ __html: moreInfo }}></p>
                        <div className="action-buttons">{actionButtons}</div>
                    </div>
                </div>
            }

        </React.Fragment>
    )
}

export default Banner
