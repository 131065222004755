import * as types from './actionTypes'

export function set(payload) {
    return {
        type: types.SET,
        payload: payload
    }
}

export function setDeepLinkPath(payload) {
    return {
        type: types.SET_DEEP_LINK_PATH,
        payload: payload
    }
}