import React from 'react'
import { injectIntl } from 'react-intl'
import { Link, withRouter } from 'react-router-dom'

import MonetaryAmount from '../../common/components/monetaryAmount'
import {getData} from '../helpers/settlementDetailsByEmidTable'
import { getSaleslocationNameFromMid } from '../helpers'
import { TEXT as TRANSACTION_TEXT } from '../constants'
import uuid from 'uuid'

import './settlementDetailByElixir.scss'


const SettlementAdjustmentTable = ({ data, formatMessage}) => {
    return (
        <div className="settlement-detail-table">
            { data.map((row) =>
                <div className="detail-group" key={uuid.v4()}>
                    <div className="title">{formatMessage(row.title)}</div>
                    <MonetaryAmount currency={true} element={row.amountItem} className={'fg-darkest'}/>
                </div>
            )}
        </div>
    )
}


const SettlementDetailByElixir = ({ adjustmentSetByElixir, chargebackByElixir, elixirId, numberOfElixirMids, saleslocations, intl, match, elixirIdsNo, settlement }) => {
    const { formatMessage } = intl
    const saleslocation = getSaleslocationNameFromMid(saleslocations, elixirId)
    return (
        <div className={`settlement-detail__item settlement-detail__item--${elixirIdsNo>1?'multi':'single'}`}>
            <div className="settlement-detail__row settlement-detail__row">
                <div className="settlement-detail__row__item settlement-detail__row__item--bold">
                    {elixirId}, {saleslocation}
                </div>
                <div className="settlement-detail__row__item settlement-detail__row__item--link">
                    {settlement.transactions_available && <Link
                        className=""
                        to={{
                            pathname:`/merchants/${match.params.merchantId}/payouts/${settlement.id}/${elixirId}/transactions`,
                            state: {
                              fromSettlement: true,
                              id: settlement.id
                            }
                        }}
                    >
                        {formatMessage(TRANSACTION_TEXT.transactions)} {'>'}
                    </Link> }
                </div>
            </div>

            <SettlementAdjustmentTable
                data={getData(chargebackByElixir, adjustmentSetByElixir, numberOfElixirMids)}
                formatMessage={formatMessage}
            />
        </div>
    )
}

export default withRouter(injectIntl(SettlementDetailByElixir))
