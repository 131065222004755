import React from 'react'
import { LoginComponent } from '@frontrunners/wl-cognito-login'
import { getPoolData } from './helpers'
import './cognitoLoginContainer.scss'


export const CognitoLoginContainer = ({ store }) => {

    const poolData = getPoolData(store)
    const searchParams = new URLSearchParams(window.location.search)
    const redirectUrl = searchParams.get('redirectUrl')
    const localeCode = store.getState().getIn(['localization', 'localeCode'])

    const onSuccessCallback = (result) => {
        const idToken = result.idToken.jwtToken
        window.location.replace(`${redirectUrl ||'/'}?#access_token=${idToken}&id_token=${idToken}`)
    }

    return (
        <LoginComponent
            poolData={poolData}
            locale={localeCode}
            applicationName={'Merchant Portal'}
            onSuccessCallback={onSuccessCallback}
            resetPasswordRoute="/reset-password"
            setPasswordRoute="/set-password"
            sessionHasExpired={!!redirectUrl && redirectUrl !== '/'}
        />
    )
}
