import React from 'react'
import { getAmountString, fnumWithM } from '../amountHelpers'

const MonetaryAmountKmb = ({ element, amount, amount_exponent, currency, className, hideInsignificantDecimals, excludeCurrency }) => {
    let elem = element || { amount: amount, amount_exponent: amount_exponent, currency: currency }
    if (typeof elem.amount === 'undefined') {
        return (<span className={className}>–</span>)
    }
    let elemClass = className || (elem.amount > 0 ? 'fg-success' : '')
    let amount_string = getAmountString(elem)
    if (hideInsignificantDecimals) {
        let splitted = amount_string.split('.')
        if (splitted[1] && parseInt(splitted[1]) == 0) {
            amount_string = splitted[0]
        }
    }

    amount_string = fnumWithM(amount_string)

    return (
        <span>
            {!excludeCurrency && <span className={elemClass}>{amount_string} {elem.currency}</span>}
            {!!excludeCurrency && <span className={elemClass}>{amount_string}</span>}
        </span>
    )
}

export default MonetaryAmountKmb

