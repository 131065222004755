import React from 'react'
import moment from 'moment'

const FormattedTime = ({date, elemClass}) => {
    const timeString = moment.parseZone(date).format('HH:mm')
    return (
        <div className={elemClass}>{timeString}</div>
    )
}

export default FormattedTime
