import React, { Component } from 'react'


class BamboraSvgIcon extends Component {
    constructor (props) {
        super(props)
    }
  // takes an icon and a fill color
    render () {
        var namedIcons = {
            'alert': require('../../../assets/icons/alert.svg'),
            'amex': require('../../../assets/icons/amex.svg'),
            'arrow-left': require('../../../assets/icons/arrow-left.svg'),
            'authorize-card': require('../../../assets/icons/authorize-card.svg'),
            'cancel': require('../../../assets/icons/cancel.svg'),
            'capture': require('../../../assets/icons/capture.svg'),
            'checkmark': require('../../../assets/icons/checkmark.svg'),
            'chevron-down': require('../../../assets/icons/chevron-down.svg'),
            'clock': require('../../../assets/icons/clock.svg'),
            'close': require('../../../assets/icons/close.svg'),
            'close-white': require('../../../assets/icons/close-white.svg'),
            'close-thin': require('../../../assets/icons/close-thin.svg'),
            'comment': require('../../../assets/icons/comment.svg'),
            'creditcard-outline': require('../../../assets/icons/creditcard-outline.svg'),
            'dankort': require('../../../assets/icons/dankort.svg'),
            'denied': require('../../../assets/icons/denied.svg'),
            'diners': require('../../../assets/icons/diners.svg'),
            'discover': require('../../../assets/icons/discover.svg'),
            'download': require('../../../assets/icons/download.svg'),
            'flag': require('../../../assets/icons/flag.svg'),
            'image-placeholder': require('../../../assets/icons/image-placeholder.svg'),
            'jcb': require('../../../assets/icons/jcb.svg'),
            'lindorff': require('../../../assets/icons/lindorff.svg'),
            'list': require('../../../assets/icons/list.svg'),
            'mastercard': require('../../../assets/icons/mastercard.svg'),
            'maestro': require('../../../assets/icons/maestro.svg'),
            'mobilepay': require('../../../assets/icons/mobilepay.svg'),
            'more': require('../../../assets/icons/more.svg'),
            'notification-active': require('../../../assets/icons/notification-active.svg'),
            'notification-inactive': require('../../../assets/icons/notification-inactive.svg'),
            'privatelabel': require('../../../assets/icons/privatelabel.svg'),
            'refund': require('../../../assets/icons/refund.svg'),
            'reload': require('../../../assets/icons/reload.svg'),
            'ressursbank': require('../../../assets/icons/ressursbank.svg'),
            'search': require('../../../assets/icons/search.svg'),
            'seb': require('../../../assets/icons/seb.svg'),
            'sent': require('../../../assets/icons/sent.svg'),
            'vipps': require('../../../assets/icons/vipps.svg'),
            'visa': require('../../../assets/icons/visa.svg'),
            'unionpay': require('../../../assets/icons/unionpay.svg'),
            'wallet': require('../../../assets/icons/payment-mobilepay.svg'),
            'history-authorized': require('../../../assets/icons/history-authorized.svg'),
            'history-capture': require('../../../assets/icons/history-capture.svg'),
            'history-captured': require('../../../assets/icons/history-capture.svg'),
            'history-cleared': require('../../../assets/icons/history-capture.svg'),
            'history-refund': require('../../../assets/icons/history-refund.svg'),
            'history-refunded': require('../../../assets/icons/history-refund.svg'),
            'history-cancel': require('../../../assets/icons/history-cancelled.svg'),
            'history-voided': require('../../../assets/icons/history-cancelled.svg'),
            'history-invoice': require('../../../assets/icons/history-invoice.svg'),
            'history-visa': require('../../../assets/icons/history-visa.svg'),
            'history-mastercard': require('../../../assets/icons/history-mastercard.svg'),
            'history-americanexpress': require('../../../assets/icons/history-amex.svg'),
            'device-desk5000': require('../../../assets/icons/device-desk5000.svg'),
            'device-ict250': require('../../../assets/icons/device-ict250.svg'),
            'device-lane3000': require('../../../assets/icons/device-lane3000.svg'),
            'device-ipp350': require('../../../assets/icons/device-ipp350.svg'),
            'device-move5000': require('../../../assets/icons/device-move5000.svg'),
            'device-iwl': require('../../../assets/icons/device-iwl.svg'),
            'new-api': require('../../../assets/icons/new-api.svg'),
            'new-business': require('../../../assets/icons/new-business.svg'),
            'new-checkout': require('../../../assets/icons/new-checkout.svg'),
            'new-language': require('../../../assets/icons/new-language.svg'),
            'new-readers': require('../../../assets/icons/new-readers.svg'),
            'new-test-mode': require('../../../assets/icons/new-users.svg'),
            'new-users': require('../../../assets/icons/new-users.svg'),
            'upload': require('../../../assets/icons/icon-upload.svg'),
            'walley': require('../../../assets/icons/walley.svg')
        }
        var classNamesConcat = this.props.classNames instanceof Array ? this.props.classNames.join(' ') : ''
        var classNameValue = this.props.className ? this.props.className :  classNamesConcat
        classNameValue += ' svg-icon'
        var iconMarkup = {__html: namedIcons[this.props.icon] || ''}
        return (
      <div className={classNameValue} dangerouslySetInnerHTML={iconMarkup} onClick={(e) => this.props.onClick && this.props.onClick(e)}>
      </div>
        )
    }
}

export default BamboraSvgIcon
