import { Map } from 'immutable'
import { GET_TOKEN, GET_CONSENT, GIVE_CONSENT } from './actionTypes'

const initialState = Map({
    givenConsent: false,
    isFetching: false
})

export default function reducer(loans = initialState, action) {
    let error
    switch (action.type) {
        case `${GIVE_CONSENT}_START`:
        case `${GET_TOKEN}_START`:
        case `${GET_CONSENT}_START`:
            return loans.set('isFetching', true)

        case `${GET_TOKEN}_SUCCESS`:
            return loans.set('isFetching', false)
                .set('access_token', action.payload.access_token)
        case `${GET_CONSENT}_SUCCESS`:
            return loans.set('isFetching', false)
                .set('givenConsent', action.payload.consent)
        case `${GIVE_CONSENT}_SUCCESS`:
            return loans.set('isFetching', false)
                .set('givenConsent', action.payload.consent)

        case `${GET_TOKEN}_ERROR`:
        case `${GET_CONSENT}_ERROR`:
        case `${GIVE_CONSENT}_ERROR`:
            error = (!!action.payload && !!action.payload.error) ? action.payload.error : 'error'
            return loans.set('isFetching', false)
                .set('error', error)
        default:
            return loans
    }
}
