import {groupBy} from '../../common/helpers/arrayHelper'
import aggregateAdjustmentType from './aggregateAdjustmentType'


export default function(adjustmentSet){
    adjustmentSet = groupBy(adjustmentSet, 'source_merchant_id')
    for(let emid in adjustmentSet){ // eslint-disable-line
        adjustmentSet[emid] = aggregateAdjustmentType(adjustmentSet[emid])
    }
    return adjustmentSet
}
