import './esw.min.js'
import './chat.js'
import './chat.scss'

const language_mapping = {
    'da': 'dk',
    'sv': 'se',
    'fi': 'fi',
    'nb': 'no',
    'en': 'en'
}


var initESW = function (locale, gslbBaseURL) {
    var siteLang = language_mapping[locale]
    var chatParameters = {
        'dk': {
            'buttonId': '57361000000TNRU',
            'sfdcLang': 'da',
            'onlineTxt': 'Chat med os',
            'offlineTxt': 'Chat lukket',
            'caseLang': 'Danish'
        },
        'en': {
            'buttonId': '57361000000TNRo',
            'sfdcLang': 'en',
            'onlineTxt': 'Chat with us',
            'offlineTxt': 'Chat closed',
            'caseLang': 'English'
        },
        'fi': {
            'buttonId': '57361000000TNRZ',
            'sfdcLang': 'fi',
            'onlineTxt': 'Keskustele kanssamme',
            'offlineTxt': 'Chat suljettu',
            'caseLang': 'Finnish'
        },
        'no': {
            'buttonId': '57361000000TNRe',
            'sfdcLang': 'no',
            'onlineTxt': 'Chat med oss',
            'offlineTxt': 'Chat lukket',
            'caseLang': 'Norwegian'
        },
        'se': {
            'buttonId': '57361000000TNRP',
            'sfdcLang': 'sv',
            'onlineTxt': 'Chatta med oss',
            'offlineTxt': 'Chatt stängd',
            'caseLang': 'Swedish'
        }
    };
    //Remove widget if lang is not supported
    if (!chatParameters[siteLang]) {
        return
    }
    embedded_svc.settings.displayHelpButton = true; //Or false
    embedded_svc.settings.language = chatParameters[siteLang].sfdcLang;
    embedded_svc.settings.defaultMinimizedText = chatParameters[siteLang].onlineTxt; //(Defaults to Chat with an Expert)
    embedded_svc.settings.disabledMinimizedText = chatParameters[siteLang].offlineTxt; //(Defaults to Agent Offline)
    // Settings for Live Agent
    embedded_svc.settings.avatarImgURL = '';
    embedded_svc.settings.prechatBackgroundImgURL = '';
    embedded_svc.settings.waitingStateBackgroundImgURL = '';
    embedded_svc.settings.smallCompanyLogoImgURL = '';
    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';
    embedded_svc.settings.extraPrechatFormDetails = [{
        "label": "Lang",
        "value": chatParameters[siteLang].caseLang,
        "displayToAgent": true
    }, {
        "label": "Origin",
        "value": "chat",
        "displayToAgent": true
    }];
    embedded_svc.settings.extraPrechatInfo = [{
        "entityFieldMaps": [{
            "doCreate": true,
            "doFind": false,
            "fieldName": "Language__c",
            "isExactMatch": false,
            "label": "Lang"
        }, {
            "doCreate": true,
            "doFind": false,
            "fieldName": "Origin",
            "isExactMatch": false,
            "label": "Origin"
        }],
        "showOnCreate": true,
        "saveToTranscript": "CaseId",
        "entityName": "Case"
    }];
    embedded_svc.init(
        'https://bambora.my.salesforce.com',
        'https://bambora.secure.force.com/chat',
        gslbBaseURL,
        '00D610000007qyq',
        'Bambora_com', {
        baseLiveAgentContentURL: 'https://c.la1-c1-dfw.salesforceliveagent.com/content',
        deploymentId: '57261000000Gxsu',
        buttonId: chatParameters[siteLang].buttonId,
        baseLiveAgentURL: 'https://d.la1-c1-dfw.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I61000000TN1oEAG_16198f18539'
    });
};

export function initChat(locale) {
    if (!window.embedded_svc) {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://bambora.my.salesforce.com/embeddedservice/4.1/esw.min.js');
        s.onload = function () {
            initESW(locale);
        };
        document.body.appendChild(s);
    } else {
        initESW(locale, 'https://service.force.com');
    }
}
