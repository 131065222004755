import { STATES } from './constants'

export const getSelectableMerchants = (merchants) => {
    const validStates = [STATES.PROD, STATES.ACQUIRER, STATES.TRIAL_NOT_ACTIVATED]
    const activeMerchants = merchants.filter(merchant => merchant.status === 'active')

    /* By default we hide the linked trial account from the merchant selector and just redirect to dashboard with the prod account
    * This solves the issue if a user has lost access to the prod account,
    * the user will now instead be redirected to dashboard with the linked trial account.
    */

    if (activeMerchants.length === 1 && activeMerchants[0].state === STATES.TRIAL_ACTIVATED) {
        return activeMerchants
    }

    return activeMerchants.filter(m => (validStates.includes(m.state)))
}

export const isMultiMerchant = (merchants) => {
    const selectableMerchants = getSelectableMerchants(merchants)
    return selectableMerchants.length > 1
}

export const getSystemIdToMid = (merchant, system, system_id) => {
    switch (system) {
        case 'zero':
            if (merchant.extra_data?.online_id_to_acquiring_mid) {
                return merchant.extra_data?.online_id_to_acquiring_mid?.find(system_id_to_mid => {
                    return system_id_to_mid['source_merchant_id'] === system_id
                })
            }
            break
        case 'samport':
            if (merchant.extra_data.instore_id_to_acquiring_mid) {
                return merchant.extra_data.instore_id_to_acquiring_mid.find(system_id_to_mid => {
                    return system_id_to_mid['source_merchant_id'] === system_id
                })
            }
            break
        default:
            console.log('Failed to get system id to mid. Unknown system: ' + system)
    }
}

export const getSaleslocationFromAcquiringMid = (merchant, acquiring_mid) => {
    let saleslocation = merchant.saleslocation_set.find((sl) => {
        return sl.mid_set.find(mid => mid.mid === acquiring_mid)
    })
    return saleslocation
}
