import querystring from 'querystring'

import { PAGE_LIMIT, PAGE_OFFSET } from './constants'
import { REDIRECT_LINK } from '../config/constants'

const getNextPageQuery = (search) => {
    // get next page query obj based on current search query,
    // use default search query if the current search query is undefined
    let { limit, offset } = parseSearch(search)
    limit = parseInt(limit) || PAGE_LIMIT
    offset = parseInt(offset) || PAGE_OFFSET
    const nextPageOffset = offset + limit
    return { offset: nextPageOffset, limit }
}

export const parseSearch = (locationSearch) => {
    // takes location params/search and turns it into an object
    if (!locationSearch) return {}
    let trimmed = locationSearch.replace('?', '')
    let pairs = trimmed.split('&')
    return pairs.reduce((acc, pair) => {
        const [key, value] = pair.split('=')
        acc[key] = value
        return acc
    }, {})
}

export const createSearch = (dict) => {
    // takes an object and turns it into a location params/search string
    if (!dict) return ''
    let kvs = Object.entries(dict)
    if (kvs.length === 0) return ''
    return kvs.reduce((acc, [key, value], i) => {
        const seperator = i > 0 ? '&' : ''
        return acc + seperator + key + '=' + value
    }, '?')
}

export const mergeNextPageSearch = (nextPage, search) => {
    let searchObjectNoLimitOffset = {}
    let mergedSearch = {}
    let searchObject = parseSearch(search)
    let nextPageObject = parseSearch(nextPage)
    const limitOffset = ['offset', 'limit']

    Object.keys(searchObject).forEach(function (key) {
        if (!limitOffset.includes(key)) {
            searchObjectNoLimitOffset[key] = searchObject[key]
        }
    })

    mergedSearch.offset = nextPageObject.offset
    mergedSearch.limit = nextPageObject.limit

    const mergedSearchObject = Object.assign({}, mergedSearch, searchObjectNoLimitOffset)
    return createSearch(mergedSearchObject)
}

export const hasNextPage = (search, total) => {
    const { offset } = getNextPageQuery(search)
    return (!isNaN(offset) && offset < total)
}

export const getNextPageUrl = (search, defaults) => {
    const merged = Object.assign({}, defaults, parseSearch(search))
    return createSearch(Object.assign({}, merged, getNextPageQuery(search)))
}

export const isTheOffsetOutOfRange = (search, total) => {
    let { offset } = parseSearch(search)
    offset = parseInt(offset)
    if (total === -1) {
        return false
    }
    if (offset && offset >= total) {
        return true
    }
    return false
}


const getDeepLinkPathFromHash = (hash, defaultValue = null) => {
    const query = querystring.parse(hash)

    if ('state' in query) {
        return convertBase64ToObj(query.state)
    }

    return defaultValue
}

const getDeepLinkPathFromPathName = (pathname) => {
    let redirectLink = ''
    const pathRegex = /\/merchants\/[a-f0-9-]+\/(.+)/
    const groups = pathname.match(pathRegex)

    if (groups) {
        redirectLink = groups[1]
    }
    else {
        redirectLink = pathname.substring(1) // Remove first slash
    }

    let deepLinkPath = {}

    deepLinkPath[REDIRECT_LINK] = redirectLink

    return deepLinkPath
}

export const convertObjToBase64 = (obj) => {
    try {
        return btoa(JSON.stringify(obj))
    } catch (error) {
        console.error(error)
        return ''
    }
}

export const convertBase64ToObj = (base64Str) => {
    try {
        return JSON.parse(atob(base64Str))
    } catch (error) {
        console.error(error)
        return null
    }

}

export const getDeepLink = (location) => {
    //deeplink can be
    //  1. getDeepLinkPathFromHash: from the "state" which is a query string from login service return url, it is a object in base64 string
    //  2. getDeepLinkPathFromPathName: from url, which in a readable string, e.g.welcome
    const deepLinkPath = getDeepLinkPathFromHash(location.hash) || getDeepLinkPathFromPathName(location.pathname)
    return deepLinkPath
}

export const parseHash = () => {
    const hash = window.location.hash.substr(1)
    return querystring.parse(hash)
}

