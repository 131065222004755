import { Map } from 'immutable'
import * as types from './actionTypes'

export default function configReducer (config = Map(), action) {
    switch (action.type) {
    case types.SET:
        return config.merge(Map(action.payload))
    case types.SET_DEEP_LINK_PATH:
        return config.merge(Map(action.payload))
    default:
        return config
    }
}
