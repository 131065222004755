import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import SettlementDetailsComponent from '../components/settlementDetailsComponent'
import * as actions from '../actions'
import BamboraSvgIcon from '../../common/components/bamboraSvgIcon'

class SettlementDetailsContainer extends Component {

    constructor(props) {
        super(props)

        this.fetch()
        window.scrollTo(0, 0)
    }

    fetch() {
        if(this.props.settlements.getIn(['list', 'data'])) {
            this.props.dispatch(actions.getDetails(this.props.match.params.settlementId))
        } else {
            this.redirectToListView()
        }
    }

    redirectToListView() {
        this.props.history.push(`/merchants/${this.props.match.params.merchantId}/payouts`)
    }

    render() {
        const settlement = this.props.settlements.getIn(['current', 'data'])

        return (
            <div className="main-width-2 settlement-detail-container">
                {settlement &&
                    <div>
                        <div className="secondary-nav">
                            <div className="pull-left">
                                <a className="nav-link" onClick={() => this.props.history.goBack()}>
                                    <BamboraSvgIcon icon="arrow-left" className="fill-inherit back-icon" />
                                    <span className="after-rotate">
                                        <FormattedMessage
                                            id="settlement-details-navbar-back"
                                            defaultMessage={'Back'}
                                            description="Back button in navbar, that lets the user go back to the previous browser location"
                                        />
                                    </span>
                                </a>
                                <div className="line-vertical"></div>
                                <h1>
                                <FormattedMessage
                                    id="Settlement Details"
                                    defaultMessage={'Settlement Details'}
                                    description="Settlement Details title"
                                />
                                </h1>
                            </div>
                        </div>
                        <SettlementDetailsComponent settlement={settlement.toJS()} disableDownloadBtn={!settlement.toJS().transactions_available} />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])
    return {
        settlements: state.getIn([activeMerchant, 'settlements'])
    }
}

export default connect(mapStateToProps)(SettlementDetailsContainer)
