import { ROLES, SETTINGS_TEXTS } from '../merchant/constants'


export const getAssignableUserRoles = (formatMessage) => [
    {
        text: getRoleDisplayText(formatMessage, ROLES.USER),
        value: ROLES.USER,
        subtext: getRoleDescriptionText(formatMessage, ROLES.USER)
    },
    {
        text: getRoleDisplayText(formatMessage, ROLES.ADMIN),
        value: ROLES.ADMIN,
        subtext: getRoleDescriptionText(formatMessage, ROLES.ADMIN)
    }
]
export const getRoleDisplayText = (formatMessage, role) => {
    if (role === ROLES.OWNER) {
        return formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_role_owner_title'])
    }
    if (role === ROLES.ADMIN) {
        return formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_role_admin_title'])
    }
    if (role === ROLES.USER) {
        return formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_role_user_title'])
    }
    return formatMessage(SETTINGS_TEXTS['merchant-profile-merchant-user-role-none-title'])
}

const getRoleDescriptionText = (formatMessage, role) => {
    if (role === ROLES.ADMIN) {
        return formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_role_admin_description'])
    }
    if (role === ROLES.USER) {
        return formatMessage(SETTINGS_TEXTS['merchant_profile_merchant_user_role_user_description'])
    }
}

export const isOwner = (role) => role === ROLES.OWNER
export const isAdmin = (role) => role === ROLES.ADMIN
