import React from 'react'
import { ORDER_OPERATION_MODAL } from '../translations'
import { useTable } from 'react-table'
import SelectDropdown from '../../../common/components/numSelectDropdown'
import { getExponentedAmountString } from '../../../common/amountHelpers'
import './orderOperationComponent.scss'

/* NOTE: This is use for the "new" CollectorBank API */

const OrderOperationComponent = (props) => {
    const { formatMessage, orderLines, operationName, amountExponent, selectItem, setSelectItem, onOperation, onHide } = props

    const isSelectedALL = () => {
        const itemSelectDoesntMatchTotal = orderLines && orderLines.find(item => {
            const matchItem = selectItem && selectItem.find(selectItem => selectItem.unique_id === item.unique_id)
            if (!matchItem || item.quantity !== matchItem.quantity) return true
        })
        return !itemSelectDoesntMatchTotal
    }

    const selectItemQuantity = (unique_id, quantity) => {
        const newSelectItem = selectItem && selectItem.map(item => {
            if (item.unique_id === unique_id) return { id: item.id, quantity, unitPrice: item.unitPrice, description: item.description, unique_id: item.unique_id  }
            else return item
        })
        setSelectItem(newSelectItem)
    }

    const toggleSelectAll = () => {
        const selectAll = !isSelectedALL()
        const newSelectItem = orderLines && orderLines.map(item => {
            const quantity = selectAll ? item.quantity : 0
            return { id: item.id, unitPrice: item.unitPrice, quantity, description: item.description, unique_id: item.unique_id }
        })
        setSelectItem(newSelectItem)
    }

    const getTotalAmount = () => {
        const amount = orderLines && orderLines.reduce((acc, current) => acc + current.quantity * current.unitPrice, 0)
        return getExponentedAmountString(amount, amountExponent)
    }
    const getSelectedAmount = () => {
        const selectedAmount = selectItem ? selectItem.reduce((acc, current) => {
            const quantity = current ? current.quantity : 0
            return acc + quantity * current.unitPrice
        }, 0) : 0
        return getExponentedAmountString(selectedAmount, amountExponent)
    }

    const isButtonDisabled = () => {
        const selectAmount = getSelectedAmount()
        return !(Math.round(selectAmount) > 0)
    }

    const columns = React.useMemo(() => [
        {
            Header: () => <React.Fragment>{formatMessage(ORDER_OPERATION_MODAL['items'])}</React.Fragment>,
            id: 'items',
            accessor: 'text',
            className: 'items',
            headerClassName: 'items'
        },
        {
            Header: () => <React.Fragment>{formatMessage(ORDER_OPERATION_MODAL[operationName])}</React.Fragment>,
            id: operationName,
            accessor: d => {
                const total = d.quantity
                const selected = selectItem && selectItem.find(item => item.unique_id === d.unique_id)
                const selectedQuantity = selected ? selected.quantity : 0
                return <SelectDropdown
                    total={total}
                    selected={selectedQuantity}
                    onChange={selectItemQuantity.bind(undefined, d.unique_id)}
                />
            },
            width: 70
        },
        {
            Header: () => <React.Fragment>{formatMessage(ORDER_OPERATION_MODAL['amount'])}</React.Fragment>,
            headerClassName: 'rt-td amount',
            id: 'price',
            className: 'rt-td amount',
            accessor: d => {
                const selected = selectItem && selectItem.find(item => item.id === d.id)
                const quantity = selected ? selected.quantity : 0
                return getExponentedAmountString(quantity * d.unitPrice, amountExponent)
            },
            width: 70
        }
    ])

    return <div className="order-operation">
        <div className="order-operation__select-all" onClick={toggleSelectAll}>
            <div>
                <input
                    type="checkbox"
                    checked={isSelectedALL()}
                    readOnly={true}
                />
                <div className="checkmark"></div>
            </div>
            <div>{formatMessage(ORDER_OPERATION_MODAL[`${operationName}_full_order`])}</div>
        </div>
        <Table data={orderLines} columns={columns}/>
        <div className="order-operation__amount-row">
            <div>{formatMessage(ORDER_OPERATION_MODAL.amount_available)}</div>
            <div>{getTotalAmount()}</div>
        </div>
        <div className="order-operation__amount-row order-operation__selected-amount">
            <div>{formatMessage(ORDER_OPERATION_MODAL[`amount_to_${operationName}`])}</div>
            <div>{getSelectedAmount()}</div>
        </div>
        <div className="order-operation__btns">
            <button disabled={isButtonDisabled()} data-testid={`${props.operationName}-btn`} onClick={onOperation} className="rebranded-primary-btn">{formatMessage(ORDER_OPERATION_MODAL[props.operationName])}</button>
            <button onClick={onHide} className="btn btn-small btn-cancel">{formatMessage(ORDER_OPERATION_MODAL.cancel)}</button>
        </div>

    </div>
}

const Table = ({data, columns}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
        } = useTable({columns, data})


    return (
        <div {...getTableProps()} className="order-operation">
                <div className="rt-thead -header thead">
                    {headerGroups.map(headerGroup => (
                        <div className="tr-head rt-tr"  {...headerGroup.getHeaderGroupProps()}>
                            { headerGroup.headers.map(column => (
                                <div  style={{flex: column.id === 'items' ? '250 0 auto' : '75 0 auto', width: column.id === 'items' ? '250px' : '75px', maxWidth: column.id === 'items' ? '250px' : 'none'}} {...column.getHeaderProps({className: column.headerClassName})}>
                                    {column.render('Header')}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="rt-tbody tbody" {...getTableBodyProps()}>
                    { rows.map((row, i ) =>
                        prepareRow(row) || (
                            <React.Fragment key={i}>
                                <div className={`${row.isExpanded ? 'expanded' : ''} tr-body rt-tr`} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <div className="td-body rt-td" style={{flex: cell.column.id === 'items' ? '250 0 auto' : '75 0 auto', width: cell.column.id === 'items' ? '250px' : '75px', maxWidth: cell.column.id === 'items' ? '250px' : 'none'}} {...cell.getCellProps({className: cell.column.className})}>{cell.render('Cell')}</div>
                                        )
                                    })}
                                </div >
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
    )
}

export default OrderOperationComponent
