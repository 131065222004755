import React from 'react'

const emptyState = ({children}) => {
  // a generic empty state for a collection of things
    return (
    <div className="empty-list-notification">
      <div className="book-icon"></div>
      {children}
    </div>
    )
}

export default emptyState
