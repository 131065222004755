
import React, { useEffect, useState, useMemo } from 'react'
import Graph from './graph'

const GraphWrapper = (props) => {
    if (!props.dataset) return ''

    const [winWidth, setWinWidth] = useState(window.innerWidth)

    const graph = useMemo(() => {
        const isScreen = winWidth > 1200 ? false : true
        return <div className="graph-container">
            <div className="graph">
                <Graph {...props} isScreen={isScreen} />
            </div>
        </div>
    }, [props.dataset, winWidth])

    useEffect(() => {
        const handleResize = () => setWinWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return <React.Fragment>{graph}</React.Fragment>

}

export default GraphWrapper
