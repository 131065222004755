export const mixpanelPrefix = {
    LOGIN: 'Login',
    WELCOME: 'Welcome:',
    OVERVIEW: 'Overview:',
    DASHBOARD: 'Dashboard:',
    LOAN: 'Loan:',
    LOAN_COMPONENT: 'Growth Finance:',
    INVOICES: 'Invoices:',
    PAYOUTS: 'Payouts:',
    SETTINGS: 'Settings:',
    SALES: 'Sales:',
    SUPPORT: 'Support:',
    CHAT: 'Chat:',
    SUPPORTED: 'Supported: ',
    SWITCH_MERCHANT: 'Switch Merchant:',
    MONTHLY_REPORTS: 'Monthly Reports:',
    REPORTS: 'Reports:',
    TERMINAL_SWAP: 'Terminal Swap:'
}
