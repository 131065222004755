import { REPORT_TITLE } from './constants'
import React from 'react'
import { CUSTOMER_TYPE_DACH } from '../common/constants'

export const getMerchant = (merchantId, merchants) => {
    return merchants.find(m => m.id === merchantId)
}

export const getProgressPercent = (items_fetched, items_total) => {
    items_total = parseInt(items_total)
    items_fetched = parseInt(items_fetched)

    if (typeof items_total !== 'number' || typeof items_fetched !== 'number') return 0

    else if (items_total === 0) return 80
    else if (items_total < items_fetched) items_fetched = items_total

    return items_fetched && items_total ? Math.round((items_fetched / items_total) * 0.8 * 100) : 0
}

export const updateSettlementReorts = (reports, data, loading) => {
    let { format, settlementId } = data
    data.loading = loading


    const isReportExist = reports.find(report => (report.settlementId === settlementId && report.format === format))

    if (isReportExist) {
        return reports.map(report => {
            if (report.settlementId === settlementId && report.format === format) return data
            else return report
        })
    } else {
        return [...reports, data]
    }

}
export const updatedReports = (reports, data, loadingStatus) => {
    const { format, type, items_fetched, items_total } = data
    return reports.map(monthReports => {
        const dateMatch = monthReports.date.valueOf() == data.date.valueOf()
        let newMonthReports = monthReports.reports
        if (dateMatch) {
            newMonthReports = monthReports.reports.map(report => {

                const formatMatch = report.format === format
                const typeMatch = report.type === type || report.type === 'multimid' && type === 'multimid_v2'

                if (formatMatch && typeMatch) {
                    let updateReport = {
                        format,
                        type,
                        loading: loadingStatus,
                        error: data.error ? data.error : false
                    }
                    if (items_fetched && items_total) {
                        updateReport = { ...updateReport, items_fetched, items_total }
                    }
                    return updateReport
                } else return report
            })
        }

        return {
            date: monthReports.date,
            active: monthReports.active,
            reports: newMonthReports
        }
    })
}

/**
 * FIXME This function returns a list that represents both the "report type" to be used for ordering payout reports AND
 * the resulting report task (we copy state from the input fetchedReports into the output of this function).
 *
 * It's very hard to follow along with this flow and it needs to be refactored.
 */
export const getReportsArr = (fetchedReports, settlement, merchant) => {
    let settlementReports
    if (merchant.customer_type === CUSTOMER_TYPE_DACH) {
        settlementReports = [{
            type: 'detailed',
            format: 'excel',
            loading: false,
            error: false,
            settlementId: settlement.id,
            reportTitle: REPORT_TITLE.detailPDF
        },
        {
            type: 'detailed',
            format: 'pdf',
            loading: false,
            error: false,
            settlementId: settlement.id,
            reportTitle: REPORT_TITLE.detailPDF
        }
        ]
    }
    else {
        settlementReports = [{
            type: 'multimid_v2',
            format: 'excel',
            loading: false,
            error: false,
            settlementId: settlement.id,
            reportTitle: REPORT_TITLE.detailPDF
        },
        {
            type: 'multimid',
            format: 'pdf',
            loading: false,
            error: false,
            settlementId: settlement.id,
            reportTitle: REPORT_TITLE.summaryPDF
        }
        ]
    }

    return settlementReports.map(report => {
        const matchingFetchedReport = fetchedReports.find(item => item.settlementId === settlement.id && item.format === report.format && item.type === report.type)

        if (matchingFetchedReport) {
            report.loading = matchingFetchedReport.loading || false
            report.error = matchingFetchedReport.error || false
            report.items_fetched = matchingFetchedReport.items_fetched
            report.items_total = matchingFetchedReport.items_total
        }

        return {
            report,
            ref: React.createRef()
        }
    })
}
