import React from 'react'
import { ResetPasswordComponent } from '@frontrunners/wl-cognito-login'
import { getPoolData } from './helpers'


export const CognitoResetPasswordContainer = ({ store }) => {
    const poolData = getPoolData(store)
    const localeCode = store.getState().getIn(['localization', 'localeCode'])

    return <ResetPasswordComponent poolData={poolData} locale={localeCode}/>
}
