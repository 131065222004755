import { useMutation, useQuery } from 'react-query'
import { callApi } from '../../common/reactQuery'
import { MERCHANT_ONLINE_ROLES_MAPPING } from '../../merchant/constants'

export const getEpayCheckoutsQuery = (apiHost, activeMerchant, options) => useQuery(
    'epay-checkouts',
    async () => {
        const checkoutCalls = activeMerchant.systemSet
            .filter((system) => system.system === 'zero')
            .map((system) => callApi({
                url: `${apiHost}/v1/merchants/${activeMerchant.id}/adapters/epay/checkout`,
                additionalHeaders: {
                    'Epaymerchantid': system.system_id
                }
            })
                .then((result) => {
                    return {
                        ...result,
                        systemId: system.system_id
                    }
                })
            )

        /**
         * On the EPay side, checkouts (payment windows) aren't connected directly to EPay IDs, but rather to a company.
         * One company can have many EPay IDs, but it only has one set of payment windows. An EPay company roughly
         * corresponds to a merchant on our side, so in practice we should pretty much always get the same set of payment
         * windows back regardless of which EPay ID we pass in for a given merchant.
         *
         * An exception to this is that EPay IDs are sometimes deactivated, resulting in a 401 response from EPay when
         * querying with that EPay ID. To handle that scenario, we simply try all EPay IDs and return the first result we get.
         */
        return Promise.allSettled(checkoutCalls)
            .then((results) => {
                for (const checkoutResponse of results) { // eslint-disable-line no-unused-vars
                    if (checkoutResponse.status === 'fulfilled') {
                        const firstResult = checkoutResponse.value?.paymentwindows?.map(checkout => parseEpayCheckoutResponse({ checkout, systemId: checkoutResponse.value.systemId }))
                            .sort((a, b) => a.text.localeCompare(b.text))
                            || []

                        return Promise.resolve(firstResult)
                    }
                }
                return Promise.reject('No successful results')
            })
    },
    options
)

export const getUpdateCheckoutMutation = (queryClient, apiHost, merchantId, activeCheckout) => useMutation({
    mutationFn: async ({
        checkoutName = null,
        transactionFeeOption = null
    }) => {

        const body = {
            paymentwindow: {}
        }

        if (checkoutName !== null) {
            body.paymentwindow.companyname = checkoutName
        }

        if (transactionFeeOption !== null) {
            body.paymentwindow.addfee = transactionFeeOption
        }

        return callApi({
            method: 'PUT',
            url: `${apiHost}/v1/merchants/${merchantId}/adapters/epay/checkout/${activeCheckout.checkout.id}`,
            body,
            additionalHeaders: {
                'Epaymerchantid': activeCheckout.systemId
            }
        }).then((response) => parseEpayCheckoutResponse({ checkout: response.paymentwindow, systemId: activeCheckout.systemId }))
    }
    ,
    onSuccess: (checkout) => {
        queryClient.setQueryData('epay-checkouts', (oldData) => {
            if (!oldData) {
                return [checkout]
            }

            return oldData.map(c => {
                if (c.id === checkout.id) {
                    return checkout
                }
                return c
            })
        })
    }
})

export const parseEpayCheckoutResponse = ({ checkout, systemId }) => {
    return {
        id: `${systemId}/${checkout.id}`,
        text: `${checkout.companyname}`,
        checkout,
        systemId
    }
}

export const getUploadCheckoutLogoMutation = (queryClient, apiHost, activeMerchant, activeCheckout) => useMutation({
    mutationFn: async (image) => {
        const index = image.src.indexOf('base64') + 7
        return callApi({
            method: 'PUT',
            url: `${apiHost}/v1/merchants/${activeMerchant.id}/adapters/epay/checkout/${activeCheckout.checkout.id}/logo`,
            body: {
                base64image: image.src.substring(index)
            },
            additionalHeaders: {
                'Epaymerchantid': activeCheckout.systemId
            }
        }).then((response) => parseEpayCheckoutResponse({ checkout: response.paymentwindow, systemId: activeCheckout.systemId }))

    },
    onSuccess: (checkout) => {
        queryClient.setQueryData('epay-checkouts', (oldData) => {
            if (!oldData) {
                return [checkout]
            }

            return oldData.map(c => {
                if (c.id === checkout.id) {
                    return checkout
                }
                return c
            })
        })
    }
})

export const getRemoveCheckoutLogoMutation = (queryClient, apiHost, activeMerchant, activeCheckout) => useMutation({
    mutationFn: async () => {
        return callApi({
            method: 'DELETE',
            url: `${apiHost}/v1/merchants/${activeMerchant.id}/adapters/epay/checkout/${activeCheckout.checkout.id}/logo`,
            additionalHeaders: {
                'Epaymerchantid': activeCheckout.systemId
            }
        }).then((response) => parseEpayCheckoutResponse({ checkout: response.paymentwindow, systemId: activeCheckout.systemId }))
    },

    onSuccess: (checkout) => {
        queryClient.setQueryData('epay-checkouts', (oldData) => {
            if (!oldData) {
                return [checkout]
            }

            return oldData.map(c => {
                if (c.id === checkout.id) {
                    return checkout
                }
                return c
            })
        })
    }
})

export const getEpayRedirect = (apiHost, activeMerchant, loggedInUser, user, options) => useQuery({
    queryKey: 'epay-redirect-link',
    queryFn: () => {
        const params = new URLSearchParams({role: MERCHANT_ONLINE_ROLES_MAPPING[loggedInUser.role], email: loggedInUser.email, user_id: loggedInUser.email, name: `${user.givenName} ${user.familyName}`})
        const url = `${apiHost}/v1/merchants/${activeMerchant.id}/epay/redirect?${params}`
        return callApi({ method: 'GET', url, params})
    },
    ...options
})

export const getActiveZeroMerchants = (apiHost, merchantId) => useQuery({
    queryKey: 'epay-api-keys-inactive',
    queryFn: () => {
        const url = `${apiHost}/v1/merchants/${merchantId}/adapters/epay/active`
        return callApi({ method: 'GET', url })
    },
    enabled: !!merchantId,
    refetchOnMount: false,
    retry: 2
})

export const getEpayApiKeysQuery = (apiHost, activeMerchant, activeSystemId, options) => useQuery(
    `epay-api-keys-${activeSystemId}`,
    () => callApi({
        url: `${apiHost}/v1/merchants/${activeMerchant.id}/adapters/epay/apikeys`,
        additionalHeaders: {
            'Epaymerchantid': activeSystemId
        }
    })
        .then((result) => result.apiusers),
    options
)

export const getCreateEpayApiKeyMutation = (queryClient, apiHost, activeMerchant, activeSystemId, loggedInUser) => useMutation({
    mutationFn: async (apiKeyName) => callApi({
        method: 'POST',
        url: `${apiHost}/v1/merchants/${activeMerchant.id}/adapters/epay/apikeys`,
        body: {
            apiuser: {
                description: apiKeyName || 'Checkout API key',
                email: loggedInUser.email
            }
        },
        additionalHeaders: {
            'Epaymerchantid': activeSystemId
        }
    }),
    onSuccess: (apiKeyResponse) => {
        const newApiKey = apiKeyResponse.apiuser
        queryClient.setQueryData(`epay-api-keys-${activeSystemId}`, (oldData) => {
            if (!oldData) {
                return [newApiKey]
            }

            return [...oldData, newApiKey]
        })
        return newApiKey
    }
})

export const getDeleteEpayApiKeyMutation = (queryClient, apiHost, activeMerchant, activeSystemId) => useMutation({
    mutationFn: async (apiKey) => callApi({
        method: 'DELETE',
        url: `${apiHost}/v1/merchants/${activeMerchant.id}/adapters/epay/apikeys/${apiKey.accesstoken}`,
        additionalHeaders: {
            'Epaymerchantid': activeSystemId
        }
    }),
    onSuccess: (_, variables) => {
        queryClient.setQueryData(`epay-api-keys-${activeSystemId}`, (oldData) => {
            return oldData.filter(key => key.accesstoken !== variables.accesstoken)
        })
    }
})
