import {sumAmounts} from '../../common/amountHelpers'
import {getTextForAdjustmentFee} from './adjustmentFeeText'
import { SETTLEMENT_DETAIL_PANEL_TEXT, SETTLEMENT_TABLE_HEADER, ADJUSTMENT_TEXT, SALES_ADJUSTMENT_TYPE } from '../constants'


export function getData(chargebackByElixir, adjustmentSetByElixir, numberOfElixirMids){
    let data = []
    const chargeback =(chargebackByElixir && chargebackByElixir.length > 0) ? sumAmounts(chargebackByElixir) : null
    const sales = adjustmentSetByElixir.find( adjustment => (adjustment.reason_code === SALES_ADJUSTMENT_TYPE) )

    if(sales){
        data.push({
            title: SETTLEMENT_TABLE_HEADER.sales,
            amountItem: sales
        })
    }

    const adjustments = adjustmentSetByElixir
    .filter(adjustment => (adjustment.reason_code!==SALES_ADJUSTMENT_TYPE))
    .map(adjustment => ({
        title: getTextForAdjustmentFee(adjustment),
        amountItem: adjustment
    }))

    data = [...data, ...adjustments]

    if(chargeback){
        data.push({
            title: ADJUSTMENT_TEXT['chargebacks'],
            amountItem: chargeback
        })
    }

    data.push({
            title: numberOfElixirMids > 1 ? SETTLEMENT_DETAIL_PANEL_TEXT['settlement-detail-payout-subtotal'] : SETTLEMENT_TABLE_HEADER['payout'],
            amountItem: getTotal(chargeback, adjustmentSetByElixir)
    })

    return data

}

const getTotal = (chargeback, adjustmentSetByElixir)=>{
    let total = []

    if(adjustmentSetByElixir && adjustmentSetByElixir.length>0) total.push(sumAmounts(adjustmentSetByElixir))

    if(chargeback) total.push(chargeback)

    return sumAmounts(total)
}
