import React, { useState } from 'react'
import * as helpers from './helpers'
import { Accordion } from '@frontrunners/ui-components'

const MonthList = ({fromDate, toDate, locale, createMonthItem }) => {
    const months = helpers.getMonthRange(fromDate, toDate)
    const monthsGroupedByYear = helpers.groupDatesByYear(months)
    const yearsInReverseOrder = Object.keys(monthsGroupedByYear).sort().reverse()
    const [selectedYear, setSelectedYear] = useState(yearsInReverseOrder[0])

    return (
        <div className="monthly-reports-list">
            { yearsInReverseOrder.map((year) => (
                <div key={year} className="report-group" data-testid="report-group">
                    <Accordion
                        header={year}
                        isOpen={year === selectedYear}
                        onToggleClick={() => {
                            setSelectedYear(selectedYear === year ? null : year)
                        }}
                        content={
                            monthsGroupedByYear[year].map((month) => (
                                <MonthRow key={month.getTime()} month={month} locale={locale} item={createMonthItem(month)}/>
                            ))
                        }
                    />
                </div>
            ))}
        </div>
    )
}

const MonthRow = ({ month, locale, item }) => {
    return (
        <div className="report-item" data-testid="report-item">
            <div className="month-title" data-testid="month-title">
                {month.toLocaleString(locale, {month: 'long'})}
            </div>
            { item }
        </div>
    )
}

export default MonthList
