import React, { Component } from 'react'
import uuid from 'uuid'
import { FILTER_BUTTON_TEXT } from '../../sales/sales/constants'
import { injectIntl } from 'react-intl'
import truncateText from '../../common/helpers/truncate-text'


class SelectDropDown extends Component {
    /** props:
     *   - options:options for the dropdown. array of object. e.g. [{text:'sek'}] or [{text:'sek', id:'001',img:'abc.svg'}]
     *          - id
     *          - text (compulsary)
     *          - img
     *          - imgClass
     *          - subtext
     *          - buttonText
     *   - isDisabled
     *   - selectedOptions: the options has been selected. array of object. e.g. [{text:'sek'}] or [{text:'sek', id:'001'}]
     *   - idKey: which attribute use as identifier. e.g. 'text' or 'id'
     *   - mode: 'single' or 'multi'. 'multi' as default
     *   - align: 'left'(default) or 'right'
     */
    constructor(props) {
        super(props)
        this.state = {
            options: [],
            isDisabled: false,
            selectedOptions: [],
            idKey: '',
            mode: 'multi',
            dropDownOpen: false
        }

        this.wrapperRef = React.createRef()
    }
    static getDerivedStateFromProps(props, state) {

        const { isDisabled = false, mode = 'multi', align } = props
        let { idKey = 'text', options = [], selectedOptions = [] } = props

        if (state.optionsTemp) {
            options = state.optionsTemp
        }
        else {
            options = Array.isArray(options) ? options : []
        }
        selectedOptions = Array.isArray(selectedOptions) ? selectedOptions : []

        idKey = (options && options.length > 0 && options[0].id !== undefined) ? 'id' : idKey

        return {
            options,
            isDisabled,
            selectedOptions,
            idKey,
            mode,
            align,
            dropDownOpen: state.dropDownOpen
        }
    }
    render() {
        const { options, isDisabled, selectedOptions, dropDownOpen, idKey, mode, align } = this.state

        if (!options || options.length === 0) return null

        const { formatMessage } = this.props.intl
        const buttonText = (mode === 'single' && selectedOptions && selectedOptions.length > 0) ? selectedOptions[0].text : this.props.buttonText
        const dropdownClassName = `dropdownmenu ${dropDownOpen ? 'dropdownmenu--open' : ''} ${align === 'right' ? 'dropdownmenu--right' : ''}`

        return <div className={`select-dropdown ${this.props.className}`}>
            <div className="select-dropdown__wrapper" ref={this.wrapperRef}>
                <button
                    className={`toggle-button btn-small ${selectedOptions && selectedOptions.length > 0 ? 'btn-selected' : ''}`}
                    disabled={isDisabled}
                    onClick={this.toggleDropdownOpen.bind(this)}>
                    {buttonText}
                    { !isDisabled && <div className="arrow-down-selected"></div> }
                </button>
                <div className={dropdownClassName}>
                    <ul className="mids">
                        {options.map(option =>
                            <li
                                key={uuid.v4()}
                                onClick={this.toggleSelect.bind(this, option)}
                            >
                                {mode === 'multi' &&
                                    <input
                                        name={`checkout${uuid.v4()}`}
                                        type="checkbox"
                                        checked={this.isSelected(option, selectedOptions, idKey)}
                                        readOnly={true} />
                                }
                                {mode === 'multi' && <div className="checkmark"></div>}
                                <div className="text-content">
                                    <div title={option.text} className="info">{truncateText(option.text, 30)}</div>
                                    {option.subtext && <div title={option.subtext} className="subtext">{option.subtext}</div>}
                                </div>

                                {option.img &&
                                    <img className="currency-flag" src={option.img} />
                                }
                                {option.imgClass &&
                                    <div className={`img-div ${option.imgClass}`}></div>
                                }
                            </li>
                        )}
                    </ul>
                    {mode === 'multi' &&
                        <div
                            className={`select-dropdown__clear ${selectedOptions && selectedOptions.length > 0 ? 'show' : ''}`}
                            onClick={() => this.clear()}>
                            <a>{formatMessage(FILTER_BUTTON_TEXT['clear'])}</a>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside.bind(this))
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside.bind(this))
    }


    // end of React life cycle
    handleClickOutside(event) {
        if (this.state.dropDownOpen && this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.toggleDropdownOpen()
        }
    }
    toggleDropdownOpen() {
        //group the selected options and reserve the order when the dropdown open
        let { options, selectedOptions, idKey, dropDownOpen } = this.state
        let optionsTemp
        if (!dropDownOpen) {
            const unselectedOptions = options.filter(option => !this.isSelected(option, selectedOptions, idKey))
            optionsTemp =  [...selectedOptions, ...unselectedOptions]
        }
        else {
            optionsTemp = null
        }

        this.setState({ optionsTemp })
        this.setState({ 'dropDownOpen': !this.state.dropDownOpen })
    }

    isSelected(option, selectedOptions, idKey) {
        const isSelected = selectedOptions && selectedOptions.some(
            item => item.id ? item.id === option.id : item[idKey] === option[idKey]
        )
        return isSelected
    }

    toggleSelect(option) {
        let newSelectOption = []
        const { selectedOptions, idKey } = this.state

        if (this.state.mode === 'single') {
            if (!this.isSelected(option, selectedOptions, idKey)) newSelectOption = [option]
            else newSelectOption = selectedOptions
        }
        else {
            if (this.isSelected(option, selectedOptions, idKey)) newSelectOption = selectedOptions.filter(item => item[idKey] != option[idKey])
            else newSelectOption = [...selectedOptions, option]
        }

        this.setState({ selectedOptions: newSelectOption })
        this.props.onChange(newSelectOption)
        if (this.state.mode === 'single') this.toggleDropdownOpen()

    }
    clear() {
        this.setState({ selectedOptions: [] })
        this.props.onChange([])
        this.toggleDropdownOpen()
    }
}

export default injectIntl(SelectDropDown)
