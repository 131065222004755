import React from 'react'
import moment from 'moment'

import { useTable, usePagination} from 'react-table'
import { TRANSACTION_TABLE_HEADER, TRANSACTION_TYPE } from '../constants'
import { getIconKey } from '../../sales/helpers'
import { getAmexTranslation } from '../../common/cardHelpers'

import MonetaryAmount from '../../common/components/monetaryAmount'

import './transactiontable.scss'


const TABLE_ROWS_PER_PAGE = 20


const TransactionTable = ({transactions, merchant, formatMessage, navigateToPayment, loadMore, tableSize, increaseTableSize, nextPageAvailable}) => {

    const paginate = () => {
        increaseTableSize(tableSize + TABLE_ROWS_PER_PAGE)
        loadMore()
    }

    const getCustomerReference = (transaction, merchant) => {
        if (merchant.customer_type == 'dach' && transaction.reference_additional_1) {
            return transaction.reference_additional_1
        } else {
            return transaction.reference
        }
    }

    const getType = (type) => {
        return formatMessage(TRANSACTION_TYPE[type.toLowerCase().replace(' ', '-')])
    }

    const columns = React.useMemo(() => [
        {
            Header: formatMessage(TRANSACTION_TABLE_HEADER.date),
            id: 'date',
            className: 'date rt-td',
            headerClassName: 'th-head date',
            accessor: d => moment(d.date_time_local).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            Header: formatMessage(TRANSACTION_TABLE_HEADER.ref),
            id: 'ref',
            className: 'table-cell-ellipsis ref rt-td',
            headerClassName: 'th-head ref',
            accessor: row => getCustomerReference(row, merchant)
        },
        {
            Header: formatMessage(TRANSACTION_TABLE_HEADER['payment-method']),
            Cell: ({ row }) => (
                <div className="card-item">
                    <div className={`new-card-icons ${getIconKey(row.original.card_scheme.toLowerCase())}`}></div>
                    <div>{row.original.card_number.substr(row.original.card_number.length - 4)}</div>
                </div>

            ),
            className: 'payment-method rt-td',
            headerClassName: 'th-head payment-method'
        },
        {
            Header: formatMessage(TRANSACTION_TABLE_HEADER.type),
            id: 'type',
            className: 'type rt-td',
            headerClassName: 'th-head type',
            accessor: row => getType(row.state)
        },
        {
            Header: formatMessage(TRANSACTION_TABLE_HEADER.status),
            Cell: ({row}) => formatMessage(getAmexTranslation(row.original)),
            className: 'status rt-td',
            headerClassName: 'th-head status'
        },
        {
            Header: formatMessage(TRANSACTION_TABLE_HEADER.currency),
            accessor: 'currency',
            className: 'currency rt-td',
            headerClassName: 'th-head currency'
        },
        {
            Header: formatMessage(TRANSACTION_TABLE_HEADER.amount),
            id: 'amount',
            headerClassName: 'th-head amount',
            className: 'amount rt-td',
            accessor: row => row,
            Cell: ({row}) => {
                return (
                    <MonetaryAmount amount_exponent={row.original.amount_exponent} amount={row.original.amount} className="fg-darkest" excludeCurrency={true} />
                )
            }
        }
    ])

    return (
        <Table data={transactions} columns={columns} navigateToPayment={navigateToPayment} paginate={paginate} tableSize={tableSize} nextPageAvailable={nextPageAvailable}/>
    )
}


const Table = ({data, columns, navigateToPayment, paginate, tableSize, nextPageAvailable}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
        } = useTable({
            useControlledState: state => {
                return React.useMemo(
                    () => ({
                        ...state,
                        pageSize: tableSize
                    })
                )
            },
        columns,
        data
    }, usePagination)

    return (
        <React.Fragment>
            <div className="ReactTable-v7 data-list transaction-list" {...getTableProps()}>
                <div className="rt-thead thead">
                    {headerGroups.map(headerGroup => (
                        <div className="tr-head rt-tr" style={{justifyContent: 'space-between'}} {...headerGroup.getHeaderGroupProps()}>
                            { headerGroup.headers.map(column => (
                                <div style={{flex: '100 0 auto', width: '100px'}} {...column.getHeaderProps({className: column.headerClassName})}>
                                    {column.render('Header')}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="rt-tbody tbody" {...getTableBodyProps()}>
                    { page.map((row, i ) =>
                        prepareRow(row) || (
                            <div className="tr-body rt-tr" style={{justifyContent: 'space-between'}} key={i} {...row.getRowProps()} onClick={() => navigateToPayment(row.original)}>
                                {row.cells.map(cell => {
                                    return (
                                        <div className="td-body rt-td" style={{flex: '100 0 auto', width: '100px'}} {...cell.getCellProps({className: cell.column.className})}>
                                            {cell.render('Cell')}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    )}
                </div>
            </div>
            { nextPageAvailable && <div className="pagination-btn">
                <button className="rebranded-primary-btn" onClick={() => paginate()}>Load More</button>
            </div> }
        </React.Fragment>
    )
}

export default TransactionTable
