
export const SUPPORT_NUMBER_BY_COUNTRY = {
    'sweden': '+46 (0) 10 10 66 000',
    'norway': '+47 21 93 95 90',
    'denmark': '+45 78 79 47 00',
    'finland': '+358 09 315 825 55',
    'switzerland': '+41 0848 912 800'
}

export const SUPPORT_EMAIL_BY_COUNTRY = {
    'switzerland': 'support@innocard.ch'
}
