import { createContext } from 'react'
import { ALLOWED_DEVELOPER_USERS_FOR_HIDDEN_FEATURES } from '../../constants'
import { ENVIRONMENTS, MERCHANTS } from '../constants'
import { localStorageGetItem } from '../localstorageHelpers'

export const isProd = (environment) => {
    return environment === ENVIRONMENTS.PROD
}
export const isHualong = (merchantID) => {
    return merchantID === MERCHANTS.HUALONG
}

export const isDeveloper = (sub) => {
    return ALLOWED_DEVELOPER_USERS_FOR_HIDDEN_FEATURES.includes(sub)
}

export const defaultDeveloperModeState = {
    developerMode: false,
    featureToggle: {
        devModeIndicator: true,
        leftNav: false,
        frodaLoanFrame: false,
        mixpanel: false
    }
}

export const featureToggleDisplayNames = {
    frodaLoanFrame: 'Froda iFrame',
    leftNav: 'Left Navbar',
    devModeIndicator: 'Indicator'
}

export const getInitialDeveloperModeState = () => {
    try {
        const localStorage = JSON.parse(localStorageGetItem('developerMode'))

        // Filter out obsolete feature toggle options that are saved in local storage but has been removed in the code.
        // This prevents obsolete options from showing up in the feature toggle
        const localFeatureToggle = Object.fromEntries(
            Object.entries(localStorage.featureToggle).filter(
                feature => feature[0] in defaultDeveloperModeState.featureToggle
            )
        )

        return {
            developerMode:
                localStorage.developerMode !== undefined ?
                    localStorage.developerMode :
                    defaultDeveloperModeState.developerMode,
            featureToggle: {
                ...defaultDeveloperModeState.featureToggle,
                ...localFeatureToggle
            }
        }
    } catch (_) {
        return defaultDeveloperModeState
    }
}

export const DeveloperModeContext = createContext({})
