import React from 'react'

import { Icon } from '@frontrunners/ui-components'

import { Accounts } from './accounts2/accounts2'
import { General } from './general/general'
import { Support } from './support2/support'
import { UserProfile } from './user_profile/userProfile'
import { Online } from './online/online'
import { Terminals } from './terminals2/terminals2'
import { SETTINGS_PAGES as T } from './intlMessageKeys'


export const settingsPages = ({ activeZeroIdSet, activeMerchant, formatMessage: fm }) => {
    const views = [
        {
            name: fm(T['general']),
            icon: <Icon iconName="settings" />,
            component: <General />,
            path: '/settings/general',
            active: true
        },
        {
            name: fm(T['accounts']),
            icon: <Icon iconName="account" />,
            component: <Accounts />,
            path: '/settings/accounts',
            active: true
        },
        {
            name: fm(T['my-profile']),
            icon: <Icon iconName="userCogWheel" scale="1.2" />,
            component: <UserProfile />,
            path: '/settings/user',
            active: true
        },
        {
            name: fm(T['online']),
            icon: <Icon iconName="laptopMac" />,
            component: <Online />,
            path: '/settings/online',
            active: !!activeZeroIdSet?.data && activeZeroIdSet?.data.length > 0
        },
        {
            name: fm(T['instore']),
            icon: <Icon iconName="house" scale="0.8" />,
            component: <Terminals />,
            path: '/settings/instore',
            active: activeMerchant.hasInstore
        },
        {
            name: fm(T['support']),
            icon: <Icon iconName="lifebuoy" scale="1.2" />,
            component: <Support />,
            path: '/settings/support',
            active: true
        }
    ]

    return views
}
