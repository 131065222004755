import * as types from './actionTypes'
import { fetchJson } from '../common/requestActionHelper'
import { REPORTS_BASE, CUSTOMER_TYPE_DACH, REPORTS_BASE_V2 } from '../common/constants'
import convertFilterToQuery from './helpers/convertFilterToQuery'
import * as mixpanelTrackers from '../common/helpers/mixpanelTrackers'

import { fakeDownloadReportTotal, fakeDownloadReportIncreasement } from './constants'
import {downloadFileFromLink} from '../helpers/requestHelper'

export const requestSettlementReport = (merchantId, settlementId, format, type, locale) => {
    return (dispatch, getState) => { // eslint-disable-line
        dispatch(requestSettlementReportStart(settlementId, format, type))
        const url = `${REPORTS_BASE_V2}/settlements/specific/${format}?settlement_id=${settlementId}&locale=${locale}`
        const successFn = (data) => {
            dispatch(requestSettlementReportSuccess(data, settlementId, format, type))
            setTimeout(() => {
                const error = false
                dispatch(requestSettlementReportReset(settlementId, format, type, error))
            }, 1000)
        }
        const errorFn = () => {
            const error = true
            dispatch(requestSettlementReportError(settlementId, format, type, error))
            setTimeout(() => {
                dispatch(requestSettlementReportReset(settlementId, format, type, error))
            }, 500)
        }
        const progressFn = (progress) => {
            dispatch(requestSettlementReportProcess(progress, settlementId, format, type))
        }
        requestReport(merchantId, dispatch, getState, url, successFn, errorFn, progressFn)
    }
}

export const requestSalesReport = (merchantId, locale, downloadLink, filter, activeView) => {

    const queries = convertFilterToQuery(filter, activeView)
    return (dispatch, getState) => { // eslint-disable-line
        dispatch(requestSalesReportStart(activeView))

        const url = `${REPORTS_BASE_V2}/sales/${activeView}/excel${queries}&locale=${locale}`
        const successFn = (data) => {
            dispatch(requestSalesReportSuccess(data, downloadLink, activeView))
        }
        const errorFn = (error) => {
            dispatch(requestSalesReportError(error))
        }
        const progressFakeFn = () => {
            let items_fetched = getState().getIn([merchantId, 'reports', 'sales', 'items_fetched'])

            if (items_fetched >= fakeDownloadReportTotal) return

            items_fetched = items_fetched + fakeDownloadReportIncreasement

            dispatch(requestSalesProgress({ items_fetched }))
        }
        requestReport(merchantId, dispatch, getState, url, successFn, errorFn, null, progressFakeFn)
    }
}

const requestSettlementReportStart = (settlementId, format, type) => {
    return {
        type: types.GET_REPORT_TASK_START,
        payload: {
            settlementId,
            format,
            type
        },
        meta: {
            mixpanel: {
                event: 'Start settlement report download',
                props: {
                    settlementId,
                    format,
                    type
                }
            }
        }
    }
}
const requestSettlementReportProcess = (progress, settlementId, format, type) => {
    const { items_fetched, items_total } = progress
    return {
        type: types.GET_REPORT_TASK_START,
        payload: {
            settlementId,
            format,
            type,
            items_fetched,
            items_total
        }
    }
}

const requestSettlementReportError = (settlementId, format, type, error) => {
    return {
        type: types.GET_REPORT_TASK_ERROR,
        payload: {
            settlementId,
            format,
            type,
            error
        },
        meta: {
            mixpanel: { event: 'Downloaded settlement report error' }
        }
    }
}

const requestSettlementReportSuccess = (data, settlementId, format, type) => {
    downloadFileFromLink(data.link)
    return {
        type: types.GET_REPORT_TASK_DONE,
        payload: {
            settlementId,
            format,
            type
        },
        meta: {
            mixpanel: {
                event: 'Successfully downloaded settlement report',
                props: {
                    settlementId,
                    format,
                    type
                }
            }
        }
    }
}

const requestSalesReportStart = (activeView) => {
    return {
        type: types.GET_SALES_REPORT_TASK_START,
        meta: {
            mixpanel: {
                event: 'Start sales report download',
                props: { channel: activeView }
            }
        }
    }
}

const requestSalesReportError = (error) => {
    return {
        type: types.GET_SALES_REPORT_TASK_ERROR,
        payload: { error },
        meta: {
            mixpanel: { event: 'Downloaded sales report error' }
        }
    }
}

const requestSalesReportSuccess = (data, downloadLink, activeView) => {
    downloadLink.href = data.link
    downloadLink.click()
    downloadLink.href = '#'
    return {
        type: types.POLL_SALES_REPORT_TASK_SUCCESS,
        meta: {
            mixpanel: {
                event: 'Successfully downloaded sales report',
                props: {
                    channel: activeView
                }
            }
        }
    }
}

export const getMonthlyDates = (date) => {

    const tzoffsetFromDate = date.getTimezoneOffset() * 60000 // get offset in ms
    let from_date = (new Date(date - tzoffsetFromDate)).toISOString().substr(0, 10)
    let to_date = new Date(date.valueOf())
    to_date.setMonth(date.getMonth() + 1)
    const tzoffsetToDate = to_date.getTimezoneOffset() * 60000 // since this is a new month, timezone may have shifted
    to_date = (new Date(to_date - tzoffsetToDate)).toISOString().substr(0, 10)
    return [from_date, to_date]
}

const requestSalesProgress = ({ items_fetched }) => {
    return {
        type: types.GET_SALES_REPORT_TASK_PROCESS,
        payload: {
            items_fetched
        }
    }
}

export const requestSettlementReportReset = (settlementId, format, type, error) => {
    return {
        type: types.GET_SETTLEMENT_REPORT_TASK_RESET,
        payload: {
            settlementId,
            format,
            type,
            error
        }
    }
}

export const requestSalesReportReset = () => {
    return {
        type: types.GET_SALES_REPORT_TASK_RESET
    }
}

export const requestReport = (merchantId, dispatch, getState, url, successFn, errorFn, progressFn, progressFakeFn) => {
    const pollInterval = 1000

    return fetchJson(dispatch, getState, { url, merchantId })
        .then((response) => {
            mixpanelTrackers.trackOrderReport(response)
            pollReport(merchantId, dispatch, getState, response.id, pollInterval, successFn, errorFn, progressFn, progressFakeFn)
        })
        .catch((error) => {
            errorFn(error)
        })
}


const pollReport = (merchantId, dispatch, getState, taskId, interval, successFn, errorFn, progressFn, progressFakeFn) => {
    const baseUrl = `${REPORTS_BASE}/${taskId}`
    let pollCount = 0
    const pollCountLimit = 1020 // 17 minutes until we have asynch downloads in place
    const poll = () => {
        const reqObj = { url: `${baseUrl}`, merchantId }
        fetchJson(dispatch, getState, reqObj).then(response => {
            if (response.status == 'done') {
                return successFn(response)
            } else if (response.status === 'failed') {
                return errorFn()
            } else if (response.status === 'pending' && progressFn) {
                if (response.progress) {
                    const { items_total, items_fetched } = response.progress
                    if (items_total && items_fetched) {
                        progressFn({ items_fetched, items_total })
                    }
                }
            } else if (progressFakeFn && ['started', 'pending'].includes(response.status)) {
                progressFakeFn()
            }
            if (pollCount >= pollCountLimit) {
                window.clearTimeout()
                return errorFn()
            }
            window.setTimeout(poll, interval)
            pollCount++
        })
    }
    poll()
}

export const downloadableReports = (merchant) => {
    let reports = [{
            type: 'multimid_v2',
            format: 'excel',
            loading: false
        },
        {
            type: 'multimid',
            format: 'pdf',
            loading: false
        },
        {
            type: 'multimid_detailed',
            format: 'pdf',
            loading: false
        }
    ]

    if (merchant.customer_type === CUSTOMER_TYPE_DACH) {
        reports = [{
                type: 'detailed',
                format: 'excel',
                loading: false
            },
            {
                type: 'summary',
                format: 'pdf',
                loading: false
            },
            {
                type: 'detailed',
                format: 'pdf',
                loading: false
            }
        ]
    }
    // Hide pdf option for device only
    if (merchant.device || merchant.isDeviceMerchant) {
        reports = reports.filter(report => report.format !== 'pdf')
    }

    return reports
}
