import React from 'react'
import uuid from 'uuid'
import './togglebutton.scss'

const ToggleButton = ({ data, className }) => {
    className = className ? className : ''

    return (
        <div className={`toggle-button ${className}`}>
            {data.map(({ name, value, id, checked, onChange, text }) => (
                <div className="toggle-button__item" key={uuid.v4()}>
                    <input
                        type="radio"
                        name={name}
                        value={value}
                        id={id ? id : value}
                        checked={checked}
                        onChange={onChange}
                    />
                    <label htmlFor={id ? id : value}> {text}</label>
                </div>
            ))}
        </div>
    )
}

export default ToggleButton
