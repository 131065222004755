import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { hideCancelModal, cancelOrder } from '../../actions'
import Spinner from '../../../../common/components/spinner'
import NotFoundState from '../../../../common/components/notFoundState'
import { isCancelable} from '../../helpers'
import BamboraSvgIcon from '../../../../common/components/bamboraSvgIcon'

class OnlineCancelModal extends Component {
    closeModal() {
        this.props.dispatch(hideCancelModal())
    }

    cancelOrder() {
        const paymentId = this.props.cancel.get('orderId')
        const merchantId = this.props.merchantId
        this.props.dispatch(cancelOrder(merchantId, paymentId))
    }

    renderCancelableContent(){
        return <div>
            <h3><FormattedMessage
                id="order-cancel-modal-header"
                defaultMessage={'Cancel order'}
                description="Cancel this order"
            /></h3>
            <p><FormattedMessage
                id="order-cancel-modal-warning"
                defaultMessage={`The order will be canceled permanently.
                                         This action cannot be changed. Are you sure?`}
                description={'Warning text before removing a user'}
            /></p>

            <div className="btn-row">
                <button className="btn btn-cancel" onClick={() => this.closeModal()}>
                    <FormattedMessage
                        id="order-cancel-modal-abort"
                        defaultMessage={'No'}
                        description={'Answer to if you are sure you want to cancel - no'}
                    />
                </button>
                <button className="btn btn-delete bg-danger" style={{ backgroundColor: '#e05043'}} onClick={() => this.cancelOrder()}>
                    <FormattedMessage
                        id="order-cancel-modal-confirm"
                        defaultMessage={'Yes, cancel'}
                        description={'Answer to if you are sure you want to cancel - yes'}
                    />
                </button>
            </div>
        </div>
    }

    renderWrapper(content){
        const waiting = this.props.cancel.get('isFetching')
        return (
            <div className="cancel-payment-modal-wrapper">
                <div className="operations-modal" role="dialog">
                    <span className="close-operation" onClick={() => waiting || this.closeModal()}><BamboraSvgIcon icon="close" /></span>
                    {content}
                </div>
                <div className="backdrop" onClick={() => waiting || this.closeModal()}></div>
            </div>
        )
    }

    render() {
        const waiting = this.props.cancel.get('isFetching')
        if(waiting) {
            return this.renderWrapper(
                <div className="operations-state-change">
                    <Spinner waiting={true} />
                </div>
            )
        }

        const error = this.props.cancel.get('isError')
        const order = this.props.cancel.get('order')
        const canBeCanceled = order  && isCancelable(order.toJS())

        if (error || !canBeCanceled) {
            return this.renderWrapper(
                <NotFoundState>
                    <div className="text-center">
                        <FormattedMessage
                            id="order-cancel-modal-error"
                            defaultMessage={'Unable to cancel payment'}
                            description={'Cancel payment error message'}
                        />
                    </div>
                </NotFoundState>
            )
        }

        return this.renderWrapper(
            this.renderCancelableContent()
        )

    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        cancel: state.getIn([activeMerchant, 'online', 'cancel'])
    }
}

export default connect(mapStateToProps)(OnlineCancelModal)
