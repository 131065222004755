import React from 'react'
import './visitingAddressComponent.scss'

const getMerchantVisitAddress = ({ address }) => {
    if (address) {
        const { city, country, postal_code, street_address } = address
        return <div data-testid="visiting-address" className="visiting-address">
            {street_address && <span>{street_address}</span>}
            {street_address && (city || postal_code || country) && < span >, </span>}
            {city && <span className="visiting-address__capitalized">{city}</span>}
            {city && postal_code && <span> </span>}
            {postal_code && <span>{postal_code}</span>}
            {(city || postal_code) && country && <span>, </span>}
            {country && <span className="visiting-address__capitalized">{country}</span>}
        </div >
    }
    return <div></div>
}

export default getMerchantVisitAddress
