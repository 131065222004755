import React from 'react'
import MonetaryAmount from '../../../common/components/monetaryAmount'
import { getIconKey } from '../../sales/helpers'
import { FILTER_STATES } from '../../sales/constants'
import { switchFormattedMessages } from '../../acquiring/components/acquiringListItem'
import moment from 'moment'


const InstoreListItem = ({ instorePayment, onClick, formatMessage }) => {
    let instorePaymentDatetime

    if (instorePayment && instorePayment.created_local) {
        instorePaymentDatetime = moment.parseZone(instorePayment.created_local).format('YYYY-MM-DD HH:mm:ss')
    }

    const cardNumber = instorePayment.card_number
    const strippedCardNumber = cardNumber ? cardNumber.substr(cardNumber.length - 4) : ''
    const icon = instorePayment.wallet || instorePayment.payment_type

    return (
        <div className="instore-list-item" onClick={onClick}>
            <span className="element-column date-time instore">
                {instorePaymentDatetime}
            </span>
            <span className="element-column merchant-reference">
                {instorePayment.payment_id}
            </span>
            <span className="element-column payment-type">
                <div className={`new-card-icons ${getIconKey(icon.toLowerCase())}`}></div>
                {strippedCardNumber}
            </span>
            <span className="element-column type">
                {switchFormattedMessages(instorePayment.payment_category, formatMessage)}
            </span>
            <span className="element-column state">
                {formatMessage(FILTER_STATES[instorePayment.state.toLowerCase()])}
            </span>
            <span className="element-column currency">
                {instorePayment.currency}
            </span>
            <span className="element-column amount">
                <MonetaryAmount amount={instorePayment.authorized_amount} amount_exponent={instorePayment.currency_exponent} className="fg-darkest" hideInsignificantDecimals={false} />
            </span>
        </div>
    )
}


export default InstoreListItem
