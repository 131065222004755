import '@babel/polyfill'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import storeCreator from './store'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'ui.bambora/dist/ui.bambora.1.2.0.css'
import './main.scss'
import config from './config'

import * as userActions from './user/actions'
import * as merchantActions from './merchant/actions'
import { registerMixpanelProfile } from './integrations/mixpanel/mixpanel'
import { handleRuntimeErrors } from './handleRuntimeErrors'
import * as localstorageHelpers from './common/localstorageHelpers'
import * as loginActions from './common/loginActions'
import Main from './main'
import * as locationHelpers from './common/locationHelpers'
import CookieBanner from './common/components/cookieBanner'
import EmptyNavBar from './common/components/emptyNavBar'
import { IntlProvider } from 'react-intl'
import { getInitialLoalizationState } from './localization/helpers'
import { CognitoLoginContainer } from './login/cognitoLoginContainer'
import { CognitoResetPasswordContainer } from './login/cognitoResetPasswordContainer'
import { CognitoSetPasswordContainer } from './login/cognitoSetPasswordContainer'
import { QueryClient, QueryClientProvider } from 'react-query'


var store = null // we initialize the store after we get the config

const ENABLE_COOKIE_BANNER = false
const VERSION = process.env.VERSION
const COGNITO_PATHS = [
    { pathname: '/login', component: CognitoLoginContainer },
    { pathname: '/reset-password', component: CognitoResetPasswordContainer },
    { pathname: '/set-password', component: CognitoSetPasswordContainer }
]

const startApp = () => {
    const deepLinkPath = locationHelpers.getDeepLink(window.location)

    //to save impersonate user id also in localstorage, because when refresh, user will lose deepLinkPath
    localstorageHelpers.setImpersonateUserId(deepLinkPath)

    if (loginActions.isLoginRedirect()) {
        processLoginRedirect()
    }
    // remove invalid selected GMID
    localstorageHelpers.removeInvalidGlobalMerchant()

    getEnvConfig().then(configResponse => {
        // init mixpanel
        if (configResponse.MIXPANEL_TOKEN) {
            console.log('initializing mixpanel')
            mixpanel.init(configResponse.MIXPANEL_TOKEN, { disable_cookie: true })
        }

        // init store with mixpanel and thunk middleware
        store = storeCreator(configResponse, mixpanel)

        store.dispatch(config.actions.set({
            environment: configResponse.ENVIRONMENT,
            scopes: configResponse.SCOPES,
            backendHost: configResponse.BACKEND_HOST,
            loginHost: configResponse.LOGIN_HOST,
            apiHost: configResponse.API_HOST,
            frodaHost: configResponse.FRODA_HOST,
            frodaApiHost: configResponse.FRODA_API_HOST,
            gitHead: configResponse.GIT_HEAD,
            packageVersion: configResponse.PACKAGE_VERSION,
            mixpanelToken: configResponse.MIXPANEL_TOKEN,
            clientId: configResponse.CLIENT_ID,
            cognitoClientId: configResponse.COGNITO_CLIENT_ID,
            cognitoPoolId: configResponse.COGNITO_POOL_ID,
            datadogClientId: configResponse.DATADOG_CLIENT_ID,
            datadogAppId: configResponse.DATADOG_APP_ID
        }))

        const injectedAccessToken = configResponse.ACCESS_TOKEN
        const injectedIdToken = configResponse.ID_TOKEN

        store.dispatch(config.actions.setDeepLinkPath({ deepLinkPath }))

        if (injectedAccessToken && injectedIdToken) {
            injectIdToken(injectedAccessToken, injectedIdToken)
            setInitialState(store)
        } else if (!loginActions.sessionExists() && !loginActions.logOutView()) {
            console.info('No token in localstorage')
            const cognitoPath = COGNITO_PATHS.find(c => c.pathname === window.location.pathname)

            if (ENABLE_COOKIE_BANNER) {
                return renderCookieConsentBanner()
            } else if (cognitoPath) {
                renderCognitoComponent(cognitoPath.component)
            } else {
                window.location.replace('/login')
            }
        } else {
            setInitialState(store)
        }

        store.subscribe(() => {
            const userPreferenceState = store.getState().get('user').get('preference')
            const userPreferenceStorage = localstorageHelpers.getUserPreference()
            if (JSON.stringify(userPreferenceState) !== JSON.stringify(userPreferenceStorage)) {
                localstorageHelpers.setUserPreference(userPreferenceState)
            }
        })

    })

}

const renderCognitoComponent = (Component) => {
    const root = document.createElement('div')
    root.className = 'login-wrapper'
    document.body.appendChild(root)
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
            }
        }
    })

    return render(
        <QueryClientProvider client={queryClient}>
            <Component store={store} />
        </QueryClientProvider>, root
    )
}

function renderCookieConsentBanner() {
    const root1 = document.createElement('div')
    root1.className = 'root'
    document.body.appendChild(root1)
    const localization = getInitialLoalizationState()
    const languageMessages = localization.get('languageMessages')
    const localeCode = localization.get('localeCode')
    return render(
        <Provider store={store}>
            <IntlProvider locale={localeCode} key={localeCode} messages={languageMessages}>
                <EmptyNavBar />
                <CookieBanner config={config} />
            </IntlProvider>
        </Provider>, root1
    )
}

function processLoginRedirect() {
    loginActions.setAccessToken()
    localstorageHelpers.setUserProfile()
    window.location.hash = ''
}

function injectIdToken(injectedAccessToken, injectedIdToken) {
    const injectedquery = {
        access_token: injectedAccessToken,
        id_token: injectedIdToken
    }
    loginActions.injectAccessToken(injectedAccessToken)
    localstorageHelpers.setUserProfile(injectedquery)
}

async function getEnvConfig() {
    const primaryConfig = `/config_env_${VERSION}.json`
    const fallbackConfig = '/config_env.json'

    try {
        const response = await fetch(primaryConfig)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Not found')
                }
                return response.json()
            })
        return response
    } catch (error) {
        return fetch(fallbackConfig)
            .then(response => response.json())
    }
}

const setInitialState = (store) => {
    const userProfile = JSON.parse(localstorageHelpers.localStorageGetItem('userProfile'))
    const userPreference = localstorageHelpers.getUserPreference()

    registerMixpanelProfile(store, mixpanel, userProfile)
    handleRuntimeErrors()


    if ('custom:bsub' in userProfile) {
        userProfile.sub = userProfile['custom:bsub']
    }

    store.dispatch(userActions.initialUserPreference(userPreference))
    store.dispatch(userActions.setUserProfile(userProfile))
    store.dispatch(merchantActions.getMerchantsForUser(userProfile.sub, store)).then(() => {
        /* Only run init if the getMerchantForUser call has successfully finished. */
        init(store)
    })
}


function init(store) {
    // Bootstrap our root element for the app mountpoint
    const root = document.createElement('div')
    root.className = 'root'
    document.body.appendChild(root)

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnMount: true
            }
        }
    })

    return render(
        <QueryClientProvider client={queryClient} contextSharing={true}>
            <Provider store={store}>
                <Main />
            </Provider>
        </QueryClientProvider>,
        root
    )
}

function render(component, container) {
    const root = createRoot(container)
    root.render(component)
}

startApp()
