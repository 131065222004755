
import * as salesActions from '../sales/actionTypes'

let actions = {}

for(let actionName of Object.keys(salesActions)){ // eslint-disable-line
  actions[actionName] = `online/${actionName}`
}

actions['GET_DETAILS_SUCCESS'] = 'online/GET_DETAILS_SUCCESS'
actions['GET_DETAILS_ERROR'] = 'online/GET_DETAILS_ERROR'
actions['GET_DETAILS_START'] = 'online/GET_DETAILS_START'

actions['LIST_UPDATE_ITEM'] = 'online/LIST_UPDATE_ITEM'

actions['SHOW_OPERATIONS_MODAL'] = 'online/SHOW_OPERATIONS_MODAL'
actions['SET_OPERATIONS_MODAL_STATE'] = 'online/SET_OPERATIONS_MODAL_STATE'
actions['HIDE_OPERATIONS_MODAL'] = 'online/HIDE_OPERATIONS_MODAL'
actions['OPERATIONS_MODAL_ERROR'] = 'online/OPERATIONS_MODAL_ERROR'

actions['CAPTURE_ORDER_START'] = 'online/CAPTURE_ORDER_START'
actions['CAPTURE_ORDER_ERROR'] = 'online/CAPTURE_ORDER_ERROR'
actions['CAPTURE_ORDER_SUCCESS'] = 'online/CAPTURE_ORDER_SUCCESS'

actions['REFUND_ORDER_START'] = 'online/REFUND_ORDER_START'
actions['REFUND_ORDER_ERROR'] = 'online/REFUND_ORDER_ERROR'
actions['REFUND_ORDER_SUCCESS'] = 'online/REFUND_ORDER_SUCCESS'
actions['REFUND_INVOICE_START'] = 'online/REFUND_INVOICE_START'
actions['REFUND_INVOICE_SUCCESS'] = 'online/REFUND_INVOICE_SUCCESS'

actions['SHOW_CANCEL_ORDER_MODAL'] = 'online/SHOW_CANCEL_ORDER_MODAL'
actions['SET_CANCEL_ORDER_MODAL_STATE'] = 'online/SET_CANCEL_ORDER_MODAL_STATE'
actions['HIDE_CANCEL_ORDER_MODAL'] = 'online/HIDE_CANCEL_ORDER_MODAL'

actions['CANCEL_ORDER_ERROR'] = 'online/CANCEL_ORDER_ERROR'
actions['CANCEL_ORDER_SUCCESS'] = 'online/CANCEL_ORDER_SUCCESS'
actions['CANCEL_ORDER_START'] = 'online/CANCEL_ORDER_START'

actions['LIST_SETTLED_START'] = 'online/LIST_SETTLED_START'
actions['LIST_SETTLED_SUCCESS'] = 'online/LIST_SETTLED_SUCCESS'
actions['LIST_SETTLED_ERROR'] = 'online/LIST_SETTLED_ERROR'

actions['LIST_REFUNDS_START'] = 'online/LIST_REFUNDS_START'
actions['LIST_REFUNDS_SUCCESS'] = 'online/LIST_REFUNDS_SUCCESS'
actions['LIST_REFUNDS_ERROR'] = 'online/LIST_REFUNDS_ERROR'

actions['REFUND_INVOICE_SET_AMOUNT'] = 'online/REFUND_INVOICE_SET_AMOUNT'
actions['REFUND_INVOICE_SET_AMOUNT_VALID'] = 'online/REFUND_INVOICE_SET_AMOUNT_VALID'
actions['REFUND_INVOICE_SET_CHECKED'] = 'online/REFUND_INVOICE_SET_CHECKED'
actions['REFUND_INVOICE_SET_EXPONENT'] = 'online/REFUND_INVOICE_SET_EXPONENT'
actions['REFUND_INVOICE_CALCULATE_TOTAL'] = 'online/REFUND_INVOICE_CALCULATE_TOTAL'
actions['REFUND_INVOICE_CLEAR_STATE'] = 'online/REFUND_INVOICE_CLEAR_STATE'
actions['REFUND_INVOICE_SET_VALID'] = 'online/REFUND_INVOICE_SET_VALID'

actions['REMOVE_CURRENT_PAYMENT'] = 'online/REMOVE_CURRENT_PAYMENT'

export default actions
