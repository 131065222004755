import React from 'react'
import { TRIAL_STATES } from '../../../merchant/constants'
import { getMerchant } from '../../../reports/helpers'
import { SALES_DETAILS_TEXTS } from '../constants'
import BamboraSvgIcon from '../../../common/components/bamboraSvgIcon'

const BackBtn = ({ backText }) => {
    return (
        <div>
            <BamboraSvgIcon icon="arrow-left" className="fill-inherit back-icon" />
            <span className="after-rotate">{backText}</span>
        </div>
    )
}

const SubMenu = ({formatMessage, merchantId, merchants, goBack, className}) => {
    const merchant = getMerchant(merchantId, merchants)
    const bannerClass = TRIAL_STATES.includes(merchant.state) ? ' __banner' : ''
    const backText = formatMessage(SALES_DETAILS_TEXTS['sales_details_back'])

    return (
        <div className={`sub-menu-container small-bg ${className} sales ${bannerClass} details`}>
            <div className="sub-menu">
                <a className={'nav-link'} onClick={() => goBack()}>
                    <BackBtn backText={backText} />
                </a>
                <div className="line-vertical"></div>
                <h1>{formatMessage(SALES_DETAILS_TEXTS['transaction_details'])}</h1>
            </div>
        </div>
    )
}

export default SubMenu
