
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Icon, Button, Modal, Textfield, List } from '@frontrunners/ui-components'
import { ADD_USER_MODAL, REMOVE_USER_MODAL, RENAME_USER_MODAL } from './intlMessageKeys'
import { getAssignableUserRoles } from '../../../common/userRoles'
import './userModals.scss'

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const AddUserModal = ({ onClose, onConfirm }) => {
    const { formatMessage } = useIntl()
    const [invalidEmail, setInvalidEmail] = useState(false)
    const userRoles = getAssignableUserRoles(formatMessage)
    const givenName = useRef()
    const familyName = useRef()
    const email = useRef()
    const role = useRef(userRoles[0])

    return <Modal onClose={() => onClose()} className="add-user-modal">
        <h1>{formatMessage(ADD_USER_MODAL['modal_header'])}</h1>
        <div className="description">
            <p>{formatMessage(ADD_USER_MODAL['modal_description'])}</p>
            <div className="input-item">
                <label>{formatMessage(ADD_USER_MODAL['input_first_name'])}</label>
                <Textfield
                    inputRef={givenName}
                    placeholder={formatMessage(ADD_USER_MODAL['input_first_name'])}
                    className="compact"
                />
            </div>
            <div className="input-item">
                <label>{formatMessage(ADD_USER_MODAL['input_last_name'])}</label>
                <Textfield
                    inputRef={familyName}
                    placeholder={formatMessage(ADD_USER_MODAL['input_last_name'])}
                    className="compact"
                />
            </div>
            <div className="input-item">
                <label>{formatMessage(ADD_USER_MODAL['input_email'])}</label>
                <Textfield
                    inputRef={email}
                    onKeyUp={()=> setInvalidEmail(false)}
                    placeholder={formatMessage(ADD_USER_MODAL['input_email'])}
                    className="compact"
                    autoComplete="email"
                    type="email"
                    name="email"
                />
                {invalidEmail && <label htmlFor="email">{formatMessage(ADD_USER_MODAL['input_email_invalid'])}</label>}
            </div>
            <div className="input-item">
                <label>{formatMessage(ADD_USER_MODAL['input_role'])}</label>
                <List
                    items={userRoles}
                    multiSelect={false}
                    onChange={(selectedRole) => { role.current = selectedRole }}
                    defaultSelectedItems={[userRoles[0]]}
                    checkboxClassName="radio"
                    minSelectedItems={1}
                />
            </div>
        </div>
        <div className="buttons">
            <Button
                label={formatMessage(ADD_USER_MODAL['button_add_user'])}
                className="mint no-margin"
                onClick={() => {
                    if (EMAIL_REGEX.test(email.current.value)) {
                        onConfirm({
                            given_name: givenName.current.value,
                            family_name: familyName.current.value,
                            email: email.current.value,
                            role: role.current.value
                        })
                    } else {
                        setInvalidEmail(true)
                    }

                }}
            />
        </div>
    </Modal>
}

export const RenameUserModal = ({ user, onClose, onConfirm }) => {
    const { formatMessage } = useIntl()
    const givenName = useRef()
    const familyName = useRef()

    return <Modal onClose={() => onClose()} className="rename-user-modal">
        <h1>{formatMessage(RENAME_USER_MODAL['modal_header'])}</h1>
        <div className="description">
            <p>{formatMessage(RENAME_USER_MODAL['modal_description'])}</p>
            <div className="input-item">
                <label>{formatMessage(RENAME_USER_MODAL['input_first_name'])}</label>
                <Textfield
                    inputRef={givenName}
                    value={user.givenName}
                    placeholder={formatMessage(RENAME_USER_MODAL['input_first_name'])}
                    className="compact"
                />
            </div>
            <div className="input-item">
                <label>{formatMessage(RENAME_USER_MODAL['input_last_name'])}</label>
                <Textfield
                    inputRef={familyName}
                    value={user.familyName}
                    placeholder={formatMessage(RENAME_USER_MODAL['input_last_name'])}
                    className="compact"
                />
            </div>
        </div>
        <div className="buttons justify-center">
            <Button
                label={formatMessage(RENAME_USER_MODAL['button_save'])}
                className="mint no-margin"
                onClick={() => onConfirm({
                    id: user.id,
                    givenName: givenName.current.value,
                    familyName: familyName.current.value
                })} />
        </div>
    </Modal>
}

export const RemoveUserModal = ({ user, onClose, onConfirm }) => {
    const { formatMessage } = useIntl()
    return <Modal onClose={() => onClose()} className="remove-user-modal">
        <h1>{formatMessage(REMOVE_USER_MODAL['modal_header'])}</h1>
        <div className="description">
            <p>{formatMessage(REMOVE_USER_MODAL['modal_description'])}</p>
            <Icon
                className="check-icon"
                iconName="checkmarkRounded"
                scale="1.2"
            />
            <span>{user.email}</span>
        </div>
        <div className="buttons">
            <Button
                label={formatMessage(REMOVE_USER_MODAL['button_cancel'])}
                className="blank mint no-margin"
                onClick={() => onClose()}
            />
            <Button
                label={formatMessage(REMOVE_USER_MODAL['button_remove'])}
                className="mint no-margin"
                onClick={() => onConfirm()} />
        </div>
    </Modal>
}
