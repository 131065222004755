import React, { useState, useRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import Spinner from '../../common/components/spinner'
import requestHelper from '../../helpers/requestHelper'
import { UPDATE_MERCHANT_REQUEST as T } from './intlMessageKeys'
import { Button, Modal, Icon } from '@frontrunners/ui-components'
import { ApplicationContext } from '../../common/reactContext'

import './updateMerchantModal.scss'


export const UpdateMerchantComponent = ({ visible=true, onClose=()=>{} }) => {
    const { activeMerchant, config, loggedInUser } = useContext(ApplicationContext)

    const disableFormSubmit = loggedInUser.isSupport

    const formData = useRef({})
    const [showSpinner, setShowSpinner] = useState(false)
    const [requestSuccessful, setRequestSuccessful] = useState(false)
    const [requestFail, setRequestFail] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(0)

    const updateRequest = (merchantInfo) => {
        setRequestFail(false)
        const baseUrl = config.apiHost
        const path = `/v1/merchants/${activeMerchant.id}/adapters/email/info-update`
        const success = () => {
            setTimeout(()=>{
                setRequestSuccessful(true)
                setShowSpinner(false)
            }, 1000)
        }

        const error = () => {
            setTimeout(()=>{
                setRequestFail(true)
                setShowSpinner(false)
            }, 1000)
        }

        requestHelper(baseUrl, path, success, error, null, merchantInfo, 'POST')
    }

    const submitForm = (form) => {
        setShowSpinner(true)
        setCurrentSlide(2)

        const merchantInfo = mapFormData(form)

        updateRequest(merchantInfo)
    }

    const closeModal = () => {
        formData.current = {}
        setRequestSuccessful(false)
        setShowSpinner(false)
        setCurrentSlide(0)
        onClose()
    }

    const Slide = () => {
        const slides = [
            <InputPage
                closeModal={closeModal}
                setCurrentSlide={setCurrentSlide}
                formData={formData}
            />,
            <SummaryPage
                disableFormSubmit={disableFormSubmit}
                closeModal={closeModal}
                setCurrentSlide={setCurrentSlide}
                formData={removeUneditedFields(activeMerchant, formData.current)}
                submitForm={submitForm}
            />,
            requestSuccessful ?
                <ConfirmationPage closeModal={closeModal} /> :
                <ErrorResponseMessage closeModal={closeModal}/>
        ]
        return slides[currentSlide]
    }

    if (!visible) {
        return null
    }
    return (
        <Modal onClose={closeModal} className="update-merchant-request">
            {showSpinner ?
                <Spinner waiting={!requestSuccessful} success={requestSuccessful} error={requestFail} /> :
                <Slide />
            }
        </Modal>
    )
}

const InputPage = ({ closeModal, setCurrentSlide, formData }) => {
    const { activeMerchant } = useContext(ApplicationContext)
    const { formatMessage: fm } = useIntl()

    const [formIsUpdated, setFormIsUpdated] = useState(isFormUpdated(activeMerchant, formData.current))

    const handleFormInputChange = (element) => {
        const newFormData = {
            ...formData.current
        }
        newFormData[element.name] = element.value
        formData.current = newFormData
        setFormIsUpdated(isFormUpdated(activeMerchant, formData.current))
    }

    return (
        <>
            <h2>{fm(T.header)}</h2>

            <div className="update-merchant-request-body">
                <p>{fm(T.informationText2)}</p>

                <FormInput
                    attribute="phone"
                    attributeValue={activeMerchant.phoneNumber}
                    title={fm(T.phone)}
                    onChange={handleFormInputChange}
                    value={formData.current.phone}
                />
                <FormInput
                    attribute="street_address"
                    attributeValue={activeMerchant.billingAddress.street_address}
                    title={fm(T.street_address)}
                    onChange={handleFormInputChange}
                    value={formData.current.street_address}
                />
                <FormInput
                    attribute="city"
                    attributeValue={activeMerchant.billingAddress.city}
                    title={fm(T.city)}
                    onChange={handleFormInputChange}
                    value={formData.current.city}
                />
                <FormInput
                    attribute="postal_code"
                    attributeValue={activeMerchant.billingAddress.postal_code}
                    title={fm(T.postal_code)}
                    onChange={handleFormInputChange}
                    value={formData.current.postal_code}
                />
                <FormInput
                    attribute="country"
                    attributeValue={activeMerchant.billingAddress.country}
                    title={fm(T.country)}
                    onChange={handleFormInputChange}
                    value={formData.current.country}
                />
            </div>

            <div className="update-merchant-request-buttons">
                <Button label={fm(T.cancelButton)} className="blank cancel" onClick={() => closeModal()} />
                <Button
                    label={fm(T.reviewButton)}
                    className={`mint ${formIsUpdated ? '' : 'disabled'}`}
                    onClick={() => setCurrentSlide(formIsUpdated ? 1 : 0)}
                />
            </div>
        </>
    )
}

const SummaryPage = ({ disableFormSubmit, closeModal, setCurrentSlide, formData, submitForm }) => {
    const { activeMerchant } = useContext(ApplicationContext)
    const { formatMessage: fm } = useIntl()
    const uneditedFields = removeEditedFields(activeMerchant, formData)

    return (
        <>
            <h2>{fm(T.summaryHeader)}</h2>

            <div className="update-merchant-request-body">
                <p>{fm(T.informationText2)}</p>

                <div className="summary-section-header edited">
                    {fm(T.summaryChangedDetails)}
                </div>
                <SummaryItems formData={formData} testid="edited-details"/>

                <div className="summary-section-header unedited">
                    {fm(T.summaryUnchangedDetails)}
                </div>
                <SummaryItems formData={uneditedFields} testid="unedited-details"/>
            </div>

            <div className="update-merchant-request-buttons">
                <Button
                    label={fm(T.backButton)}
                    className="blank back align-left"
                    onClick={() => setCurrentSlide(0)}
                />
                <div className="align-right">
                    <Button label={fm(T.cancelButton)} className="blank cancel" onClick={closeModal} />
                    <Button
                        label={fm(T.submitButton)}
                        className="mint"
                        disabled={disableFormSubmit}
                        title={disableFormSubmit ? 'Disabled' : ''}
                        onClick={() => submitForm(formData)}
                    />
                </div>
            </div>
        </>
    )
}

const ConfirmationPage = ({ closeModal }) => {
    const { formatMessage: fm } = useIntl()
    return (
        <div className="update-merchant-request-confirmation">

            <Icon iconName="checkmarkRounded" scale={1.5} />

            <h2>
                {fm(T.confirmationHeader)}
            </h2>
            <p>
                {fm(T.confirmationText)}
            </p>
            <Button label={fm(T.closeButton)} className="blank" onClick={closeModal} />
        </div>
    )
}

const ErrorResponseMessage = ({ closeModal }) => {
    const { formatMessage: fm } = useIntl()
    return (
        <div className="update-merchant-request-error-response">
            <h2>{fm(T.merchantRequestFail)}</h2>
            <div>{fm(T.merchantRequestFailBody)}</div>
            <Button label={fm(T.closeButton)} className="blank" onClick={closeModal} />
        </div>
    )
}

const FormInput = ({ attribute, attributeValue, title, onChange, value }) => {
    return (
        <div className="form-input">
            <div className="form-input-title">
                {title}
            </div>
            <input
                data-testid="form-input"
                type="text"
                name={`${attribute}`}
                defaultValue={value || attributeValue}
                autoComplete="off"
                onChange={(e) => onChange(e.target)}
            />
        </div>
    )
}

const SummaryItems = ({ formData, testid }) => {
    const { formatMessage: fm } = useIntl()
    return Object.keys(formData).map((field) => {
        return (
            <div key={field} className="summary-items" data-testid={testid}>
                <div className="item-title">
                    {fm(T[field])}
                </div>
                <div className="item-value">
                    {formData[field]}
                </div>
            </div>
        )
    })
}

export const mapFormData = (formData) => {
    const addressFields = ['street_address', 'city', 'postal_code', 'country']
        .filter((field) => Object.keys(formData).includes(field))
    const merchantInfo = {}
    const billingAddress = {}

    addressFields.map((field) => {billingAddress[field] = formData[field]})

    if (Object.keys(formData).includes('phone')) {
        merchantInfo['phone'] = formData['phone']
    }

    if (addressFields.length > 0) {
        merchantInfo['billing_address'] = billingAddress
    }

    return merchantInfo
}

const currentMerchantData = (merchant) => {
    return {
        phone: merchant.phoneNumber,
        street_address: merchant.billingAddress.street_address,
        city: merchant.billingAddress.city,
        postal_code: merchant.billingAddress.postal_code,
        country: merchant.billingAddress.country
    }
}

const removeUneditedFields = (merchant, formData) => {
    const newFormData = {}

    Object.entries(currentMerchantData(merchant)).map((field) => {
        const key = field[0]
        const currentValue = field[1]
        if (formData[key] && formData[key] !== currentValue) {
            newFormData[key] = formData[key]
        }
    })

    return newFormData
}

const removeEditedFields = (merchant, formData) => {
    const currentData = currentMerchantData(merchant)

    Object.entries(formData).map((field) => {
        const key = field[0]
        delete currentData[key]
    })

    return currentData
}

const isFormUpdated = (merchant, formData) => {
    return Object.keys(removeUneditedFields(merchant, formData)).length > 0
}
