import React from 'react'
import { formatCardNumber } from '../../../common/cardHelpers'
import { getIconKey } from '../helpers'



const cardNumberStyle = {
    position: 'absolute',
    fontFamily: 'Electrolize-Regular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '26px',
    fontSize: '17px',
    letterSpacing: '0.225px',
    color: '#fff',
    marginLeft: '20px',
    marginTop: '65px',
    paddingPop: '65px'
}

const appendBackgroundImage = (style, paymentType) => {
    if(!paymentType) return style

    try {
        var bg = require(`../../../styles/img/cards/new-${paymentType}-big.svg`)
        style.backgroundImage = `url(${bg})`
    } catch {
        console.log('Error loading file')
    }
    return style
}


const CardHeader = ({cardNumber, paymentType}) => {
    let largeCardIconStyles = { height: '175px', width: '275px' }
    const payment = getIconKey(paymentType.toLowerCase())
    largeCardIconStyles = appendBackgroundImage(largeCardIconStyles, payment)
    return (
        <div className="wrapper">
            <div className="card">
                <div style={cardNumberStyle}>
                    {formatCardNumber(cardNumber)}
                </div>
                <div className="card-wrapper-new-icons">
                    <div style={largeCardIconStyles} className={`new-card-icons ${getIconKey(paymentType.toLowerCase()) || ''}`}></div>
                </div>
            </div>
        </div>
    )
}

export default CardHeader
