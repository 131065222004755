import React from 'react'
import './developerModeIndicator.scss'

export const DeveloperModeIndicator = () => {
    return (
        <div className="dev-mode-indicator">
            Developer Mode
        </div>
    )
}
