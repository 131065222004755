import * as t from './actionTypes'
import { runRequest } from './../../common/requestActionHelper'
import { createSearch, getNextPageUrl } from '../../common/locationHelpers'
import { flattenFilters, addDayToISOstring } from './helpers'

import { PAYMENTS_BASE, PAGE_LIMIT, PAGE_OFFSET, ONLINE_BASE, INSTORE_BASE, PAYMENTS_BASE_HEADERS, INCLUDE_PSP_MAPPED, EXCLUDE_PSP_MAPPED } from './../../common/constants'
import getActiveFilterTypes from './helpers/getActiveFilterTypes.js'
import { getMerchant } from '../../common/helpers/findMerchant'
import { mixpanelPrefix } from '../../common/helpers/mixpanelPrefix'
import { DataAvailableFromDate } from '../../common/dateHelpers'

const BASE_URLS = {
    acquiring: PAYMENTS_BASE,
    online: ONLINE_BASE,
    instore: INSTORE_BASE
}

export const showSalesView = (views, activeView) => {
    return {
        type: t.SHOW_SELECTED_SALES_VIEW,
        payload: {
            views,
            activeView
        }
    }
}

export const load = (activeView) => {
    const baseUrl = BASE_URLS[activeView]
    const scopedType = (actionType) => `${activeView}/${actionType}`
    const actions = {
        filterChange: (filterType, filterValue) => {
            let mixpanel_data = {}

            if (filterValue.length > 0) {
                mixpanel_data = {
                    event: `${mixpanelPrefix.SALES} Filter added in ${activeView} view.`,
                    props: { 'filterType': filterType }
                }
            }

            return {
                type: scopedType(t.CHANGE_FILTER),
                payload: {
                    filterType,
                    filterValue
                },
                meta: {
                    mixpanel: mixpanel_data
                }
            }
        },
        toggleFilter: (filterType, opened) => {
            return {
                type: scopedType(t.TOGGLE_FILTER),
                payload: {
                    filterType,
                    opened
                }
            }
        },
        clearFilter: (merchantId, activeView, filterType) => {
            return (dispatch, getState) => {
                dispatch({
                    type: scopedType(t.CLEAR_FILTER),
                    payload: {
                        filterType
                    }
                })

                const filters = getState().getIn([merchantId, activeView, 'filters'])
                const flattenedFilters = flattenFilters(filters.toJS())
                const merchant = getState().get('merchant')
                const merchantObject = getMerchant(merchantId, merchant)
                dispatch(actions.getList(merchantObject, merchantId, flattenedFilters))
            }
        },
        clearAllFilters: (merchantId, refetch=true) => {
            return (dispatch, getState) => {
                dispatch({
                    type: scopedType(t.CLEAR_ALL_FILTERS)
                })

                const filters = getState().getIn([merchantId, activeView, 'filters'])
                const flattenedFilters = flattenFilters(filters.toJS())
                const merchant = getState().get('merchant')
                const merchantObject = getMerchant(merchantId, merchant)
                if (refetch) {
                    dispatch(actions.getList(merchantObject, merchantId, flattenedFilters))
                }
            }
        },
        applyFilter: (activeView, filterType, filterValue) => {
            return (dispatch) => {
                dispatch({
                    type: scopedType(t.APPLY_DATE_FILTER),
                    payload: {
                        filterType: filterType,
                        filterValue: filterValue
                    }
                })
            }
        },

        getDetailsStart: () => {
            return {
                type: scopedType(t.GET_DETAILS_START)
            }
        },

        getDetailsSuccess: (payment) => {
            return {
                type: scopedType(t.GET_DETAILS_SUCCESS),
                payload: { payment },
                meta: {
                    mixpanel: {
                        event: `Sucessfully fetched ${activeView} payment details`
                    }
                }
            }
        },

        getDetailsError: () => {
            return {
                type: scopedType(t.GET_DETAILS_ERROR),
                meta: {
                    mixpanel: {
                        event: `Error when fetching ${activeView} payment details`
                    }
                }
            }
        },

        getDetails: (merchantId, paymentId) => {
            const url = `${baseUrl}/${paymentId}`
            return runRequest({
                startAction: () => actions.getDetailsStart(url),
                successAction: (payment) => {
                    return (dispatch) => dispatch(actions.getDetailsSuccess(payment))
                },
                errorAction: actions.getDetailsError,
                requestObj: {
                    url,
                    headers: PAYMENTS_BASE_HEADERS,
                    merchantId
                }
            })
        },
        setCurrent: (transaction) => {
            return {
                type: scopedType(t.SET_CURRENT),
                payload: {
                    transaction: transaction
                }
            }
        },

        getNextPageList: (merchantId, search, merchantObject = null) => {
            const defaults = {
                offset: PAGE_OFFSET + PAGE_LIMIT,
                limit: PAGE_LIMIT,
                from_datetime: DataAvailableFromDate.instance.getDatetimeIsoStringUTC()
            }
            const mergedSearch = Object.assign({}, defaults, search)
            const nextPageUrl = getNextPageUrl(createSearch(mergedSearch), defaults)

            let psp_mapped = ''

            if (activeView === 'acquiring') {
                // We only want to include_psp_mapped transaction for instore merchant
                // who who NOT instore_eligible
                if (merchantObject.system_set.find(system => system.system === 'samport') && !merchantObject.extra_data.instore_eligible) {
                    psp_mapped = INCLUDE_PSP_MAPPED
                } else if (merchantObject.features && merchantObject.features.include_psp_mapped_acquiring_transactions){
                    psp_mapped = INCLUDE_PSP_MAPPED
                } else {
                    psp_mapped = EXCLUDE_PSP_MAPPED
                }
            }

            const url = `${baseUrl}${nextPageUrl}${psp_mapped}`

            return runRequest({
                successAction: (transactions) => {
                    return actions.getNextPageListSuccess(merchantId, transactions)
                },
                errorAction: actions.listError,
                requestObj: {
                    url,
                    merchantId,
                    cache: true
                }
            })
        },
        getNextPageListSuccess: (merchantId, transactions = {
            data: []
        }) => {
            return {
                type: scopedType(t.LIST_NEXT_PAGE_SUCCESS),
                payload: {
                    transactions,
                    merchantId
                }
            }
        },

        getListStart: (url, hasActiveFilters, filters) => {
            let mixpanel_event

            const activeFilterTypes = getActiveFilterTypes(activeView, filters)

            if (activeFilterTypes !== null) {
                mixpanel_event = {
                    event: `${mixpanelPrefix.SALES} Starting to list sales and apply filter(s) in ${activeView} view.`,
                    props: activeFilterTypes
                }
            } else {
                mixpanel_event = {
                    event: `${mixpanelPrefix.SALES} Starting to list sales in ${activeView} view.`
                }
            }

            return {
                type: scopedType(t.LIST_START),
                payload: {
                    url,
                    hasActiveFilters
                },
                isFetching: true,
                meta: {
                    mixpanel: mixpanel_event
                }
            }
        },
        getListSuccess: (merchantId, transactions, search, merchantObject = null, filters) => {
            let mixpanel_event

            const activeFilterTypes = getActiveFilterTypes(activeView, filters)

            if (activeFilterTypes !== null) {
                mixpanel_event = {
                    event: `${mixpanelPrefix.SALES} Successfully listed sales and applied filter(s) in ${activeView} view.`,
                    props: activeFilterTypes
                }
            } else {
                mixpanel_event = {
                    event: `${mixpanelPrefix.SALES} Successfully listed sales in ${activeView} view.`
                }
            }

            return dispatch => {
                dispatch({
                    type: scopedType(t.LIST_SUCCESS),
                    payload: {
                        transactions,
                        search
                    },
                    meta: {
                        mixpanel: mixpanel_event
                    }
                })

                dispatch(actions.getNextPageList(merchantId, search, merchantObject))
            }
        },


        getList: (merchantObject, merchantId, filters, hasActiveFilters = false) => {
            if (['instore', 'online'].includes(activeView)) {
                const { source_merchant_id, ...rest } = filters
                if (source_merchant_id) filters = { source_merchant_ids: source_merchant_id, ...rest }
            }
            const defaults = {
                offset: PAGE_OFFSET,
                limit: PAGE_LIMIT,
                from_datetime: DataAvailableFromDate.instance.getDatetimeIsoStringUTC()
            }

            let flattenedFilters = flattenFilters(filters)
            if (flattenedFilters.to_datetime) {
                flattenedFilters.to_datetime = addDayToISOstring(flattenedFilters.to_datetime)
            }

            const mergedSearch = Object.assign({}, defaults, flattenedFilters)
            const fullSearch = createSearch(mergedSearch)
            let psp_mapped = ''

            if (activeView === 'acquiring') {
                // We only want to include_psp_mapped transaction for instore merchant
                // who who NOT instore_eligible
                if (merchantObject.system_set.find(system => system.system === 'samport') && !merchantObject.extra_data.instore_eligible) {
                    psp_mapped = INCLUDE_PSP_MAPPED
                } else if (merchantObject.features && merchantObject.features.include_psp_mapped_acquiring_transactions){
                    psp_mapped = INCLUDE_PSP_MAPPED
                } else {
                    psp_mapped = EXCLUDE_PSP_MAPPED
                }
            }

            const url = `${baseUrl}${fullSearch}${psp_mapped}`
            const cache = activeView === 'online' ? false : true

            return runRequest({
                startAction: () => actions.getListStart(url, hasActiveFilters, filters),
                successAction: (transactions) => actions.getListSuccess(
                    merchantId,
                    transactions,
                    flattenedFilters,
                    merchantObject,
                    filters
                ),
                outOfRangeAction: () => actions.getListOutOfRange(merchantId),
                errorAction: actions.listError,
                requestObj: {
                    url,
                    cache,
                    merchantId
                }
            })
        },
        getListOutOfRange: (merchantId) => {
            return {
                type: scopedType(t.LIST_OUT_OF_RANGE),
                payload: {
                    merchantId
                }
            }
        },
        setDefaultPagination: () => {
            return {
                type: scopedType(t.RESET_PAGINATION)
            }
        },
        listError: () => {
            return {
                type: scopedType(t.LIST_ERROR),
                isFetching: false,
                isError: true,
                meta: {
                    mixpanel: {
                        event: `Error when fetching ${activeView} payments for list view`
                    }
                }
            }
        }
    }
    return actions
}

export const setActiveView = (activeView) => {
    return {
        type: t.SET_ACTIVE_VIEW,
        payload: {
            activeView
        }
    }
}
