import { defineMessages } from 'react-intl'

export const SUPPORT_TEXT = defineMessages({
        'contact-card-header': {
            id: 'header',
            defaultMessage: 'Contact us',
            description: 'Header for the contact info card'
        },
        'contact-email': {
            id: 'email',
            defaultMessage: 'Email',
            description: 'Contact email'
        },
        'contact-phone': {
            id: 'phone',
            defaultMessage: 'Phone',
            description: 'Contact phone'
        },
        'toc-header': {
            id: 'toc-header',
            defaultMessage: 'Terms and conditions',
            description: 'Terms and conditions support card header'
        },
        'toc-body': {
            id: 'toc-body',
            defaultMessage: 'Legal information on our products and services',
            description: 'Terms and conditions support card body'
        },
        'button-text-read': {
            id: 'read',
            defaultMessage: 'Read',
            description: 'Text for Read button'
        },
        'chat-header': {
            id: 'chat-header',
            defaultMessage: 'Chat with us',
            description: 'Chat support card header'
        },
        'chat-body': {
            id: 'chat-body',
            defaultMessage: 'Get in touch with one of our support agents',
            description: 'Chat support card body'
        },
        'button-text-chat': {
            id: 'chat',
            defaultMessage: 'Chat',
            description: 'Text for Chat button'
        },
        'faq-header': {
            id: 'faq-header',
            defaultMessage: 'FAQ',
            description: 'FAQ (Frequently asked questions) header'
        },
        'faq-body': {
            id: 'faq-body',
            defaultMessage: 'Read the answers to the most frequent questions',
            description: 'FAQ (Frequently asked questions) body'
        },
        'welcome-guide-header': {
            id: 'welcome-guide-header',
            defaultMessage: 'Welcome Guide',
            description: 'Welcome Guide header'
        },
        'welcome-guide-body': {
            id: 'welcome-guide-body',
            defaultMessage: 'A brief walk-through that helps you get up to speed',
            description: 'Welcome Guide body'
        },
        'button-text-start': {
            id: 'start',
            defaultMessage: 'Start',
            description: 'Text for Start button'
        }
})
