import React from 'react'
import uuid from 'uuid'
import MonetaryAmount from '../../../common/components/monetaryAmount'
import { getIconKey } from '../../sales/helpers'
import moment from 'moment'
import { TRANSACTION_TYPE } from '../../../settlements/constants'
import { getAmexTranslation } from '../../../common/cardHelpers'


const TransactionListItem = ({ transaction, merchant, onClick, formatMessage }) => {
    return (
        <div className="acquiring-list-item" key={uuid.v4()} onClick={onClick}>
            <span className="element-column date-time">
                {moment(transaction.date_time_local).format('YYYY-MM-DD HH:mm:ss')}
            </span>
            <span className="element-column merchant-reference">
                {customer_type_reference(transaction, merchant)}
            </span>
            <span className="element-column payment-method">
                <div className={`new-card-icons ${getIconKey(transaction.card_scheme.toLowerCase())}`}></div>
                <div>{transaction.card_number.substr(transaction.card_number.length - 4)}</div>
            </span>
            <span className="element-column type">
                {switchFormattedMessages(transaction.type, formatMessage)}
            </span>
            <span className="element-column status">
                {formatMessage(getAmexTranslation(transaction))}
            </span>
            <span className="element-column currency">
                {transaction.currency}
            </span>
            <span className="element-column amount">
                <MonetaryAmount amount={transaction.amount} amount_exponent={transaction.amount_exponent} className="fg-darkest" hideInsignificantDecimals={false} />
            </span>
        </div>
    )
}

export const switchFormattedMessages = function (type, formatMessage) {
    switch (type) {
        case 'Sale':
            return formatMessage(TRANSACTION_TYPE['sale'])
        case 'Refund':
            return formatMessage(TRANSACTION_TYPE['refund'])
        case 'Original credit':
            return formatMessage(TRANSACTION_TYPE['original-credit'])
        case 'Cash advance':
            return formatMessage(TRANSACTION_TYPE['cash-advance'])
        case 'Invalid':
            return formatMessage(TRANSACTION_TYPE['invalid'])
    }
}

export const customer_type_reference = (transaction, merchant) => {
    if (merchant.customer_type == 'dach' && transaction.reference_additional_1) {
        return transaction.reference_additional_1
    } else {
        return transaction.reference
    }
}

export default TransactionListItem
