import { connect } from 'react-redux'
import { Component } from 'react'
import { ROLES } from '../../merchant/constants'
import { withRouter } from 'react-router-dom'


class RoleToggle extends Component {
    // gets merchant id from route and merchat from state and
    // checks if the current logged in user has role in allowed
    render() {
        const merchantId = this.props.match.params.merchantId
        const merchant = this.props.merchants.find(m => m.id === merchantId) || {}
        const role = merchant.loggedInUserRole || ROLES.USER
        const showChildren = this.props.allow.indexOf(role) >= 0
        if (!showChildren) return false
        return this.props.children
    }
}

function mapStateToProps(state) {
    return {
        merchants: state.getIn(['merchant', 'merchants'])
    }
}

export default connect(mapStateToProps)(withRouter(RoleToggle))

export const RoleToggle2 = ({ userRole, allow, children, fallback }) => {
    const role = userRole || ROLES.USER
    if (allow.includes(role)) {
        return children
    }
    if (fallback) {
        return fallback
    }
    return false
}
