import { defineMessages } from 'react-intl'

export const CUSTOMER_FEEDBACK_WINDOW = defineMessages({
    // Page 1: Customer feedback
    'customer_feedback_window_header': {
        id: 'customer-feedback-window-header',
        defaultMessage: 'Help us improve the merchant portal?',
        description: 'Header to collect customer feedback'
    },
    'customer_feedback_window_description': {
        id: 'customer-feedback-window-description',
        defaultMessage: 'Help us improve your experience of the merchant portal. Submit your interest and we may contact you for a short interview.',
        description: 'Description text to collect customer feedback'
    },
    'customer_feedback_window_opt_in_button': {
        id: 'customer-feedback-window-opt-in-button',
        defaultMessage: 'Contact me',
        description: 'Button to opt in for customer feedback'
    },
    'customer_feedback_window_opt_out_button': {
        id: 'customer-feedback-window-opt-out-button',
        defaultMessage: 'Not now',
        description: 'Button to opt out for customer feedback'
    },
    // Page 2: Success message
    'customer_feedback_window_success_header': {
        id: 'customer-feedback-window-success-header',
        defaultMessage: 'Thank You!',
        description: 'Header text after the user has opted in for customer feedback'
    },
    'customer_feedback_window_success_description': {
        id: 'customer-feedback-window-success-description',
        defaultMessage: 'We may be in touch with you shortly using the email address associated with your account.',
        description: 'Description text after the user has opted in for customer feedback'
    },
    'customer_feedback_window_success_close': {
        id: 'customer-feedback-window-success-close',
        defaultMessage: 'Close',
        description: 'Button to close the success message'
    }


})
