import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { ApplicationContext } from '../../common/reactContext'
import { Dropdown } from '@frontrunners/ui-components'
import { getUpdateCheckoutMutation } from './epayQueries'
import { CHECKOUT_TRANSACTION_FEE_DROPDOWN as T } from './intlMessageKeys'

import './checkoutTransactionFeeDropdown.scss'

export const CheckoutTransactionFeeDropdown = ({ activeCheckout, onChange }) => {
    const { formatMessage: fm } = useIntl()
    const queryClient = useQueryClient()

    const { config, activeMerchant, loggedInUser } = useContext(ApplicationContext)
    const { mutateAsync: updateCheckout } = getUpdateCheckoutMutation(queryClient, config.apiHost, activeMerchant.id, activeCheckout)

    const transactionFeeList = getTransactionFeeList({ formatMessage: fm })
    const currentTransactionFeeOption = activeCheckout.checkout?.addfee

    const selectedItem = transactionFeeList.find(item => item.value === currentTransactionFeeOption)

    return <div className="checkout-transaction-fees">
        <h4>{fm(T.header)}</h4>
        <Dropdown
            className="checkout-transaction-fee-dropdown"
            label="Single"
            multiSelect={false}
            items={transactionFeeList}
            minSelectedItems={1}
            onChange={async (item) => {
                if (loggedInUser.isSupport) {
                    return
                }
                const checkout = await updateCheckout({ transactionFeeOption: item.value })
                onChange(checkout)
            }}
            defaultSelectedItems={[selectedItem || transactionFeeList[0]]}
        />
    </div>
}

const getTransactionFeeList = ({ formatMessage: fm }) => [
    {
        id: 1,
        text: fm(T.txn_fee_on_merchant),
        value: false
    },
    {
        id: 2,
        text: fm(T.txn_fee_on_customer),
        value: true
    }
]
