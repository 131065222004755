import React from 'react'
import FilterMobile from './filterMobile'
import { FILTER_BUTTON_TEXT } from '../../sales/sales/constants'
import { injectIntl } from 'react-intl'
import BamboraSvgIcon from './bamboraSvgIcon'
import Modal from 'react-bootstrap/Modal'
import './filterModal.scss'

const FilterModalHeader = ({ toggleFilter, formatMessage, renderClearAll, isMobile }) => {
    return (
        <React.Fragment>
            <h3>{formatMessage(FILTER_BUTTON_TEXT['filters'])}</h3>
            {renderClearAll && isMobile && renderClearAll()}
            <div className="close-modal" onClick={() => toggleFilter()}>
                <BamboraSvgIcon className="fill-inherit" icon={'close-thin'} />
            </div>
        </React.Fragment>
    )
}

const _isAnyActive = (filters, availableFilters, isMobile, isDateSelected) => {

    if (filters && filters.size) filters = filters.toJS()
    let hasFilter = false
    if (isMobile) hasFilter = isDateSelected()
    if (!hasFilter) {
        hasFilter = !!availableFilters.find((item) => {
            return filters && filters[item.filterType] &&
                (filters[item.filterType].length ||
                    (filters[item.filterType]['selected'] && filters[item.filterType]['selected'].length)
                )
        })
    }

    return hasFilter
}

const Button = ({ btnClassName, btnText, onClickFunc }) => (
    <button className={btnClassName} onClick={() => onClickFunc()}>
        {btnText}
    </button>
)

const Header = ( {formatMessage, isMobile, toggleCombineFilter, renderClearAll} ) => (
    <Modal.Header>
        <FilterModalHeader formatMessage={formatMessage} isMobile={isMobile} toggleFilter={toggleCombineFilter} renderClearAll={renderClearAll} />
    </Modal.Header>
)

const Body = ( {isMobile, renderDateFilter, availableFilters, onFilterChange} ) => (
    <Modal.Body>
        {isMobile && <div className="filter-modal__date"> {renderDateFilter()}</div>}
        <div>
            {availableFilters.map(item => <FilterMobile key={item.filterType} {...item} onChange={onFilterChange.bind(null, item.filterType)} />)}
        </div>
    </Modal.Body>
)

const FilterModal = ({ intl, onFilterChange, toggleCombineFilter, opened, filters, availableFilters, moreFilters, isMobile, renderClearAll, renderDateFilter, isDateSelected }) => {
    const { formatMessage } = intl
    const modalOpen = !!opened
    const filterButtonActiveClass = _isAnyActive(filters, availableFilters, isMobile, isDateSelected) ? 'active-filter' : ''
    let btnText = formatMessage(FILTER_BUTTON_TEXT['filters'])
    btnText = moreFilters ? `+ ${btnText}` : btnText
    const btnClassName = `btn toggle-filter ${filterButtonActiveClass} btn-small`
    const applyBtnText = formatMessage(FILTER_BUTTON_TEXT['apply'])

    if (!availableFilters || !availableFilters.length) return ''

    return (
        <div className={`filters-sales--combined ${isMobile ? 'filters-sales--combined-mobile' : ''}`}>
            <Button btnClassName={btnClassName} btnText={btnText} onClickFunc={toggleCombineFilter} />
            <Modal show={modalOpen} onHide={() => toggleCombineFilter()} className="filter-modal">
                <Header formatMessage={formatMessage} isMobile={isMobile} toggleCombineFilter={toggleCombineFilter} renderClearAll={renderClearAll}/>
                <Body isMobile={isMobile} renderDateFilter={renderDateFilter} availableFilters={availableFilters} onFilterChange={onFilterChange} />
                <Button btnClassName="rebranded-primary-btn filter-modal__apply-btn" btnText={applyBtnText} onClickFunc={toggleCombineFilter} />
            </Modal>
        </div>
    )
}

export default injectIntl(FilterModal)
