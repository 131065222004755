import React from 'react'
import moment from 'moment'
import { parseLocalizedIsoString } from '../dateHelpers'

const FormattedDate = ({date, elementClass=''}) => {
    if(!date || typeof date !== 'string') return ''

    let dateObject = parseLocalizedIsoString(date)
    let dateString = moment(dateObject).format('YYYY-MM-DD')

    return (
        <span className={elementClass}>{dateString}</span>
    )
}

export default FormattedDate
