import React, { Component } from 'react'
import {injectIntl} from 'react-intl'
import uuid from 'uuid'
import ReportForCountry from '../components/invoiceReportForCountryComponent'
import Spinner from '../../common/components/spinner'


class InvoiceDetailComponent extends Component {

    render() {
        const activeReportJson = this.props.activeReportJson.get('data')
        const isFetching = this.props.activeReportJson.get('isFetching')

        const countryReports = !!activeReportJson && !!activeReportJson.countries &&  Object.values(activeReportJson.countries)

        return (
            <div className="invoice-detail">
            {
                isFetching &&
                <div>
                    <Spinner waiting={true}/>
                </div>
            }
            { !isFetching && countryReports && countryReports
                .map(countryReport => <ReportForCountry key={uuid.v4()} countryReport={countryReport} report={this.props.report}/> )
            }
            </div>
        )
    }
}

export default injectIntl(InvoiceDetailComponent)
