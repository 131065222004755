import { defineMessages } from 'react-intl'


export const NAVLINK_TEXTS = defineMessages({
    'navbar-main-link-overview': {
        id: 'navbar-main-link-overview',
        defaultMessage: 'Overview',
        description: 'Main navbar link for the merchant dashboard'
    },
    'navbar-main-link-sales': {
        id: 'navbar-main-link-sales',
        defaultMessage: 'Sales',
        description: 'Main navbar link for transactions'
    },
    'navbar-main-link-payouts': {
        id: 'navbar-main-link-settlements',
        defaultMessage: 'Payouts',
        description: 'Main navbar link for settlements'
    },
    'navbar-main-link-loans': {
        id: 'navbar-main-loans',
        defaultMessage: 'Loans',
        description: 'Main navbar link for loans'
    },
    'navbar-main-link-reports': {
        id: 'navbar-main-reports',
        defaultMessage: 'Reports',
        description: 'Main navbar link for reports'
    },
    'navbar-main-link-report': {
        id: 'dashboard-reports-dropdown-nav-title',
        defaultMessage: 'Monthly reports',
        description: 'Montly reports list nav menu elements'
    },
    'navbar-main-link-settings': {
        id: 'navbar-merchant-settings-link',
        defaultMessage: 'Settings',
        description: 'Settings'
    },
    'navbar-main-link-support': {
        id: 'navbar-merchant-support',
        defaultMessage: 'Support',
        description: 'Link to support information'
    },
    'navbar-main-link-invoices': {
        id: 'navbar-merchant-settings-invoices',
        defaultMessage: 'Invoices',
        description: 'Link to page where invoice statements are available'
    },
    'navbar_main_link_online_portal': {
        id: 'online',
        defaultMessage: 'Online',
        description: 'Link text to open the online portal'
    },
    'navbar-main-link-switch': {
        id: 'navbar-merchant-switch-merchant',
        defaultMessage: 'Switch merchant',
        description: 'Switch merchant'
    },
    'navbar-merchant-log-out-button': {
        id: 'navbar-merchant-log-out-button',
        defaultMessage: 'Sign out',
        description: 'Link to log out of the application'
    },
    'navbar-no-merchant-name-edge-case':{
        id:'navbar-no-merchant-name-edge-case',
        defaultMessage: 'Unknown merchant',
        description: 'Merchant filler text when we have incompletet or missing merchant data'
    }
})
