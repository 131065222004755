// NOTE: start WIP
import React from 'react'
import OnlineListItem from './onlineListItem'
import OnlineListHeaders from './onlineListHeaders'
import Spinner from '../../../common/components/spinner'
import EmptyState from '../../../common/components/emptyState'
import { SALES_TYPES, SALES_EMPTY_FILTER_TITLE, SALES_EMPTY_FILTER_BODY } from '../../sales/constants'
import CommonErrorComponent from '../../../common/commonErrorComponent'
import { getMerchant } from '../../../reports/helpers'


const OnlineList = (props) => {
    if (!props.list) {
        return (null)
    }

    let orders = props.list.get('orders')
    const isFetching = props.list.get('isFetching')
    const offset = props.filter.get('offset')
    const merchant = getMerchant(props.merchant, props.merchants)

    if (isFetching && offset === 0) {
        return (
            <div className="orders-list">
                <Spinner waiting={isFetching} />
            </div>
        )
    }

    if (props.list.get('isError')) {
        return <CommonErrorComponent />
    }

    if (props.list.get('emptyList')) {
        return (
            <EmptyState>
                <h2 className="text-center">
                    {props.formatMessage(SALES_EMPTY_FILTER_TITLE['empty_filter'])}
                </h2>
                <p className="text-center">
                    {props.formatMessage(SALES_EMPTY_FILTER_BODY[props.salesType])}
                </p>
            </EmptyState>
        )
    }

    if (!orders) {
        return null
    }

    return (
        <div className="online-sales">
            <OnlineListHeaders />
            {orders.map((order) =>
                <OnlineListItem showOperationsModal={props.showOperationsModal}
                    formatMessage={props.formatMessage}
                    order={order}
                    key={order.id}
                    onClick={props.pickPage.bind(null, SALES_TYPES.online, order)}
                    merchant={merchant} />
            )}
            {isFetching && <Spinner waiting={isFetching} />}
        </div>
    )
}

export default OnlineList
// NOTE: end WIP
